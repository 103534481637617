<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация 
      .col.col-md-auto.ms-md-auto
        transition-group(name="translucent")
          template
      .col-14
       AppNavDocs
       .NavChart.NavNoticesAdmin.mt-0.mb-0
            span.tabs-nav__button.button(@click="selectDocType('health_certificate')" :class="{active: filters.document_type == 'health_certificate'}") О здоровье
            span.tabs-nav__button.button(@click="selectDocType('injury_certificate')" :class="{active: filters.document_type == 'injury_certificate'}") О травме
            span.tabs-nav__button.button(@click="selectDocType('hti_certificate')" :class="{active: filters.document_type == 'hti_certificate'}") Результаты ХТИ
            span.tabs-nav__button.button(@click="selectDocType('medical_exam_conclusion')" :class="{active: filters.document_type == 'medical_exam_conclusion'}") Медосмотры
            span.tabs-nav__button.button(@click="selectDocType('medical_exam_certificate')" :class="{active: filters.document_type == 'medical_exam_certificate'}") Акт МО
            span.tabs-nav__button.button(@click="selectDocType('client_request')" :class="{active: filters.document_type == 'client_request'}") Обращение клиента  

  .profile-patients__content.mt-2.position-relative
    transition-group(name="translucent")
      template
        app-loading(v-if="isLoading" key='loading')
        app-error-message(v-if="error" :message="error")
        //- div(v-if="patients")
        div(key="oneadad")
          template
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body#incoming
                li.profile-table__row.profile-table__row--notice(v-for='item in results')
                  client-notice(
                    :data="item"
                    @open="openModal('handlerDoc')"
                    :changeDocStatus="changeDocStatus"
                  )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          //- template(v-else)
          //-   .text-color--blue.text-size--small Пользователи не найдены

  // filter
  app-filter-page-incoming(
    @submit="submitFilterDoc"
  )
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    actionTypes
} from '@/store/modules/patients';

import incomingMedDocs from '@/api/incomingMedDocs';

import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
//import AppGallery from '@/components/common/Gallery';

import AppFilter from '@/components/profile/admin/docs/FilterDocs';
import AppFilterPageIncoming from '@/components/FilterPageIncoming';
import ClientNotice from '@/components/profile/admin/docs/AdminPatientNotice';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import NavNoticesAdmin from '@/components/profile/common/NavNoticesAdmin';
import AppNavDocs from '@/components/common/NavDocsAdmin';
import {
    filterMixin
} from '@/mixins/filter';

import {modalsMixin} from '@/mixins/modals';
import useVuelidate from '@vuelidate/core';
export default {
    name: 'Pageincoming',
    components: {
        AppModal,
        AppTitle,
        AppSelect,
        AppSvgIcon,
        AppLoading,
        AppErrorMessage,
        AppPagination,
        AppFilter,
        // AppGallery,
        ClientNotice,
        AppPatientChangeAvatarModal,
        NavNoticesAdmin,
        AppNavDocs,
        AppFilterPageIncoming
    },
    mixins: [filterMixin, modalsMixin],
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            form: {
                file: null,
            },
            v: variables,
            filterOptions: {
                patients: true,
                date: true,
                methods: {
                    getData: ['getIncomingDocs'],
                    getCount: 'getCountPatients',
                },
            },
            pagination: {
                limit: 12,
                total: 10,
            },
            sortKey: 'create_datetime',
            sortInverse: true,
            sortOptions: [{
                    caption: 'дате регистрации',
                    value: 'creation_date',
                    selected: true,
                    inverse: true,
                },
                {
                    caption: 'e-mail',
                    value: 'email',
                    selected: false,
                    inverse: false,
                },
                {
                    caption: 'ФИО пользователя',
                    value: 'full_name',
                    selected: false,
                    inverse: false,
                },
                {
                    caption: 'кол-ву осмотров',
                    value: 'exam_count',
                    selected: false,
                    inverse: true,
                },
            ],
            columns: [{
                    class: 'col-auto',
                    caption: '№',
                    key: 'id',
                    inverse: false,
                },
                {
                    class: 'col-2',
                    caption: 'Статус',
                    key: 'document_status',
                    inverse: false,
                },
                {
                    class: 'col-2',
                    caption: 'Дата и время',
                    key: 'create_datetime',
                    inverse: true,
                },
                {
                    class: 'col-2',
                    caption: 'Организация ',
                    key: 'company_name',
                    inverse: false,
                },
                {
                    class: 'col-3',
                    caption: 'Фио пользователя',
                    key: 'patient_full_name',   
                    inverse: true,
                },
                {
                    class: 'col-2',
                    caption: 'Дата выдачи',
                    key: 'issue_date',
                    inverse: true,
                },
                 {
                    class: 'col-auto',
                    caption: 'Срок действия',
                    key: 'expiration_date',
                    inverse: true,
                }
            ],
            isModalVisible: {
                changeAvatar: false,
                deleteAvatar: false,
                addPdn: false,
                handlerDoc:false,
                handlerSelect: false
            },
            results: [

            ],
            successMessage: 'Успех!',
            filter: {},
            filters: {
                document_type: "health_certificate"
            }
        };
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.patients.isLoading,
            patients: (state) => state.patients.data,
            patientsCount: (state) => state.patients.count,
            error: (state) => state.patients.error,
        }),
        currentPage() {
            return Number(this.$route.query.page || '1');
        },
        baseUrl() {
            return this.$route.path;
        },
        offset() {
            return this.currentPage * this.pagination.limit - this.pagination.limit;
        },
        settings() {
            return {
                filters: {
                    patients: {
                        negative: false,
                        payload: [],
                    },
                },
                sorting_params: {
                    desc: this.sortInverse,
                    field: this.sortKey,
                },
            };
        },
    },
    watch: {
        currentPage() {
            this.getIncomingDocs(this.filters, this.pagination.limit, this.offset);
        },
    },
    created() {
        this.getIncomingDocs(this.filters, this.pagination.limit, this.offset);
    },
    mounted() {
        //this.$store.dispatch("actionGetDoc", {});
        if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
    },
    methods: {
        changeDocStatus() {
            console.log('doc status changed');
            this.getIncomingDocs(this.filters, this.pagination.limit, this.offset);
        },
        selectDocType(newDocType) {
            console.log(newDocType);
            this.filters.document_type = newDocType;
            this.getIncomingDocs();
        },
        goToFirstPage() {
            if (this.currentPage !== 1) this.$router.push({
                query: {
                    page: '1'
                }
            });
        },
        async getIncomingDocs(filters, limit, offset) {
            this.filter.paging_params = {
                limit,
                offset
            }
            this.filter.sorting_params = {
              desc: this.sortInverse,
              field: this.sortKey
            }
            console.log(this.filters);
            let data = await incomingMedDocs.getIncomingMedDocument({
                "filters": this.filters,
                "paging_params": this.filter.paging_params,
                "sorting_params": this.filter.sorting_params
            });
            console.log(data);
            this.results = data.data.result;
            this.pagination.total = data.data.total;
            this.pagination.limit = data.data.limit;
        },
        getCountPatients(filters) {
            let data = {
                filters: Object.assign({}, this.settings.filters, filters),
                sorting_params: this.settings.sorting_params,
            };

            this.$store.dispatch(actionTypes.getCountPatients, data);
        },
        sortBySelect({
            value,
            inverse
        }) {
            this.sortOptions.forEach((el) => (el.selected = el.value === value));
            this.sortingPatients(value, inverse);
        },
        sortingPatients(sortKey, inverse) {
            this.sortKey = sortKey;
            this.sortInverse = inverse;
            this.getIncomingDocs(this.filter, this.pagination.limit, this.offset);
        },
        submitFilterDoc(filters) {
          console.log(214);
          console.log('data', filters)
          filters['document_type'] = this.filters['document_type']
          this.filters = filters    
          this.getIncomingDocs(this.filter, this.pagination.limit, this.offset);
          
        },
        clearClose(){
          this.isFilterOpen = false
        }
    },
};
</script>
<style lang="scss">
    .profile-table__row--notice{
        .accordion__button{
            top: 21px;
        }
    }
</style>
