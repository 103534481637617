<template lang="pug">
.profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
  .profile-patients__header
    .row.align-items-center
      .col-14.col-md-auto
        app-title(
          :type="'large'"
          :classes="'text-center mb-3 mb-md-0'"
        ) Медицинская документация 
      .col-12
        nav-notices  

  .profile-patients__content.mt-20.position-relative
    transition-group(name="translucent")
      template
        app-loading(v-if="isLoading" key='loading')
        app-error-message(v-if="error" :message="error")
        //- div(v-if="patients")
        div(key="oneadad")
          template
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body(v-if='$store.state.documentation.inquiries.inquiries && $store.state.documentation.inquiries.inquiries.result.length>0')
                li.profile-table__row(v-for='item in $store.state.documentation.inquiries.inquiries.result')
                  client-notice(
                    :data="item"
                  )
            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="$store.state.documentation.inquiries.inquiries.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          //- template(v-else)
          //-   .text-color--blue.text-size--small Пользователи не найдены

  // filter
  app-filter(
    ref="filter"
    :options="filterOptions"
    :is-open="isFilterOpen"
    :is-filtered="isFiltered"
    @getCount="getCountFilter"
    @open="openFilter"
    @clearClose="clearClose"
    @clear="clearFilter"
    @submit="submitFilterDoc"
    @closeFilter="clearClose"
  )
</template>

<script>
import {
    mapState
} from 'vuex';
import {
    actionTypes
} from '@/store/modules/patients';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
//import AppGallery from '@/components/common/Gallery';

import AppFilter from '@/components/profile/common/FilterNotices';
import ClientNotice from '@/components/profile/client/patients/ClientPatientNotice';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import NavNotices from '@/components/profile/common/NavNotices';

import {
    filterMixin
} from '@/mixins/filter';
import {
    galleryMixin
} from '@/mixins/gallery';
import useVuelidate from '@vuelidate/core';
export default {
    name: 'PageNotiecs',
    components: {
        AppModal,
        AppTitle,
        AppSelect,
        AppSvgIcon,
        AppLoading,
        AppErrorMessage,
        AppPagination,
        AppFilter,
        // AppGallery,
        ClientNotice,
        AppPatientChangeAvatarModal,
        NavNotices
    },
    mixins: [filterMixin, galleryMixin],
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            form: {
                file: null,
            },
            v: variables,
            filterOptions: {
                patients: true,
                date: true,
                methods: {
                    getData: ['getPatients'],
                    getCount: 'getCountPatients',
                },
            },
            pagination: {
                limit: 15,
                total: 0,
            },
            sortKey: 'datetime',
            sortInverse: true,
            sortOptions: [{
                    caption: 'дате регистрации',
                    value: 'creation_date',
                    selected: true,
                    inverse: true,
                },
                {
                    caption: 'e-mail',
                    value: 'email',
                    selected: false,
                    inverse: false,
                },
                {
                    caption: 'ФИО пользователя',
                    value: 'full_name',
                    selected: false,
                    inverse: false,
                },
                {
                    caption: 'кол-ву осмотров',
                    value: 'exam_count',
                    selected: false,
                    inverse: true,
                },
            ],
            columns: [{
                    class: 'col-auto',
                    caption: '№',
                    key: 'number',
                    inverse: false,
                },
                {
                    class: 'col-1',
                    caption: 'Дата',
                    key: 'datetime',
                    inverse: false,
                },
                {
                    class: 'col-1',
                    caption: 'Время',
                    key: 'datetimed',
                    inverse: true,
                },
                {
                    class: 'col-1',
                    caption: 'Тип',
                    key: 'document_type',
                    inverse: false,
                },
                {
                    class: 'col-3',
                    caption: 'ФИО медработника',
                    key: 'doctor_full_name',
                    inverse: false,
                },
                {
                    class: 'col-3',
                    caption: 'Фио пользователя',
                    key: 'patient_full_name',
                    inverse: true,
                },
                {
                    class: 'col-4',
                    caption: 'Предварительный диагноз',
                    key: 'pre_diagnosis',
                    inverse: true,
                },
            ],
            isModalVisible: {
                changeAvatar: false,
                deleteAvatar: false,
                addPdn: false
            },
            successMessage: 'Успех!',
            filter: {}
        };
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.patients.isLoading,
            patients: (state) => state.patients.data,
            patientsCount: (state) => state.patients.count,
            error: (state) => state.patients.error,
        }),
        currentPage() {
            return Number(this.$route.query.page || '1');
        },
        baseUrl() {
            return this.$route.path;
        },
        offset() {
            return this.currentPage * this.pagination.limit - this.pagination.limit;
        },
        settings() {
            return {
                filters: {
                    patients: {
                        negative: false,
                        payload: [],
                    },
                },
                sorting_params: {
                    desc: this.sortInverse,
                    field: this.sortKey,
                },
            };
        },
    },
    watch: {
        currentPage() {
            this.getPatients(this.filters, this.pagination.limit, this.offset);
        },
    },
    created() {
        this.getPatients(this.filters, this.pagination.limit, this.offset);
    },
    mounted() {
        //this.$store.dispatch("actionGetDoc", {});
        if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
    },
    methods: {
        goToFirstPage() {
            if (this.currentPage !== 1) this.$router.push({
                query: {
                    page: '1'
                }
            });
        },
        async getPatients(filters, limit, offset) {
            console.log(limit)
            console.log(offset)
            // let data = {
            //   filters: Object.assign({}, this.settings.filters, filters),
            //   paging_params: {
            //     limit,
            //     offset,
            //   },
            //   sorting_params: this.settings.sorting_params,
            // };
            this.filter.paging_params = {
                limit,
                offset
            }
            this.filter.sorting_params = {
              desc: this.sortInverse,
              field: this.sortKey
            }
            await this.$store.dispatch("actionGetDoc", this.filter);
            this.pagination.total = this.$store.state.documentation.inquiries?.inquiries?.total
            this.reverseSorting();
            // this.$store.dispatch(actionTypes.getPatients, data).then((response) => {
            //   if (this.isTouchDevice) this.closeFilter('submit');
            //   this.isFiltered = Object.keys(filters).length > 0;
            //   this.pagination.total = response.total;
            //   this.reverseSorting();
            // });
        },
        getCountPatients(filters) {
            let data = {
                filters: Object.assign({}, this.settings.filters, filters),
                sorting_params: this.settings.sorting_params,
            };

            this.$store.dispatch(actionTypes.getCountPatients, data);
        },
        sortBySelect({
            value,
            inverse
        }) {
            this.sortOptions.forEach((el) => (el.selected = el.value === value));
            this.sortingPatients(value, inverse);
        },
        sortingPatients(sortKey, inverse) {
            this.sortKey = sortKey;
            this.sortInverse = inverse;
            this.getPatients(this.filter, this.pagination.limit, this.offset);
        },
        reverseSorting() {
            if (is.not.empty(this.sortKey)) {
                this.columns.forEach((el) => {
                    if (this.sortKey === el.key) el.inverse = !el.inverse;
                });
            }
        },
        submitFilterDoc(data) {
          console.log('this.filter',this.filter)
            this.filter = data
        },
        clearClose(){
          this.isFilterOpen = false
        },
    },
};
</script>
