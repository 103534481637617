<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" :classes="`doctor-exam-detail-modal modal-reasons`")
    template(v-slot:body)
      .text-center.mb-2 
        app-title(:type="'large'" v-if="!type") Выберите причину отстранения 
        app-title(:type="'large'" v-else) УКАЖИТЕ причину 
      p.text-size--small.text-color--blue.mt-0.pt-0.text-uppercase.text-center {{ data.patient_full_name }}
      .row.justify-content-center.mt-5
        .col-auto 
          .d-flex.align-items-center
            span(:class="[bpHighColor(data.bp_high), bpLowColor(data.bp_low)]").flex-shrink-0.me-2
              app-svg-icon(name="pulse") 
            span(v-if="data.bp_high && data.bp_low" :class="bpHighColor(data.bp_high)") {{ data.bp_high }}/
            span(v-if="data.bp_high && data.bp_low" :class="bpLowColor(data.bp_low)") {{ data.bp_low }}
            span.text-color--blue(v-else) &#8212;
        .col-auto 
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="cardio")
            span(v-if="data.heart_rate") {{ data.heart_rate }}
            span.text-color--blue(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              app-svg-icon(name="temp")
            span(v-if="data.body_temp" :class="bpTemp(data.body_temp)") {{ data.body_temp }}&nbsp;c&#176;
            span.text-color--blue(v-else) &#8212;
        .col-auto
          .d-flex.align-items-center
            span(:class="alcoholColor(data.alcohol)").flex-shrink-0.me-2
              app-svg-icon(name="breathalyzer")
            span(:class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
        .col-auto(v-if="data.sleep_info=='no'")
          .d-flex.align-items-center
            span.flex-shrink-0.me-2
              img(src="@/assets/img/sleep.png")
            span.orange Не выспался
      .row
        .col-14
          .reasonsHeader Медицинские
          .reasons
           template(v-for='reason in reasons.medical')
             .reason.reason-input(:key='reason.id' v-if="type == '3no' || type == '1no' || type == '5no'")
               input(type='checkbox' :id="'med'+reason.id" v-model='reason.checked' @change="addReason(reason)" :disabled='disableCheckbox(reason.id)')
               label(:for="'med'+reason.id" @click="showInjurys(reason.text)") {{reason.text}}
               .injuryTrigger.me-5(v-if="reason.text=='Травма'" @click="showInjurys(reason.text)") 
                 span(v-if='injury') Скрыть
                 span(v-if='!injury') Показать
             .reason.reason-input(:key='reason.id' v-if="(type == '2yes' || type == '4yes') && (reason.id==1 || reason.id==4 || reason.id==7 || reason.id==5 || reason.id==9)")
                input(type='checkbox' :id="'med'+reason.id" v-model='reason.checked' @change="addReason(reason)" :disabled='disableCheckbox(reason.id)')
                label(:for="'med'+reason.id" @click="showInjurys(reason.text)") {{reason.text}} 
                .injuryTrigger.me-5(v-if="reason.text=='Травма'" @click="showInjurys(reason.text)") 
                  span(v-if='injury') Скрыть
                  span(v-if='!injury') Показать
             .reason.reason-input(:key='reason.id' v-if="(type == '2no' || type == '4no') && (reason.id==3 || reason.id==6 || reason.id==8)")
                input(type='checkbox' :id="'med'+reason.id" v-model='reason.checked' @change="addReason(reason)" :disabled='disableCheckbox(reason.id)')
                label(:for="'med'+reason.id" @click="showInjurys(reason.text)") {{reason.text}}
                .injuryTrigger.me-5(v-if="reason.text=='Травма'" @click="showInjurys(reason.text)") 
                  span(v-if='injury') Скрыть
                  span(v-if='!injury') Показать
        .col-14.mt-5(v-if='injury')
          .row
            .col-7
              .row.mb-4
                .col-8.black.text-bold Локализация травмы
                .col-6
                  .row
                    .col-auto.black.text-bold Слева
                    .col-auto.black.text-bold Справа
                    .col-auto.black.text-bold Общая
            .col-7
              .row.mb-4
                .col-8.black.text-bold Локализация травмы
                .col-6
                  .row
                    .col-auto.black.text-bold Слева
                    .col-auto.black.text-bold Справа
                    .col-auto.black.text-bold Общая
            .col-7(v-for='injurie in reasons.injuries' :key='injurie.id')    
              .row.align-items-center.mb-3
                .col-8 {{injurie.text}}
                .col-6
                  .row
                    .col-auto.reason-short
                      .reason(v-show='injurie.left!=null')
                        input(:id="'left'+injurie.id"  v-model='injurie.left' type='checkbox' @change="checkInjuriePart(injurie)")
                        label(:for="'left'+injurie.id")
                    .col-auto.reason-short
                      .reason(v-show='injurie.right!=null')
                        input(:id="'right'+injurie.id" v-model='injurie.right' type='checkbox' @change="checkInjuriePart(injurie)")
                        label(:for="'right'+injurie.id")
                    .col-auto.reason-short
                      .reason(v-show='injurie.both!=null')
                        input(:id="'both'+injurie.id" type='checkbox' v-model='injurie.both' @change="checkInjurieBoth(injurie)")
                        label(:for="'both'+injurie.id")
        .col-14(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no' || type == '5no'")
          .reasonsHeader Административные
          .reasons
            .reason.reason-input(v-for='reason in reasons.administrative' :key='reason.id')
              input(type='checkbox' :id="'adm'+reason.id" @change="addReason(reason)")
              label(:for="'adm'+reason.id") {{reason.text}}
        .col-14
          .reasonsHeader(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no'  || type == '5no'") Технические
          .d-flex
            .reasons.reasons--w66(v-if="type=='3no' || type=='2no' || type == '4no' || type == '1no' || type == '5no'")
              .reason.reason-input.w-50(v-for='reason in reasons.technical' :key='reason.id')
                input(type='checkbox' :id="'tecn'+reason.id" @change="addReason(reason)")
                label(:for="'tecn'+reason.id") {{reason.text}}
            .reason.d-flex.justify-content-end(:class="classObject")
              button(
                type="button"
                @click="refuseOpen()"
                class=['button', 'button--accent','button--height', 'mt-3' ]
                  )
                    span.button__content.d-flex.align-items-center
                      span(v-if="type == '2yes' || type == '4yes' || type == '5yes'") Прошел
                      span(v-else) Отстранить
</template>

<script>
import {
    mapState
} from 'vuex';
//import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {
    required,
    minLength
} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
    name: 'addDoctorReasons',
    components: {
        AppModal,
        AppTitle,
        AppValidationErrors,
        AppSvgIcon,
    },
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
        data: {
            required: true,
            type: Object,

        },
        type:{
            required: true,
            type: String,
            default: '',
        },
         time:{
            required: true,
            type: Number,
            default: 0,
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            v: variables,
            form: {
                version: '',
                controlSum: ''

            },
            result: [],
            injury: false,
            reasons: {
                medical: [{
                        id: 1,
                        text: "АД выходит за границы нормы",
                        checked: false
                    },
                    {
                        id: 2,
                        text: "Нарушение режима отдыха",
                        checked: false
                    },
                    {
                        id: 3,
                        text: "Обнаружен алкоголь",
                        checked: false
                    },
                    {
                        id: 4,
                        text: "ЧСС выходит за границы нормы",
                        checked: false
                    },
                    {
                        id: 5,
                        text: "Наличие жалоб",
                        checked: false
                    },
                    {
                        id: 6,
                        text: "Признаки особенности поведения",
                        checked: false
                    },
                    {
                        id: 7,
                        text: "t°C выходит за границы нормы",
                        checked: false
                    },
                    {
                        id: 8,
                        text: "Неустойчивая концентрация внимания",
                        checked: false
                    },
                    {
                        id: 9,
                        text: "Травма",
                        checked: false
                    }
                ],
                administrative: [{
                        id: 10,
                        text: "Прохождение осмотра посторонним",
                        checked: false
                    },
                    {
                        id: 11,
                        text: "Алкотестирование вне зоны видимости",
                        checked: false
                    },
                    {
                        id: 12,
                        text: "Нарушение правил алкотестирования",
                        checked: false
                    },
                    {
                        id: 13,
                        text: "Нахождение вне зоны видимости камеры",
                        checked: false
                    },
                    {
                        id: 14,
                        text: "Тонометрия вне зоны видимости",
                        checked: false
                    },
                    {
                        id: 15,
                        text: "Нарушение правил тонометрии",
                        checked: false
                    },
                    {
                        id: 16,
                        text: "Нарушение порядка прохождения",
                        checked: false
                    },
                    {
                        id: 17,
                        text: "Термометрия вне зоны видимости",
                        checked: false
                    }, {
                        id: 18,
                        text: "Нарушение правил термометрии",
                        checked: false
                    },
                    {
                        id: 19,
                        text: "Посторонние в кадре",
                        checked: false
                    },
                    {
                        id: 20,
                        text: "Не снят головной убор/верх. одежда",
                        checked: false
                    },
                    {
                        id: 21,
                        text: "Недостаточное освещение",
                        checked: false
                    },
                    {
                        id: 22,
                        text: "Незавершенный осмотр",
                        checked: false
                    },
                    {
                        id: 23,
                        text: "Не сняты очки",
                        checked: false
                    },
                    {
                        id: 24,
                        text: "Засвет видео",
                        checked: false
                    },
                    {
                        id: 25,
                        text: "Некорректная подпись",
                        checked: false
                    },
                    {
                        id: 26,
                        text: "Снимите маску / перчатки",
                        checked: false
                    },
                    {
                        id: 27,
                        text: "Снимите пластырь / повязку",
                        checked: false
                    }
                ],
                technical: [{
                        id: 28,
                        text: "Нет видео",
                        checked: false
                    },
                    {
                        id: 29,
                        text: "Сбой мед.оборудования",
                        checked: false
                    },
                    {
                        id: 30,
                        text: "Нет фото работника",
                        checked: false
                    },
                    {
                        id: 31,
                        text: "Некорректная видеофиксация",
                        checked: false
                    }
                ],
                injuries: [{
                        id: 32,
                        text: 'Волосистой области',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 33,
                        text: 'Околоушно-жевательной области',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 34,
                        text: 'Лобной области',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 35,
                        text: 'Уха',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 36,
                        text: 'Височной области',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 37,
                        text: 'Мягких тканей лица',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 38,
                        text: 'Надбровной области',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 39,
                        text: 'Верхней губы',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 40,
                        text: 'Параорбитальная гематома',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 41,
                        text: 'Нижней губы',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 42,
                        text: 'Века',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 43,
                        text: 'Подбородочной области',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 44,
                        text: 'Воспаление века неуточненное',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 45,
                        text: 'Множественные травмы головы',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 46,
                        text: 'Подглазничной области',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 47,
                        text: 'Шеи неуточненная',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 48,
                        text: 'Скуловой области',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 49,
                        text: 'Грудной клетки неуточненная',
                        left: null,
                        right: null,
                        both: false
                    },
                    {
                        id: 50,
                        text: 'Спинки носа',
                        left: null,
                        right: null,
                        both: false
                    },
                    {
                        id: 51,
                        text: 'Верхней конечности',
                        left: false,
                        right: false,
                        both: null
                    },
                    {
                        id: 52,
                        text: 'Носа',
                        left: false,
                        right: false,
                        both: false
                    },
                    {
                        id: 53,
                        text: 'Нижней конечности',
                        left: false,
                        right: false,
                        both: null
                    },
                ]
            }
        };
    },
    validations() {
        return {
            form: {
                version: {
                    required,
                    minLength: minLength(1)
                },
                controlSum: {
                    required,
                    minLength: minLength(1)
                },
            },
        };
    },
    mounted() {
      this.clearList()
    },
    computed: {
        ...mapState({
            isLoading: (state) => state.requests.isLoading,
            error: (state) => state.requests.error,
        }),
        classObject(){
            if(this.type == '2yes' || this.type == '4yes' || this.type == '5yes') {
                return 'w-100'
            } else {
                return ''
            }
        }
    },
    watch:{
      time(){
         this.clearList()
      }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
        success() {
            this.$emit('success');
        },
        bpHighColor(key) {
            return {
                'yellowText': (key > 140 && key < 160) || (key > 60 && key < 100),
                'redText': key >= 160
            }
        },
        bpLowColor(key) {
            return {
                'yellowText': (key >= 90 && key <= 99) || (key < 60),
                'redText': key >= 100
            }
        },
        alcoholColor(key) {
            return {
                'yellowText': (key > 0 && key <= 0.16),
                'redText': key > 0.16
            }
        },
        bpTemp(key) {
            return {
                'yellowText': (key > 37 && key <= 37.9),
                'redText': key >= 38
            }
        },
        showInjurys(text) {
            if (text == 'Травма') {
                this.injury = !this.injury
            }

        },
        refuseOpen() {
            this.$emit('refuseOpen',this.result);
        },
        checkInjurieBoth(item) {
            const id = item.id
            let index = this.reasons.injuries.findIndex(el => el.id === id);
            if (this.reasons.injuries[index]['both']) {
                if (this.reasons.injuries[index]['left'] != null) {
                    this.reasons.injuries[index]['left'] = true
                }
                if (this.reasons.injuries[index]['right'] != null) {
                    this.reasons.injuries[index]['right'] = true
                }
            } else {
                if (this.reasons.injuries[index]['left'] != null) {
                    this.reasons.injuries[index]['left'] = false
                }
                if (this.reasons.injuries[index]['right'] != null) {
                    this.reasons.injuries[index]['right'] = false
                }
            }

            this.addInjurie(item)

        },
        checkInjuriePart(item) {
            const id = item.id
            let index = this.reasons.injuries.findIndex(el => el.id === id);
            if (this.reasons.injuries[index]['both'] != null) {
                if (this.reasons.injuries[index]['right'] && this.reasons.injuries[index]['left']) {
                    this.reasons.injuries[index]['both'] = true
                } else {
                    this.reasons.injuries[index]['both'] = false
                }
            }
            this.addInjurie(item)

        },
        addReason(item) {
            let index = this.result.findIndex(el => el.id === item.id);
            if (index == -1) {
                this.result.push(item)
            } else {
                this.result.splice(index, 1);
            }
        },
        addInjurie(item) {
            let index = this.result.findIndex(el => el.id === item.id);
            if (index == -1) {
                this.result.push(item)
            } else {
                if (!this.result[index]['left'] && !this.result[index]['right']) {
                    this.result.splice(index, 1);
                } else {
                    this.result[index] = item
                }

            }
        },
        clearList(){
            this.result.length = 0
            this.injury = false
            this.reasons.medical.forEach((element) => {
             element.checked = false
            })
            this.reasons.administrative.forEach((element) => {
             element.checked = false
            })
            this.reasons.technical.forEach((element) => {
             element.checked = false
            })
           this.reasons.injuries.forEach((element) => {
             element.right = false
             element.left = false
             if(element.both!=null){
                element.both=false
             }
            })
            if(this.data.sleep_info=='no'){
              this.reasons.medical[1].checked = true
              this.result.push(this.reasons.medical[1])
            }
            if(this.data.health_complaint){
              this.reasons.medical[4].checked = true
              this.result.push(this.reasons.medical[4])
            }
            
        },
        disableCheckbox(id){
              if((id == 2 && this.data.sleep_info=='no') || (id == 2 && this.data.sleep_info=='yes')){
                return true
              }
              if(id==5){
                return true
              }
        }
    },
    

}
</script>

<style lang="scss">
.reasonsHeader {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 17px;
    margin-top: 35px;
}

.reasons {
    display: flex;
    flex-wrap: wrap;
}

.reason-input {
    width: calc(100% / 3);
    margin-bottom: 15px;
}

.reason {
    position: relative;
    display: block;

    // outline: 1px solid red;
}

.reason input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.reason label {
    color: #59658D;
    position: relative;
    cursor: pointer;
}

.reason label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #59658D;
    border-radius: 4px;
    width: 27px;
    margin-right: 10px;
    height: 27px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
}

.reason input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 27px;
    height: 27px;
    border-radius: 4px;
    background: #59658D;
    border: 2px solid #59658D;
}

.reason input:disabled+label:before {
    content: '';
    background-color: #D1D4D8;
    border: 2px solid rgba(89, 101, 141, 0.5);
    border-radius: 4px;
    width: 27px;
    margin-right: 10px;
    height: 27px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: not-allowed;
}

.injuryTrigger {
    position: absolute;
    top: 3px;
    cursor: pointer;
    right: 0;
}

.mte-50 {
    margin-top: -33px;
}

.black {
    color: #000;
}

.text-bold {
    font-weight: 500;
}

.reasons--w66 {
    width: calc(100% - 33%);
}

.reason-short {
    width: 76px !important;
}

.button--height {
    height: 64px;
}
</style>
