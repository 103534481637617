<template lang="pug">
  .profile-doctor-detail
    .profile-doctor-detail__inner
      .profile-doctor-detail__header
        .row.align-items-center
          .col-14.col-md-auto.me-auto.order-last.order-md-first
            .row.align-items-center.g-4
              .col-auto
                app-back-button(:prev-page="'doctors'")
              .col
                app-title(:type="'large'") {{ isTouchDevice ? 'Врач' : 'Страница врача' }}

              //- .col-auto.d-md-none
              //-   .d-flex.align-items-center.justify-content-end
              //-     button(
              //-       type="button"
              //-       @click.prevent="openModal('changeDoctor')"
              //-       class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
              //-     )
              //-       span.flex-shrink-0
              //-         app-svg-icon(name="edit")

              //-     button(
              //-       type="button"
              //-       @click.prevent="openModal('deleteDoctor')"
              //-       class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
              //-     )
              //-       span.flex-shrink-0
              //-         app-svg-icon(name="trash")


      .profile-doctor-detail__content.mt-20
        transition(name="translucent")
          div(v-if="doctor")
            .row.align-items-center
              .col
                section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                  .row.g-4
                    .col-14.col-md-7.col-xl-3
                      .text-color--blue.mb-3 Ф.И.О.
                      span {{ doctor.last_name }}
                      span &#32;{{ doctor.first_name }}
                      span(v-if="doctor.middle_name") &#32;{{ doctor.middle_name }}

                    .col-14.col-md-7.col-xl-2
                      .text-color--blue.mb-3 Дата регистрации
                      span {{ toLocalDate(doctor.creation_date) }}

                    .col-14.col-md-7.col-xl-3
                      .text-color--blue.mb-3 Документ об образовании
                      span(v-if="doctor.series_number") {{ doctor.series_number }}
                      span(v-else) &#8212;

                    .col-14.col-md-7.col-xl-3
                      .text-color--blue.mb-3 Сертификат о подготовке
                      button(
                        v-if="doctor.certificate"
                        class=['profile-card__button--document', 'link--accent']
                        type="button"
                        @click.prevent="getFile(doctor.certificate)"
                      )
                        span.d-flex.align-items-center
                          span.flex-shrink-0
                            app-svg-icon(name="document")
                          span.ms-2 Документ

                      span(v-else) &#8212;
                    .col-14.col-md-7.col-xl-3.d-none.d-md-block
                     .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeDoctor')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteDoctor')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="trash")
                    //- .col-14.col-md-7.col-xl-3
                    //-   .text-color--blue.mb-3 Место работы
                    //-   span(v-if="doctor.place_of_work") {{ doctor.place_of_work }}
                      span(v-else) &#8212;
                //New
                section(class=['profile-doctor-detail__card', 'profile-card', 'no-hover'])
                  .row.g-4
                    .col-14.col-md-7.col-xl-3
                      .text-color--blue.mb-3 Место работы
                      span {{ doctor.place_of_work }}
                    .col-14.col-md-7.col-xl-2
                      .text-color--blue.mb-3 ИНН организации
                      span {{doctor.med_organisation_inn}}

                    .col-14.col-md-7.col-xl-3
                      .text-color--blue.mb-3 № лицензии
                      span(v-if="doctor.licence_number") {{ doctor.licence_number }}
                      span(v-else) &#8212;

                    .col-14.col-md-7.col-xl-2
                      .text-color--blue.mb-3 Дата выдачи
                      span(v-if="doctor.licence_data_reciving") {{ doctor.licence_data_reciving }}
                      span(v-else) &#8212;

                    .col-14.col-md-7.col-xl-3
                      .text-color--blue.mb-3 Срок окончания
                      span(v-if="doctor.licence_data_ending") {{ doctor.licence_data_ending }}
                      span(v-else) &#8212;
                    .col-14.col-md-7.col-xl-3.d-md-none
                     .d-flex.align-items-center.justify-content-end
                      button(
                        type="button"
                        @click.prevent="openModal('changeDoctor')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deleteDoctor')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="trash")

              //- .col-auto.d-none.d-md-block
              //-   .d-flex.align-items-center
              //-     button(
              //-       type="button"
              //-       @click.prevent="openModal('changeDoctor')"
              //-       class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
              //-     )
              //-       span.flex-shrink-0
              //-         app-svg-icon(name="edit")

              //-     button(
              //-       type="button"
              //-       @click.prevent="openModal('deleteDoctor')"
              //-       class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
              //-     )
              //-       span.flex-shrink-0
              //-         app-svg-icon(name="trash")

            transition(name="translucent")
              app-tab-nav(
                v-if="exams || users"
                :tabs="tabs"
                :selected="activeTabId"
                :theme="'separate'"
                @selected="setActiveTab"
                :classes="'my-20'"
              )

            .position-relative
              app-loading(v-if="isLoading")
              template(v-else-if="!exams && !users")
                .text-color--blue.text-size--small.mt-20 Нет клиентов и осмотров

              app-error-message(v-if="error" :message="error")

            // exams tab
            transition(name="translucent")
              app-tab(
                v-if="exams"
                :id="examsTab.id"
                :is-selected="activeTabId === examsTab.id"
              )
                template(v-if="exams.result && exams.result.length")
                  app-select(
                    @select="sortBySelect"
                    :classes="'d-xl-none mb-4'"
                    :options="examsTab.sortOptions"
                    :placeholder="'выберите...'"
                  )
                  .profile-table.is-exams-table
                    .profile-table__header.d-none.d-xl-block
                      .row.align-items-center.g-4
                        div(v-for="column in examsTab.columns" :key="column.key" :class="column.class")
                          button(
                            v-if="column.caption || column.icon"
                            type="button"
                            @click="sortingTable(column.key, column.inverse)"
                            class=['profile-table__sort-button']
                            :class="{'is-active': examsTab.sortKey === column.key}"
                          )
                            span.d-flex.align-items-center
                              span(v-if="column.caption") {{ column.caption }}
                              span.flex-shrink-0(v-else-if="column.icon")
                                app-svg-icon(:name="column.icon")
                              span.flex-shrink-0.d-flex.ms-3
                                app-svg-icon(name="down-arrow")


                    ul.profile-table__body(id="doctor-exams")
                      li.profile-table__row(v-for="exam in exams.result" :key="exam.id")
                        app-exam-card(
                          :data="exam"
                          :parent-id="'doctor-exams'"
                          :type="'doctor-detail'"
                          :columns="examsTab.columns"
                          :classes="'d-none d-xl-block'"
                          @openModal="openExamDetailModal"
                          @openCertificate="openCertificate"
                          @openGallery="openGallery"
                        )
                        app-exam-card-mobile(
                          :data="exam"
                          :classes="'d-xl-none'"
                          @openModal="openExamDetailMobile"
                        )

                  .d-flex.justify-content-center.mt-5
                    app-pagination(
                      :total="examsTab.pagination.total"
                      :limit="examsTab.pagination.limit"
                      :current-page="currentPage"
                      :url="baseUrl"
                    )

                template(v-else)
                  .text-color--blue.text-size--small Осмотров не найдено


            // users tab
            transition(name="translucent")
              app-tab(
                v-if="users"
                :id="usersTab.id"
                :is-selected="activeTabId === usersTab.id"
              )
                template(v-if="users.result && users.result.length")
                  app-select(
                    @select="sortBySelect"
                    :classes="'d-xl-none mb-4'"
                    :options="usersTab.sortOptions"
                    :placeholder="'выберите...'"
                  )
                  .profile-table
                    .profile-table__header.d-none.d-xl-block
                      .row.align-items-center.gx-4
                        div(v-for="column in usersTab.columns" :key="column.key" :class="column.class")
                          button(
                            type="button"
                            @click="sortingTable(column.key, column.inverse)"
                            class=['profile-table__sort-button']
                            :class="{'is-active': usersTab.sortKey === column.key}"
                          )
                            span.d-flex.align-items-center
                              span.text-overflow(v-if="column.caption") {{ column.caption }}
                              span(v-else-if="column.icon")
                                app-svg-icon(:name="column.icon")
                              span.d-flex.ms-3
                                app-svg-icon(name="down-arrow")


                    ul.profile-table__body
                      li.profile-table__row(v-for="user in users.result" :key="user.id")
                        router-link(:to="{name: 'user-detail', params: {id: user.id}}" class=['d-block'])
                          app-admin-user-card(:data="user")

                  .d-flex.justify-content-center.mt-5
                    app-pagination(
                      :total="usersTab.pagination.total"
                      :limit="usersTab.pagination.limit"
                      :current-page="currentPage"
                      :url="baseUrl"
                    )

                template(v-else)
                  .text-color--blue.text-size--small Клиенты не найдены


          template(v-else-if="!isLoading")
            .text-color--blue.text-size--small Врача не существует в системе


      // change doctor modal
      app-change-doctor-modal(
        v-if="doctor"
        :doctor="doctor"
        :is-visible="isModalVisible.changeDoctor"
        @close="closeModal('changeDoctor')"
        @success="getDoctors"
      )
      // exam detail modal
      app-exam-detail-modal(
        :is-visible="isModalVisible.examDetail"
        :data="currentExam.data"
        :type="currentExam.type"
        @close="closeModal('examDetail')"
        @openGallery="openGallery"
      )
      // exam detail modal for mobile
      app-exam-detail-mobile(
        :is-visible="isModalVisible.examDetailMobile"
        :data="currentExam.data"
        @openModal="openExamDetailModal"
        @close="closeModal('examDetailMobile')"
        @openCertificate="openCertificate"
        @openGallery="openGallery"
      )
      // certificate modal
      app-certificate-modal(
        :is-visible="isModalVisible.certificate"
        :data="certificate"
        @close="closeModal('certificate')"
      )
      // gallery
      app-gallery(
        :is-visible="isModalVisible.gallery"
        :options="gallery"
        @close="closeGallery"
      )
    // delete doctor modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteDoctor" @close="closeModal('deleteDoctor')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить врача?
            p.text-color--blue.mt-3 {{ doctor.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteDoctor')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteDoctor(doctor)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

    // success delete doctor modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Врач успешно удалён!
            p.text-color--blue.mt-3 Ф.И.О. врача: {{ doctor.full_name }}.

          .d-flex.justify-content-center.mt-5
            button(
              @click="$router.go(-1)"
              type="button"
              class=['button button--accent']
            )
              span.button__content Назад в список


</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';
import {actionTypes as usersActionTypes} from '@/store/modules/users';

import is from 'is_js';
import variables from '@/helpers/variables';
import {declOfNum, toLocalDate, getFile} from '@/helpers/utils';

import AppTab from '@/components/common/Tab';
import AppTabNav from '@/components/common/TabNav';
import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppSelect from '@/components/common/Select';
import AppPagination from '@/components/common/Pagination';
import AppGallery from '@/components/common/Gallery';

import AppCertificateModal from '@/components/profile/common/CertificateModal';

import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';

import AppAdminUserCard from '@/components/profile/admin/users/AdminUserCard';
import AppChangeDoctorModal from '@/components/profile/admin/doctors/ChangeDoctorModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';

export default {
  name: 'AppAdminDoctorDetail',
  components: {
    AppBackButton,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppModal,
    AppSelect,
    AppGallery,
    AppTab,
    AppTabNav,
    AppCertificateModal,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppAdminUserCard,
    AppChangeDoctorModal,
  },
  mixins: [galleryMixin, examDetailModalMixin, examDetailMobileMixin, certificateModalMixin],
  data() {
    return {
      declOfNum,
      isModalVisible: {
        changeDoctor: false,
        deleteDoctor: false,
      },
      activeTabId: 'exams',
      tabs: [
        {
          id: 'exams',
          caption: 'Осмотры',
          sortKey: '',
          sortInverse: false,
          pagination: {
            limit: variables.pagination.limit,
            total: 0,
          },
          sortOptions: [
            {
              caption: 'дате',
              value: 'exam_date_time',
              selected: true,
              inverse: true,
            },
            {
              caption: 'ФИО пользователя',
              value: 'patient_full_name',
              selected: false,
              inverse: false,
            },
            {
              caption: 'давлению',
              value: 'bp_high',
              selected: false,
              inverse: true,
            },
            {
              caption: 'пульсу',
              value: 'heart_rate',
              selected: false,
              inverse: true,
            },
            {
              caption: 'температуре',
              value: 'body_temp',
              selected: false,
              inverse: true,
            },
            {
              caption: 'наличию алкоголя',
              value: 'alcohol',
              selected: false,
              inverse: true,
            },
            {
              caption: 'допуску',
              value: 'admission_to_work',
              selected: false,
              inverse: true,
            },
          ],
          columns: [
            {
              class: 'col-4 col-xl-3 col-xxl-2',
              caption: 'Дата и время',
              key: 'exam_date_time',
              inverse: true,
            },
            {
              class: 'col-3',
              caption: 'ФИО пользователя',
              key: 'patient_full_name',
              inverse: false,
            },
            {
              class: 'col-1',
              icon: 'pulse',
              key: 'bp_high',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'cardio',
              key: 'heart_rate',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'temp',
              key: 'body_temp',
              inverse: true,
            },
            {
              class: 'col-1',
              icon: 'breathalyzer',
              key: 'alcohol',
              inverse: true,
            },
            {
              class: 'col-1',
              key: 'health_complaint',
              inverse: false,
            },
            {
              class: 'col-2 ms-auto',
              caption: 'Допуск',
              key: 'admission_to_work',
              inverse: false,
            },
          ],
        },
        {
          id: 'clients',
          caption: 'Клиенты',
          sortKey: '',
          sortInverse: false,
          pagination: {
            limit: variables.pagination.limit,
            total: 0,
          },
          sortOptions: [
            {
              caption: 'дате регистрации',
              value: 'creation_date',
              selected: true,
              inverse: true,
            },
            {
              caption: 'типу клиента',
              value: 'user_type',
              selected: false,
              inverse: false,
            },
            {
              caption: 'имени клиента',
              value: 'full_name',
              selected: false,
              inverse: false,
            },
            {
              caption: 'e-mail',
              value: 'email',
              selected: false,
              inverse: false,
            },
            {
              caption: 'кол-ву осмотров',
              value: 'exam_count',
              selected: false,
              inverse: true,
            },
          ],
          columns: [
            {
              class: 'col-2',
              caption: 'Дата регистрации',
              key: 'creation_date',
              inverse: true,
            },
            {
              class: 'col-2',
              caption: 'Тип клиента',
              key: 'user_type',
              inverse: false,
            },
            {
              class: 'col-3',
              caption: 'Клиент',
              key: 'full_name',
              inverse: false,
            },
            {
              class: 'col-md-3 col-xxl-2',
              caption: 'E-mail',
              key: 'email',
              inverse: false,
            },
            {
              class: 'col-md-2 col-xxl-5',
              caption: 'Осмотры',
              key: 'exam_count',
              inverse: true,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState({
      globalSettings: (state) => state.settings.data,

      isLoading: (state) => state.doctors.isLoading,
      doctors: (state) => state.doctors.data,
      exams: (state) => state.doctors.exams,
      error: (state) => state.doctors.error,

      users: (state) => state.users.data,
    }),
    hostUrl() {
      if (!this.globalSettings) return false;
      return this.globalSettings.s3_host;
    },
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.activeTab.pagination.limit - this.activeTab.pagination.limit;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    doctorId() {
      return Number(this.$route.params.id);
    },
    doctor() {
      if (this.doctors) {
        const index = this.doctors.result.map((el) => el.id).indexOf(this.doctorId);
        return index !== -1 ? this.doctors.result[index] : false;
      } else return false;
    },
    activeTab() {
      return this.tabs.find((el) => el.id === this.activeTabId);
    },
    examsTab() {
      return this.tabs[0];
    },
    usersTab() {
      return this.tabs[1];
    },
    settings() {
      return {
        sorting_params: {
          desc: this.activeTab.sortInverse,
          field: this.activeTab.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.activeTab.id === this.examsTab.id
        ? this.getDoctorExams(this.examsTab.pagination.limit, this.offset)
        : this.getDoctorUsers(this.usersTab.pagination.limit, this.offset);
    },
  },
  created() {
    if (is.null(this.doctors)) {
      this.getDoctors();
    } else if (this.doctor && this.doctor.clients) {
      this.getDoctorExams(this.examsTab.pagination.limit, 0);
      this.getDoctorUsers(this.usersTab.pagination.limit, 0);
    }
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    setActiveTab(id) {
      this.goToFirstPage();
      this.activeTabId = id;
    },
    getFile(src) {
      getFile(`${this.hostUrl}${src}`);
    },
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({query: Object.assign({}, this.$route.query, {page: 1})});
    },
    collectData(limit, offset) {
      let data = this.settings;
      if (is.all.existy(limit, offset)) data.paging_params = {limit, offset};
      return data;
    },
    getDoctors() {
      const data = this.collectData(this.v.pagination.limit, 0);

      this.$store.dispatch(doctorsActionTypes.getDoctors, data).then(() => {
        if (this.doctor.clients) {
          this.getDoctorExams(this.examsTab.pagination.limit, 0);
          this.getDoctorUsers(this.usersTab.pagination.limit, 0);
        }
      });
    },
    getDoctorExams(limit, offset) {
      const data = Object.assign({}, this.collectData(limit, offset), {
        doctor_id: this.doctorId,
        filter_params: {
          admission_to_work: true,
          exam_completed: true,
        },
      });

      this.$store.dispatch(doctorsActionTypes.getDoctorExamsAdmin, data).then((response) => {
        this.examsTab.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    getDoctorUsers(limit, offset) {
      const data = Object.assign({}, this.collectData(limit, offset), {
        filters: {
          users: {
            negative: false,
            payload: this.doctor.clients,
          },
        },
      });

      this.$store.dispatch(usersActionTypes.getUsers, data).then((response) => {
        this.usersTab.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    sortBySelect({value, inverse}) {
      this.activeTab.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingTable(value, inverse);
    },
    sortingTable(sortKey, inverse) {
      this.activeTab.sortKey = sortKey;
      this.activeTab.sortInverse = inverse;

      this.activeTab.id === this.examsTab.id
        ? this.getDoctorExams(this.examsTab.pagination.limit, this.offset)
        : this.getDoctorUsers(this.usersTab.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.activeTab.sortKey)) {
        this.activeTab.columns.forEach((el) => {
          if (this.activeTab.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    deleteDoctor({id}) {
      this.$store
        .dispatch(doctorsActionTypes.deleteDoctor, {
          id,
        })
        .then(() => {
          this.closeModal('deleteDoctor');
          this.openModal('success');
        });
    },
  },
};
</script>

<style lang="scss">
.profile-doctor-detail {
  &__card {
    border-radius: $border-radius-sm;
    padding: $spacer $spacer * 1.6;
  }
}
</style>
