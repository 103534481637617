import axios from '@/api/axios';

const createIncomingMedDocument = (credentials) => axios.post('/incoming_med_document/create', credentials);

const acceptIncomingMedDocument = (med_doc_id, credentials) => axios.post(`/incoming_med_document/accept/${med_doc_id}`, credentials);

const archiveIncomingMedDocument = (med_doc_id, credentials) => axios.post(`/incoming_med_document/archived/${med_doc_id}`, credentials);

const getIncomingMedDocument = (credentials) => axios.post('/incoming_med_document', credentials);


export default {
    createIncomingMedDocument,
    getIncomingMedDocument,
    acceptIncomingMedDocument,
    archiveIncomingMedDocument
};