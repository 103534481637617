<template lang="pug">
  div
    .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
      span(
        @click="showBlockHistory"
        class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
      ) История блокировок
    
    .profile-table(v-if="showInfo")
      .profile-table__header.d-none.d-xl-block
        .row.align-items-center.g-4
          div(v-for="column in columns" :key="column.key" :class="column.class")
            button(
                type="button"
                @click="alert(123)"
                class=['profile-table__sort-button']
            )
            span.d-flex.align-items-center
                    span.text-overflow(:title="column.caption") {{ column.caption }}
                    span.d-flex.ms-3
                    app-svg-icon(name="down-arrow"
            )

  
          ul.profile-table__body
            li.profile-table__row(v-for="blockHistoryItem in blockHistory" :key="blockHistoryItem.id")
                section(
                    class=['user-card', 'user-card-history']
                )
                  .row.align-items-center.g-4.gy-xl-0
                    .col-2
                        div {{ blockHistoryItem.id }}
                    .col-2
                        div {{ $moment(blockHistoryItem.end_datetime).format('DD.MM.YYYY')}}
                    .col-2
                        div {{ blockHistoryItem.duration_locking }}

</template>

<script>

import patients from '@/api/patients';


export default {
    name: 'AppPatientBlockHistory',
    props: {
    user_id: {
        type: String,
        required: true
      }
    },
    data() {
        return {
            showInfo: false,
            blockHistory: [
            ],
            columns: [
                {
                    num: 0,
                    class: 'col-2',
                    caption: '№',
                    key: 'id',
                    inverse: false,
                },
                {
                    num: 1,
                    class: 'col-2',
                    caption: 'Дата и время',
                    key: 'admission_datetime',
                    inverse: false,
                },
                {
                    num: 2,
                    class: 'col-2',
                    caption: 'Продолжительность',
                    key: 'patient_full_name',
                    inverse: false,
                }
            ],
        }
    },
    methods: {
        async showBlockHistory() {
            this.showInfo = !this.showInfo;
            console.log(this.user_id);
            let data = await patients.blockHistory({
                "filters": {
                    "patient_code": this.user_id
                }
            });
            this.blockHistory = data.data.result
            console.log(data);
        }
    }
}
</script>


<style lang="scss">
    .block-history-title:hover {
        cursor: pointer;
    }
    
    .user-card-history {
        padding: 10px 0;
        color: #505e8e;
    }

</style>