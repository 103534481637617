<template lang="pug">
  section(
    class=['profile-card', 'doctor-exam-card', 'cursor-pointer']
    :class="classes"
    @click.capture="openModal(type, data)"
  )
    .row.align-items-center.g-4
      div(v-for="column in columns" :key="column.key" :class="column.class")
        template(v-if="column.key === 'exam_datetime' || column.key === 'exam_date_time'")
          span {{ toLocalDate(data[column.key]) }}
          span.ms-3 {{ toLocalTimeExam(data.exam_date_time)}}

        template(v-else-if="column.key === 'patient_full_name' || column.key === 'patient_code'")
          span {{ data.patient_full_name }}

        template(v-else-if="column.key === 'lat'")
          span(v-if="data[column.key] && data.long") {{ data[column.key] }}, {{ data.long }}
          span(v-else) &#8212;

        template(v-else-if="column.key === 'bp_high'")
          span(v-if="data[column.key] && data.bp_low" :class="bpHighColor(data[column.key])") {{ data[column.key] }}/
          span(v-if="data[column.key] && data.bp_low"  :class="bpLowColor(data.bp_low)") {{ data.bp_low }}
          span(v-else) &#8212;

        template(v-else-if="column.key === 'heart_rate'")
          span(v-if="data[column.key]") {{ data[column.key] }}&nbsp;уд&#65279;/&#65279;мин
          span(v-else) &#8212;

        template(v-else-if="column.key === 'body_temp'")
          span(v-if="data[column.key]" :class="bpTemp(data[column.key])") {{ data[column.key] }}&nbsp;c&#176;
          span(v-else) &#8212;

        template(v-else-if="column.key === 'alcohol'")
          span(v-if="data[column.key] || data[column.key] === 0" :class="alcoholColor(data[column.key])") {{ data[column.key] }}&nbsp;мг&#65279;/&#65279;л
          span(v-else) &#8212;

        template(v-else-if="column.key === 'health_complaint'")
          app-tooltip(
            v-if="data[column.key]"
            :content="`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--black\">${data[column.key]}<p>`"
            :icon="'comment'"
          )

        template(v-else-if="column.key === 'admission_to_work'")
          .exam-card__status.d-flex
            app-status(:type="'doctor'" :data="data")

        template(v-else)
          span(v-if="data[column.key]") {{ data[column.key] }}
          span(v-else) &#8212;

</template>

<script>
import is from 'is_js';
import variables from '@/helpers/variables';
import {toLocalDate, toLocalTime, toLocalTimeExam} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppTooltip from '@/components/common/Tooltip';
import AppStatus from '@/components/profile/common/Status';

export default {
  name: 'AppDoctorExamCard',
  components: {
    AppSvgIcon,
    AppTooltip,
    AppStatus,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    columns: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      v: variables,
    };
  },
  computed: {
    type() {
      return is.null(this.data.admission_to_work)
        ? this.v.exams.statuses.pending
        : this.v.exams.statuses.checked;
    },
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
    openModal(type, data) {
      this.$emit('openModal', type, data);
    },
    bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),  
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<99)|| (key<60),  
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
    bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.doctor-exam-card {
  padding-top: $spacer;
  padding-bottom: $spacer;
  padding-right: $spacer * 4;

  .icon {
    &__eye-open {
      width: $spacer * 2;
      height: $spacer * 1.2;
    }
  }
}
</style>
