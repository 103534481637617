<template lang="pug">
  .profile-dashboard__content
    .mb-5(v-if="notifications && notifications.length")
      transition-group(name="fade" tag="div")
        app-notification(
          v-for="(notification, index) in notifications" :key="notification.id"
          :data="notification"
          @delete="deleteNotification(notification.id, index)"
        )

    // exams
    .position-relative.mb-5
      app-loading(v-if="examsLoading")
      app-error-message(v-if="examsError" :message="examsError")

      transition(name="translucent")
        app-dashboard-card(
          v-if="exams"
          :goTo="'exams'"
          :classes="`${exams && exams.result && !exams.result.length ? 'is-empty' : ''}`"
        )
          template(v-slot:title)
            .d-flex.align-items-center.mb-4(v-if="exams.result && exams.result.length")
              span.flex-shrink-0.text-color--blue.me-3.d-xl-none
                app-svg-icon(name="smartwatch" view-box="0 0 17 24")
              span Осмотры пользователей для оценки

            .text-color--gray-light(v-else) Здесь будут отображаться осмотры пользователей для оценки

          template(v-slot:body)
            template(v-if="exams.result && exams.result.length")
              .profile-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        v-if="column.caption || column.icon"
                        type="button"
                        @click="sortingTable(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            app-svg-icon(name="down-arrow")

                ul.profile-table__body
                  li.profile-table__row(v-for="exam in exams.result.slice(0, limit)" :key="exam.id")
                    app-doctor-exam-card(
                      :data="exam"
                      :columns="columns"
                      @openModal="openExamDetailModal"
                    )

            .text-size--small.text-color--gray-light(v-else) Пока нет осмотров для оценки

          template(v-slot:button)
            template(v-if="exams && exams.result && exams.result.length") Все осмотры


    // exam detail modal
    app-doctor-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
      @success="getExamsPending"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as notificationsActionTypes} from '@/store/modules/notifications';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppNotification from '@/components/common/Notification';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppValidationErrors from '@/components/common/ValidationErrors';

import AppDashboardCard from '@/components/profile/common/dashboard/DashboardCard';
import AppDoctorExamCard from '@/components/profile/doctor/exams/DoctorExamCard';
import AppDoctorExamDetailModal from '@/components/profile/doctor/exams/DoctorExamDetailModal';

import {galleryMixin} from '@/mixins/gallery';
import {examDetailModalMixin} from '@/mixins/examDetailModal';

export default {
  name: 'AppDoctorDashboard',
  components: {
    AppTitle,
    AppSvgIcon,
    AppModal,
    AppGallery,
    AppNotification,
    AppLoading,
    AppErrorMessage,
    AppValidationErrors,
    AppDashboardCard,
    AppDoctorExamCard,
    AppDoctorExamDetailModal,
  },
  mixins: [galleryMixin, examDetailModalMixin],
  data() {
    return {
      v: variables,
      sortKey: '',
      sortInverse: false,
      limit: 5,
      columns: [
         {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата и время',
          key: 'exam_date_time',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Тип осмотра',
          key: 'exam_type_name',
          inverse: false,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-1',
          key: 'health_complaint',
          inverse: false,
        },
        {
          class: 'col-2 ms-auto',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      notifications: (state) => state.notifications.data,

      examsLoading: (state) => state.doctors.isLoading,
      exams: (state) => state.doctors.pendingExams,
      examsError: (state) => state.doctors.error,
    }),
  },
  created() {
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      this.$store.dispatch(notificationsActionTypes.getNotifications);
    },
    deleteNotification(id, index) {
      this.$store
        .dispatch(notificationsActionTypes.deleteNotification, {id})
        .then(() => this.notifications.splice(index, 1));
    },
    getExamsPending() {
      const data = {
        paging_params: {
          limit: this.v.pagination.limit,
          offset: 0,
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };

      this.$store.dispatch(doctorsActionTypes.getDoctorPendingExams, data).then(() => {
        this.reverseSorting();
      });
    },
    sortingTable(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExamsPending();
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
  },
};
</script>
