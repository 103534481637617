<template lang="pug">
  component(:is="currentComponent")
</template>

<script>
  import AppClientPatientDetail from '@/components/profile/client/patients/ClientPatientDetail';
  import AppAdminPatientDetail from '@/components/profile/admin/patients/AdminPatientDetail';

  export default {
    name: 'AppPatientDetail',
    data() {
      return {
        roleMapping: {
          client: AppClientPatientDetail,
          admin: AppAdminPatientDetail,
        },
      };
    },
    computed: {
      currentComponent() {
        return this.roleMapping[this.$store.state.auth.userRole];
      },
    },
  };
</script>