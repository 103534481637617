import {modalsMixin} from '@/mixins/modals';
import variables from '@/helpers/variables';

export const certificateModalMixin = {
  mixins: [modalsMixin],
  data() {
    return {
      v: variables,
      isModalVisible: {
        certificate: false,
      },
      certificate: {
        id: null,
        exam_id: null,
        number_kep: null,
        owner_kep: null,
        validity_kep: null,
        data_file: null,
        sign_file: null,
      },
    };
  },
  methods: {
    openCertificate(data) {
      for (const key in this.certificate) {
        this.certificate[key] = data[key];
      }

      this.openModal('certificate');
    },
  },
};
