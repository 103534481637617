<template lang="pug">
transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="close")
    template(v-slot:body)
      .text-center.mb-5
        app-title(:type="'large'") Просмотр документа
      .px-4
        form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
          .row.g-2
            .col.mb-2
              app-select(
                ref="selectStatus"
                :type="'form'"
                :placeholder="'Выберите тип документа'"
                :options="typeOpts"
                @select="selectStatus"
              )
            
            .col-14.mb-2
              label.form__label.text-color--blue Название документа
                input(
                :class="['form__field', 'mt-2']"
                type="text"
                v-model="data.document_name"
                placeholder="Введите номер / название документа"
                )
            .col-14.mb-2
                label.form__label.text-color--blue.mb-2 Пациент
                app-multiple-select(
                    ref="multipeSelectPatients"
                    :initial-values="patientsSelected"
                    :search-type="'patients'"
                    :placeholder="'Введите ФИО пользователя'"
                    :multiple="false"
                    results-view="detail"
                    @update="updateSelectedPatients"
                )
            .col-14
              label.form__label.text-color--blue Организация выдавшая документ
                input(
                    :class="['form__field', 'mt-2']"
                    type="text"
                    v-model="data.med_org_name"
                    placeholder="Укажите организацию, выдавшую документ"
                )
            .col-14
              label.form__label.text-color--blue Комментарий клиента
                textarea(
                    class="form__field mt-2"
                    v-model="data.client_comment"
                    placeholder="Добавьте комментарий при необходимости"
                )
            .col-14(v-if='data.document_status==="confirmed" || data.document_status==="rejected"')
              label.form__label.text-color--blue Комментарий врача
                textarea(
                    class="form__field mt-2"
                    v-model="data.doctor_comment"
                    placeholder="комментарий врача"
                )
            .col-14.mt-3
              label.form__label.text-color--blue Дата выдачи документа
                div(
                  class="mt-2"
                )
                  date-picker(
                    prefix-class="app"
                    placeholder="Дата выдачи документа"
                    format="DD.MM.YYYY"
                    v-model="data.issue_date"
                    value-type="YYYY-MM-DD"
                  )
            .col-7
              .form__file.d-flex.align-items-center.mt-2
                button(
                  @click='download([data.file_link])'
                ) Скачать справку
        .col-14.mt-25
          button(
            type="submit"
            @click="close"
            class=['button', 'button--accent', 'form__button']
          )
            span.button__content Вернуться
</template>

<script>
import {mapState} from 'vuex';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import {mask} from 'vue-the-mask';
import AppSelect from '@/components/common/Select';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import {
    modalsMixin
} from '@/mixins/modals';

export default {
    name: 'AppShowDocModal',
    directives: {
        mask,
    },
    components: {
        AppLoading,
        AppErrorMessage,
        AppValidationErrors,
        AppModal,
        AppTitle,
        AppSvgIcon,
        DatePicker,
        AppSelect,
        AppMultipleSelect
    },
    mixins: [modalsMixin],
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
        closeModal: {
            required: true,
            type: Function
        },
        data: {
            require: true,
            type: Object
        }
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    computed: {
      ...mapState({
        settings: (state) => state.settings.data,
      }),
    },
    data() {
        return {
            v: variables,
            isModalVisible: {
                success: false,
            },
            form: {
                firstName: '',
                docName: '',
                orgName: '',
                patients: [],
                rejectReason: '',
                docDate: '',
                docType: '',
                signature: null,
                file: null,
            },
            activeTab: 'one',
            localValidationErrors: null,

            signature: {
                localUrl: null,
            },
            datepicker: {
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
                defaultValue: {
                    panel: 'year',
                    year: '1980',
                },
            },
            successData: null,
            typeOpts: [
                {
                caption: 'справка о состоянии здоровья',
                icon: 'allright',
                value: 'health_certificate',
                selected: false,
                },
                {
                caption: 'справка о травме',
                icon: 'allright',
                value: 'injury_certificate',
                selected: false,
                },
                {
                caption: 'справка о результатах ХТИ',
                icon: 'allright',
                value: 'hti_certificate',
                selected: false,
                },
                {
                caption: 'заключение предварительного или периодический мед. осмотра',
                icon: 'allright',
                value: 'medical_exam_conclusion',
                selected: false,
                },
                {
                caption: 'акт медицинского освидетельствования',
                icon: 'allright',
                value: 'medical_exam_certificate',
                selected: false,
                },
                {
                caption: 'официальное обращение клиента',
                icon: 'allright',
                value: 'client_request',
                selected: false,
                },
            ],
        };
    },
    methods: {
        close() {
            console.log(this.closeModal)
            this.closeModal()
        },
        download(urls) {
          for(let i = 0; i < urls.length; i++) {
            if(urls[i]){
              var frame = document.createElement("iframe");
              frame.src = `${this.settings.s3_host}${urls[i]}`;
              frame["download"] = 1
              document.body.appendChild(frame);
            }
          }
        },
    },
    watch: {
      isVisible: {
        handler(newValue, oldValue) {
          // если форма открылась подставляем нужное значение в селект
          if (newValue === true && oldValue === false){
            console.log(this.typeOpts, this.data.document_type);
            for (let optId in this.typeOpts) {
              console.log(optId);
              if (this.typeOpts[optId].value === this.data.document_type) {
                this.typeOpts[optId].selected = true
              }
              else {
                this.typeOpts[optId].selected = false
              }
            }   
            this.patientsSelected = [
              this.data.patient_code
            ]
          }             
        },
      },
    },
};
</script>