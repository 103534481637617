import axios from '@/api/axios';

const getNotifications = () => axios.get('/notification');

const deleteNotification = (id) => axios.delete(`/notification/${id}`);

export default {
  getNotifications,
  deleteNotification,
};
