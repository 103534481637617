<template lang="pug">
  button(
    type="button"
    data-bs-toggle="tooltip"
    class=['tooltip-button']
    :class="[classes, {'is-without-icon': withoutIcon}, inline ? 'd-inline' : 'd-flex align-items-center justify-content-center']"
    :title="content"
    ref="tooltip"
    :data-bs-placement="placement"
    :data-bs-trigger="trigger"
    v-click-outside="hide"
  )
    app-svg-icon(v-if="!withoutIcon" :name="icon")
    slot(name="body")
</template>

<script>
import is from 'is_js';

import {Tooltip} from 'bootstrap';
import ClickOutside from 'vue-click-outside';

import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppTooltip',
  components: {
    AppSvgIcon,
  },
  directives: {
    ClickOutside,
  },
  props: {
    content: {
      type: String,
      required: true,
      default: 'Default content',
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    icon: {
      type: String,
      required: false,
      default: 'question',
    },
    withoutIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      instance: '',
    };
  },
  computed: {
    trigger() {
      return is.touchDevice() ? 'click' : 'hover';
    },
  },
  mounted() {
    this.initialize();
  },
  beforeDestroy() {
    this.hide();
  },
  methods: {
    initialize() {
      this.instance = new Tooltip(this.$refs.tooltip, {
        html: true
      });
    },
    hide() {
      this.instance.hide();
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  &-button {
    @include transition(color);

    .icon {
      @include transition(fill);

      &__comment {
        width: $spacer * 2;
        height: $spacer * 2;
        fill: get($colors, gray-hover)
      }
    }
    @include interaction('mouse') {
      &:hover {
        color: get($colors, orange-hover);

        &:not(.is-without-icon) {
          .icon {
            fill: get($colors, orange-hover);

            &__comment {
              fill: get($colors, blue);
            }
          }
        }
      }
    }
  }
  .tooltip-inner {
    margin: $spacer;
    text-align: left;
    line-height: $line-height-lg;
    white-space: pre-wrap;
    box-shadow: get($shadows, default);
  }
}
</style>
