<template lang="pug">
  .profile-patient-detail
    .profile-patient-detail__inner.is-filterable(:class="{'filter-is-open': isFilterOpen && isFilterVisible}")
      .profile-patient-detail__header.mb-3.mb-md-0
        .row.align-items-center
          .col-14.col-md-auto.me-auto.order-last.order-md-first
            .row.align-items-center.g-4
              .col-auto
                app-back-button(:prev-page="'patients'")
              .col
                app-title(:type="'large'") {{ isTouchDevice ? 'Пользователь' : 'Страница пользователя' }}

              .col-auto.d-md-none
                .d-flex.align-items-center.justify-content-end
                  button(
                    type="button"
                    @click.prevent="openModal('changePatient')"
                    class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                  )
                    span.flex-shrink-0
                      app-svg-icon(name="edit")

                  button(
                    type="button"
                    @click.prevent="openModal('deletePatient')"
                    class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                  )
                    span.flex-shrink-0
                      app-svg-icon(name="trash")



      .profile-patient-detail__content.mt-20.position-relative(id="isAnchorForBtn")
        app-loading(v-if="patientsLoading")
        app-error-message(v-if="patientsError" :message="patientsError")

        transition(name="translucent")
          div(v-if="patient")
            section(class=['profile-patient-detail__card'])
              .row.g-4
                .col-7.col-md-3
                  .d-md-none.text-color--blue.mb-3 Телефон и e-mail
                  .d-flex.align-items-center
                    .d-none.d-md-block.me-20
                      app-avatar(
                        :type="'patient'"
                        :src="patient.avatar"
                        :viewPhoto="true"
                        @openGallery="openGallery"
                        @change="openModal('changeAvatar')"
                        :classes="'middle'"
                      )
                    ul
                      li.mb-3
                        span(v-if="patient.email") {{ patient.email }}
                        span.text-color--blue(v-else) нет e-mail
                      li
                        span(v-if="patient.phone") {{ patient.phone }}
                        span.text-color--blue(v-else) нет телефона
                //new
                .col-7.d-md-none
                  .d-md-none.text-color--blue.mb-3 В/У
                  ul
                      li.mb-3
                        span(v-if="patient.email") здесь дата
                        span.text-color--blue(v-else) нет даты
                      li
                        span(v-if="patient.phone") от здесь дата
                        span.text-color--blue(v-else) нет даты
                .col-14.col-md-3.order-first.order-md-0
                  .d-flex.align-items-center
                    app-avatar(
                      :type="'patient'"
                      :src="patient.avatar"
                      :viewPhoto="true"
                      @openGallery="openGallery"
                      @change="openModal('changeAvatar')"
                      :classes="'middle d-md-none'"
                    )
                    .ms-10
                      .text-color--blue.mb-3 ФИО
                      span {{ patient.full_name }}
                .col-7.col-md-2
                  .text-color--blue.mb-3 СНИЛС
                  span {{ patient.snils }}
                .col-7.col-md-3
                  .text-color--blue.mb-3 Дата регистрации
                  span {{ toLocalDate(patient.creation_date) }}

                .col.d-none.d-md-block
                  .d-flex.align-items-center
                    .me-auto
                      .text-color--blue.mb-3 Осмотров
                      span {{ patient.exam_count ? patient.exam_count : 'нет' }}


                    .d-flex.align-items-center
                      button(
                        type="button"
                        @click.prevent="openModal('changePatient')"
                        class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="edit")

                      button(
                        type="button"
                        @click.prevent="openModal('deletePatient')"
                        class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-none d-md-flex align-items-center justify-content-center']
                      )
                        span.flex-shrink-0
                          app-svg-icon(name="trash")


              .row.gx-4.align-items-center.mt-15
                .col-auto
                  app-signature(
                    :sample="patient.signature"
                    :theme="'shadow'"
                    @openGallery="openGallery"
                    @change="openModal('changeSignature')"
                  )
                .col
                  .profile-patient-detail__card-detail.profile-card.no-hover
                    .row.g-4
                      .col-14.col-md-3
                        .text-color--blue.mb-2 Водительское удостоверение
                        span(v-if="patient.serial_number_driving_license") {{ patient.serial_number_driving_license }} от {{ toLocalDate(patient.driving_license_receiving)}}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Карта RFID
                        span(v-if="patient.rfid_id") {{ patient.rfid_id }}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Дата рождения
                        span(v-if="patient.date_birth") {{ toLocalDate(patient.date_birth) }}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Пол
                        span(v-if="patient.gender") {{ patient.gender == '1' ? 'Мужской' : 'Женский' }}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Табельный&nbsp;номер
                        span(v-if="patient.personal_number") {{ patient.personal_number }}
                        span.text-color--blue(v-else) &#8212;
                      .col
                        button(
                          v-if='patient.lock_status'
                          type="button"
                          @click="unlockUser()"
                          class=['button', 'button--accent', 'form__button']
                        )
                          .button__content Разблокировать


              .d-md-none.mt-15
                .row.align-items-center.g-4
                  .col-7
                    .d-flex.align-items-center
                      span.text-color--gray-light.me-2.d-md-none
                        app-svg-icon(name="diary")
                      span.text-color--blue-dark(v-if="patient.exam_count") {{ patient.exam_count }} {{ declOfNum(patient.exam_count, ['осмотр', 'осмотра', 'осмотров']) }}
                      span.text-color--blue(v-else) нет осмотров

                  .col-7
                    .d-flex.align-items-center
                      span.text-color--gray-light.me-2.d-md-none
                        app-svg-icon(name="smartwatch" view-box="0 0 17 24")
                      span.text-color--blue-dark(v-if="devicesCount > 0") {{ devicesCount }} {{ declOfNum(devicesCount, ['устройство', 'устройства', 'устройств']) }}
                      span.text-color--blue(v-else) нет устройств

            // patient exams
            .position-relative
              app-loading(v-if="examsLoading")
              app-error-message(v-if="examsError" :message="examsError")

            transition(name="fade" mode="out-in")
              .mt-15(v-if="exams")
                ul.profile-patient-detail__exams(v-if="exams.result.length")
                  li.profile-patient-detail__exams-el(v-for="el in sortedExams")
                    section(:id="`owner-${el.owner}`" class=['patient-exams', 'profile-card'])
                      .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
                        span.text-color--blue.mb-2.mb-xl-0 Владелец устройств:&ensp;
                        span {{ el.exams[0].device_owner_full_name }}

                      ul.patient-exams__list
                        li.patient-exams__list-el(v-for="exam in el.exams.slice(0, el.offset)" :key="exam.id")
                          app-patient-exam-card(
                            :patient="patient"
                            :parent-id="`owner-${el.owner}`"
                            :classes="'d-none d-xl-block'"
                            :data="exam"
                            @openModal="openExamDetailModal"
                            @openGallery="openGallery"
                            @openCertificate="openCertificate"
                          )
                          app-exam-card-mobile(
                            :data="exam"
                            :type="'patient-detail'"
                            :classes="'d-xl-none'"
                            @openModal="openExamDetailMobile"
                          )
                          
                      button(
                        v-if="el.offset < el.exams.length"
                        type="button"
                        @click="el.offset += pagination.limit"
                        class=['text-size--small link--accent', 'mt-4 me-4']
                      ) Показать еще (+{{ (el.exams.length - el.offset) >= pagination.limit ? pagination.limit : el.exams.length - el.offset}})

                      button(
                        v-if="el.offset > pagination.limit"
                        type="button"
                        @click="el.offset = pagination.limit"
                        class=['text-size--small link--accent', 'mt-4']
                      ) Свернуть

                .mt-5.text-color--blue.text-size--small(v-else) Осмотров не найдено

                transition(name="fade" mode="out-in")
                  .mt-15
                    ul.profile-patient-detail__exams
                      li.profile-patient-detail__exams-el
                        section(class=['patient-exams', 'profile-card'])
                            app-patient-block-history(
                              :user_id="$route.params.id"
                            )

                transition(name="fade" mode="out-in")
                  .mt-15
                    ul.profile-patient-detail__exams
                      li.profile-patient-detail__exams-el
                        section(class=['patient-exams', 'profile-card'])
                            app-patient-personal-borders(
                              :data="patient"
                              :patientCode="patient.code"
                            )
    
                transition(name="fade" mode="out-in")
                  .mt-15
                    ul.profile-patient-detail__exams
                      li.profile-patient-detail__exams-el
                        section(class=['patient-exams', 'profile-card'])
                            app-patient-med-docs(:patientCode="patient.code", :showDocInfo="showDocInfo")
    
                transition(name="fade" mode="out-in")
                  .mt-15
                    ul.profile-patient-detail__exams
                      li.profile-patient-detail__exams-el
                        section(class=['patient-exams', 'profile-card'])
                            app-patient-comments(:patientCode="patient.code", :showDocInfo="showDocInfo")
    
    // filter
    app-filter(
      ref="filter"
      v-if="isFilterVisible"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
      @getCount="getCountFilter"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
      @openEditModal="openModal"
      @openDeleteModal="openModal"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // change avatar modal
    app-patient-change-avatar-modal(
      v-if="patient"
      ref="changeAvatar"
      :patient="patient"
      :is-visible="isModalVisible.changeAvatar"
      @success="successChangePatient('changeAvatar')"
      @close="closeModal('changeAvatar')"
    )
    // change signature modal
    app-patient-change-signature-modal(
      v-if="patient"
      ref="changeSignature"
      :patient="patient"
      :is-visible="isModalVisible.changeSignature"
      @success="successChangePatient('changeSignature')"
      @close="closeModal('changeSignature')"
    )
    // change patient modal
    app-change-patient-modal(
      v-if="patient"
      :patient="patient"
      :is-visible="isModalVisible.changePatient"
      @success="successChangePatient('changePatient')"
      @close="closeModal('changePatient')"
    )
    // delete avatar modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить фотографию пользователя?
            p.text-color--blue.mt-3 {{ patient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteAvatar')"
                class=['button', 'button--danger', 'form__button']
              )
                .button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteAvatar(patient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                .button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.
    // delete signature modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteSignature" @close="closeModal('deleteSignature')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить подпись пользователя?
            p.text-color--blue.mt-3 {{ patient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteSignature')"
                class=['button', 'button--danger', 'form__button']
              )
                .button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteSignature(patient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                .button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.
    // exam detail modal
    app-exam-detail-modal(
      :is-slider="true"
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
      @slideTo="openExamDetailModal"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      :type="'patient-detail'"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // delete patient modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deletePatient" @close="closeModal('deletePatient')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить пользователя?
            p.text-color--blue.mt-3 {{ patient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deletePatient')"
                class=['button', 'button--danger', 'form__button']
              )
                .button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deletePatient(patient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                .button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! При удалении пользователя удалятся все его осмотры.<br>Отменить действие нельзя.

    // success delete modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="$router.go(-1)")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Пользователь успешно удалён!
            p.text-color--blue.mt-3 Ф.И.О. пользователя: {{ patient.full_name }}.

          .d-flex.justify-content-center.mt-5
            button(
              @click="$router.go(-1)"
              type="button"
              class=['button button--accent']
            )
              .button__content Назад в список

    // success change modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successChange" @close="closeModal('successChange')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") {{ successMessage }}
            p.text-color--blue.mt-3(v-if="patient") Ф.И.О. пользователя: {{ patient.full_name }}.

    
    //Handler doc
    handler-doc-modal(
        :is-visible="isVisible"
        :rowData="dataToShow"
        :showData="true"
        @close="close"
    )
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import variables from '@/helpers/variables';
import {declOfNum, toLocalDate} from '@/helpers/utils';

import simplebar from 'simplebar-vue';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';

import AppFilter from '@/components/profile/common/Filter';
import AppAvatar from '@/components/profile/common/Avatar';
import AppSignature from '@/components/profile/common/Signature';
import AppCertificateModal from '@/components/profile/common/CertificateModal';

import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';

import AppPatientBlockHistory from '@/components/profile/admin/patients/AdminPatientBlockHistory';
import AppPatientPersonalBorders from '@/components/profile/admin/patients/AdminPatientPersonalBorders';
import AppPatientMedDocs from '@/components/profile/admin/patients/AdminPatientMedDocs';
import AppPatientComments from '@/components/profile/admin/patients/AdminPatientComments';
import AppPatientExamCard from '@/components/profile/common/patients/PatientExamCard';
import AppChangePatientModal from '@/components/profile/common/patients/ChangePatientModal';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import AppPatientChangeSignatureModal from '@/components/profile/common/patients/PatientChangeSignatureModal';

import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';
import {filterMixin} from '@/mixins/filter';

import HandlerDocModal from '@/components/profile/admin/docs/HandlerDocModal';
import patientApi from '@/api/patients';

export default {
  name: 'AppAdminPatientDetail',
  components: {
    AppCertificateModal,
    AppGallery,
    AppBackButton,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppModal,
    AppFilter,
    AppAvatar,
    AppSignature,
    AppPatientExamCard,
    AppAdminPatientCard,
    AppExamCardMobile,
    AppExamDetailModal,
    AppExamDetailMobile,
    AppChangePatientModal,
    AppPatientChangeAvatarModal,
    AppPatientChangeSignatureModal,
    AppPatientBlockHistory,
    AppPatientPersonalBorders,
    AppPatientMedDocs,
    simplebar,
    HandlerDocModal,
    AppPatientComments
  },
  mixins: [
    examDetailModalMixin,
    examDetailMobileMixin,
    certificateModalMixin,
    galleryMixin,
    filterMixin,
  ],
  data() {
    return {
      dataToShow: {},
      isVisible: false,
      columns: [
                {
                    num: 0,
                    class: 'col-2',
                    caption: '№',
                    key: 'id',
                    inverse: false,
                },
                {
                    num: 1,
                    class: 'col-2',
                    caption: 'Дата и время',
                    key: 'admission_datetime',
                    inverse: false,
                },
                {
                    num: 2,
                    class: 'col-2',
                    caption: 'ФИО пользователя',
                    key: 'patient_full_name',
                    inverse: false,
                },
                {
                    num: 3,
                    class: 'col-6',
                    caption: 'Предварительный диагноз',
                    key: 'ra_and_rm_pre_diagnosis',
                    inverse: false,
                }
        ],
      v: variables,
      declOfNum,
      isModalVisible: {
        changePatient: false,
        changeAvatar: false,
        changeSignature: false,
        deleteAvatar: false,
        deleteSignature: false,
        deletePatient: false,
        successChange: false,
      },
      filterOptions: {
        examParams: true,
        date: true,
        time: true,
        bodyParams: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        },
      },
      pagination: {
        limit: 3,
        offset: 3,
      },
      settings: {
        patients: {
          negative: false,
          payload: [this.$route.params.id],
        },
        users: {
          negative: true,
          payload: [],
        },
      },
      sortedExams: [],
      successMessage: 'Успешно!',
    };
  },
  computed: {
    ...mapState({
      patientsLoading: (state) => state.patients.isLoading,
      patients: (state) => state.patients.data,
      patientsError: (state) => state.patients.error,

      examsLoading: (state) => state.exams.isLoading,
      exams: (state) => state.exams.data,
      examsCount: (state) => state.exams.count,
      examsError: (state) => state.exams.error,
    }),
    patient() {
      if (this.patients) {
        return this.patients.result[0];
      } else return null;
    },
    devicesCount() {
      return Array.from(new Set(this.patient.device_serials)).length;
    },
    isFilterVisible() {
      return this.patient && this.patient.exams.length;
    },
  },
  created() {
    this.getPatient();
    this.getExams(this.filters);
  },
  methods: {
    close() {
      this.isVisible = false;
    },
    showDocInfo(item) {
      this.dataToShow = item;
      this.isVisible = true;
      console.log('showDocInfo');
    },
    async unlockUser() {
      console.log('unlockUser');
      const formData = new FormData();
      formData.append('patient_code', this.patient.code);

      let results = await patientApi.unlockPatient(formData);
      this.patient.lock_status = false;
      console.log(results);
      this.$toast.success('Пользователь разблокирован');
      this.getPatient();
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    getPatient() {
      let data = {filters: this.settings};
      this.$store.dispatch(patientsActionTypes.getPatients, data).then(() => {
        if (this.isTouchDevice && this.isFilterVisible) this.$refs.filter.setBtnPosition();
      });
    },
    getExams(filters) {
      let data = {};
      data.filters = Object.assign({}, this.settings, filters);

      this.$store.dispatch(examsActionTypes.getExams, data).then((response) => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.isFiltered = Object.keys(filters).length > 0;
        this.sortingExams(response.result);
      });
    },
    getCountExams(filters) {
      let data = {};

      data.filters = Object.assign({}, this.settings, filters);

      this.$store.dispatch(examsActionTypes.getCountExams, data);
    },
    deletePatient({code}) {
      this.$store
        .dispatch(patientsActionTypes.deletePatient, {
          id: code,
        })
        .then(() => {
          this.closeModal('deletePatient');
          this.openModal('success');
        });
    },
    sortingExams(exams) {
      this.sortedExams = [];

      if (exams.length) {
        Array.from(new Set(exams.map((el) => el.device_owner_id))).forEach((owner) => {
          this.sortedExams.push({
            owner,
            exams: exams.filter((el) => el.device_owner_id === owner),
            offset: this.pagination.offset,
          });
        });
      }
      return this.sortedExams;
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    deleteSignature({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientSignature, formData).then(() => {
        this.successChangePatient('deleteSignature');
      });
    },
    successChangePatient(slug) {
      switch (slug) {
        case 'changePatient':
          this.successMessage = 'Данные успешно изменены!';
          break;

        case 'changeAvatar':
          this.successMessage = 'Фотография успешно изменена!';
          break;

        case 'changeSignature':
          this.successMessage = 'Подпись успешно изменена!';
          break;

        case 'deleteAvatar':
          this.successMessage = 'Фотография успешно удалена!';
          break;

        case 'deleteSignature':
          this.successMessage = 'Подпись успешно удалена!';
          break;
      }

      this.getPatient();

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('successChange');
    },
  },
};
</script>

<style lang="scss">
.profile-patient-detail {
  &__card {
    @include font-size(get($text-sizes, small));
    color: get($colors, black);

    &-detail {
      border-radius: $border-radius-sm;
    }
    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
      border-top: $border-width solid get($colors, gray-hover);
      border-bottom: $border-width solid get($colors, gray-hover);
    }
  }
  &__exams {
    &-el {
      & + .profile-patient-detail__exams-el {
        margin-top: $spacer * 2;
      }
    }
  }
}
.patient-exams {
  padding-top: $spacer * 1.8;
  padding-bottom: $spacer * 1.8;
  border-radius: $border-radius-sm;
  box-shadow: get($shadows, default);

  &:before {
    display: none;
  }
  @include mediaToDown($xl) {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  &__list {
    &-el {
      border-bottom: $border-width solid get($colors, gray);

      &:last-child {
        border-bottom: none;
      }
      @include mediaToDown($xl) {
        border-bottom: none;

        & + .patient-exams__list-el {
          margin-top: $spacer * 0.6;
        }
        .profile-card {
          overflow: hidden;
          border-radius: $border-radius-sm;
          padding: $spacer * 1.6 $spacer * 2;
          box-shadow: get($shadows, default);
        }
      }
      @include mediaToDown($md) {
        .profile-card {
          padding: $spacer * 1.6 $spacer * 1.2;
        }
      }
    }
  }
}
</style>
