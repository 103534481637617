<template lang="pug">
  .profile-patient-detail
    .profile-patient-detail__inner.is-filterable(:class="{'filter-is-open': isFilterOpen && isFilterVisible}")
      .profile-patient-detail__header.mb-3.mb-md-0
        .row.align-items-center
          .col-14.col-md-auto.me-auto
            .row.align-items-center.g-4
              .col-auto
                app-back-button(:prev-page="'patients'")
              .col
                app-title(:type="'large'") {{ isTouchDevice ? 'Пользователь' : 'Страница пользователя' }}

              .col-auto.d-md-none
                .d-flex.align-items-center.justify-content-end
                  button(
                    type="button"
                    @click.prevent="openModal('changePatient')"
                    class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                  )
                    span.flex-shrink-0
                      app-svg-icon(name="edit")

                  button(
                    type="button"
                    @click.prevent="openModal('deletePatient')"
                    class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                  )
                    span.flex-shrink-0
                      app-svg-icon(name="trash")


      .profile-patient-detail__content.mt-20.position-relative(id="isAnchorForBtn")
        transition(name="fade" mode="out-in")
          app-loading(v-if="patientsLoading")
          app-error-message(v-if="patientsError" :message="patientsError")

          div(v-if="patient")
            section.profile-patient-detail__card.mb-4.mb-md-5
              .row.g-4
                .col-7.col-md-3
                  .d-md-none.text-color--blue.mb-3 Телефон и e-mail
                  .d-flex.align-items-center
                    .d-none.d-md-block.me-20
                      app-avatar(
                        :type="'patient'"
                        :src="patient.avatar"
                        :viewPhoto="true"
                        @openGallery="openGallery"
                        @change="openModal('changeAvatar')"
                        :classes="'middle'"
                      )
                    
                    ul(v-if="patient.email || patient.phone")
                      li.mb-2
                        span(v-if="patient.email") {{ patient.email }}
                        span.text-color--blue(v-else) нет e-mail
                      li
                        span(v-if="patient.phone") {{ patient.phone }}
                        span.text-color--blue(v-else) нет телефона
                    
                    span.text-color--blue(v-else) &#8212;

                .col-14.col-md-3.order-first.order-md-0
                  .d-flex.align-items-center
                    app-avatar(
                      :type="'patient'"
                      :src="patient.avatar"
                      :viewPhoto="true"
                      @openGallery="openGallery"
                      @change="openModal('changeAvatar')"
                      :classes="'middle d-md-none'"
                    )
                    .ms-10
                      .text-color--blue.mb-3.mb-md-4 ФИО
                      span {{ patient.full_name }}

                .col-7.col-md-3
                  .text-color--blue.mb-3.mb-md-4 Дата регистрации
                  span {{ toLocalDate(patient.creation_date) }}

                .col-3.d-none.d-md-block
                  .text-color--blue.mb-4 Осмотров
                  span {{ patient.exam_count ? patient.exam_count : 'нет' }}
                  
                .col-auto.ms-auto.d-none.d-md-block
                  .d-flex.align-items-center
                    button(
                      type="button"
                      @click.prevent="openModal('changePatient')"
                      class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                    )
                      span.flex-shrink-0
                        app-svg-icon(name="edit")

                    button(
                      type="button"
                      @click.prevent="openModal('deletePatient')"
                      class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                    )
                      span.flex-shrink-0
                        app-svg-icon(name="trash")
              
              
              .row.gx-4.align-items-center.mt-15
                .col-auto
                  app-signature(
                    :sample="patient.signature"
                    :theme="'shadow'"
                    @openGallery="openGallery"
                    @change="openModal('changeSignature')"
                  )
                .col
                  .profile-patient-detail__card-detail.profile-card.no-hover
                    .row.g-4
                      .col-14.col-md-3
                        .text-color--blue.mb-2 Водительское удостоверение
                        span(v-if="patient.serial_number_driving_license") {{ patient.serial_number_driving_license }}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Карта RFID
                        span(v-if="patient.rfid_id") {{ patient.rfid_id }}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Дата рождения
                        span(v-if="patient.date_birth") {{ toLocalDate(patient.date_birth) }}
                        span.text-color--blue(v-else) &#8212;

                      .col
                        .text-color--blue.mb-2 Пол
                        span(v-if="patient.gender") {{ patient.gender == '1' ? 'Мужской' : 'Женский' }}
                        span.text-color--blue(v-else) &#8212;

                      //.col
                        .text-color--blue.mb-2 Сведения о состоянии здоровья
                        span.text-color--blue &#8212;
            
              .d-md-none.mt-15
                .row.align-items-center.g-4
                  .col-7
                    .d-flex.align-items-center
                      span.text-color--gray-light.me-2.d-md-none
                        app-svg-icon(name="diary")
                      span.text-color--blue-dark(v-if="patient.exam_count") {{ patient.exam_count }} {{ declOfNum(patient.exam_count, ['осмотр', 'осмотра', 'осмотров']) }}
                      span.text-color--blue(v-else) нет осмотров
      
                  .col-7
                    .d-flex.align-items-center
                      span.text-color--gray-light.me-2.d-md-none
                        app-svg-icon(name="smartwatch" view-box="0 0 17 24")
                      span.text-color--blue-dark(v-if="patient.devices_count > 0") {{ patient.devices_count }} {{ declOfNum(patient.devices_count, ['устройство', 'устройства', 'устройств']) }}
                      span.text-color--blue(v-else) нет устройств
            
            // patient exams
            transition(name="fade" mode="out-in")
              app-loading(v-if="examsLoading")
              app-error-message(v-if="examsError" :message="examsError")

              div(v-if="exams")
                section(v-if="exams.result.length" id="patient-exams" class=['patient-exams', 'profile-card'])
                  ul.patient-exams__list
                    li.patient-exams__list-el(v-for="exam in exams.result.slice(0, pagination.offset)" :key="exam.id")
                      app-patient-exam-card(
                        :patient="patient"
                        :classes="'d-none d-xl-block'"
                        :data="exam"
                        @openModal="openExamDetailModal"
                        @openCertificate="openCertificate"
                        @openGallery="openGallery"
                      )
                      app-exam-card-mobile(
                        :data="exam"
                        :type="'patient-detail'"
                        :classes="'d-xl-none'"
                        @openModal="openExamDetailMobile"
                      )

                  button(
                    v-if="pagination.offset < exams.result.length"
                    type="button"
                    @click="pagination.offset += pagination.limit"
                    class=['text-size--small link--accent', 'mt-4 me-4']
                  ) Показать еще (+{{ (exams.result.length - pagination.offset) >= pagination.limit ? pagination.limit : exams.result.length - pagination.offset}})

                  button(
                    v-if="pagination.offset > pagination.limit"
                    type="button"
                    @click="pagination.offset = pagination.limit"
                    class=['text-size--small link--accent', 'mt-4']
                  ) Свернуть

                .text-color--blue.text-size--small(v-else) Осмотров не найдено


    // filter
    app-filter(
      ref="filter"
      v-if="isFilterVisible"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
      @getCount="getCountFilter"
    )
    // change avatar modal
    app-patient-change-avatar-modal(
      v-if="patient"
      ref="changeAvatar"
      :patient="patient"
      :is-visible="isModalVisible.changeAvatar"
      @success="successChangePatient('changeAvatar')"
      @close="closeModal('changeAvatar')"
    )
    // change signature modal
    app-patient-change-signature-modal(
      v-if="patient"
      ref="changeSignature"
      :patient="patient"
      :is-visible="isModalVisible.changeSignature"
      @success="successChangePatient('changeSignature')"
      @close="closeModal('changeSignature')"
    )
    // change patient modal
    app-change-patient-modal(
      v-if="patient"
      :patient="patient"
      :is-visible="isModalVisible.changePatient"
      @close="closeModal('changePatient')"
      @success="successChangePatient('changePatient')"
    )
    // delete avatar modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить фотографию пользователя?
            p.text-color--blue.mt-3 {{ patient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteAvatar')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteAvatar(patient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

    // delete signature modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteSignature" @close="closeModal('deleteSignature')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить подпись пользователя?
            p.text-color--blue.mt-3 {{ patient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteSignature')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteSignature(patient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      :type="'patient-detail'"
      @openModal="openExamDetailModal"
      @openCertificate="openCertificate"
      @close="closeModal('examDetailMobile')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
      @openEditModal="openModal"
      @openDeleteModal="openModal"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // delete patient modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deletePatient" @close="closeModal('deletePatient')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить пользователя?
            p.text-color--blue.mt-3 {{ patient.full_name }}
        
          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deletePatient')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deletePatient(patient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить
        
          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! При удалении пользователя удалятся все его осмотры.<br>Отменить действие нельзя.
  
    // success delete modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successDeleted" @close="$router.go(-1)")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Пользователь успешно удалён!
            p.text-color--blue.mt-3 Ф.И.О. пользователя: {{ patient.full_name }}.
        
          .d-flex.justify-content-center.mt-5
            button(
              @click="$router.go(-1)"
              type="button"
              class=['button button--accent']
            )
              span.button__content Назад в список

    // success change modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") {{ successMessage }}
            p.text-color--blue.mt-3(v-if="patient") Ф.И.О. пользователя: {{ patient.full_name }}.
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import variables from '@/helpers/variables';
import {declOfNum, toLocalDate} from '@/helpers/utils';

import simplebar from 'simplebar-vue';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppSignature from '@/components/profile/common/Signature';

import AppFilter from '@/components/profile/common/Filter';
import AppAvatar from '@/components/profile/common/Avatar';
import AppCertificateModal from '@/components/profile/common/CertificateModal';

import AppPatientExamCard from '@/components/profile/common/patients/PatientExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';

import AppChangePatientModal from '@/components/profile/common/patients/ChangePatientModal';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import AppPatientChangeSignatureModal from '@/components/profile/common/patients/PatientChangeSignatureModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';
import {filterMixin} from '@/mixins/filter';

export default {
  name: 'AppClientPatientDetail',
  components: {
    AppBackButton,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppModal,
    AppGallery,
    AppFilter,
    AppCertificateModal,
    AppAvatar,
    AppSignature,
    AppPatientExamCard,
    AppAdminPatientCard,
    AppExamCardMobile,
    AppExamDetailModal,
    AppExamDetailMobile,
    AppChangePatientModal,
    AppPatientChangeAvatarModal,
    AppPatientChangeSignatureModal,
    simplebar,
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, certificateModalMixin, galleryMixin, filterMixin],
  data() {
    return {
      v: variables,
      declOfNum,
      pagination: {
        limit: variables.pagination.limit,
        offset: variables.pagination.limit,
      },
      isModalVisible: {
        changeAvatar: false,
        changeSignature: false,
        changePatient: false,
        deleteAvatar: false,
        deleteSignature: false,
        deletePatient: false,
        successDeleted: false
      },
      filterOptions: {
        examParams: true,
        date: true,
        time: true,
        bodyParams: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        },
      },
      successMessage: 'Успешно',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      patientsLoading: (state) => state.patients.isLoading,
      patients: (state) => state.patients.data,
      patientsError: (state) => state.patients.error,

      examsLoading: (state) => state.exams.isLoading,
      exams: (state) => state.exams.data,
      examsCount: (state) => state.exams.count,
      examsError: (state) => state.exams.error,
    }),
    patient() {
      if (this.patients) {
        return this.patients.result[0];
      } else return null;
    },
    settings() {
      return {
        patients: {
          negative: false,
          payload: [this.$route.params.id],
        },
        users: {
          negative: false,
          payload: [this.currentUser.id],
        },
      };
    },
    isFilterVisible() {
      return this.patient && this.patient.exams.length;
    },
  },
  created() {
    this.getPatient();
    this.getExams(this.filters);
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    getPatient() {
      let data = {filters: this.settings};
      this.$store.dispatch(patientsActionTypes.getPatients, data).then(() => {
        if (this.isTouchDevice && this.isFilterVisible) this.$refs.filter.setBtnPosition();
      });
    },
    getExams(filters) {
      let data = {};
      data.filters = Object.assign({}, this.settings, filters);

      this.$store.dispatch(examsActionTypes.getExams, data).then(() => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.isFiltered = Object.keys(filters).length > 0;
      });
    },
    getCountExams(filters) {
      let data = {};
      data.filters = Object.assign({}, this.settings, filters);

      this.$store.dispatch(examsActionTypes.getCountExams, data);
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    deleteSignature({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientSignature, formData).then(() => {
        this.successChangePatient('deleteSignature');
      });
    },
    deletePatient({code}) {
      this.$store
        .dispatch(patientsActionTypes.deletePatient, {
          id: code,
        })
        .then(() => {
          this.closeModal('deletePatient');
          this.openModal('successDeleted');
        });
    },
    successChangePatient(slug) {
      switch (slug) {
        case 'changeAvatar':
          this.successMessage = 'Фотография успешно изменена!';
          break;

        case 'changeSignature':
          this.successMessage = 'Подпись успешно изменена!';
          break;

        case 'deleteAvatar':
          this.successMessage = 'Фотография успешно удалена!';
          break;

        case 'deleteSignature':
          this.successMessage = 'Подпись успешно удалена!';
          break;

        default:
          this.successMessage = 'Данные успешно изменены!';
          break;
      }

      this.getPatient();

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');
    },
  },
};
</script>
