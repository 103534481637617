import axios from '@/api/axios';

const getUsers = (credentials) => axios.post('/user', credentials);

const getCountUsers = (credentials) => axios.post('/user/count', credentials);

const changeUserEmail = (credentials) => axios.post('/user/changeEmail', credentials);

const changeUserNames = (credentials) => axios.post('/user/changeNames', credentials);

const changeUserPassword = (credentials) => axios.post('/user/changePassword', credentials);

const changeUserAvatar = (credentials) => axios.post('/user/setAvatar', credentials);

const forgotPassword = (credentials) => axios.post('/user/forgotPassword', credentials);

const deleteUser = (id) => axios.delete(`/user/${id}`);

export default {
  getUsers,
  getCountUsers,
  changeUserEmail,
  changeUserNames,
  changeUserPassword,
  changeUserAvatar,
  forgotPassword,
  deleteUser,
};
