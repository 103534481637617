<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(v-if="isVisible" @close="closeForm")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") {{ slug === 'create' ? 'Создание сценария' : 'Настройка автоматического врача' }}

          .px-2
            form(@submit.prevent="" :class="['form', 'text-color--blue', {'is-submitting': isSubmitting}]")
              app-validation-errors(
                v-if="locationErrors"
                :validation-errors="locationErrors"
                :classes="'mb-3'"
              )
              .mb-5
                app-title Настройки для допуска к трудовым обязанностям
                p.text-size--small.mt-2 Отобразите параметры для не допуска к трудовым обязанностям. Для всех значений, которые будут выходить за пределы интервалов, будет сформирован недопуск

              .row.g-5.mb-60
                .col-14.col-md-7
                  .form__caption.mb-4
                    span Интервал значений&ensp;
                    app-tooltip(
                      :inline="true"
                      :withoutIcon="true"
                      :content="'Систолическое давление называют верхним. На данную величину оказывает влияние сосудистое сопротивление, а также частота и сила сердечных сокращений.'"
                    )
                      template(v-slot:body)
                        span.text-decoration--underline верхнего
                    span &ensp;давления

                  .form__range(:class="{'is-changed': rangeOptions.bpHigh.isActive}")
                    .d-flex.align-items-end
                      span.form__range-icon.d-flex.me-3.flex-shrink-0
                        app-svg-icon(name="cardio")
                      .flex-grow-1
                        Slider(
                          v-model="form.bpHigh"
                          class="range"
                          :min="rangeOptions.bpHigh.min"
                          :max="rangeOptions.bpHigh.max"
                          @change="bpHighChange($event, {value: form.bpHigh})"
                        )

                    .row.align-items-center.justify-content-between.g-4.mt-0
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.bpHigh[0]"
                            :min="rangeOptions.bpHigh.min"
                            :max="rangeOptions.bpHigh.max"
                            type="number"
                            autocomplete="off"
                            @change="bpHighChange($event, {value: form.bpHigh})"
                          )
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.bpHigh[1]"
                            :min="rangeOptions.bpHigh.min"
                            :max="rangeOptions.bpHigh.max"
                            type="number"
                            autocomplete="off"
                            @change="bpHighChange($event, {value: form.bpHigh})"
                          )

                .col-14.col-md-7
                  .form__caption.mb-4
                    span Интервал значений&ensp;
                    app-tooltip(
                      :inline="true"
                      :withoutIcon="true"
                      :content="'В промежутке между сокращениями (фаза систолы), фиксируется величина диастолического или нижнего артериального давления. Данная величина зависит исключительно от сосудистого сопротивления.'"
                    )
                      template(v-slot:body)
                        span.text-decoration--underline нижнего
                    span &ensp;давления

                  .form__range(:class="{'is-changed': rangeOptions.bpLow.isActive}")
                    .d-flex.align-items-end
                      span.form__range-icon.d-flex.me-3.flex-shrink-0
                        app-svg-icon(name="cardio")
                      .flex-grow-1
                        Slider(
                          v-model="form.bpLow"
                          class="range"
                          :min="rangeOptions.bpLow.min"
                          :max="rangeOptions.bpLow.max"
                          @change="bpLowChange($event, {value: form.bpLow})"
                        )

                    .row.align-items-center.justify-content-between.g-4.mt-0
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.bpLow[0]"
                            :min="rangeOptions.bpLow.min"
                            :max="rangeOptions.bpLow.max"
                            type="number"
                            autocomplete="off"
                            @change="bpLowChange($event, {value: form.bpLow})"
                          )
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.bpLow[1]"
                            :min="rangeOptions.bpLow.min"
                            :max="rangeOptions.bpLow.max"
                            type="number"
                            autocomplete="off"
                            @change="bpLowChange($event, {value: form.bpLow})"
                          )

                .col-14.col-md-7
                  .form__caption.mb-4 Интервал значений пульса
                  .form__range(:class="{'is-changed': rangeOptions.heartRate.isActive}")
                    .d-flex.align-items-end
                      span.form__range-icon.d-flex.me-3.flex-shrink-0
                        app-svg-icon(name="pulse")
                      .flex-grow-1
                        Slider(
                          v-model="form.heartRate"
                          class="range"
                          :min="rangeOptions.heartRate.min"
                          :max="rangeOptions.heartRate.max"
                          @change="heartRateChange($event, { value: form.heartRate})"
                        )

                    .row.align-items-center.justify-content-between.g-4.mt-0
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.heartRate[0]"
                            :min="rangeOptions.heartRate.min"
                            :max="rangeOptions.heartRate.max"
                            type="number"
                            autocomplete="off"
                            @change="heartRateChange($event, {value: form.heartRate})"
                          )
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.heartRate[1]"
                            :min="rangeOptions.heartRate.min"
                            :max="rangeOptions.heartRate.max"
                            type="number"
                            autocomplete="off"
                            @change="heartRateChange($event, {value: form.heartRate})"
                          )

                .col-14.col-md-7
                  .form__caption.mb-4 Интервал значений температуры тела
                  .form__range(:class="{'is-changed': rangeOptions.bodyTemp.isActive}")
                    .d-flex.align-items-end
                      span.form__range-icon.d-flex.me-3.flex-shrink-0
                        app-svg-icon(name="temp")
                      .flex-grow-1
                        Slider(
                          v-model="form.bodyTemp"
                          class="range"
                          :step="rangeOptions.bodyTemp.step"
                          :format="rangeOptions.bodyTemp.format"
                          :min="rangeOptions.bodyTemp.min"
                          :max="rangeOptions.bodyTemp.max"
                          @change="bodyTempChange($event, { value: form.bodyTemp})"
                        )

                    .row.align-items-center.justify-content-between.g-4.mt-0
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.bodyTemp[0]"
                            :min="rangeOptions.bodyTemp.min"
                            :max="rangeOptions.bodyTemp.max"
                            type="number"
                            step="0.01"
                            autocomplete="off"
                            @change="bodyTempChange($event, {value: form.bodyTemp})"
                          )
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.bodyTemp[1]"
                            :min="rangeOptions.bodyTemp.min"
                            :max="rangeOptions.bodyTemp.max"
                            type="number"
                            step="0.01"
                            autocomplete="off"
                            @change="bodyTempChange($event, {value: form.bodyTemp})"
                          )

                .col-14.col-md-7
                  .form__caption.mb-4 Интервал значений объёма алкоголя в&nbsp;выдыхаемом воздухе, мг/л
                  .form__range(:class="{'is-changed': rangeOptions.alcohol.isActive}")
                    .d-flex.align-items-end
                      span.form__range-icon.d-flex.me-3.flex-shrink-0
                        app-svg-icon(name="breathalyzer")
                      .flex-grow-1
                        Slider(
                          v-model="form.alcohol"
                          class="range"
                          :step="rangeOptions.alcohol.step"
                          :format="rangeOptions.alcohol.format"
                          :min="rangeOptions.alcohol.min"
                          :max="rangeOptions.alcohol.max"
                          @change="alcoholChange($event, {value: form.alcohol })"
                        )

                    .row.align-items-center.justify-content-between.g-4.mt-0
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.alcohol[0]"
                            :min="rangeOptions.alcohol.min"
                            :max="rangeOptions.alcohol.max"
                            type="number"
                            step="0.01"
                            autocomplete="off"
                            @change="alcoholChange($event, {value: form.alcohol})"
                          )
                      .col-3
                        label.form__label
                          input(
                            :class="['form__range-input']"
                            v-model.lazy="form.alcohol[1]"
                            :min="rangeOptions.alcohol.min"
                            :max="rangeOptions.alcohol.max"
                            type="number"
                            step="0.01"
                            autocomplete="off"
                            @change="alcoholChange($event, {value: form.alcohol})"
                          )

              .mb-4
                app-title Назначить клиентов
                p.text-size--small.text-color--blue.mt-2 Назначьте клиентов для проверки их пользователей автоматическим врачом

              .row.align-items-end.g-5.mb-60
                .col-14
                  app-multiple-select(
                    v-if="filters"
                    ref="multipeSelectUsers"
                    :initial-values="usersValues"
                    :results-view="'detail'"
                    :search-type="'users'"
                    :placeholder="'Введите название организации или ФИО ИП'"
                    @update="updateSelectedUsers"
                    @dirty="form.isDirty.users = true"
                  )
                  ul.form__error(v-if="v$.form.users.$invalid && isDirty")
                    li {{ v.errors.required }}

              div(:class="{'is-disabled': v$.form.users.$invalid}")
                .mb-4
                  app-title Исключения
                  p.text-size--small.text-color--blue.mt-2 Вы можете добавить пользователя с индивидуальными показателями

                .row.align-items-end.g-5.mb-60
                  .col-14
                    app-multiple-select(
                      v-if="filters"
                      ref="multipeSelectPatients"
                      :initial-values="exceptionsValues"
                      :results-view="'custom'"
                      :search-params="form.users"
                      :search-type="'patients'"
                      @update="updateSelectedPatients"
                    )
                      template(v-slot:body)
                        li(v-for="(option, index) in form.patients" :key="index" class=['multiple-select__selected-list-el'])
                          .row.mb-4
                            .col
                              p.text-color--black {{ option.text }}
                              p.text-size--small Отобразите интервалы для данного пользователя.

                            .col-auto
                              button(
                                type="button"
                                @click="unselectPatient(index)"
                                class=['text-color--danger']
                              )
                                app-svg-icon(name="close")

                          .row.align-items-end.g-5
                            .col-14.col-md-7
                              .form__caption.mb-4
                                span Интервал значений&ensp;
                                app-tooltip(
                                  :inline="true"
                                  :withoutIcon="true"
                                  :content="'Систолическое давление называют верхним. На данную величину оказывает влияние сосудистое сопротивление, а также частота и сила сердечных сокращений.'"
                                )
                                  template(v-slot:body)
                                    span.text-decoration--underline верхнего
                                span &ensp;давления

                              .form__range(:class="{'is-changed': rangeOptions.bpHigh.isActive}")
                                .d-flex.align-items-end
                                  span.form__range-icon.d-flex.me-3.flex-shrink-0
                                    app-svg-icon(name="cardio")
                                  .flex-grow-1
                                    Slider(
                                      v-model="option.settings.bpHigh.values"
                                      class="range"
                                      :min="rangeOptions.bpHigh.min"
                                      :max="rangeOptions.bpHigh.max"
                                      @change="bpHighChange($event, option)"
                                    )
                                .row.align-items-center.justify-content-between.g-4.mt-0
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.bpHigh.values[0]"
                                        :min="rangeOptions.bpHigh.min"
                                        :max="rangeOptions.bpHigh.max"
                                        type="number"
                                        autocomplete="off"
                                        @change="bpHighChange($event, option)"
                                      )
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.bpHigh.values[1]"
                                        :min="rangeOptions.bpHigh.min"
                                        :max="rangeOptions.bpHigh.max"
                                        type="number"
                                        autocomplete="off"
                                        @change="bpHighChange($event, option)"
                                      )

                            .col-14.col-md-7
                              .form__caption.mb-4
                                span Интервал значений&ensp;
                                app-tooltip(
                                  :inline="true"
                                  :withoutIcon="true"
                                  :content="'В промежутке между сокращениями (фаза систолы), фиксируется величина диастолического или нижнего артериального давления. Данная величина зависит исключительно от сосудистого сопротивления.'"
                                )
                                  template(v-slot:body)
                                    span.text-decoration--underline нижнего
                                span &ensp;давления

                              .form__range(:class="{'is-changed': rangeOptions.bpLow.isActive}")
                                .d-flex.align-items-end
                                  span.form__range-icon.d-flex.me-3.flex-shrink-0
                                    app-svg-icon(name="cardio")
                                  .flex-grow-1
                                    Slider(
                                      v-model="option.settings.bpLow.values"
                                      class="range"
                                      :min="rangeOptions.bpLow.min"
                                      :max="rangeOptions.bpLow.max"
                                      @change="bpLowChange($event, option)"
                                    )

                                .row.align-items-center.justify-content-between.g-4.mt-0
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.bpLow.values[0]"
                                        :min="rangeOptions.bpLow.min"
                                        :max="rangeOptions.bpLow.max"
                                        type="number"
                                        autocomplete="off"
                                        @change="bpLowChange($event, option)"
                                      )
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.bpLow.values[1]"
                                        :min="rangeOptions.bpLow.min"
                                        :max="rangeOptions.bpLow.max"
                                        type="number"
                                        autocomplete="off"
                                        @change="bpLowChange($event, option)"
                                      )

                            .col-14.col-md-7
                              .form__caption.mb-4 Интервал значений пульса
                              .form__range(:class="{'is-changed': rangeOptions.heartRate.isActive}")
                                .d-flex.align-items-end
                                  span.form__range-icon.d-flex.me-3.flex-shrink-0
                                    app-svg-icon(name="pulse")
                                  .flex-grow-1
                                    Slider(
                                      v-model="option.settings.heartRate.values"
                                      class="range"
                                      :min="rangeOptions.heartRate.min"
                                      :max="rangeOptions.heartRate.max"
                                      @change="heartRateChange($event, option)"
                                    )

                                .row.align-items-center.justify-content-between.g-4.mt-0
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.heartRate.values[0]"
                                        :min="rangeOptions.heartRate.min"
                                        :max="rangeOptions.heartRate.max"
                                        type="number"
                                        autocomplete="off"
                                        @change="heartRateChange($event, option)"
                                      )
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.heartRate.values[1]"
                                        :min="rangeOptions.heartRate.min"
                                        :max="rangeOptions.heartRate.max"
                                        type="number"
                                        autocomplete="off"
                                        @change="heartRateChange($event, option)"
                                      )

                            .col-14.col-md-7
                              .form__caption.mb-4 Интервал значений температуры тела
                              .form__range(:class="{'is-changed': rangeOptions.bodyTemp.isActive}")
                                .d-flex.align-items-end
                                  span.form__range-icon.d-flex.me-3.flex-shrink-0
                                    app-svg-icon(name="temp")
                                  .flex-grow-1
                                    Slider(
                                      v-model="option.settings.bodyTemp.values"
                                      class="range"
                                      :step="rangeOptions.bodyTemp.step"
                                      :format="rangeOptions.bodyTemp.format"
                                      :min="rangeOptions.bodyTemp.min"
                                      :max="rangeOptions.bodyTemp.max"
                                      @change="bodyTempChange($event, option)"
                                    )

                                .row.align-items-center.justify-content-between.g-4.mt-0
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.bodyTemp.values[0]"
                                        :min="rangeOptions.bodyTemp.min"
                                        :max="rangeOptions.bodyTemp.max"
                                        type="number"
                                        step="0.01"
                                        autocomplete="off"
                                        @change="bodyTempChange($event, option)"
                                      )
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.bodyTemp.values[1]"
                                        :min="rangeOptions.bodyTemp.min"
                                        :max="rangeOptions.bodyTemp.max"
                                        type="number"
                                        step="0.01"
                                        autocomplete="off"
                                        @change="bodyTempChange($event, option)"
                                      )

                            .col-14.col-md-7
                              .form__caption.mb-4 Интервал значений объёма алкоголя в&nbsp;выдыхаемом воздухе, мг/л
                              .form__range(:class="{'is-changed': rangeOptions.alcohol.isActive}")
                                .d-flex.align-items-end
                                  span.form__range-icon.d-flex.me-3.flex-shrink-0
                                    app-svg-icon(name="breathalyzer")
                                  .flex-grow-1
                                    Slider(
                                      v-model="option.settings.alcohol.values"
                                      class="range"
                                      :step="rangeOptions.alcohol.step"
                                      :format="rangeOptions.alcohol.format"
                                      :min="rangeOptions.alcohol.min"
                                      :max="rangeOptions.alcohol.max"
                                      @change="alcoholChange($event, option)"
                                    )

                                .row.align-items-center.justify-content-between.g-4.mt-0
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.alcohol.values[0]"
                                        :min="rangeOptions.alcohol.min"
                                        :max="rangeOptions.alcohol.max"
                                        type="number"
                                        step="0.01"
                                        autocomplete="off"
                                        @change="alcoholChange($event, option)"
                                      )
                                  .col-3
                                    label.form__label
                                      input(
                                        :class="['form__range-input']"
                                        v-model.lazy="option.settings.alcohol.values[1]"
                                        :min="rangeOptions.alcohol.min"
                                        :max="rangeOptions.alcohol.max"
                                        type="number"
                                        step="0.01"
                                        autocomplete="off"
                                        @change="alcoholChange($event, option)"
                                      )

              button(
                v-if="slug === 'create'"
                type="submit"
                @click="onSubmit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Создать автоматического врача

              button(
                v-else
                type="submit"
                @click="onSubmit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Сохранить изменения


    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Автоматический врач успешно {{ slug === 'create' ? 'создан' : 'сохранен' }}

          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeSuccessModal"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content Назад

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as autoDoctorActionTypes} from '@/store/modules/autoDoctor';

import variables from '@/helpers/variables';
import {arrayDifference, uniqueArray} from '@/helpers/utils';
import _ from 'lodash';
import is from 'is_js';

import useVuelidate from '@vuelidate/core';
import {required} from '@vuelidate/validators';

import simplebar from 'simplebar-vue';
import Slider from '@vueform/slider/dist/slider.vue2';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTabNav from '@/components/common/TabNav.vue';
import AppTab from '@/components/common/Tab.vue';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppTooltip from '@/components/common/Tooltip';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppCreateAutoDoctorModal',
  components: {
    AppTab,
    AppTabNav,
    AppModal,
    AppTitle,
    AppSvgIcon,
    AppValidationErrors,
    AppMultipleSelect,
    AppTooltip,
    Slider,
    simplebar,
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
    slug: {
      required: false,
      type: String,
      default: 'create',
    },
    initialValues: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        isDirty: {
          users: false,
        },
        users: [],
        patients: [],
        exceptions: [],
        bpHigh: [100, 160],
        bpLow: [60, 100],
        heartRate: [50, 120],
        bodyTemp: [30, 37.5],
        alcohol: [0, 0.16],
      },
      rangeOptions: {
        bpHigh: {
          min: 80,
          max: 200,
          values: [100, 160],
          changed: false,
          isActive: false,
        },
        bpLow: {
          min: 40,
          max: 140,
          values: [60, 100],
          changed: false,
          isActive: false,
        },
        heartRate: {
          min: 40,
          max: 200,
          values: [50, 120],
          changed: false,
          isActive: false,
        },
        bodyTemp: {
          min: 20,
          max: 40,
          values: [30, 37.5],
          step: -1,
          format: {
            decimals: 1,
          },
          changed: false,
          isActive: false,
        },
        alcohol: {
          min: 0,
          max: 0.16,
          values: [0, 0.16],
          step: -1,
          format: {
            decimals: 2,
          },
          changed: false,
          isActive: false,
        },
      },
      locationErrors: null,
      isChanged: false,
      isLoaded: false,
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.doctors.isSubmitting,
      validationErrors: (state) => state.doctors.validationErrors,
      filters: (state) => state.filters.data,
    }),
    isDirty() {
      return Object.values(this.form.isDirty).some((item) => item === true);
    },
    usersValues() {
      return this.initialValues.clients;
    },
    exceptionsValues() {
      return this.initialValues.exceptions.length
        ? this.initialValues.exceptions.map((el) => el.patient_code)
        : [];
    },
  },
  validations() {
    return {
      form: {
        users: {
          required,
        },
      },
    };
  },
  updated() {
    this.setInitialValues();
  },
  methods: {
    formChange(option) {
      if (!option) return true;

      let rewriteObjectsName = (name) => {
        let objName;

        switch (name) {
          case 'alcohol':
            objName = 'alcohol';
            break;
          case 'bpHigh':
            objName = 'bp_high';
            break;
          case 'bpLow':
            objName = 'bp_low';
            break;
          case 'heartRate':
            objName = 'heart_rate';
            break;
          default:
            objName = 'body_temp';
        }

        return objName;
      };

      let findInitialValue = this.initialValues.exceptions.find(
        (item) => item.patient_code === option.data.value
      ); //find initial user obj value

      if (!findInitialValue) {
        this.isChanged = !_.isEqual(
          {
            max: option.data.value[1],
            min: option.data.value[0],
          },
          {
            max: this.initialValues[rewriteObjectsName(option.field_name)].max,
            min: this.initialValues[rewriteObjectsName(option.field_name)].min,
          }
        );
      } else {
        this.isChanged = !_.isEqual(
          {
            max: option.data.settings[option.field_name].values[1],
            min: option.data.settings[option.field_name].values[0],
          },
          {
            max: findInitialValue.settings[rewriteObjectsName(option.field_name)].max,
            min: findInitialValue.settings[rewriteObjectsName(option.field_name)].min,
          }
        );
      }
    },
    setInitialValues() {
      if (is.not.empty(this.initialValues)) {
        this.rangeOptions.bpHigh.isActive = true;
        this.rangeOptions.bpLow.isActive = true;
        this.rangeOptions.heartRate.isActive = true;
        this.rangeOptions.bodyTemp.isActive = true;
        this.rangeOptions.alcohol.isActive = true;

        this.form.bpHigh = [this.initialValues.bp_high.min, this.initialValues.bp_high.max];
        this.form.bpLow = [this.initialValues.bp_low.min, this.initialValues.bp_low.max];
        this.form.heartRate = [
          this.initialValues.heart_rate.min,
          this.initialValues.heart_rate.max,
        ];
        this.form.bodyTemp = [this.initialValues.body_temp.min, this.initialValues.body_temp.max];
        this.form.alcohol = [this.initialValues.alcohol.min, this.initialValues.alcohol.max];
      }
    },
    updateSelectedUsers(data) {
      this.form.users = data.map((el) => el.value);

      if (data.length === 0) {
        this.form.patients = [];
        this.$refs.multipeSelectPatients.clear();
      }
      if (this.isLoaded)
        this.isChanged = !!`arrayDifference`(this.form.users, this.initialValues.clients).length;
      this.isLoaded = true;

      let userId = arrayDifference(this.form.users, this.initialValues.clients);
      let patientsCodes = uniqueArray(this.filters.patients).reduce((acc, el) => {
        userId.forEach((item) => {
          if (el.user_id === item) {
            acc.push(el.value);
          }
        });
        return acc;
      }, []);

      let localPatientsId = [];

      this.form.patients.forEach((patient) => {
        patientsCodes.forEach((item) => {
          if (item === patient.value) {
            localPatientsId.push(patient.value);
          }
        });
      });

      localPatientsId.forEach((item) => {
        this.$refs.multipeSelectPatients.externalToggle(item);
      });

      localPatientsId = patientsCodes = [];
    },
    updateSelectedPatients(data) {
      this.form.patients = [];

      if (data.length) {
        data.forEach((el) => {
          let newElement = {
            text: el.text,
            value: el.value,
            settings: {
              bpHigh: {
                values: this.rangeOptions.bpHigh.values,
              },
              bpLow: {
                values: this.rangeOptions.bpLow.values,
              },
              heartRate: {
                values: this.rangeOptions.heartRate.values,
              },
              bodyTemp: {
                values: this.rangeOptions.bodyTemp.values,
              },
              alcohol: {
                values: this.rangeOptions.alcohol.values,
              },
            },
          };

          this.initialValues.exceptions.forEach((item) => {
            if (item.patient_code === el.value) {
              newElement.settings = {
                bpHigh: {
                  values: [item.settings.bp_high.min, item.settings.bp_high.max],
                },
                bpLow: {
                  values: [item.settings.bp_low.min, item.settings.bp_low.max],
                },
                heartRate: {
                  values: [item.settings.heart_rate.min, item.settings.heart_rate.max],
                },
                bodyTemp: {
                  values: [item.settings.body_temp.min, item.settings.body_temp.max],
                },
                alcohol: {
                  values: [item.settings.alcohol.min, item.settings.alcohol.max],
                },
              };
            }
          });

          this.form.patients.push(newElement);
        });

        this.$nextTick(() => {
          if (this.isLoaded) {
            let patientsCodes = this.form.patients.reduce((acc, el) => {
              acc.push(el.value);
              return acc;
            }, []);

            let initialPatientsCodes = this.initialValues.exceptions.reduce((acc, el) => {
              acc.push(el.patient_code);
              return acc;
            }, []);

            this.isChanged = !!arrayDifference(patientsCodes, initialPatientsCodes).length;
          }
        });

        this.isLoaded = true;
      }
    },
    unselectPatient(index) {
      this.$refs.multipeSelectPatients.externalToggle(this.form.patients[index].value);

      this.$nextTick(() => {
        if (this.isLoaded) {
          let patientsCodes = this.form.patients.reduce((acc, el) => {
            acc.push(el.value);
            return acc;
          }, []);

          let initialPatientsCodes = this.initialValues.exceptions.reduce((acc, el) => {
            acc.push(el.patient_code);
            return acc;
          }, []);

          this.isChanged = !!arrayDifference(patientsCodes, initialPatientsCodes).length;
        }
      });

      this.isLoaded = true;
    },
    bpHighChange(value, option = null) {
      if (value[0] !== this.rangeOptions.bpHigh.min || value[1] !== this.rangeOptions.bpHigh.max) {
        this.rangeOptions.bpHigh.changed = true;
        this.rangeOptions.bpHigh.isActive = true;
        // this.form.bpHigh.min = value[0];
        // this.form.bpHigh.max = value[1];
      } else {
        this.rangeOptions.bpHigh.changed = false;
        this.rangeOptions.bpHigh.isActive = false;
        // this.form.bpHigh.min = null;
        // this.form.bpHigh.max = null;
      }
      this.formChange({data: option, field_name: 'bpHigh'});
    },
    bpLowChange(value, option = null) {
      if (value[0] !== this.rangeOptions.bpLow.min || value[1] !== this.rangeOptions.bpLow.max) {
        this.rangeOptions.bpLow.changed = true;
        this.rangeOptions.bpLow.isActive = true;
        // this.form.bpLow.min = value[0];
        // this.form.bpLow.max = value[1];
      } else {
        this.rangeOptions.bpLow.changed = false;
        this.rangeOptions.bpLow.isActive = false;
        // this.form.bpLow.min = null;
        // this.form.bpLow.max = null;
      }
      this.formChange({data: option, field_name: 'bpLow'});
    },
    heartRateChange(value, option = null) {
      if (
        value[0] !== this.rangeOptions.heartRate.min ||
        value[1] !== this.rangeOptions.heartRate.max
      ) {
        this.rangeOptions.heartRate.changed = true;
        this.rangeOptions.heartRate.isActive = true;
        // this.form.heartRate.min = value[0];
        // this.form.heartRate.max = value[1];
      } else {
        this.rangeOptions.heartRate.changed = false;
        this.rangeOptions.heartRate.isActive = false;
        // this.form.heartRate.min = null;
        // this.form.heartRate.max = null;
      }
      this.formChange({data: option, field_name: 'heartRate'});
    },
    bodyTempChange(value, option = null) {
      // value[0] !== this.rangeOptions.bodyTemp.min
      //   ? (this.form.bodyTemp.min = Number(value[0].toFixed(1)))
      //   : (this.form.bodyTemp.min = null);

      // value[1] !== this.rangeOptions.bodyTemp.max
      //   ? (this.form.bodyTemp.max = Number(value[0].toFixed(1)))
      //   : (this.form.bodyTemp.max = null);

      this.rangeOptions.bodyTemp.changed =
        value[0] !== this.rangeOptions.bodyTemp.min || value[1] !== this.rangeOptions.bodyTemp.max;
      this.rangeOptions.bodyTemp.isActive =
        value[0] !== this.rangeOptions.bodyTemp.min || value[1] !== this.rangeOptions.bodyTemp.max;

      this.formChange({data: option, field_name: 'bodyTemp'});
    },
    alcoholChange(value, option = null) {
      if (
        value[0] !== this.rangeOptions.alcohol.min ||
        value[1] !== this.rangeOptions.alcohol.max
      ) {
        this.rangeOptions.alcohol.changed = true;
        this.rangeOptions.alcohol.isActive = true;
        // this.form.alcohol.min = value[0];
        // this.form.alcohol.max = value[1];
      } else {
        this.rangeOptions.alcohol.changed = false;
        this.rangeOptions.alcohol.isActive = false;
        // this.form.alcohol.min = null;
        // this.form.alcohol.max = null;
      }

      this.formChange({data: option, field_name: 'alcohol'});
    },
    onSubmit() {
      let data = {
        alcohol: {
          min: this.form.alcohol[0],
          max: this.form.alcohol[1],
        },
        body_temp: {
          min: this.form.bodyTemp[0],
          max: this.form.bodyTemp[1],
        },
        bp_high: {
          min: this.form.bpHigh[0],
          max: this.form.bpHigh[1],
        },
        bp_low: {
          min: this.form.bpLow[0],
          max: this.form.bpLow[1],
        },
        heart_rate: {
          min: this.form.heartRate[0],
          max: this.form.heartRate[1],
        },
        clients: this.form.users,
        exceptions: [],
      };

      if (this.form.patients.length) {
        this.form.patients.forEach((el) => {
          data.exceptions.push({
            patient_code: el.value,
            settings: {
              bp_high: {
                min: el.settings.bpHigh.values[0],
                max: el.settings.bpHigh.values[1],
              },
              bp_low: {
                min: el.settings.bpLow.values[0],
                max: el.settings.bpLow.values[1],
              },
              heart_rate: {
                min: el.settings.heartRate.values[0],
                max: el.settings.heartRate.values[1],
              },
              body_temp: {
                min: el.settings.bodyTemp.values[0],
                max: el.settings.bodyTemp.values[1],
              },
              alcohol: {
                min: el.settings.alcohol.values[0],
                max: el.settings.alcohol.values[1],
              },
            },
          });
        });
      }

      this.$store
        .dispatch(autoDoctorActionTypes.updateAutoDoctor, data)
        .then(() => {
          this.closeForm();
          this.openModal('success');
        })
        .catch(() => (this.locationErrors = this.validationErrors));
    },
    closeSuccessModal() {
      this.closeModal('success');
      this.$emit('success');
    },
    resetForm() {
      this.v$.$reset();

      this.rangeOptions.bpHigh.changed = false;
      this.rangeOptions.bpLow.changed = false;
      this.rangeOptions.heartRate.changed = false;
      this.rangeOptions.bodyTemp.changed = false;
      this.rangeOptions.alcohol.changed = false;

      this.$refs.multipeSelectUsers.clear();
      this.form.users = [];

      if (this.$refs.multipeSelectPatients) {
        this.$refs.multipeSelectPatients.clear();
        this.form.patients = [];
      }

      for (const key in this.form.isDirty) {
        this.form.isDirty[key] = false;
      }

      this.locationErrors = null;

      if (this.slug === 'update') this.setInitialValues();
    },
    closeForm() {
      this.resetForm();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &__range {
    &-icon {
      svg {
        width: $spacer * 2.4;
        height: $spacer * 2.4;
      }
    }
  }
}
</style>
