<template lang="pug">
  .profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-patients__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center mb-3 mb-md-0'"
          ) Справки и извещения
        .col.col-md-auto.ms-md-auto
          transition(name="translucent")
              button(
                type="button"
                @click="exportExams(filters)"
                class=['link--accent']
              )
                .d-flex.align-items-center
                  span.flex-shrink-0.me-3
                    app-svg-icon(name="xls-doc")
                  span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}
        .row.align-items-center
          .col-8
            nav-notices  
          .col.col-md-auto.ms-md-auto
            button(
            type="button"
            class=['button button--border-blue']
            @click="openModal('loadDoc')"
            )
              span.button__content
              span ЗАГРУЗИТЬ ДОКУМЕНТ

    .profile-patients__content.mt-20.position-relative
      transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")
        //- div(v-if="patients")
        div
          template
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")


              ul.profile-table__body
                li.profile-table__row(v-for='item in results')
                    client-incoming-notice(
                      :data="item"
                      :openShowModal="openShowModal"
                    )

        
            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          //- template(v-else)
          //-   .text-color--blue.text-size--small Пользователи не найдены

    // filter
    app-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
    // create patients modal
    load-doc-modal(
      :is-visible="isModalVisible.loadDoc"
      @open="openModal('loadDoc')"
      @close="createDoc"
      @openGallery="openGallery"
      @success="getPatients(pagination.limit, offset)"
    )
    // create patients modal
    show-doc-modal(
      :is-visible="isShowDataVisible"
      :closeModal="closeShowModal"
      :data="currentDoc"
    )


</template>

<script>
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/patients';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import ClientIncomingNotice from '@/components/profile/client/patients/ClientIncomingNotice';
//import AppGallery from '@/components/common/Gallery';

import AppFilter from '@/components/FilterPageNotices';
import ClientNotice from '@/components/profile/client/patients/ClientPatientNotice';
import ModalNotice from '@/components/profile/common/patients/ModalNotice';
import NavNotices from '@/components/profile/common/NavNotices';
import {filterMixin} from '@/mixins/filter';
import {galleryMixin} from '@/mixins/gallery';
import useVuelidate from '@vuelidate/core';
import {requiredIf} from '@vuelidate/validators';

import LoadDocModal from '@/components/profile/client/docs/LoadDocModal';
import ShowDocModal from '@/components/profile/client/docs/ShowDocModal';


import incomingMedDocs from '@/api/incomingMedDocs';


export default {
  name: 'PageNotiecs',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppFilter,
   // AppGallery,
    ClientNotice,
    ModalNotice,
    NavNotices,
    LoadDocModal,
    ClientIncomingNotice,
    ShowDocModal
  },
  mixins: [filterMixin, galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      isShowDataVisible: false,
      currentDoc: {

      },
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getPatients'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 12,
        total: 0,
      },
      sortKey: 'create_datetime',
      sortInverse: true,
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'create_datetime',
          selected: true,
          inverse: true,
        },
        {
          caption: 'e-mail',
          value: 'email',
          selected: false,
          inverse: false,
        },
        {
          caption: 'ФИО пользователя',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'кол-ву осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-auto',
          caption: '№',
          key: 'id',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Статус',
          key: 'document_status',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Дата и время',
          key: 'create_datetime',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Дата выдачи',
          key: 'issue_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Срок действия',
          key: 'expiration_date',
          inverse: true,
        },
        {
          class: 'col-auto',
          caption: 'Дата и время подтверждения',
          key: 'verification_datetime',
          inverse: true,
        },
        {
          class: 'col-auto',
          caption: 'Просмотр',
          key: 'view',
          inverse: true,
        },
      ],
      results: [
      ],
      isModalVisible: {
       notice:false,
       loadDoc:false
      },
      successMessage: 'Успех!',
     
    };
  },
  validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.patients.isLoading,
      patients: (state) => state.patients.data,
      patientsCount: (state) => state.patients.count,
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    async currentPage() {
      let data = await incomingMedDocs.getIncomingMedDocument({"paging_params": {
          "offset": this.currentPage * this.pagination.limit - this.pagination.limit,
          "limit": 15
        },
        "sorting_params": {
          "field": this.sortKey,
          "desc": this.sortInverse
        }
      });
      console.log(data);
      this.results = data.data.result;
      this.pagination.total = data.data.total;
    },
  },
  async created() {
    let data = await incomingMedDocs.getIncomingMedDocument({
        "sorting_params": {
          "field": this.sortKey,
          "desc": this.sortInverse
        }
    });
    console.log(data);
    this.results = data.data.result;
    this.pagination.total = data.data.total;
  },
  mounted() {
    if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
  },
  methods: {
    closeShowModal() {
      this.isShowDataVisible = false;
    },
    openShowModal(data) {
      console.log(data);
      this.currentDoc = data;
      this.isShowDataVisible = true;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getPatients(filters, limit, offset) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(actionTypes.getPatients, data).then((response) => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.isFiltered = Object.keys(filters).length > 0;
        this.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    getCountPatients(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(actionTypes.getCountPatients, data);
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingPatients(value, inverse);
    },
    async sortingPatients(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;
      let data = await incomingMedDocs.getIncomingMedDocument({"paging_params": {
          "offset": this.currentPage * this.pagination.limit - this.pagination.limit,
          "limit": 15
        },
        "sorting_params": {
          "field": this.sortKey,
          "desc": this.sortInverse
        }
      });
      console.log(data);
      this.results = data.data.result;
      this.pagination.total = data.data.total;
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openChangeAvatarModal(data) {
      this.currentPatient = data;
      this.openModal('changeAvatar');
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(actionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    successChangePatient(slug) {
      this.successMessage = `Фотография успешно ${
        slug === 'changeAvatar' ? 'изменена' : 'удалена'
      }!`;

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');

      this.getPatients(this.filters, this.pagination.limit, this.offset);
    },
    async createDoc(){
      console.log('123');
      let data = await incomingMedDocs.getIncomingMedDocument({});
      console.log(data);
      this.results = data.data.result;
      this.pagination.total = data.data.total;
      this.closeModal('loadDoc')
    },
     onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },

  },
};
</script>
