<template lang="pug">
div
  .patient-exams__header.text-size--normal.mb-4.d-flex.flex-xl-row.flex-column
    span(
      @click="showBlockHistory"
      class=['text-color--blue', 'mb-2', 'mb-xl-0', 'block-history-title']
    ) Медицинские документы пользователя

  .profile-table
    .profile-table__header.d-none.d-xl-block
      .row.align-items-center
        .col-3
          button.profile-table__sort-button(type='button')
            span.d-flex.align-items-center
              span Дата рег. 
              span.d-flex.ms-3
        .col-3
          button.profile-table__sort-button(type='button')
            span.d-flex.align-items-center
              span Статус 
              span.d-flex.ms-3
        .col-4
          button.profile-table__sort-button(type='button')
            span.d-flex.align-items-center
              span Тип документа
              span.d-flex.ms-3
        .col-4
          button.profile-table__sort-button(type='button')
            span.d-flex.align-items-center
              span Срок действия
              span.d-flex.ms-3
    ul.profile-table__body
      li.profile-table__row(v-for="item in result.result.slice(0, pagination.offset)" :key="item.id")
        section.profile-card
          .row.align-items-md-center.gy-4.gy-md-0 
            .col-14.col-md-3
              span(v-if='item.issue_date') {{ $moment(item.issue_date).format('DD.MM.YYYY') }} 
              span(v-else) -
            .col-14.col-md-3
              span(v-if='item.document_status=="confirmed"') {{ "Подтверждено" }}
              span(v-else-if='item.document_status=="awaiting"') {{ "На подтверждении" }}
              span(v-else-if='item.document_status=="rejected"') {{ "Отклонено" }}
              span(v-else) {{" В архиве "}}
            .col-14.col-md-4
              span {{ docsTypes[item.document_type] }}
            .col-14.col-md-3
              span(v-if='item.expiration_date') {{ $moment(item.expiration_date).format('DD.MM.YYYY')}} 
              span(v-else) -
            .col-14.col-md-1.d-flex.justify-content-end
              button(@click="showDocCard(item)")
                app-svg-icon(name="search") 
    button(
      v-if="pagination.offset < result.result.length"
      type="button"
      @click="pagination.offset += pagination.limit"
      class=['text-size--small link--accent', 'mt-4 me-4']
      ) Показать еще (+{{ (result.result.length - result.offset) >= pagination.limit ? pagination.limit : result.result.length - result.offset}})

    button(
      v-if="pagination.offset > pagination.limit"
      type="button"
      @click="pagination.offset = pagination.limit"
      class=['text-size--small link--accent', 'mt-4']
      ) Свернуть

</template>

  
<script>

import AppSvgIcon from '@/components/common/SvgIcon';
import patientApi from '@/api/patients';
export default {
    name: 'AppPatientMedDocs',
    props: {
        patientCode: String,
        showDocInfo: Function,
    },
    components: {
        AppSvgIcon
    },
    data() {
        return {
            showInfo: true,
            docsTypes: {
                'health_certificate' : 'Cправка о состоянии здоровья',
                'injury_certificate': 'Cправка о травме',
                'hti_certificate': 'Справка о результатах ХТИ',
                'medical_exam_conclusion': 'Заключение предварительного или периодический мед. осмотр',
                'medical_exam_certificate': 'Акт медицинского освидетельствования',
                'client_request': 'Официальное обращение клиента',
            },
            columns: [{
                    num: 0,
                    class: 'col-2',
                    caption: '№',
                    key: 'id',
                    inverse: false,
                },
                {
                    num: 1,
                    class: 'col-2',
                    caption: 'Дата и время',
                    key: 'admission_datetime',
                    inverse: false,
                },
                {
                    num: 2,
                    class: 'col-2',
                    caption: 'Продолжительность',
                    key: 'patient_full_name',
                    inverse: false,
                }
            ],
            pagination: {
                limit: 5,
                offset: 5,
            },
            result: {}
        }
    },
    async mounted() {
        const res = await patientApi.medDocsUser({
            "filters":{
              "patients": {
                "negative": false,
                "payload": [
                    this.patientCode
                ]
              },
            },
            "pagination": {
                "offset": this.pagination.offset,
                "limit": this.pagination.limit
            }
        })
        this.result = res.data
    },
    methods: {
        showBlockHistory() {
            console.log(123);
            this.showInfo = !this.showInfo;
        },
        showDocCard(item){
          this.showDocInfo(item);
        }
    }
}
</script>

  
<style lang="scss">
.block-history-title:hover {
    cursor: pointer;
}

.user-card-history {
    padding: 10px 0;
    color: #505e8e;
}
</style>
