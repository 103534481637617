import axios from '@/api/axios';

const userAgreements = (credentials) => axios.get('/user_agreements', credentials);

const userAgreementsToUsers = (credentials) => axios.get('/user_agreements_by_users', credentials);


export default {
    userAgreements,
    userAgreementsToUsers
};