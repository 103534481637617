<template lang="pug">
  .profile-map
    .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
      .profile-map__header
        .row.align-items-center.flex-md-row.g-4
          .col-12  
           .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
            //app-back-button(:classes="'me-5'")
            app-title(
                :type="'large'"
                :classes="'text-center'"
              ) {{ isTouchDevice ? 'Отчеты' : 'Отчеты' }}
           nav-chart
           .chart-heder Группы риска по артериальному давлению за период
          .col-md-12.d-md-flex 
           .legend.legend--column.mt-5.mb-5 
            .legend-item.legend-item--low.mb-3 Пониженное давление
            .legend-item.legend-item--normal.mb-3 Нормальное давление
            .legend-item.legend-item--high.mb-3 Повышенное давление
            .legend-item.legend-item--hiper.mb-3 Гипертония 
           .charts  
            line-chart-pie-tab
          .col-14
            ul.tabs-nav.d-flex.is-styled.jointly-theme.jointly-theme-orange.mb-20.mt-5
              li.tabs-nav__item.flex-shrink-0
                router-link(
                  to="/profile/chart-pie-tab"
                  class="tabs-nav__button button"
                )
                 .button__content.d-flex.align-items-center.justify-content-center
                  span Осмотры
              li.tabs-nav__item.flex-shrink-0
                button.tabs-nav__button.button.is-active.is-activeOrange(type='button')
                 .button__content.d-flex.align-items-center.justify-content-center
                  span Пользователи              
            .tableView
              table-view-users
    // filter
    filter-chart-pie-tab(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
</template>
<script>
import {mapState} from 'vuex';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import TableViewUsers from '@/components/common/ChartTableUsers';
import FilterChartPieTab from '@/components/profile/common/FilterChartPieTab';
import {filterMixin} from '@/mixins/filter';
import LineChartPieTab from '@/components/common/LineChartPieTab';
import NavChart from '@/components/profile/common/NavChart';
export default {
  name: 'AppMapExams',
  components: {
    AppBackButton,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    FilterChartPieTab,
    LineChartPieTab,
    TableViewUsers,
    NavChart
  },
  mixins: [ filterMixin],

  data() {
    return {
      t:{},
      v: variables,

      filterOptions: {
        patients: true,
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },


    };
  },

  computed: {
    ...mapState({
    //  examsLoading: (state) => state.exams.isLoading,
    
    }),
    isTouchDevice() {
      return is.touchDevice();
    },

  },

  mounted() {
 
  },
  methods: {

  //  upd(){
  //    this.t.update();
  //    console.log('upd',this.chartData)
  //  }
  },
};
</script>
<style lang="scss" scoped>
  .d-block{
    position: relative;
   // overflow: hidden;
   //height: fit-content;
   
  }
  .legend{
    display: flex;
    max-width: 680px;
    justify-content: space-between;
      &--column{
     display: block;
    }
  }
  .legend-item{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
position: relative;
padding-left: 20px;
color:#5B6483;
&:before{
  content: '';
  width: 10px;
  height: 10px;
  background: red;
position: absolute;
left: 0;
border-radius: 50%;
top:5px;


}
    &--low{
      &:before{
  content: '';
  background: #90AAED;
}
    }
     &--normal{
             &:before{
  content: '';
   background: #96E6FF;
}
    
    }
     &--high{
                    &:before{
  content: '';
 background: #B78CD9;
}
     
    }
     &--hiper{
                          &:before{
  content: '';
background: #E37FAF;
}
    }
     &--green{
                          &:before{
  content: '';
background: #5BCB88;
}
    }
  }
    .charts-empty{
   background: #FFFFFF;
border-radius: 6px;
max-width: 770px;
padding-top: 25px;
padding-bottom: 25px;
text-align: center;
  }
  .chart-heder{
    font-weight: 400;
font-size: 14px;
line-height: 18px;
color:#5B6483;
padding-top: 30px;
padding-bottom: 10px;
letter-spacing: 0.06em;
text-transform: uppercase;
  }
  .is-activeOrange{
    color:#fff !important;
  }
  .is-activeOrange:before {
    border-radius: 0;
 background: linear-gradient(98.53deg, #EC9D25 34.72%, #EC8525 91.84%) !important;
}

.tabs-nav.jointly-theme-orange .tabs-nav__button {
    width: 100%;
    border-radius: 0;
    padding: 0.625rem;
    color: #515f8f;
    box-shadow: inset 0 0 0 1px #EC8525;
    background: #fff;
}
</style>
