<template lang="pug">
  .profile-devices__inner.is-filterable
    .profile-devices__header
      .row.align-items-center.flex-column.flex-md-row.g-5.gy-md-0
        .col.position-relative
          .d-flex.align-items-center.flex-column-reverse.flex-md-row
            app-title(:type="'large'") Мои устройства
            
            .text-family--light.text-color--blue.mb-2.ms-md-4.mb-md-0(v-if="devices")
              transition(name="translucent")
                span(v-if="devices.length") {{ devices.length }} {{ declOfNum(devices.length, ['устройство', 'устройства', 'устройств']) }}
                span(v-if="devices.length === 0 && !isTouchDevice") Нет устройств
                
          button(
            v-if="devices && devices.length"
            type="button"
            class=['profile-devices__add-button', 'button--blue', 'd-md-none']
            @click="openModal('createDevice')"
          )
            app-svg-icon(name="plus-thin")

        .col.col-md-auto.ms-md-auto(v-if="devices && devices.length")
         button(
        type="button"
        class=['profile-sidebar__button', 'button button--blue', 'mt-auto px-3 w-100']
        @click="isModalVisible.createDevice = true"
      )
          span.button__content
           span.d-flex.flex-shrink-0.me-3
            app-svg-icon(name="plus-thin")
           span Добавить устройства
          //- app-select(
          //-   @select="sortBy"
          //-   :options="sortOptions"
          //-   :placeholder="'выберите...'"
          //- )

    .profile-devices__content.position-relative.mt-20
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      transition(name="translucent")
        div(v-if="devices")
          .row.flex-column.align-items-start(v-if="sortedDevices.length")
            .col
              .row.g-4
                .col-14(v-for="device in sortedDevices" :key="device.serial")
                  app-client-device-card(:data="device" @delete="openDeleteModal"  @change="openChangeModal(device)")
            
            .col.col-md-auto
              button(
                type="button"
                class=['button button--blue', 'mt-5 w-100', 'd-xl-none']
                @click="openModal('createDevice')"
              )
                span.button__content
                  span.d-flex.flex-shrink-0.me-3
                    app-svg-icon(name="plus")
                  span Добавить устройства
            
          // if there are no devices
          template(v-else)
            .row.justify-content-center.justify-content-md-start.mb-md-5(v-if="isTouchDevice")
              .col-10.col-md-auto
                .text-size--small.text-family--light.text-center На данный момент нет зарегистрированных устройств и&nbsp;активных&nbsp;заявок
            
            button(
              type="button"
              class=['profile-devices__create-button', 'button button--accent button--middle']
              @click="openModal('createDevice')"
            )
              .button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить устройства
    // change doctor modal
    change-device-modal(
      :is-visible="isModalVisible.changeDevice"
      :toEdit="toEdit"
      @close="closeModal('changeDevice')" 
    )
    // create device
    app-create-device-modal(
      :is-visible="isModalVisible.createDevice"
      @close="closeModal('createDevice')"
      @success="createdDevice"
    )
    // delete modal
    app-delete-device-modal(
      :data="deleteDevice"
      :is-visible="isModalVisible.deleteDevice"
      @close="closeModal('deleteDevice')"
      @success="deletedDevice"
    )
    // success modals
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successDeleted" @close="closeModal('successDeleted')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Устройство успешно удалено
            p.text-size--small.text-color--blue.mt-3
              span Вам придет уведомление на e-mail об удалении устройства
              span.text-family--medium.text-color--blue-dark &ensp;{{ deleteDevice.serial}}
        
          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeModal('successDeleted')"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content Назад
    
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successCreated" @close="closeModal('successCreated')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Успешно отправлено на рассмотрение
            p.text-size--small.text-color--blue.mt-3 Заявки на добавление устройств рассматриваются администраторами от 1 до 4 рабочих дней. Статус&nbsp;проверки вы можете отследить в личном кабинете.
        
          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeModal('successCreated')"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content Назад
      // filter
    filter-device(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
  
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import is from 'is_js';
import variables from '@/helpers/variables';
import {declOfNum, sortBy} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppPagination from '@/components/common/Pagination';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';

import AppClientDeviceCard from '@/components/profile/client/devices/ClientDeviceCard';
import AppCreateDeviceModal from '@/components/profile/client/devices/CreateDeviceModal';
import AppDeleteDeviceModal from '@/components/profile/common/devices/DeleteDeviceModal';

import {modalsMixin} from '@/mixins/modals';
import FilterDevice from '@/components/profile/common/FilterDevice';

import ChangeDeviceModal from '@/components/profile/client/devices/ChangeDeviceModal';
export default {
  name: 'AppClientDevices',
  components: {
    AppTitle,
    AppSvgIcon,
    AppPagination,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppModal,
    AppCreateDeviceModal,
    AppDeleteDeviceModal,
    AppClientDeviceCard,
    FilterDevice,
    ChangeDeviceModal
  },
  mixins: [modalsMixin],
  data() {
    return {
      v: variables,
      declOfNum,
      isModalVisible: {
        createDevice: false,
        deleteDevice: false,
        successDeleted: false,
        successCreated: false,
        changeDevice: false,
      },
      deleteDevice: {},
      sortKey: 'creation_date',
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'creation_date',
          selected: true,
        },
        {
          caption: 'серийному номеру',
          value: 'serial',
          selected: false,
        },
      ],
            filterOptions: {
        patients: true,
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },
      toEdit:[]
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      devices: (state) => state.devices.data,
      error: (state) => state.devices.error,
    }),
    sortedDevices() {
      return sortBy(this.devices, this.sortKey);
    },
    isTouchDevice() {
      return is.touchDevice();
    },
  },
  created() {
    this.getDevices();
  },
  methods: {
    sortBy({value}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortKey = value;
    },
    getDevices() {
      this.$store.dispatch(devicesActionTypes.getDevices);
    },
    openDeleteModal(data) {
      this.deleteDevice = data;
      this.isModalVisible.deleteDevice = true;
    },
    deletedDevice() {
      this.openModal('successDeleted');
      this.getDevices();
    },
    createdDevice() {
      this.openModal('successCreated');
      this.getDevices();
    },
      openChangeModal(data) {
      this.deleteDevice = data;
      this.isModalVisible.changeDevice = true;
      this.toEdit=data
    },
  },
};
</script>
