<template lang="pug">
  .profile-patients.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-patients__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center mb-3 mb-md-0'"
          ) Пользователи
    
    .profile-patients__content.mt-20.position-relative
      transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")
    
        div(v-if="patients")
          template(v-if="patients.result.length")
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")


              ul.profile-table__body
                li.profile-table__row(v-for="patient in patients.result" :key="patient.code")
                  router-link(:to="{name: 'patient-detail', params: {id: patient.code}}" class=['d-block'])
                    app-admin-patient-card(
                      :data="patient"
                      @openGallery="openGallery"
                      @changeAvatar="openChangeAvatarModal"
                    )
        
            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Пользователи не найдены

    // filter
    app-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="patientsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
      @openEditModal="openModal"
      @openDeleteModal="openModal"
    )
    // change avatar modal
    app-patient-change-avatar-modal(
      ref="changeAvatar"
      :patient="currentPatient"
      :is-visible="isModalVisible.changeAvatar"
      @success="successChangePatient('changeAvatar')"
      @close="closeModal('changeAvatar')"
    )
    // delete avatar modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить фотографию пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteAvatar')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteAvatar(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.
    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") {{ successMessage }}
            p.text-color--blue.mt-3 Ф.И.О. пользователя: {{ currentPatient.full_name }}.

          .d-flex.justify-content-center.mt-5
            button(
              @click="closeModal('success')"
              type="button"
              class=['button button--accent']
            )
              span.button__content Назад в список
</template>

<script>
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/patients';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import AppGallery from '@/components/common/Gallery';

import AppFilter from '@/components/profile/common/FilterUsers';
import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';

import {filterMixin} from '@/mixins/filter';
import {galleryMixin} from '@/mixins/gallery';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'AppAdminPatients',
  components: {
    AppModal,
    AppTitle,
    AppSelect,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppFilter,
    AppGallery,
    AppAdminPatientCard,
    AppPatientChangeAvatarModal,
  },
  mixins: [filterMixin, galleryMixin],
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      form: {
        file: null,
      },
      v: variables,
      filterOptions: {
        patients: true,
        date: true,
        methods: {
          getData: ['getPatients'],
          getCount: 'getCountPatients',
        },
      },
      pagination: {
        limit: 12,
        total: 0,
      },
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'creation_date',
          selected: true,
          inverse: true,
        },
        {
          caption: 'e-mail',
          value: 'email',
          selected: false,
          inverse: false,
        },
        {
          caption: 'ФИО пользователя',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'кол-ву осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-3',
          caption: 'Фото и e-mail',
          key: 'email',
          inverse: false,
        },
        // {
        //   class: 'col-2',
        //   caption: 'Телефон',
        //   key: 'phone',
        //   inverse: false,
        // },
        {
          class: 'col-3',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-4',
          caption: 'ФИО пользователя',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Кол-во осмотров',
          key: 'exam_count',
          inverse: true,
        },
        // {
        //   class: 'col-2',
        //   caption: 'Кол-во устройств',
        //   key: 'devices_count',
        //   inverse: true,
        // },
      ],
      isModalVisible: {
        changeAvatar: false,
        deleteAvatar: false,
      },
      successMessage: 'Успех!',
     
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.patients.isLoading,
      patients: (state) => state.patients.data,
      patientsCount: (state) => state.patients.count,
      error: (state) => state.patients.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getPatients(this.filters, this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getPatients(this.filters, this.pagination.limit, this.offset);
  },
  mounted() {
    if (this.isTouchDevice) this.$refs.filter.setBtnPosition();
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getPatients(filters, limit, offset) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(actionTypes.getPatients, data).then((response) => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.isFiltered = Object.keys(filters).length > 0;
        this.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    getCountPatients(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(actionTypes.getCountPatients, data);
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingPatients(value, inverse);
    },
    sortingPatients(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getPatients(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openChangeAvatarModal(data) {
      this.currentPatient = data;
      this.openModal('changeAvatar');
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(actionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    successChangePatient(slug) {
      this.successMessage = `Фотография успешно ${
        slug === 'changeAvatar' ? 'изменена' : 'удалена'
      }!`;

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');

      this.getPatients(this.filters, this.pagination.limit, this.offset);
    },
  },
};
</script>
