import filtersApi from '@/api/filters';
import filtersTypeApi from '@/api/filtersType';

const state = {
  data: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getFiltersStart: '[filters] get data start',
  getFiltersSuccess: '[filters] get data success',
  getFiltersFailure: '[filters] get data failure',
};

export const actionTypes = {
  getFilters: '[filters] get data',
  getFiltersType: '[filters] get dataType',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getFiltersStart](state) {
    state.isLoading = true;
    state.data = null;
    state.error = null;
  },
  [mutationTypes.getFiltersSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
    var arrayObj = state.data.types.map(item => {
        return {
            text: item.name,
            value: item.id,
          };
         });
         state.data.typess = arrayObj;
  },
  [mutationTypes.getFiltersFailure](state, payload) {
    state.isLoading = false;
    state.error = payload;
  },
    [mutationTypes.getFiltersTypeSuccess](state, payload) {
    state.isLoading = false;
    state.data.push(payload)
  },
};

const actions = {
  [actionTypes.getFilters](context) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getFiltersStart);
      filtersApi
        .getFilters()
        .then((response) => {
          context.commit(mutationTypes.getFiltersSuccess, response.data);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.getFiltersFailure, result.response.data.message);
        });
    });
  },
  // получаем тип осмотора для формы фильтра
  [actionTypes.getFiltersType](context) {
    return new Promise((resolve) => {
    //  context.commit(mutationTypes.getFiltersStart);
      filtersTypeApi
        .filtersApiType()
        .then((response) => {
          console.log('ny',response.data)
          //context.commit(mutationTypes.getFiltersTypeSuccess, response.data);
          resolve(response);
        })
        .catch((result) => {
          context.commit(mutationTypes.getFiltersFailure, result.response.data.message);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
