<template lang="pug">
  .profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-devices__header
      .row.align-items-center
        .col-14.col-md-auto.order-last.order-md-first
          app-title(
            :type="'large'"
            :id="'isAnchorForBtn'"
            :classes="'profile__title text-center mt-4 mt-md-0'"
          ) {{ isTouchDevice ? 'Все устройства' : 'Устройства' }}

        .col-auto.order-1.order-md-0.ms-auto
          transition(name="translucent")
            router-link(
              v-if="devices && devices.result.length"
              :to="{name: 'map'}"
              class=['d-flex align-items-center', 'link--accent']
            )
              span.flex-shrink-0.me-2
                app-svg-icon(name="map-point")
              span.text-size--small {{ isTouchDevice ? 'Геокарта' : 'Просмотреть геокарту' }}
      

        .col-auto
          transition(name="translucent")
            button(
              v-if="devices && devices.result.length"
              type="button"
              @click="exportDevices(filters)"
              class=['link--accent']
            )
              .d-flex.align-items-center
                span.flex-shrink-0.me-3
                  app-svg-icon(name="xls-doc")
                span.text-size--small {{ isTouchDevice ? 'Выгрузить .xlsx' : 'Выгрузить данные .xlsx' }}
        .col-12
         NavDevice


    .profile-devices__content.mt-20.position-relative
      transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="devices")
          template(v-if="devices.result && devices.result.length")
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingDevices(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="device in devices.result" :key="device.serial")
                  router-link(:to="{name: 'device-detail', params: {id: device.serial}}" class=['d-block'])
                    section.profile-card
                      .row.align-items-md-center.gy-4.gy-md-0
                        .col-7.col-md-3
                          .text-color--blue.mb-2.d-xl-none Серийный номер
                          span {{ device.serial }}
  
                        .col-7.col-md-2
                          .d-flex.align-items-center.justify-content-end.justify-content-md-start
                            app-status(:type="'device'" :data="device")
                            span.ms-4 {{ toLocalDate(device.creation_date) }}
  
                        .col-14.col-md-3
                          .text-color--blue.mb-2.d-xl-none Владелец
                          span {{ device.full_name }} 
  
                        .col-14.col-md-2
                          .text-color--blue.mb-2.d-xl-none Контроль ПО
                          span(v-if="device.device_integrity === null ? '---' : device.device_integrity === true") Пройден
                          span(v-else class="red") Не пройден
                        .col-14.col-md-2
                          .text-color--blue.mb-2.d-xl-none Поверка
                          span(v-if='device.device_attestation_ending >= 0') Поверено
                          span(v-else class="red") Не поверено
                        .col-14.col-md-2
                         .d-flex.align-items-center
                          .text-color--blue.mb-2.d-xl-none Корпус
                          span(v-if=" device.case_opened === null ? '---' : device.case_opened === false") Не вскрыт
                          span(v-else class="red") Вскрыт
                          span.text-color--accent.ms-auto.flex-shrink-0.d-none.d-md-inline
                              app-svg-icon(name="right-arrow")
  
            
            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .d-flex.align-items-center.text-color--blue.text-size--small
              span.d-flex.me-3
                app-svg-icon(name="wrong")
              span Нет устройств

    // filter
    app-filter(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="devicesCount"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
      @getCount="getCountFilter"
    )

</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import is from 'is_js';
import variables from '@/helpers/variables';
import {declOfNum, blobToFile, toLocalDate} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';

import AppFilter from '@/components/profile/common/Filter';
import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import {modalsMixin} from '@/mixins/modals';
import {filterMixin} from '@/mixins/filter';

import NavDevice from '@/components/profile/common/NavDevice';

export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    AppFilter,
    AppTitle,
    AppSvgIcon,
    AppPagination,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    NavDevice
  },
  mixins: [modalsMixin, filterMixin],
  data() {
    return {
      v: variables,
      declOfNum,
      filterOptions: {
        users: {
          title: 'Владельцы устройств',
          placeholder: 'Введите название компании или ФИО владельца',
        },
        date: true,
        devices: true,
        methods: {
          getData: ['getDevices'],
          getCount: 'getCountDevices',
        }
      },
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'creation_date',
          selected: true,
          inverse: true,
        },
        {
          caption: 'серийному номеру',
          value: 'serial',
          selected: false,
          inverse: false,
        },
        {
          caption: 'владельцу устройства',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'количеству осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-3',
          caption: 'Серийный номер',
          key: 'serial',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Статус / Дата',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'Владелец устройства',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Контроль целостности ПО',
          key: 'firmware',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Поверка',
          key: 'check',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Корпус',
          key: 'frame',
          inverse: true,
        },
      ],
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      devices: (state) => state.devices.data,
      devicesCount: (state) => state.devices.count,
      error: (state) => state.devices.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          devices: [],
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getDevices(this.filters, this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getDevices(this.filters, this.pagination.limit, this.offset);
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getDevices(filters, limit, offset) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.filterDevices, data).then((response) => {
        if (this.isTouchDevice) {
          this.closeFilter('submit');
          this.$refs.filter.setBtnPosition();
        }
        this.pagination.total = response.total;
        this.isFiltered = Object.keys(filters).length > 0;
        this.reverseSorting();
      });
    },
    getCountDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.getCountDevices, data);
    },
    exportDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store
        .dispatch(devicesActionTypes.exportDevices, data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingDevices(value, inverse);
    },
    sortingDevices(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getDevices(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
  },
};
</script>
