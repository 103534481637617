<template lang="pug">
    div
        template
         .doctor-exam-detail-modal__info.doctor-exam-detail-modal__info--white(v-for="exam in $store.state.chart.lastInspections.five_exams" :key="exam.id")
            .doctor-exam-detail-modal__info-item
              .row.align-items-center.gx-0
                .col-3
                  .roundGreen(v-if="exam.admission_to_work && exam.exam_completed")
                  .roundYellow(v-if="exam.admission_to_work === null && exam.exam_completed")
                  .roundRed(v-if="exam.admission_to_work === false && exam.exam_completed")
                  .text-color--blue.mb-2 Дата и время осмотра
                  span {{exam.exam_datetime}}
                .col-5
                 .row
                  .col-3
                   .d-flex.align-items-center
                      span(:class="[bpHighColor(exam.bp_high), bpLowColor(exam.bp_low)]").flex-shrink-0.me-2
                          app-svg-icon(name="pulse")    
                      span(:class="bpHighColor(exam.bp_high)") {{exam.bp_high}}
                      span &nbsp;/&nbsp;
                      span(:class="bpLowColor(exam.bp_low)") {{exam.bp_low}}
                  .col-3
                    .d-flex.align-items-center.ps-4
                      span.flex-shrink-0.me-2
                          app-svg-icon(name="cardio")
                      span {{exam.heart_rate}}
                  .col-3
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                          app-svg-icon(name="temp")
                      span(:class="bpTemp(exam.body_temp)") {{exam.body_temp}}&nbsp;c&#176;
                  .col-3
                   .d-flex.align-items-center
                    span(:class="alcoholColor(exam.alcohol)").flex-shrink-0.me-2
                      app-svg-icon(name="breathalyzer")
                    span(:class="alcoholColor(exam.alcohol)") {{exam.alcohol}}&nbsp;мг&#65279;/&#65279;л
                  .col-2
                   .d-flex.align-items-center.btn-comment
                    app-tooltip(
                       v-if="exam.health_complaint"
                       :content="`<p class='mb-2'>Жалоба на состояние здоровья:</p><p class='text-color--black'>${exam.health_complaint}<p>`"
                      :icon="'comment'"
                     )
                .col-2
                  .text-color--blue.mb-2.ps-4 Тип осмотра:
                  span.ps-4 {{exam.exam_type_name}}
                .col-2
                  .text-color--blue.mb-2 Допуск
                  span.fw(v-if="exam.admission_to_work && exam.exam_completed") Допущен
                  span.fw(v-if="exam.admission_to_work === null && exam.exam_completed") Нет заключения
                  span.fw(v-if="exam.admission_to_work === false && exam.exam_completed") Не допущен
                .col-2
                  .text-color--blue.mb-2 Врач
                  span(v-if='exam.exam_doctor_full_name') {{exam.exam_doctor_full_name}}
                  span -
  
</template>
<script>
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTooltip from '@/components/common/Tooltip';
export default {
  data() {
    return {
      show:false
    }
  },
  components: {
   AppSvgIcon,
   AppTooltip 
  },
   props: {
     userId:String
   },
  async mounted() {
      
        await this.$store.dispatch("actionGetlastInspection", this.userId);
   
   },
   methods:{
         bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),  
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<=99)|| (key<60),  
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
     bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    }
   }
}
</script>
<style lang="scss">
 .roundGreen{
     width: 10px;
    position: absolute;;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(323.53deg, #12B048 -0.16%, #11D8B4 98.63%);
        left: 0;
    top: 27px;
 }
 .roundYellow{
     width: 10px;
    position: absolute;;
    height: 10px;
    border-radius: 50%;
    background: #EC9D25;
        left: 0;
    top: 27px;
 }
 .roundRed{
     width: 10px;
    position: absolute;;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(139.71deg, #EC0C27 3.74%, #E50169 96.76%);
        left: 0;
    top: 27px;
 }

  .doctor-exam-detail-modal__info--white{
    background: #fff !important;
    border-bottom: 1px solid #DFE4F8;
  }
  .fw{
    white-space: nowrap;
  }
  .redText{
  color:#E80744;
  svg{
    fill:#E80744 !important;
  }
}
.yellowText{
  color:#EC9125;
}
.btn-comment{
  button{
    position: relative;
    left:8px;
  }
}
.showLastExms{
 transition: all .2s ease;
 cursor: pointer;
}
.showLastExmsRotate{
  transform: rotate(180deg);
}
.cup{
  cursor: pointer;
}
</style>