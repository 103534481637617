<template lang="pug">
  section(class=['patient-card', 'profile-card'] :class="classes")
    .row.align-items-center.g-4.gy-xl-0
      .col-7.col-md-4.col-xl-3
        .d-flex.align-items-center
          app-avatar(
            :src="data.avatar"
            :viewPhoto="true"
            :type="'patient'"
            @openGallery="openGallery"
            @change="changeAvatar"
          )
          .ms-3
            ul.d-xl-none
              li(v-if="data.email" :class="{'mb-2': data.phone}") {{ data.email }}
              li(v-if="data.phone") {{ data.phone }}

            .d-none.d-xl-block
              span.text-overflow(v-if="data.email" :title="data.email") {{ data.email }}
              span.text-color--blue(v-else) нет e-mail

      //- .d-none.d-xl-block.col-2
      //-   span(v-if="data.phone") {{ data.phone }}
      //-   span.text-color--blue(v-else) нет телефона

      .col-auto.col-md-2.ms-auto.ms-md-0.col-xl-3
        span {{ toLocalDate(data.creation_date) }}

      .col-14.col-md-4.col-xl-4
        span.text-overflow(:title="data.full_name") {{ data.full_name }}

      .col-7.col-md-2
        .d-flex.align-items-center
          span.text-color--gray-light.me-2.d-md-none
            app-svg-icon(name="diary")
          span(v-if="data.exam_count") {{ data.exam_count }} {{ declOfNum(data.exam_count, ['осмотр', 'осмотра', 'осмотров']) }}
          span.text-color--blue(v-else) нет осмотров

      .col-7.col-md-2
        .d-flex.align-items-center
          .d-flex.align-items-center
            //- span.text-color--gray-light.me-2.d-md-none
            //-   app-svg-icon(name="smartwatch" view-box="0 0 17 24")
            //- span(v-if="devicesCount > 0") {{ devicesCount }} {{ declOfNum(devicesCount, ['устройство', 'устройства', 'устройств']) }}
            //- span.text-color--blue(v-else) нет устройств

          span.ms-auto.text-color--accent.d-none.d-xl-inline
            app-svg-icon(name="right-arrow")
          
</template>

<script>
import {toLocalDate, declOfNum} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';

export default {
  name: 'AppAdminPatientCard',
  components: {
    AppAvatar,
    AppSvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
  computed: {
    devicesCount() {
      return Array.from(new Set(this.data.device_serials)).length;
    },
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    openGallery(data) {
      this.$emit('openGallery', {gallery: data, patient: this.data});
    },
    changeAvatar() {
      this.$emit('changeAvatar', this.data);
    },
  },
};
</script>

<style lang="scss">
.patient-card {
  &.profile-card {
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;

    .icon {
      &__breathalyzer {
        width: $spacer * 1.8;
        height: $spacer * 1.8;
      }
    }

    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
    }
  }
}
</style>
