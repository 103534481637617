<template lang="pug">
  .profile-sidebar__content.d-flex.flex-column
    .profile-sidebar__header.d-flex.align-items-center.align-items-xxl-start
      .profile-sidebar__user.d-flex.align-items-center(v-if="currentUser")
        router-link(:to="{name: 'settings'}" class=['d-block', 'me-4'])
          app-avatar(
            :classes="'large'"
            :src="currentUser.avatar"
          )
        ul.text-size--small
          li.mb-1
            span.text-color--gray-light Личный кабинет
          li.mb-1
            app-title(:routerLink="{name: 'settings'}") {{ currentUser.full_name }}
          li
            span {{ currentUser.email }}

    
    .d-flex.flex-column.align-items-center.flex-grow-1.pt-4
      ul.profile-sidebar__menu.text-center
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'devices'}" :classes="'d-block profile-sidebar__menu-link'")
            span Мои устройства

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'exams'}" :classes="`d-block profile-sidebar__menu-link ${$route.name.indexOf('map') > -1 ? 'is-active' : ''}`")
            span Журнал осмотров

        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'patients'}" :classes="'d-block profile-sidebar__menu-link'")
            span Пользователи
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'chart'}" :classes="'d-block profile-sidebar__menu-link'")
            span Отчеты
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'references'}" :classes="'d-block profile-sidebar__menu-link'")
            span Меддокументация
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'suspended-workers'}" :classes="`d-block profile-sidebar__menu-link`")
            span Отстраненные работники  
        li.profile-sidebar__menu-item
          app-title(:routerLink="{name: 'risk-groups'}" :classes="`d-block profile-sidebar__menu-link`")
            span Группы риска  

      
      button(
        type="button"
        class=['profile-sidebar__button', 'button button--blue', 'mt-auto px-3 w-100']
        @click="isModalVisible.createDevice = true"
      )
        span.button__content
          span.d-flex.flex-shrink-0.me-3
            app-svg-icon(name="plus-thin")
          span Добавить устройства

      app-create-device-modal(
        :is-visible="isModalVisible.createDevice"
        @close="closeModal('createDevice')"
        @success="openModal('successCreatedDevice')"
      )
      // success created device
      transition(name="fade" mode="out-in")
        app-modal(v-if="isModalVisible.successCreatedDevice" @close="closeSuccessModal('successCreatedDevice')")
          template(v-slot:body)
            .text-center.pt-4
              app-title(:type="'large'") Успешно отправлено на рассмотрение
              p.text-size--small.text-color--blue.mt-3 Заявки на добавление устройств рассматриваются администраторами от 1 до 4 рабочих дней. Статус&nbsp;проверки вы можете отследить в личном кабинете.
        
            .row.justify-content-center.mt-5
              .col-14.col-md-7
                button(
                  @click="closeSuccessModal('successCreatedDevice')"
                  type="button"
                  class=['button button--accent', 'w-100']
                )
                  span.button__content В список устройств
</template>

<script>
import {mapGetters} from 'vuex';
import {
  actionTypes as authActionTypes,
  gettersTypes as authGettersTypes,
} from '@/store/modules/auth';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
import AppModal from '@/components/common/Modal';
import AppCreateDeviceModal from '@/components/profile/client/devices/CreateDeviceModal';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppClientSidebar',
  components: {
    AppAvatar,
    AppModal,
    AppCreateDeviceModal,
    AppSvgIcon,
    AppTitle,
  },
  mixins: [modalsMixin],
  data() {
    return {
      isModalVisible: {
        createDevice: false,
        successCreatedDevice: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
  },
  methods: {
    onLogout() {
      this.$store.dispatch(authActionTypes.logout).then(() => {
        this.$router.push({name: 'home'});
      });
    },
    getDevices() {
      this.$store.dispatch(devicesActionTypes.getDevices);
    },
    closeSuccessModal() {
      this.closeModal('successCreatedDevice');
      if (this.$route.name !== 'devices') this.$router.push({name: 'devices'});
      else this.getDevices();
    },
  },
};
</script>
