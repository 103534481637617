import Vue from 'vue';
import VueRouter from 'vue-router';
import is from 'is_js';
import store from '@/store';
import {
  actionTypes as authActionTypes,
  gettersTypes as authGettersTypes,
} from '@/store/modules/auth';

import Home from '@/views/Home';
import Docs from '@/views/Docs';

import Auth from '@/views/Auth';
import Register from '@/components/auth/Register';
import RegisterStatus from '@/components/auth/RegisterStatus';
import Login from '@/components/auth/Login';
import Restore from '@/components/auth/Restore';
import ChangePass from '@/components/auth/ChangePass';

import Profile from '@/views/Profile';
import Dashboard from '@/components/profile/Dashboard';
import Settings from '@/components/profile/Settings';
import Requests from '@/components/profile/admin/requests/AdminRequests';
import Devices from '@/components/profile/Devices';
import DeviceDetail from '@/components/profile/admin/devices/AdminDeviceDetail';
import Exams from '@/components/profile/Exams';
import MapExams from '@/components/profile/common/map/MapExams';
import MapRoutes from '@/components/profile/common/map/MapRoutes';
import Users from '@/components/profile/admin/users/AdminUsers';
import UserDetail from '@/components/profile/admin/users/AdminUserDetail';
import Patients from '@/components/profile/Patients';
import PatientDetail from '@/components/profile/common/patients/PatientDetail';
import Doctors from '@/components/profile/admin/doctors/AdminDoctors';
import AutoDoctor from '@/components/profile/admin/doctors/AdminAutoDoctor';
import DoctorDetail from '@/components/profile/admin/doctors/AdminDoctorDetail';

import Pdn from '@/components/profile/admin/pdns/Pdn';
import PdnUsers from '@/components/profile/admin/pdns/PdnUsers';

import RiskGroupsDashboard from '@/components/profile/admin/risk_groups/RiskGroupsDashboard';

import SuspendedWorkersDashboard from '@/components/profile/admin/suspended_workers/SuspendedWorkersDashboard';

import PoliceExamDetail from '@/views/Police';
import CheckPoliceExamDetail from '@/views/CheckPolice';

import PageNotFound from '@/components/PageNotFound';

import PageChart from '@/components/PageChart';
import PageChartPie from '@/components/PageChartPie';
import PageChartPieTab from '@/components/PageChartPieTab';
import PageChartPieTabUsers from '@/components/PageChartPieTabUsers';

import TableViewUsers from '@/components/common/ChartTableUsers';
import TableView from '@/components/common/ChartTable';

import PageNotices from '@/components/PageNotices';
import PageReferences from '@/components/PageReferences';
import PageIncoming from '@/components/PageIncoming';
import PageOutgoing from '@/components/PageOutgoing';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/docs/',
    name: 'docs',
    component: Docs,
    meta: {
      public: true,
    },
  },
  {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: '',
        component: Login,
        meta: {
          auth: true,
        },
      },
      {
        path: '*/*',
        component: Login,
        meta: {
          auth: true,
        },
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          auth: true,
        },
      },
      {
        path: 'register',
        name: 'register',
        component: Register,
        meta: {
          auth: true,
        },
      },
      {
        path: 'status',
        name: 'registerStatus',
        component: RegisterStatus,
        meta: {
          auth: true,
        },
      },
      {
        path: 'restore',
        name: 'restore',
        component: Restore,
        meta: {
          auth: true,
        },
      },
      {
        path: 'changePass',
        name: 'changePass',
        component: ChangePass,
        meta: {
          auth: true,
        },
      },
    ],
  },
  {
    path: '/profile',
    component: Profile,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
      },
      {
        path: 'settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: 'requests',
        name: 'requests',
        component: Requests,
        meta: {
          admin: true,
        },
      },
      {
        path: 'devices',
        name: 'devices',
        component: Devices,
      },
      {
        path: 'devices-control',
        name: 'devices-control',
        component: Devices,
      },
      {
        path: 'devices-check',
        name: 'devices-check',
        component: Devices,
      },
      {
        path: 'devices/:id',
        name: 'device-detail',
        component: DeviceDetail,
      },
      {
        path: 'exams',
        name: 'exams',
        component: Exams,
      },
      {
        path: 'map',
        name: 'map',
        component: MapExams,
      },
      {
        path: 'map/routes',
        name: 'map-routes',
        component: MapRoutes,
      },
      {
        path: 'users',
        name: 'users',
        component: Users,
        meta: {
          admin: true,
        },
      },
      {
        path: 'users/:id',
        name: 'user-detail',
        component: UserDetail,
        meta: {
          admin: true,
        },
      },
      {
        path: 'patients',
        name: 'patients',
        component: Patients,
      },
      {
        path: 'patients/:id',
        name: 'patient-detail',
        component: PatientDetail,
      },
      {
        path: 'doctors',
        name: 'doctors',
        component: Doctors,
        meta: {
          admin: true,
        },
      },
      {
        path: 'doctors/:id',
        name: 'doctor-detail',
        component: DoctorDetail,
        meta: {
          admin: true,
        },
      },
      {
        path: 'auto-doctor',
        name: 'auto-doctor',
        component: AutoDoctor,
        meta: {
          admin: true,
        },
      },
      {
        path: 'chart',
        name: 'chart',
        component: PageChart,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'chart-pie',
        name: 'chart-pie',
        component: PageChartPie,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'chart-pie-tab',
        name: 'chart-pie-tab',
        component: PageChartPieTab,
        // meta: {
        //   admin: true,
        // },
        children: [
          {
            path: 'inspections',
            name: 'inspections',
            component: TableView,
          },
          {
            path: 'users',
            name: 'users',
            component: TableViewUsers,
          },
        ]
      },
      {
        path: 'chart-pie-tab-users',
        name: 'chart-pie-tab-users',
        component: PageChartPieTabUsers,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'chart',
        name: 'chart',
        component: PageChart,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'pdn',
        name: 'pdn',
        component: Pdn,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'pdn-users',
        name: 'pdn-users',
        component: PdnUsers,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'risk-groups',
        name: 'risk-groups',
        component: RiskGroupsDashboard,
      },
      {
        path: 'suspended-workers',
        name: 'suspended-workers',
        component: SuspendedWorkersDashboard,
      },
      {
        path: 'chart-pie',
        name: 'chart-pie',
        component: PageChartPie,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'notices',
        name: 'notices',
        component: PageNotices,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'references',
        name: 'references',
        component: PageReferences,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'incoming',
        name: 'incoming',
        component: PageIncoming,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'outgoing',
        name: 'outgoing',
        component: PageOutgoing,
        // meta: {
        //   admin: true,
        // },
      },
      {
        path: 'chart-pie-tab',
        name: 'chart-pie-tab',
        component: PageChartPieTab,
        // meta: {
        //   admin: true,
        // },
        children: [
          {
            path: 'inspections',
            name: 'inspections',
            component: TableView,
          },
          {
            path: 'users',
            name: 'users',
            component: TableViewUsers,
          },
        ]
      },
      {
        path: 'chart-pie-tab-users',
        name: 'chart-pie-tab-users',
        component: PageChartPieTabUsers,
        // meta: {
        //   admin: true,
        // },
      },
      
    ],
  },
  {
    path: '/check-police/',
    name: 'checkPolice',
    component: CheckPoliceExamDetail,
    meta: {
      public: true,
    },
  },
  {
    path: '/police/:id',
    name: 'police',
    component: PoliceExamDetail,
    meta: {
      public: true,
    },
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const checkAccessRights = () => {
    if (is.existy(to.query.code) && is.not.empty(to.query.code)) {
      store.dispatch(authActionTypes.logout).then(() => next());
    } else {
      if (to.meta.admin) {
        store.getters[authGettersTypes.userRole] === 'admin' ? next() : next('/profile');
      } else next();

      if (to.meta.auth) store.getters[authGettersTypes.isLoggedIn] ? next('/profile') : next();
      if (to.name === 'home')
        store.getters[authGettersTypes.isLoggedIn] ? next('/profile') : next('/');
    }
  };

  if (to.meta.public) next();
  else {
    if (is.null(store.state.auth.isLoggedIn)) {
      store
        .dispatch(authActionTypes.getCurrentUser)
        .then(() => checkAccessRights())
        .catch(() => {
          to.meta.auth ? next() : next('/');
        });
    } else checkAccessRights();
  }
});

export default router;
