<template lang="pug">
  .profile-map
    
    .profile-map__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
      .profile-map__header
        .row.align-items-center.flex-md-row.g-4
          .col-12  
           .profile__title.d-flex.align-items-center.justify-content-center.justify-content-md-start.position-relative(id="isAnchorForBtn")
            //app-back-button(:classes="'me-5'")
            app-title(
                :type="'large'"
                :classes="'text-center'"
              ) {{ isTouchDevice ? 'Отчеты' : 'Отчеты' }}
           nav-chart
           .chart-heder Статистика осмотров за период
          
          .col-14.col-md-12.d-md-flex.align-items-center  
           .legend.legend--column.mt-md-5.mb-5 
            .legend-item.legend-item--green.mb-3 Допущен
            .legend-item.legend-item--red.mb-3 Не допущен
            .legend-item.legend-item--yellow.mb-3 Нет заключения
            .legend-item.legend-item--gray.mb-3 Не завершён
           .charts.mx-auto  
            line-chart-pie
          .col-14
            .tableView
              table-view
    // filter
    filter-chart-pie(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
</template>
<script>
import {mapState} from 'vuex';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import TableView from '@/components/common/ChartTable';
import FilterChartPie from '@/components/profile/common/FilterChartPie';
import {filterMixin} from '@/mixins/filter';
import LineChartPie from '@/components/common/LineChartPie';
import NavChart from '@/components/profile/common/NavChart';
export default {
  name: 'AppMapExams',
  components: {
    AppBackButton,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    FilterChartPie,
    LineChartPie,
    TableView,
    NavChart
  },
  mixins: [ filterMixin],

  data() {
    return {
      t:{},
      v: variables,

      filterOptions: {
        patients: true,
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },


    };
  },

  computed: {
    ...mapState({
    //  examsLoading: (state) => state.exams.isLoading,
    
    }),
    isTouchDevice() {
      return is.touchDevice();
    },

  },

  mounted() {
 
  },
  methods: {

  //  upd(){
  //    this.t.update();
  //    console.log('upd',this.chartData)
  //  }
  },
};
</script>
<style lang="scss" scoped>
  .d-block{
    position: relative;
   // overflow: hidden;
   //height: fit-content;
   
  }
  .legend{
    display: flex;
    max-width: 680px;
    justify-content: space-between;
  }
  .legend-item{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
position: relative;
padding-left: 20px;
color:#5B6483;
&:before{
  content: '';
  width: 10px;
  height: 10px;
  background: red;
position: absolute;
left: 0;
border-radius: 50%;
top:5px;


}
    &--violet{
      &:before{
  content: '';
  background: #90AAED;
}
    }
     &--blue{
             &:before{
  content: '';
   background: #96E6FF;
}
    
    }
     &--red{
                    &:before{
  content: '';
 background: #E37FAF;
}
     
    }
     &--yellow{
                          &:before{
  content: '';
background: #F5C261;
}
    }
     &--green{
                          &:before{
  content: '';
background: #5BCB88;
}
    }
         &--gray{
                          &:before{
  content: '';
background: #989FB8;
}
    }
  }
    .charts-empty{
   background: #FFFFFF;
border-radius: 6px;
max-width: 770px;
padding-top: 25px;
padding-bottom: 25px;
text-align: center;
  }
  .chart-heder{
    font-weight: 400;
font-size: 14px;
line-height: 18px;
color:#5B6483;
padding-top: 30px;
padding-bottom: 10px;
letter-spacing: 0.06em;
text-transform: uppercase;
@media (max-width: 768px){
  padding-top: 0;
}
  }
  .legend--column{
    display: block;
  }
</style>
