<template lang="pug">
 transition(name="fade")
      app-modal(v-if="isVisible" @close="closeModal('confirm')")
        template(v-slot:body)
          .row.justify-content-center
            .col-14.col-md-14
              .text-center 
                app-title(:type="'large'") {{ currentRequest.type_name }}
                .mt-3
                 p {{currentRequest.user_full_name}}
                  .row.flex-column.align-items-center
                    .col.mb-5
                     .responsibleListSerial.text-uppercase
                      ul
                       li.my-3 Серийный номер: {{currentRequest.additional_params.device_serial}}
                      
                    .col
                      button(
                        type="submit"
                        :disabled="false"
                        class=['button', 'form__button', 'maw182', 'noBg', 'me-2']
                        @click="onSubmit(false)"
                      )
                        .button__content Клиент
                      button(
                        type="submit"
                        :disabled="false"
                        class=['button', 'button--accent', 'form__button', 'maw182']
                        @click="onSubmit(true)"
                      )
                        .button__content Medconrol
</template>

<script>
import {mapState} from 'vuex';
//import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import variables from '@/helpers/variables';

//import useVuelidate from '@vuelidate/core';
//import {required,minLength} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';

import requestsApi from '@/api/requests';

import {actionTypes as requestsActionTypes} from '@/store/modules/requests';


import 'vue2-datepicker/locale/ru';
export default {
  name: 'ConfirmDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,

  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: true,
    },
    currentRequest: {
      type: Object,
      required: true,
      default: () => 1,
    },
  },
  // setup() {
  //   return {
  //     v$: useVuelidate(),
  //   };
  // },
  data() {
    return {
      v: variables,
      // form: {
      // version:'',
      // controlSum:''
     
      // },
      // validationErrors: {
      //   message: '',
      //   info: null,
      // },
      // limit: 100,
    };
  },
  // validations() {
  //   return {
  //     form: {
  //      version: {
  //         required,
  //         minLength: minLength(7)
  //       },
  //       controlSum: {
  //         required,
  //          minLength: minLength(7)
  //       },
  //     },
  //   };
  // },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
  },
  methods: {
    closeModal() {
      //this.resetForm();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },


    async onSubmit(value) {
      console.log(value);
      console.log(this.currentRequest);
      if (this.currentRequest) {
          if (this.currentRequest.type_id === 6){
              requestsApi.changeUpdateDeviceRequest({
              id: this.currentRequest.id,
              body: {
                reason: '',
                status_code: this.v.requests.statuses.approved,
                device_verification_owner_is_admin: value,
                device_serial: this.currentRequest.additional_params.device_serial,
                device_attestation_end: this.currentRequest.additional_params.device_attestation_end,
                device_attestation_start: this.currentRequest.additional_params.device_attestation_start,
                device_attestation: this.currentRequest.additional_params.device_attestation,
              },
            })
            .then(() => this.closeModal()).then(() => this.$toast.success('Заявка одобрена'));
          }
          else if  (this.currentRequest.type_id === 3) {
            this.$store
              .dispatch(requestsActionTypes.changeStatusDevice, {
                id: this.currentRequest.id,
                body: {
                  reason: '',
                  status_code: this.v.requests.statuses.approved,
                  device_verification_owner_is_admin: value,
                },
              })
              .then(() => this.closeModal()).then(() => this.$toast.success('Заявка одобрена'));
          }
      //   if (this.currentRequest.type_id === 1) {
      //   this.$store
      //     .dispatch(requestsActionTypes.changeStatusRegistration, {
      //       id: this.currentRequest.id,
      //       body: {
      //         reason: '',
      //         status_code: this.v.requests.statuses.approved,
      //       },
      //     })
      //     .then(() => this.successCallback('approve'));
      // } else {
      //   alert('2')
      //   this.$store
      //     .dispatch(requestsActionTypes.changeStatusDevice, {
      //       id: this.currentRequest.id,
      //       body: {
      //         reason: '',
      //         status_code: this.v.requests.statuses.approved,
      //       },
      //     })
      //     .then(() => this.successCallback('approve'));
      // }
      }
    },
    resetForm() {
      this.v$.$reset();

      this.validationErrors.message = '';
      this.validationErrors.info = null;

      this.form.devices = [{id: 0, value: ''}];
      this.clearFileInput();
    },
  },
};
</script>
<style lang="scss">
  .maw182{
    width: 182px !important;
  }
  .noBg{
    border: 1px solid #59658D;
  }
   .responsibleListSerial{
    border: 1px solid  #59658D;
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    padding: 15px 10px 15px;
    margin-top: 14px;
    margin-bottom: 10px;
   }
</style>