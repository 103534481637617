<template lang="pug">
div
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Загрузка документа
        .px-4
          form(@submit.prevent="" :class="['form', {'is-submitting': isSubmitting}]")
            .row.g-2
              .col.mb-2
                app-select(
                  ref="selectStatus"
                  :type="'form'"
                  :placeholder="'Выберите тип документа'"
                  :options="typeOpts"
                  @select="selectStatus"
              )
              
              .col-14
                label.form__label
                  input(
                    :class="['form__field']"
                    type="text"
                    v-model="form.docName"
                    placeholder="Введите номер / название документа"
                  )
              .col-14.mb-2
                    app-multiple-select(
                      ref="multipeSelectPatients"
                      :multiple="false"
                      :search-type="'patients'"
                      :placeholder="'Введите ФИО пользователя'"
                      @update="updateSelectedPatients"
                    )
              .col-14
                label.form__label
                  input(
                    :class="['form__field']"
                    type="text"
                    v-model="form.orgName"
                    placeholder="Укажите организацию, выдавшую документ"
                  )
              .col-14
                      label.form__label
                        textarea(
                          class="form__field"
                          v-model="form.rejectReason"
                          placeholder="Добавьте комментарий при необходимости"
                        )
              .col-7
               date-picker(
                  prefix-class="app"
                  placeholder="Дата выдачи документа"
                  format="DD.MM.YYYY"
                  v-model="form.docDate"
              )
              .col-14
              .col-7
                .form__file.d-flex.align-items-center
                  input(
                    class="d-none"
                    type="file"
                    name="document"
                    @change="onSignatureSelected"
                    @click="v$.$touch()"
                    ref="signatureInput"
                    accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                  )

                  div(
                    v-if="form.signature"
                    class=['form__file-element', 'd-flex align-items-center', 'text-size--small']
                  )
                    span.flex-shrink-0.me-3
                      app-svg-icon(name="image")

                    ul.flex-grow-1.overflow-hidden
                      li.form__file-name.text-color--black {{ form.signature.name }}
                      li.mt-1 {{ Number(form.signature.size/1000000).toFixed(4) }} mb

                    button(
                      type="button"
                      @click="clearSignatureInput"
                      class=['form__file-delete', 'flex-shrink-0 ms-3']
                    )
                      app-svg-icon(name="trash")

                  .w-100.text-color--blue.text-size--small.text-center(v-else) Загрузите документ

                  button(
                    class=['form__file-button', 'd-flex justify-content-end align-items-end align-items-md-center']
                    @click="$refs.signatureInput.click()"
                  )
                    app-svg-icon(name="clip")
          .col-14.mt-25
            button(
              type="submit"
              @click="onSubmit"
              class=['button', 'button--accent', 'form__button']
            )
              span.button__content Отправить документ
</template>

<script>
import {
    mapState
} from 'vuex';

import variables from '@/helpers/variables';
import {
    toLocalDate,
} from '@/helpers/utils';

import useVuelidate from '@vuelidate/core';
import {
    required,
} from '@vuelidate/validators';

import {
    EXIF
} from 'exif-js';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import {mask} from 'vue-the-mask';
import AppSelect from '@/components/common/Select';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import {
    modalsMixin
} from '@/mixins/modals';

import incomingMedDocs from '@/api/incomingMedDocs';

export default {
    name: 'AppCreatePatientsModal',
    directives: {
        mask,
    },
    components: {
        AppLoading,
        AppErrorMessage,
        AppValidationErrors,
        AppModal,
        AppTitle,
        AppSvgIcon,
        DatePicker,
        AppSelect,
        AppMultipleSelect
    },
    mixins: [modalsMixin],
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },
    data() {
        return {
            v: variables,
            isModalVisible: {
                success: false,
            },
            form: {
                firstName: '',
                docName: '',
                orgName: '',
                patients: [],
                rejectReason: '',
                docDate: '',
                docType: '',
                signature: null,
                file: null,
            },
            activeTab: 'one',
            localValidationErrors: null,

            signature: {
                localUrl: null,
            },
            datepicker: {
                lang: {
                    formatLocale: {
                        firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
                defaultValue: {
                    panel: 'year',
                    year: '1980',
                },
            },
            successData: null,
            typeOpts: [
                {
                caption: 'справка о состоянии здоровья',
                icon: 'allright',
                value: 'health_certificate',
                selected: false,
                },
                {
                caption: 'справка о травме',
                icon: 'allright',
                value: 'injury_certificate',
                selected: false,
                },
                {
                caption: 'справка о результатах ХТИ',
                icon: 'allright',
                value: 'hti_certificate',
                selected: false,
                },
                {
                caption: 'заключение предварительного или периодический мед. осмотра',
                icon: 'allright',
                value: 'medical_exam_conclusion',
                selected: false,
                },
                {
                caption: 'акт медицинского освидетельствования',
                icon: 'allright',
                value: 'medical_exam_certificate',
                selected: false,
                },
                {
                caption: 'официальное обращение клиента',
                icon: 'allright',
                value: 'client_request',
                selected: false,
                },
            ],
        };
    },
    computed: {
        ...mapState({
            isSubmitting: (state) => state.patients.isSubmitting,
            validationErrors: (state) => state.patients.validationErrors,
        }),
        isOnePatientTab() {
            return this.activeTab === 'one';
        },
        defaultDate() {
            return new Date().setYear(this.datepicker.defaultValue.year);
        },
    },
    validations() {
        if (this.isOnePatientTab) {
            return {
                form: {
                    firstName: {
                        required,
                    },
                },
            };
        } else {
            return {
                form: {
                    file: {
                        required,
                    },
                },
            };
        }
    },
    mounted() {

    },
    methods: {
        getMetaDataImage(file, variable) {
            if (file) {
                EXIF.getData(file, () => {
                    this[variable].exifdata = file.exifdata;
                });
            }
        },
        toLocalDate(date) {
            if (date) {
                return toLocalDate(date);
            } else return false;
        },
        onFileSelected({
            target
        }) {
            this.form.file = target.files[0];
        },

        onSignatureSelected({
            target
        }) {
            const reader = new FileReader();

            this.form.signature = target.files[0];

            reader.readAsDataURL(this.form.signature);
            reader.onload = () => (this.signature.localUrl = reader.result);
        },
        clearSignatureInput() {
            this.form.signature = null;
            if (this.$refs.signatureInput) this.$refs.signatureInput.value = '';
        },
        clearFileInput() {
            this.form.file = null;
            if (this.$refs.fileInput) this.$refs.fileInput.value = '';
        },
        disableAfterToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        disableBeforeAdult(date) {
            const today = new Date();

            today.setFullYear(today.getFullYear() -18);
           // today.setMonth(8);
            today.setHours(0, 0, 0, 0);

            return date > today;
        },
        disableAfterTodayDrive(date) {
            const start = new Date(1930, 1, 1);
            // const end = new(Date) 
            start.setHours(0, 0, 0, 0);

            return date < start;
        },
        close() {
            this.resetForm();
            this.$emit('close');
        },
        success(data) {
            if (!this.isOnePatientTab) this.successData = data;

            this.openModal('success');
            this.$emit('success');
        },
        resetForm() {
            this.v$.$reset();

            for (let key in this.form) {
                if (Object.prototype.hasOwnProperty.call(this.form, key)) {
                    this.form[key] = '';
                }
            }
            this.form.gender = '1';
            this.localValidationErrors = null;

            this.clearFileInput();
            this.clearSignatureInput();
        },
        selectStatus(option) {
            console.log(option);
            this.typeOpts.map((el) => (el.selected = el.value === option.value));
            this.form.docType = option.value;
        },
    updateSelectedPatients(data) {
      this.form.patients = data.map((el) => el.value);

    //   if (data.length) {
    //     if (is.null(this.form.negative.patients)) this.form.negative.patients = false;
    //   } else this.form.negative.patients = null;

    //   this.getCount();
    },
       async onSubmit() {
            console.log('submit');
            const formData = new FormData();
            formData.append('document_type', this.form.docType);
            formData.append('document_name', this.form.docName);
            formData.append('patient_code', this.form.patients[0]);
            formData.append('med_org_name', this.form.orgName);
            formData.append('client_comment', this.form.rejectReason);
            formData.append('issue_date', this.$moment(this.form.docDate).format('DD.MM.YYYY'));
            formData.append('med_report_file', this.form.signature, this.form.signature.name);
            // create request for income docs
            let result = await incomingMedDocs.createIncomingMedDocument(formData);
            this.$emit('close');
            this.$toast.success('Документ загружен')
            console.log(result);
        },
        backToForm() {
            this.closeSuccessModal();
            this.$emit('open');
        },

    },
};
</script>
