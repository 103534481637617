<template lang="pug">
  section(class=['patient-card', 'profile-card'] :class="classes")
    .row.align-items-center.g-4.gy-xl-0 
      .col-auto
          span.idDoc {{data.exams_data_id}}       
      .d-none.d-xl-block.col-2
        span {{$moment(data.datetime).format('DD.MM.YYYY')}}
        span  {{$moment(data.datetime).format('HH:mm:ss')}}
      .col-auto.col-2
        span {{data.patient_company_name}}

      .col-14.col-md-2.col-xl-2
        span.text-overflow {{data.patient_full_name}}

      .col-7.col-md-2.col-xl-2
        span.text-overflow {{data.patient_birth}}
      .col-7.col-md-3.col-xl-3
        .d-flex.align-items-center
          .d-flex.align-items-center
            span {{data.pre_diagnosis}}
      .col-7.col-md-2.col-xl-2.d-flex
        span.text-overflow.underline(type="button" @click.prevent="openCertificate(data)") {{data.doctor_full_name}}
        span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='download(data.med_file)')
            span
             img(src="@/assets/img/notice.svg" )
          
</template>

<script>
import {mapState} from 'vuex';

import {toLocalDate, declOfNum} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
export default {
  name: 'PatientNotice',
  components: {
    AppAvatar,
    AppSvgIcon,
     
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
     download(urls) {
      for(let i = 0; i < urls.length; i++) {
        if(urls[i]){
          var frame = document.createElement("iframe");
          frame.src = `${this.settings.s3_host}sd-sign-by-exams/${urls[i]}`;
          frame["download"] = 1
          document.body.appendChild(frame);
        }
      }
  },
   openCertificate(data) {
      this.$emit('openCertificate', data);
    },
  },
};
</script>

<style lang="scss">
.idDoc{
  min-width: 30px;
  display: block;
}
.patient-card {
  &.profile-card {
    padding-top: $spacer * 0.6;
    padding-bottom: $spacer * 0.6;

    .icon {
      &__breathalyzer {
        width: $spacer * 1.8;
        height: $spacer * 1.8;
      }
    }

    @include mediaToDown($md) {
      padding-top: $spacer * 1.6;
      padding-bottom: $spacer * 1.6;
    }
  }
}
.pointer{
  cursor: pointer;
}
.underline{
  text-decoration: underline;
}
</style>
