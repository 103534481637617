<template lang="pug">
  ul.tabs-nav.d-flex(:class="[{'is-styled': isStyled}, theme && `${theme}-theme`, classes]")
    li.tabs-nav__item.flex-shrink-0(v-for="tab in tabs" :key="tab.id")
      button(
        type="button"
        :class="['tabs-nav__button', 'button', { 'is-active': tab.id === selected }]"
        @click="setTab(tab.id)"
      )
        .button__content.d-flex.align-items-center.justify-content-center
          span.flex-shrink-0.d-flex.me-3(v-if="tab.icon")
            app-svg-icon(:name="tab.icon")
          span {{ tab.caption }}
          span.button__content-add(v-if="tab.add") &nbsp;{{ tab.add }}
</template>

<script>
import simplebar from 'simplebar-vue';
import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppTabNav',
  components: {
    AppSvgIcon,
    simplebar,
  },
  props: {
    tabs: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    selected: {
      type: String,
      required: true,
      default: '',
    },
    theme: {
      type: String,
      required: false,
      default: '',
    },
    isStyled: {
      type: Boolean,
      required: false,
      default: true,
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    setTab(tab) {
      this.$emit('selected', tab);
    },
  },
};
</script>

<style lang="scss">
.tabs-nav {
  overflow-y: visible;
  overflow-x: auto;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &.jointly-theme {
    @include mediaToDown($md) {
      flex-direction: column;
    }
    .tabs-nav {
      &__item {
        flex: 1 0 0;

        &:first-child {
          .tabs-nav__button,
          .tabs-nav__button:before {
            border-top-left-radius: $border-radius-sm;
            border-bottom-left-radius: $border-radius-sm;
          }
        }
        &:last-child {
          .tabs-nav__button,
          .tabs-nav__button:before {
            border-top-right-radius: $border-radius-sm;
            border-bottom-right-radius: $border-radius-sm;
          }
        }
        @include mediaToDown($md) {
          &:first-child {
            .tabs-nav__button,
            .tabs-nav__button:before {
              border-top-left-radius: $border-radius-sm;
              border-top-right-radius: $border-radius-sm;
              border-bottom-left-radius: 0;
            }
          }
          &:last-child {
            .tabs-nav__button,
            .tabs-nav__button:before {
              border-bottom-left-radius: $border-radius-sm;
              border-bottom-right-radius: $border-radius-sm;
              border-top-right-radius: 0;
            }
          }
        }
      }
      &__button {
        width: 100%;
        border-radius: 0;
        padding: $spacer;
        color: get($colors, blue-dark);
        box-shadow: inset 0 0 0 $border-width get($colors, blue-hover);

        &:before {
          border-radius: 0;
          background-color: get($colors, blue-hover);
        }
        &.is-active {
          color: get($colors, white);

          &:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
  &.separate-theme {
    .tabs-nav {
      &__item {
        flex-shrink: 0;
        margin-right: $spacer * 0.6;
      }
      &__button {
        background-color: get($colors, white);
        border-radius: $border-radius-sm;
        border: $border-width solid get($colors, blue);
        color: get($colors, blue);
        text-transform: none;
        white-space: nowrap;
        padding: $spacer * 0.4 $spacer * 2;

        &:before {
          background-color: get($colors, blue-hover);
        }
        .button__content {
          svg {
            width: $spacer * 1.6;
            height: $spacer * 1.6;
          }
        }
        &.is-active {
          border-color: transparent;
          color: get($colors, white);

          &:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
