<template lang="pug">
  figure.logo.d-flex.flex-column.align-items-center
      img(class="logo__img" src="@/assets/img/logo_mc_blue.svg")
      figcaption(class="logo__caption text-color--blue" v-if="caption") {{ caption }}

</template>

<script>
export default {
  name: 'Logo',
  props: {
    caption: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.logo {
  &__caption {
    margin-top: $spacer * 2.3;
  }
}
@include mediaToDown($md) {
  .logo {
    &__img {
      max-width: $spacer * 19;
    }
    &__caption {
      margin-top: $spacer;
    }
  }
}
</style>
