<template lang="pug">
  .profile-user-detail
    .profile-user-detail__inner(:class="[{'is-filterable': isFilterVisible}, {'filter-is-open': isFilterOpen && isFilterVisible}]")
      .profile-user-detail__header.mb-3.mb-md-0
        .row.align-items-center
          .col-14.col-xxl-auto.me-auto.order-last.order-md-first
            .row.align-items-center.g-5
              .col-4.col-xl-auto
                app-back-button(:prev-page="'users'")
              .col-4.col-xl-auto.me-auto
                app-title(
                  :type="'large'"
                  :classes="'text-center'"
                ) {{ isTouchDevice ? 'Клиент' : 'Страница клиента' }}
              .col-4.col-xl-auto.d-xxl-none
                .d-flex.align-items-center.justify-content-end
                  button(
                    type="button"
                    @click.prevent="openModal('changeUser')"
                    class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
                  )
                    span.flex-shrink-0
                      app-svg-icon(name="edit")

                  button(
                    type="button"
                    @click.prevent="openModal('deleteUser')"
                    class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
                  )
                    span.flex-shrink-0
                      app-svg-icon(name="trash")

      
      .profile-user-detail__content.mt-20.position-relative
        app-loading(v-if="usersLoading")
        app-error-message(v-if="usersError" :message="usersError")

        transition(name="fade" mode="out-in")
          div(v-if="user")
            // client mobile card
            section.profile-user-detail__card.d-md-none
              .row.align-items-center.g-4
                .col-7
                  span.text-color--blue.mb-3 ИНН:&ensp;
                  span(v-if="user.inn") {{ user.inn }}
                  span(v-else) &#8212;

                .col-auto.ms-auto
                  span {{ user.creation_date }}

                .col-8
                  ul
                    li.mb-2 {{ user.full_name }}
                    li
                      span(v-if="user.email") {{ user.email }}
                      span(v-else) &#8212;

                .col-auto.ms-auto
                  app-avatar(:src="user.avatar")


            // client desktop card
            section.profile-user-detail__card.d-none.d-md-block
              .row.align-items-center.g-4.gy-xxl-0
                .col-7.col-xl-3
                  .d-none.d-xl-block.text-color--blue.mb-3 Клиент
                  .d-flex.align-items-center
                    app-avatar(:src="user.avatar")
                    span.ms-3  {{ user.full_name }}
                
                .col-7.col-xl-3
                  .text-color--blue.mb-3 Дата регистрации
                  span {{ user.creation_date }}
                
                .col-7.col-xl-auto.flex-grow-1
                  .text-color--blue.mb-3 Контактные данные
                  span(v-if="user.email") {{ user.email }}
                  span(v-else) &#8212;
                
                .col-7.col-xl-2
                  .text-color--blue.mb-3 ИНН
                  span(v-if="user.inn") {{ user.inn }}
                  span(v-else) &#8212;
  
                .col-auto.d-none.d-xxl-block
                  .d-flex.align-items-center
                    button(
                      type="button"
                      @click="isModalVisible.changeUser = true"
                      class=['profile-card__button profile-card__button--edit', 'd-flex align-items-center justify-content-center me-3']
                    )
                      app-svg-icon(name="edit")
  
                    button(
                      type="button"
                      @click="isModalVisible.deleteUser = true"
                      class=['profile-card__button profile-card__button--delete', 'd-flex align-items-center justify-content-center']
                    )
                      app-svg-icon(name="trash")
  

            // devices
            .profile-user-detail__devices.mt-5.position-relative
              .d-flex.align-items-center.mb-4.mb-xl-3
                span.d-xl-none.text-color--blue.me-3.flex-shrink-0
                  app-svg-icon(name="smartwatch" view-box="0 0 17 24")
                app-title Устройства клиента
  

              app-loading(v-if="devicesLoading")
              app-error-message(v-if="devicesError" :message="devicesError")

              transition(name="fade" mode="out-in")
                template(v-if="devices")
                  div(v-if="devices.result && devices.result.length")

                    .d-none.d-md-block
                      ul.profile-user-detail__devices-list
                        li.mb-3(v-for="device in devices.result.slice(0, pagination.devices.total)" :key="device.serial")
                          //- router-link(:to="{name:'device-detail', params: {id: device.serial}}" class=['d-block'])
                          app-admin-user-device-card(:data="device" @delete="openDeleteModal"  @change="openChangeModal(device)")

                      button(
                        v-if="pagination.devices.total < devices.result.length"
                        type="button"
                        @click="pagination.devices.total += pagination.devices.offset"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3 me-4']
                      ) Показать еще (+{{ (devices.result.length - pagination.devices.total) >= pagination.devices.offset ? pagination.devices.offset : devices.result.length - pagination.devices.total }})

                      button(
                        v-if="pagination.devices.total > pagination.devices.start"
                        type="button"
                        @click="pagination.devices.total = pagination.devices.start"
                        class=['text-size--small text-decoration--underline link--accent', 'mt-3']
                      ) Свернуть


                    // slider for mobile
                    .profile-modile-slider.d-md-none
                      swiper(
                        :options="Object.assign({}, slider.common, slider.devices)"
                        :auto-update="true"
                        :auto-destroy="false"
                      )
                        swiper-slide(v-for="device in devices.result" :key="device.serial")
                          router-link(:to="{name:'device-detail', params: {id: device.serial}}" class=['d-block'])
                            app-admin-user-device-card(:data="device" @delete="openDeleteModal")

                        div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-devices-pagination'])
  

                  .text-color--blue.text-size--small(v-else) нет устройств
  
  
            //exams
            .profile-user-detail__exams.mt-5.position-relative
              .d-flex.align-items-center.mb-4.mb-xl-3
                span.d-xl-none.d-flex.text-color--blue.me-3.flex-shrink-0
                  app-svg-icon(name="diary")
                app-title(:classes="'me-auto'") {{ isTouchDevice ? 'Последние данные с&nbsp;устройств' : 'Последние зарегистрированные осмотры с устройств' }}
                router-link(
                  v-if="exams && exams.result.length"
                  :to="{name: 'exams'}"
                  class=['text-color--accent link--accent', 'd-xl-none d-flex align-items-center flex-shrink-0', 'ms-3 text-uppercase ls-lg']
                )
                  span Все
                  span.ms-3.d-flex.flex-shrink-0
                    app-svg-icon(name="right-long-arrow")
  
              app-loading(v-if="examsLoading")
              app-error-message(v-if="examsError" :message="examsError")
  
              transition(name="translucent")
                template(v-if="exams")
                  div(v-if="exams.result.length")
                    .d-none.d-xl-block.position-relative
                      .profile-table.is-exams-table.flex-grow-1
                        .profile-table__header
                          .row.align-items-center.g-4
                            div(v-for="column in columns" :key="column.key" :class="column.class")
                              button(
                                type="button"
                                @click="sortingExams(column.key, column.inverse)"
                                class=['profile-table__sort-button']
                                :class="{'is-active': sortKey === column.key}"
                              )
                                span.d-flex.align-items-center
                                  span(v-if="column.caption") {{ column.caption }}
                                  span(v-else-if="column.icon")
                                    app-svg-icon(:name="column.icon")
                                  span.d-flex.ms-3
                                    app-svg-icon(name="down-arrow")

                        ul.profile-table__body(id="user-exams")
                          li.profile-table__row(v-for="exam in exams.result.slice(0, pagination.exams.total)" :key="exam.id")
                            app-exam-card(
                              :data="exam"
                              :parent-id="'user-exams'"
                              :type="'user-detail'"
                              :columns="columns"
                              @openModal="openExamDetailModal"
                            )
                      
                      router-link(
                        :to="{name: 'exams', query: {user: user.id}}"
                        class=['text-size--small text-decoration--underline link--accent', 'd-block mt-4']
                      ) Показать все

                    // slider for mobile
                    .profile-modile-slider.d-xl-none
                      swiper(
                        :options="Object.assign({}, slider.common, slider.exams)"
                        :auto-update="true"
                        :auto-destroy="false"
                      )
                        swiper-slide(v-for="exam in exams.result.slice(0, 5)" :key="exam.id")
                          app-exam-card-mobile(:data="exam" @openModal="openExamDetailMobile")

                        div(slot="pagination" class=['swiper-pagination', 'mt-4', 'js-exams-pagination'])
  
                  .text-color--blue.text-size--small(v-else) нет осмотров
  

    

    // filter
    app-filter(
      v-if="isFilterVisible && !isTouchDevice"
      :options="filterOptions"
      :is-filtered="isFiltered"
      :is-open="isFilterOpen"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
    // change user modal
    app-admin-change-user-modal(
      v-if="user"
      ref="changeUser"
      :is-visible="isModalVisible.changeUser"
      :user="user"
      :is-loading="usersLoading"
      :error="usersError"
      @submit="changeUser"
      @close="closeModal"
    )
    // delete device modal
    app-delete-device-modal(
      :data="deleteDevice"
      :is-visible="isModalVisible.deleteDevice"
      @close="closeModal('deleteDevice')"
      @success="deletedDevice"
    )
    // exam detail modal
    app-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
    )
    // exam detail modal for mobile
    app-exam-detail-mobile(
      :is-visible="isModalVisible.examDetailMobile"
      :data="currentExam.data"
      :type="'user-detail'"
      @openModal="openExamDetailModal"
      @close="closeModal('examDetailMobile')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )
    change-device-modal(
      :is-visible="isModalVisible.changeDevice"
      :toEdit="toEdit"
      @close="closeModal('changeDevice')" 
    )
    // delete user modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteUser" @close="closeModal('deleteUser')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить клиента?
            p.text-color--blue.mt-3 {{ user.email }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteUser')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteUser(user)"
                :disabled="usersLoading"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Также удалятся все связанные устройства и осмотры.<br>>Отменить действие нельзя.

    // success modals
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successChanged" @close="closeModal('successChanged')" :without-close-btn="true")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Успешно изменено!

          .d-flex.justify-content-center.mt-5
            button(
              @click="closeModal('successChanged')"
              type="button"
              class=['button button--accent']
            )
              span.button__content Закрыть
              
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successDeletedDevice" @close="closeModal('successDeletedDevice')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Устройство успешно удалено
            p.text-size--small.text-color--blue.mt-3
              template(v-if="userRole === 'client'")
                span Вам придет уведомление на e-mail об удалении устройства
                span.text-family--medium.text-color--blue-dark &ensp;{{ deleteDevice.serial}}
              template(v-else)
                span {{ user.full_name }} будет уведомлен об удалении устройства
                span.text-family--medium.text-color--blue-dark &ensp;{{ deleteDevice.serial}}
        
          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeModal('successDeletedDevice')"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content Назад
                
    
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.successDeletedUser" @close="$router.go(-1)")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Успешно удалён!
            p.text-color--blue.mt-3 {{ user.full_name }} будет оповещен о Вашем решении.
        
          .d-flex.justify-content-center.mt-5
            button(
              @click="$router.go(-1)"
              type="button"
              class=['button button--accent']
            )
              span.button__content Назад в список
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as usersActionTypes} from '@/store/modules/users';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {actionTypes as examsActionTypes} from '@/store/modules/exams';

import is from 'is_js';
import variables from '@/helpers/variables';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import SwiperCore, {Pagination} from 'swiper';

import AppModal from '@/components/common/Modal';
import AppGallery from '@/components/common/Gallery';
import AppBackButton from '@/components/common/BackButton';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';

import AppAvatar from '@/components/profile/common/Avatar';
import AppFilter from '@/components/profile/common/Filter';
import AppStatus from '@/components/profile/common/Status';
import AppDeleteDeviceModal from '@/components/profile/common/devices/DeleteDeviceModal';
import AppExamCard from '@/components/profile/common/exams/ExamCard';
import AppExamCardMobile from '@/components/profile/common/exams/ExamCardMobile';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppExamDetailMobile from '@/components/profile/common/exams/ExamDetailMobile';
import AppAdminChangeUserModal from '@/components/profile/admin/users/AdminChangeUserModal';
//import AppAdminUserDeviceCard from '@/components/profile/admin/users/AdminUserDeviceCard';
import AppAdminUserDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';
import ChangeDeviceModal from '@/components/profile/client/devices/ChangeDeviceModal';

import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {examDetailMobileMixin} from '@/mixins/examDetailMobile';
import {galleryMixin} from '@/mixins/gallery';
import {filterMixin} from '@/mixins/filter';

SwiperCore.use([Pagination]);

export default {
  name: 'AppAdminUserDetail',
  components: {
    AppAvatar,
    AppTitle,
    AppModal,
    AppGallery,
    AppBackButton,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppFilter,
    AppStatus,
    AppExamCard,
    AppExamCardMobile,
    AppExamDetailMobile,
    AppExamDetailModal,
    AppDeleteDeviceModal,
    AppAdminChangeUserModal,
    AppAdminUserDeviceCard,
    Swiper,
    SwiperSlide,
    ChangeDeviceModal
  },
  mixins: [examDetailModalMixin, examDetailMobileMixin, galleryMixin, filterMixin],
  data() {
    return {
      toEdit:[],
      v: variables,
      filterOptions: {
        patients: true,
        examParams: true,
        date: true,
        time: true,
        devices: true,
        bodyParams: true,
        methods: {
          getData: ['getExams', 'getDevices'],
          getCount: 'getCountExams',
        }
      },
      pagination: {
        devices: {
          start: 3,
          total: 3,
          offset: 10,
        },
        exams: {
          start: 10,
          total: 10,
          offset: 10,
        },
      },
      sortKey: '',
      sortInverse: false,
      columns: [
        {
          class: 'col-4 col-xxl-2',
          caption: 'Дата',
          key: 'exam_date_time',
          inverse: true,
        },
        {
          class: 'col-3 col-xxl-4',
          caption: 'Код и ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
      ],
      isModalVisible: {
        changeUser: false,
        deleteUser: false,
        deleteDevice: false,
        successChanged: false,
        successDeletedUser: false,
        successDeletedDevice: false,
        changeDevice:false
      },
      deleteDevice: {},
      slider: {
        common: {
          slidesPerView: 1,
          spaceBetween: 10,
          breakpoints: {
            640: {
              slidesPerView: 2,
            },
          },
        },
        exams: {
          pagination: {
            el: '.js-exams-pagination',
            dynamicBullets: true,
            renderBullet: function (index, className) {
              const slide = this.slides[index];
              const status = slide.querySelector('[data-status]');

              return `<span data-status="${status.dataset.status}" class="${className}"></span>`;
            },
          },
        },
        devices: {
          pagination: {
            el: '.js-devices-pagination',
            dynamicBullets: true,
            renderBullet: function (index, className) {
              const slide = this.slides[index];
              const status = slide.querySelector('[data-status]');

              return `<span data-status="${status.dataset.status}" class="${className}"></span>`;
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
      userRole: authGettersTypes.userRole,
    }),
    ...mapState({
      usersLoading: (state) => state.users.isLoading,
      users: (state) => state.users.data,
      usersError: (state) => state.users.error,

      devicesLoading: (state) => state.devices.isLoading,
      devices: (state) => state.devices.data,
      devicesError: (state) => state.devices.error,

      examsLoading: (state) => state.exams.isLoading,
      exams: (state) => state.exams.data,
      examsCount: (state) => state.exams.count,
      examsError: (state) => state.exams.error,
    }),
    user() {
      if (this.users) {
        return this.users.result[0];
      } else return false;
    },
    isFilterVisible() {
      return this.user && this.user.exam_count;
    },
    settings() {
      return {
        filters: {
          patients: {
            negative: true,
            payload: [],
          },
          users: {
            negative: false,
            payload: [Number(this.$route.params.id)],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  created() {
    this.getUser(this.filters);
    this.getDevices(this.filters);
    this.getExams(this.filters);
  },
  methods: {
    goToFirstPage() {
      this.pagination.devices.total = this.pagination.devices.start;
    },
    getUser(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store
        .dispatch(usersActionTypes.getUsers, data)
        .then(() => this.$refs.changeUser.resetForm());
    },
    getDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.filterDevices, data).then(() => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.pagination.devices.total = this.pagination.devices.start;
        this.isFiltered = Object.keys(filters).length > 0;
      });
    },
    getExams(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(examsActionTypes.getExams, data).then(() => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.reverseSorting();
      });
    },
    getCountExams(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(examsActionTypes.getCountExams, data);
    },
    sortingExams(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExams(this.filters);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openDeleteModal(data) {
      this.deleteDevice = data;
      this.openModal('deleteDevice');
    },
    changeUser(data) {
      data.form.user_id = this.user.id;

      this.$store.dispatch(usersActionTypes[data.slug], data.form).then(() => {
        this.closeModal('changeUser');
        this.openModal('successChanged');
        this.getUser(this.filters);
      });
    },
    deleteUser({id}) {
      this.$store
        .dispatch(usersActionTypes.deleteUser, {
          id,
        })
        .then(() => {
          this.closeModal('deleteUser');
          this.openModal('successDeletedUser');
        });
    },
    deletedDevice() {
      this.openModal('successDeletedDevice');
      this.getDevices(this.filters);
    },
     openChangeModal(data) {
      //this.deleteDevice = data;
      this.isModalVisible.changeDevice = true;
      this.toEdit=data
    },
  },
};
</script>

<style lang="scss">
.profile-user-detail {
  &__card {
    @include font-size(get($text-sizes, small));
    color: get($colors, black);

    @include mediaToDown($xl) {
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
      border-top: $border-width solid get($colors, gray-hover);
      border-bottom: $border-width solid get($colors, gray-hover);
    }
    @include mediaToDown($md) {
      padding-top: $spacer * 1.2;
      padding-bottom: $spacer * 1.2;
    }
  }
}
</style>
