<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="close" :classes="`full-content close-outside ${options.type || 'gallery'}`")
      template(v-slot:body)
        .modal__preview.d-flex.align-items-center.justify-content-center(v-if="options.preview")
          img(:src="options.preview")

        ul
          li(v-for="image in options.sources" :key="image.src")
            img(:src="image.src" class=['d-block mx-auto'])

      template(v-slot:footer)
        .row.gx-4.mt-4(v-if="options.edit || options.delete")
          .col-7(v-if="options.edit")
            button(
              type="button"
              @click="openEditModal(options.edit)"
              class=['button button--blue', 'w-100']
            )
              span.button__content Изменить

          .col-7(v-if="options.delete")
            button(
              type="button"
              @click="openDeleteModal(options.delete)"
              class=['button button--blue', 'w-100']
            )
              span.button__content Удалить
        
</template>

<script>
import AppSvgIcon from '@/components/common/SvgIcon';
import AppModal from '@/components/common/Modal';

export default {
  name: 'AppGallery',
  components: {
    AppSvgIcon,
    AppModal,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit('close');
    },
    openEditModal(slug) {
      this.$emit('openEditModal', slug);
    },
    openDeleteModal(slug) {
      this.$emit('openDeleteModal', slug);
    },
  },
};
</script>
