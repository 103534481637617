<template lang="pug">
  .profile-devices__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
    .profile-devices__header
      .row
        .col-14.col-md-7
          app-title(
            :type="'large'"
            :id="'isAnchorForBtn'"
            :classes="'profile__title  mt-4 mt-md-0'"
          ) Поверка устройств
        .col-12
         NavDevice


    .profile-devices__content.mt-20.position-relative
      transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="devices")
          template(v-if="devices.result && devices.result.length")
            app-select(
              @select="sortBySelect"
              :classes="'d-xl-none mb-4'"
              :options="sortOptions"
              :placeholder="'выберите...'"
            )
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingDevices(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="device in devices.result" :key="device.serial")
                    section.profile-card
                      .row.align-items-md-center.gy-4.gy-md-0
                        .col-7.col-md-2
                          .text-color--blue.mb-2.d-xl-none Серийный номер
                          span {{ device.serial }}

                        .col-7.col-md-2
                         .text-color--blue.mb-2.d-xl-none {{device.device_verification_owner == null ? 'Клиент' : 'MedControl'}}
                         span {{device.device_verification_owner == null ? 'Клиент' : 'MedControl'}}

                        .col-14.col-md-2
                          .text-color--blue.mb-2.d-xl-none {{toLocalDate(device.device_attestation_start)}}
                          span {{toLocalDate(device.device_attestation_start)}}
                        .col-14.col-md-2
                          .text-color--blue.mb-2.d-xl-none {{toLocalDate(device.device_attestation_end)}}
                          span {{toLocalDate(device.device_attestation_end)}}
                        .col-14.col-md-4
                          .text-color--blue.mb-2.d-xl-none {{device.device_attestation}}
                          span {{device.device_attestation}}

                        .col-14.col-md-2.d-flex
                          span.text-color--blue-dark.green.d-flex.align-items-center(v-if='device.device_attestation_ending >= 30') Поверено
                          span.text-color--blue-dark.yellow.d-flex.align-items-center(v-else-if='device.device_attestation_ending < 30 && device.device_attestation_ending >= 0') Заканчивается
                          span.text-color--blue-dark.red.d-flex.align-items-center(v-else) Не поверено
                          //.d-flex.align-items-center Поверено {{device.device_attestation_ending}}
                          button(
                          type="button"
                          @click.prevent="editModal(device)"
                          class=['profile-card__button profile-card__button--delete no-border', 'd-flex align-items-center justify-content-center']
                          )
                           span.flex-shrink-0
                           app-svg-icon(name="edit")



            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .d-flex.align-items-center.text-color--blue.text-size--small
              span.d-flex.me-3
                app-svg-icon(name="wrong")
              span Нет устройств
    app-edit(
     :is-visible="isModalVisible.edit"
     @close="closeModal('edit')"
     :toEdit="toEdit"
     )
    // filter
    filter-check(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="devicesCount"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
      @getCount="getCountFilter"
    )


</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {actionTypes as devicesActionTypes} from '@/store/modules/devices';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import is from 'is_js';
import variables from '@/helpers/variables';
import {declOfNum, blobToFile, toLocalDate} from '@/helpers/utils';

import AppTitle from '@/components/common/Title';
import AppSelect from '@/components/common/Select';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';

import FilterCheck from '@/components/profile/common/FilterCheck';
import AppStatus from '@/components/profile/common/Status';
import AppAdminDeviceCard from '@/components/profile/admin/devices/AdminDeviceCard';

import {modalsMixin} from '@/mixins/modals';
import {filterMixin} from '@/mixins/filter';

import NavDevice from '@/components/profile/common/NavDevice';

import AppEdit from '@/components/profile/admin/devices/AppDeviceCheckEditModal';
export default {
  name: 'AppAdminDevices',
  components: {
    AppStatus,
    FilterCheck,
    AppTitle,
    AppSvgIcon,
    AppPagination,
    AppLoading,
    AppErrorMessage,
    AppSelect,
    AppAdminDeviceCard,
    NavDevice,
    AppEdit
  },
  mixins: [modalsMixin, filterMixin],
  data() {
    return {
      v: variables,
      toEdit:[],
      declOfNum,
      isModalVisible:{
        create:false,
        edit:false
      },
      filterOptions: {
        users: {
          title: 'Владельцы устройств',
          placeholder: 'Введите название компании или ФИО владельца',
        },
        date: true,
        devices: true,
        methods: {
          getData: ['getDevices'],
          getCount: 'getCountDevices',
        }
      },
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'creation_date',
          selected: true,
          inverse: true,
        },
        {
          caption: 'серийному номеру',
          value: 'serial',
          selected: false,
          inverse: false,
        },
        {
          caption: 'владельцу устройства',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'количеству осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-2',
          caption: 'Серийный номер',
          key: 'serial',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Ответственный',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Дата поверки',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Действие до',
          key: 'exam_count',
          inverse: true,
        },
         {
          class: 'col-4',
          caption: 'Номер свидетельства',
          key: 'exam_count',
          inverse: true,
        },
          {
          class: 'col-2',
          caption: 'Статус',
          key: 'exam_count',
          inverse: true,
        },
      ],
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.devices.isLoading,
      devices: (state) => state.devices.data,
      devicesCount: (state) => state.devices.count,
      error: (state) => state.devices.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    settings() {
      return {
        filters: {
          devices: [],
          users: {
            negative: true,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getDevices(this.filters, this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getDevices(this.filters, this.pagination.limit, this.offset);
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getDevices(filters, limit, offset) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.filterDevices, data).then((response) => {
        if (this.isTouchDevice) {
          this.closeFilter('submit');
          this.$refs.filter.setBtnPosition();
        }
        this.pagination.total = response.total;
        this.isFiltered = Object.keys(filters).length > 0;
        this.reverseSorting();
      });
    },
    getCountDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(devicesActionTypes.getCountDevices, data);
    },
    exportDevices(filters) {
      let data = {
        filters: Object.assign({}, this.settings.filters, filters),
        sorting_params: this.settings.sorting_params,
      };

      this.$store
        .dispatch(devicesActionTypes.exportDevices, data)
        .then((binary) => blobToFile(binary, 'xlsx'));
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingDevices(value, inverse);
    },
    sortingDevices(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getDevices(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
     closeModal(slug) {
      this.isModalVisible[slug] = false;
    },
    editModal(data){
      this.isModalVisible.edit = true;
      this.toEdit = data
      console.log(this.toEdit)
    }
  },
};
</script>
