import axios from '@/api/axios';

const getExams = (credentials) => axios.post('/exams', credentials);

const getExamById = (id) => axios.get(`/exams/detail/${id}`);

const getCountExams = (credentials) => axios.post('/exams/count', credentials);

const exportExams = (credentials) =>
  axios.post('/exams/export', credentials, {responseType: 'blob'});

const exportExams835 = (credentials) =>
  axios.post('/exams/export_835', credentials, {responseType: 'blob'});

export default {
  getExams,
  getExamById,
  getCountExams,
  exportExams,
  exportExams835,
};
