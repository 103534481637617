<template lang="pug">
#app(:class="{'is-modal-open': v.state.isModalOpen}")
        app-header
        main(:class="[{'is-profile': isLoggedIn}]")
            transition(name="fade" mode="out-in")
                router-view
</template>

<script>
import {
    mapGetters
} from 'vuex';
import {
    gettersTypes as authGettersTypes
} from '@/store/modules/auth';
import {
    actionTypes as settingsActionTypes
} from '@/store/modules/settings';

import variables from '@/helpers/variables';

import AppHeader from '@/components/Header';

export default {
    components: {
        AppHeader,
    },
    data() {
        return {
            v: variables
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: authGettersTypes.isLoggedIn,
        }),
    },
    mounted() {
        this.getSettings();
    },
    methods: {
        getSettings() {
            this.$store.dispatch(settingsActionTypes.getSettings);
        },
    },
};
</script>

<style lang="scss">
.v-toast__item.v-toast__item--bottom-right {
    align-self: center !important;
}

.v-toast__item {
    max-width: 470px !important;
    width: 100% !important;
    text-align: center !important;

}

.v-toast__item--success {
    background: #fff !important;
    color: #11D1A1 !important;
    box-shadow: 0px 73px 72px -31px rgba(7, 36, 136, 0.12) !important;
    border-radius: 10px 10px 0px 0px !important;
    font-size: 14px !important;
    text-transform: uppercase;
     margin: 0 !important;
}

.v-toast {
    padding: 0 !important;
   
    p{
      width: 100%;
    }
}

.v-toast__item .v-toast__text {
    text-align: center !important;
}
.v-toast__icon{
  display: none !important;
}
</style>
