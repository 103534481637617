<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(v-if="isVisible" @close="closeModal")
      template(v-slot:body)
        .text-center.mb-5
          app-title(:type="'large'") Добавление устройств
          p.text-size--small.text-color--blue.mt-3 Для успешного добавления укажите серийный номер и скан документа, подтверждающего покупку. Если все устройства указаны в одном документе, приложите его для каждого устройства

        form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isLoading}]")
          
          .row.flex-column.align-items-center.g-4
            .col-14.col-md-10(v-if="validationErrors.message")
              app-validation-errors(:validation-errors="validationErrors.message")

              template(v-if="validationErrors.info")
                ul(
                  v-for="(request, i) in validationErrors.info"
                  :key="request.serial"
                  class=['form__info', 'text-size--small', 'mt-2']
                )
                  li
                    span.text-family--italic.me-3
                      template(v-if="request.serial") {{ `#${request.serial}` }}
                      template(v-else) {{ `#${request}` }}
                    
                    span(v-if="request.comment") {{ request.comment }}
                    
            .col-14.col-md-10
             .form__caption.text-center.maw365.pb-2 Владелец устройства
             .maw365
                autocomplete(
                  ref="autocomplete"
                  placeholder="Найти пользователя"
                  :source="patientsList"
                  results-property='id' 
                  results-display='text'
                  @selected="selectedPatients"
                  )     
             .form__caption.text-center.maw365.mb-3.mt-4.pt-1 Ответственный за поверку
              .row.flex-columnalign-items-center.gx-0.maw365.mb-4.mt-2
                .col-7
                  label.form__radio.d-block 
                    input(
                      type="radio"
                      name="users-negative"
                      :value="true"    
                      checked
                      v-model="form.user" 
                    )
                    span.form__radio-button.form__radio-button--high.form__radio-button--left medcontrol
                .col-7
                  label.form__radio.d-block
                    input(
                      type="radio"
                      name="users-negative"
                      :value="false"  
                      v-model="form.user"      
                    )
                    span.form__radio-button.form__radio-button--high.form__radio-button--right Клиент    
            .col-14.col-md-10(v-for="(device, i) in form.devices" :key="i" :set="$response = v$.form.devices.$each.$response")     
              
              //- .form__caption.text-center.maw365.mb-3.mt-4.pt-1 Ответственный за поверку
              //- .row.flex-columnalign-items-center.gx-0.maw365.mb-4
              //-   .col-7
              //-     label.form__radio.d-block
              //-       input(
              //-         type="radio"
              //-         name="users-negative"
              //-         :value="true"     
              //-       )
              //-       span.form__radio-button.form__radio-button--high.form__radio-button--left medcontrol
              //-   .col-7
              //-     label.form__radio.d-block
              //-       input(
              //-         type="radio"
              //-         name="users-negative"
              //-         :value="false"       
              //-       )
              //-       span.form__radio-button.form__radio-button--high.form__radio-button--right Клиент
              .row.g-4
                .col
                 .outer-form
                  //- span.addCheck Добавить поверку
                  label.form__label.form__label--float
                    input(
                      :class="['form__field form__field--no-border', { 'is-error': $response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty }]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="device.value"
                      autocomplete="off"
                      placeholder="&nbsp;"
                    )
                    span.fl Серийный номер
                  ul.form__error(v-if="$response.$errors[i].value && $response.$errors[i].value.length && v$.form.$dirty")
                   li(v-for="error of $response.$errors[i].value" :key="error.$uid")
                     template(v-if="error.$params.type === 'required'") {{ v.errors.required }}
                     template(v-if="error.$params.type === 'alphaNum'") {{ v.errors.alphaNum }}
                     template(v-if="error.$params.type === 'isUnique'") Серийные номера не должны совпадать
                  .row.gx-4 
                   .col-7
                      .text-size--small.mb-2.mt-4 Дата поверки
                      date-picker(
                          prefix-class="app"
                          placeholder="Дата поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                          v-model="device.starts"
                      )
                   .col-7 
                     .text-size--small.mb-2.mt-4  Окончание срока поверки
                     date-picker(
                          prefix-class="app"
                          placeholder="Окончание срока поверки"
                          format="DD.MM.YYYY"
                          lang="ru"
                          :editable="true"
                           v-model="device.ends"
                      )
                   .col-14
                    .text-size--small.mb-2.mt-4  Номер свидетельства
                    input(
                      :class="['form__field', { 'is-error': $response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty}]"
                      type="text"
                      name="id"
                      @keydown="v$.$touch()"
                      v-model="device.device_attestations"
                      placeholder="Введите номер свидетельства"
                      autocomplete="off"
                    )
                .col-auto
                  button(
                    v-if="i === form.devices.length - 1"
                    type="button"
                    :disabled="form.devices.length >= limit"
                    @click="changeNumOfDevices('add')"
                    class=['form__add-button']
                  )
                    app-svg-icon(name="plus-thin")
                    
                  button(
                    v-else
                    type="button"
                    @click="changeNumOfDevices('delete', i)"
                    class=['form__delete-button']
                  )
                    app-svg-icon(name="minus")
              ul.form__error(v-if="$response.$errors[i].device_attestations && $response.$errors[i].device_attestations.length && v$.form.$dirty")
                li(v-for="error of $response.$errors[i].device_attestations" :key="error.$uid")
                  template(v-if="error.$params.type === 'required'") {{ v.errors.required }}
                  template(v-if="error.$params.type === 'alphaNum'") {{ v.errors.alphaNum }}
                  template(v-if="error.$params.type === 'isUnique'") Серийные номера не должны совпадать

            .col-14.col-md-10
              .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                input(
                  class="d-none"
                  type="file"
                  name="document"
                  @change="onFileSelected"
                  @click="v$.$touch()"
                  ref="fileInput"
                  accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                )

                div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                  span.flex-shrink-0.me-3
                    app-svg-icon(name="document")

                  ul.flex-grow-1.overflow-hidden
                    li.form__file-name.text-color--black {{ form.file.name }}
                    li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                  button(
                    type="button"
                    @click="clearFileInput"
                    class=['form__file-delete', 'flex-shrink-0 ms-3']
                  )
                    app-svg-icon(name="trash")

                .text-color--blue.text-size--small(v-else) Приложите скан документа

                button(
                  class=['form__file-button', 'd-flex justify-content-end align-items-center maw365']
                  @click="$refs.fileInput.click()"
                )
                  app-svg-icon(name="clip")

              ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                li {{ v.errors.required }}


            .col-14.col-md-10.mt-5
              button(
                type="submit"
                :disabled="v$.$invalid"
                class=['button', 'button--accent', 'form__button','maw365']
              )
                span.button__content Отправить
            .col-14.col-md-9
              p.text-color--blue.text-size--small.text-center Заявки на добавление устройств рассматриваются администраторами от 1 до 4 рабочих дней. Статус проверки вы можете отследить в личном кабинете

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as requestsActionTypes} from '@/store/modules/requests';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {helpers, required, requiredIf, alphaNum} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';
import {convertDate} from '@/helpers/utils';
import Autocomplete from 'vuejs-auto-complete'
import {actionTypes as filtersActionTypes} from '@/store/modules/filters';
//import is from 'is_js';
export default {
  name: 'AppCreateDeviceModal',
  components: {
    AppModal,
    AppTitle,
    AppValidationErrors,
    AppSvgIcon,
    DatePicker,
    Autocomplete
  },
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        devices: [
          {
            id: 0,
            value: '',
            device_attestations: '',
            starts:'',
            ends:''
          },
        ],
        file: null,
        user:true,
        userId:'',
      },
      validationErrors: {
        message: '',
        info: null,
      },
      limit: 100,
      patientsList:[],
      
    };
  },
  validations() {
    return {
      form: {
        devices: {
          $each: helpers.forEach({
            id: {},
            value: {
              required,
              alphaNum,
              isUnique: function (value) {
                if (value === '') return true;

                let count = 0;
                this.form.devices.forEach((device) => {
                  if (value === device.value) ++count;
                });
                return count < 2;
              },
            },
            device_attestations: {required},
            starts:{required},
            ends:{required}
          }),
        },
        userId:{required},
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      error: (state) => state.requests.error,
    }),
  },
    watch: {
 '$store.state.filters.data': {
            //immediate: true,
       handler() {
       const  state  = this.$store.state?.filters?.data?.users;   
       this.patientsList = state?.map(item => {
        return {
            text: item.text,
            id: item.value,
            role_code: item.role_code
          };
         });
         
        this.patientsList = this.patientsList?.reduce((o, i) => {
          if (!o.find(v => v.id == i.id) && (i.role_code === 'USER')) {
            o.push(i);
          }
          return o;
        }, []);
        console.log(this.patientsList)
        }
      },
    },
    mounted() {
      this.$store.dispatch(filtersActionTypes.getFilters);
      this.$store.dispatch(filtersActionTypes.getFiltersType);
    },
  methods: {
    closeModal() {
      this.resetForm();
      this.$emit('close');
    },
    success() {
      this.$emit('success');
    },
    changeNumOfDevices(slug, index) {
      slug === 'add'
        ? this.form.devices.push({id: index, value: ''})
        : this.form.devices.splice(index, 1);
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();
        formData.append('user_id', this.form.userId);
        formData.append('user', this.form.user);
        formData.append(
          'device_ids',
          this.form.devices.map((el) => el.value)
        );

        formData.append(
          'device_attestations',
          this.form.devices.map((el) => el.device_attestations)
        );   
 
        formData.append(
          'device_attestations_starts',
          this.form.devices.map((el) => convertDate(el.starts))
        );
         formData.append(
          'device_attestations_ends',
          this.form.devices.map((el) => convertDate(el.ends))
         );
        formData.append('document', this.form.file, this.form.file.name);

        this.$store
          .dispatch(requestsActionTypes.createDeviceRequest, formData)
          .then(() => {
            this.closeModal();
            //this.success();
            this.$store.dispatch(requestsActionTypes.getRequests);
            this.$toast.success('Устройство добавлено')
          })
          .catch((result) => {
            if (this.error === 'device_exists') {
              this.validationErrors.message = this.error;
              this.validationErrors.info = result;
            } else this.validationErrors.message = result;
          });
      }
    },
    resetForm() {
      this.v$.$reset();

      this.validationErrors.message = '';
      this.validationErrors.info = null;

      this.form.devices = [{id: 0, value: ''}];
      this.clearFileInput();
    },
    selectedPatients(input){
      //this.collectData.patients.payload=[]
       this.form.userId = input.value
       console.log(this.form.userId)
      
      },
  },
};
</script>
<style lang="scss">

  .form__radio-button--high{
     padding-top: 9px !important;
     padding-bottom: 9px !important;
     text-transform: uppercase;
     margin-bottom: 20px;
  }
  .form__radio-button--left{
    border-radius: 6px 0 0 6px !important;
    border-right:none !important;
  }
.form__radio-button--right{
 border-radius: 0 6px 6px 0 !important;
}
</style>