import axios from '@/api/axios';

const createPatients = (credentials) => axios.post('/patient/create', credentials);

const setPatientAvatar = (credentials) => axios.post('/patient/avatar', credentials);

const deletePatientAvatar = (credentials) => axios.post('/patient/avatar/delete', credentials);

const setPatientSignature = (credentials) => axios.post('/patient/signature', credentials);

const deletePatientSignature = (credentials) =>
  axios.post('/patient/signature/delete', credentials);

const getPatients = (credentials) => axios.post('/patient', credentials);

const getCountPatients = (credentials) => axios.post('/patient/count', credentials);

const importPatients = (credentials) => axios.post('/patient/import_csv', credentials);

const changePatient = (credentials) => axios.post('/patient/edit', credentials);

const deletePatientRFID = (id) => axios.post(`/patient/rfid/delete/${id}`);

const deletePatient = (id) => axios.delete(`/patient/${id}`);

const unlockPatient = (credentials) => axios.post('/patient/unlocking', credentials);

const blockHistory = (credentials) => axios.post('/patient/lock_history', credentials);

const updatePersonalBorders = (pat_code, credentials) => axios.post(`/patient/edit/personal_borders/${pat_code}`, credentials);

const medDocsUser = (credentials) => axios.post(`/incoming_med_document`,credentials);

const v2Url = () => {
  if (process.env.NODE_ENV !== 'production') {
    return 'http://127.0.0.1:8081/api/v2'
  } else {
    return '/api/v2'
  }
}

const createPatientComment = (credentials) => fetch(`${v2Url()}/patient/comment`, {
    "credentials": "include",
    "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    "body": credentials,
    "method": "POST"
  });

const getPatientComment = (patient_code) => fetch(`${v2Url()}/patient/comment?patient_code=${patient_code}&page=1&limit=50`, {
    "credentials": "include",
    "headers": {
        "Accept": "application/json",
    },
    "method": "GET",
  });


export default {
  createPatients,
  setPatientAvatar,
  setPatientSignature,
  deletePatientAvatar,
  deletePatientSignature,
  getPatients,
  getCountPatients,
  importPatients,
  changePatient,
  deletePatientRFID,
  deletePatient,
  unlockPatient,
  blockHistory,
  updatePersonalBorders,
  medDocsUser,
  createPatientComment,
  getPatientComment
};
