import axios from 'axios';

export default {
  state: {
    chartResultMessage: 'Выберете параметры запроса в фильтре',
    chartFilter:{
      filters:{
        type:'',
        "patients": {
          "negative": false,
          "payload": []
      },
      }
    },
    chart: {
      result: [
        {
          heart_rate: [0],
          exam_date: [0],
        },
      ],
    },
    chartTable: {
      result: [
        {
          heart_rate: [0],
          exam_date: [0],
        },
      ],
    },
    pressuare:[],
    hide_bp: true,
    hide_rate: true,
    hide_alcohol: true,
    hide_temp: true,
    total:0,
    chartPatients:{},
    lastInspections:{},
    loading: false,
    admissions:[]
  },
  mutations: {
    chartResultMessage(state, payload) {
      state.chartResultMessage = payload;
    },
    chartFilter(state, payload) {
      state.chartFilter = payload.chartFilter;
    },
    chartData(state, payload) {
      state.chart = payload.chart;
    },
    chartDataTable(state, payload) {
      state.chartTable = payload.chartTable;
    },
    hideBp(state, payload) {
      state.hide_bp = payload;
    },
    hideRate(state, payload) {
      state.hide_rate = payload;
    },
    hideAlcohol(state, payload) {
      state.hide_alcohol = payload;
    },
    hideTemp(state, payload) {
      state.hide_temp = payload;
    },
    diagramPresuare(state, payload) {
      state.pressuare = payload.pressuare;
    },
    diagramPresuareTable(state, payload) {
      state.pressuareTable = payload.pressuareTable;
    },
    setTotal(state, payload) {
      state.total = payload.total;
    },
    chartPatients(state, payload) {
      state.chartPatients = payload.chartPatients;
    },
    lastInspections(state, payload) {
      state.lastInspections = payload.lastInspections;
    },
    loading(state, payload) {
      state.loading = payload;
    },
    admissions(state, payload) {
      state.admissions = payload;
     },
     
   
    
  },

  actions: {
    async actionGetChartData({commit}, data) {
      //Data for table
      try {
        var resw = await axios({
          url: '/exams',
          method: 'POST',
          data: data,
        });
      } catch (error) {
        alert(error)
      }
   
      console.log('resultChartTable', resw);
      commit('chartFilter', {
        chartFilter: data,
      });
      commit('chartDataTable', {
        chartTable: resw.data,
      });
      commit('setTotal', {
        total: resw.data.total,
      });
        //Data for Charts
      return resw;
    },
    async actionGetChartDataOnlyChart({commit}, data) {
    //  alert('actionGetChartDataOnlyChart')
     delete data['paging_params'];
      try {
        var res = await axios({
          url: '/exams',
          method: 'POST',
          data: data,
        });
      } catch (error) {
        alert(error)
      }
   
      console.log('resultChart', res);
      commit('chartData', {
        chart: res.data,
      });
      return res;
    },
    // Only pie table
    async actionGetChartDataTable({commit}, data) {
      //Data for table
      try {
        var resw = await axios({
          url: '/exams',
          method: 'POST',
          data: data,
        });
      } catch (error) {
        alert(error)
      }
    
      console.log('resultChartTable', resw);
      commit('chartFilter', {
        chartFilter: data,
      });
      commit('chartDataTable', {
        chartTable: resw.data,
      });
      commit('setTotal', {
        total: resw.data.total,
      });
        //Data for Charts
    
  
      return resw;
    },
 
    //Получаем данные для диграммы по давлению
    async actionGetDiagramPressuare({commit}, data) {
      try {
        var res = await axios({
          url: '/exams/diagram',
          method: 'POST',
          data: data,
        });
      } catch (error) {
        alert(error)
      }
    
      console.log('result', res.data);

      commit('diagramPresuare', {
        pressuare: res.data,
      });
      return res;
    },
    //Получаем данные для ТАБЛИЦЫ диаграммы по давлению
    async actionGetDiagramPressuareTable({commit}, data) {
      try {
        var res = await axios({
          url: '/exams/diagram/table',
          method: 'POST',
          data: data,
        });
      } catch (error) {
       alert(error) 
      }
   
      console.log('actionGetDiagramPressuareTable', res.data);
      commit('chartFilter', {
        chartFilter: data,
      });
      commit('setTotal', {
        total: res.data.total,
      });
      commit('chartDataTable', {
        chartTable: res.data,
      });
      
      return res;
    },
        //Получаем данные для ТАБЛИЦЫ диаграммы по давлению
        async actionGetPatients({commit}, data) {
          try {
            var res = await axios({
              url: '/patient/diagram/table',
              method: 'POST',
              data: data,
            });
          } catch (error) {
            alert(error)
          }
        
          commit('chartPatients', {
            chartPatients: res.data,
          });
          // commit('chartDataTable', {
          //   chartTable: res.data,
          // });
          return res;
        },
        async actionGetlastInspection({commit},id) {
          try {
            var res = await axios({
              url: `/doctor/exams/${id}`,
              method: 'GET',
            });
          } catch (error) {
            alert(error)
          }
       
          console.log('last-5',res.data)
           commit('lastInspections', {
            lastInspections: res.data,
           });
    
          return res;
        },
  },



  getters: {
    dateChart(state) {
      let arr = [];
      state.chart.result.forEach(element => {
        console.log(element)
        arr.push([element.exam_date, ' '+element.exam_time])
      });
      return arr;
    },
    heartRate(state) {
      return state.chart.result.map((item) => item.heart_rate);
    },
    alcohol(state) {
      return state.chart.result.map((item) => item.alcohol);
    },
    bodyTemp(state) {
      return state.chart.result.map((item) => item.body_temp);
    },
    bpHigh(state) {
      return state.chart.result.map((item) => item.bp_high);
    },
    bpLow(state) {
      return state.chart.result.map((item) => item.bp_low);
    },
    pieChart(state) {
      var pieData = [];
      var admitted = 0;
      state.chart.result.map((item) => {
        if (item.admission_to_work && item.exam_completed) {
          admitted++;
        }
      });
      pieData.push(admitted);

      var noConclusion = 0;
      state.chart.result.map((item) => {
        if (item.admission_to_work === null && item.exam_completed) {
          noConclusion++;
        }
      });
      pieData.push(noConclusion);

      var noAdmitted = 0;
      state.chart.result.map((item) => {
        if (item.admission_to_work === false && item.exam_completed) {
          noAdmitted++;
        }
      });
      pieData.push(noAdmitted);

      var notСompleted = 0;
      state.chart.result.map((item) => {
        if (item.admission_to_work === null && item.exam_completed===false) {
          notСompleted++;
        }
      });
      pieData.push(notСompleted); 

      return pieData;
    },
    pressuareRiskData(state) {
      let riskData = [];
      riskData.push(state.pressuare[0].high_pressure,state.pressuare[0].hypertension,state.pressuare[0].low_pressure,state.pressuare[0].normal_pressure)

      return riskData;
    },
  },
};
