<template lang="pug">
  .profile-filter(:class="{'is-open': isOpen}")
    .profile-filter__container.d-flex.flex-column
      button(
        @click="open"
        type="button"
        :class="{'is-dirty': isDirty}"
        class=['profile-filter__open-button-mobile', 'd-xl-none']
        :style="{'top': style.top}"
      )
        app-svg-icon(name="filter")

      .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
        button(
          @click="open"
          type="button"
          class="profile-filter__open-button"
        )
          app-svg-icon(name="filter")

        transition(name="fade")
          button(
            v-if="isDirty"
            type="button"
            @click="clear"
            class=['profile-filter__button', 'text-color--blue-dark text-size--small']
          )
            span.me-3
              app-svg-icon(name="close")
            span Сбросить фильтры

        button(
          @click="close"
          type="button"
          class="filter__close-button"
        )
          app-svg-icon(name="right-long-arrow")

      .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
        simplebar(class="profile-filter__form flex-grow-1")
          form(@submit.prevent="submit" :class="['form']" id="filter-form")
            .container-fluid.px-3
              .row.flex-column.align-items-center.g-3
                // search users
                template(v-if="options.users && filters && filters.users")
                    .form__caption Ответственный за поверку
                    .row.align-items-center.gx-2
                      .col-7
                        label.form__radio
                          input(
                            type="radio"
                            name="users-negative"
                            :value="false"
                            v-model='form.responsible'
                            @change="selectResponsible()"
                          )
                          span.form__radio-button Клиент
                      .col-7
                        label.form__radio
                          input(
                            type="radio"
                            name="users-negative"
                            :value="true"
                            v-model='form.responsible'
                            @change="selectResponsible()"
                          )
                          span.form__radio-button MedControl

                // date interval
                template(v-if="options.date")
                  .col
                    .form__caption.mb-3 Интервал даты {{ options.time ? 'и времени' : 'регистрации'}}
                    .row.g-3
                      .col-7
                        date-picker(
                          v-model="form.dateStart.from"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"

                          @change="datepickerChange('dateStartFrom')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
                      .col-7
                        date-picker(
                          v-model="form.dateStart.to"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"

                          @change="datepickerChange('dateStartTo')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
                    .form__caption.mb-3.mt-4 Интервал окончания поверки
                    .row.g-3
                      .col-7
                        date-picker(
                          v-model="form.dateEnd.from"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"

                          @change="datepickerChange('dateEndFrom')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
                      .col-7
                        date-picker(
                          v-model="form.dateEnd.to"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          @change="datepickerChange('dateEndTo')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
              // devices
              template
                .row.flex-column.align-items-center.g-3.mt-3
                  .col
                    app-multiple-select(
                      ref="multipeSelectDevices"
                      :search-type="'devices'"
                      :placeholder="'Серийный номер устройства'"
                      @update="updateSelectedDevices"
                    )
                  .col
                       app-select(
                         ref="selectCheck"
                         :type="'form'"
                         :placeholder="'Статус поверки'"
                         :options="videoOpts"
                         @select="selectCheck"
                       )

        // submit button
        .profile-filter__submit-button.flex-shrink-0.mt-auto
          button(
            type="submit"
            form="filter-form"
            :disabled="!isDirty || count === 0 || isFiltered"
            class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
          )
            span.button__content
              span Показать
              transition(name="translucent" mode="out-in")
                span.profile-filter__count(v-if="isDirty && count" :key="count") &nbsp;({{ count }})

      button(
        v-if="isFiltered && isDirty"
        type="button"
        @click="clear"
        class=['profile-filter__clear-button', 'd-flex flex-column align-items-center']
      )
        span.d-flex.mb-2
          app-svg-icon(name="close")
        span.text-size--extra-small.text-decoration--underline Сбросить

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as filtersActionTypes} from '@/store/modules/filters';

import {toLocalDate} from '@/helpers/utils';
import is from 'is_js';

import simplebar from 'simplebar-vue';
import Slider from '@vueform/slider/dist/slider.vue2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import AppValidationErrors from '@/components/common/ValidationErrors';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppMultipleSelectFull from '@/components/common/MultipleSelectFull';
import AppSelect from '@/components/common/Select';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppFilter',
  components: {
    AppTooltip,
    AppSelect,
    AppMultipleSelect,
    AppSvgIcon,
    AppValidationErrors,
    DatePicker,
    Slider,
    simplebar,
    AppMultipleSelectFull
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: true,
    },
    isFiltered: {
      type: Boolean,
      required: true,
      default: false,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
    options: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      style: {
        top: null,
      },
      delay: 2000,
      anchor: '#isAnchorForBtn',
      form: {
        isDirty: {
          responsible:false,
          dateStartFrom:false,
          dateStartTo:false,
           dateEndFrom:false,
          dateEndTo:false,
          status: false,
        },
        responsible:null,
        dateStart: {
          from: null,
          to: null,
        },
         dateEnd: {
          from: null,
          to: null,
        },
        status:true
      },
      videoOpts: [
        {
          caption: 'Пройдена',
          icon: 'allright',
          value: true,
          selected: false,
        },
        {
          caption: 'Не пройдена',
          icon: 'wrong',
          value: false,
          selected: false,
        },
        {
          caption: 'Заканчивается',
          icon: 'wrong',
          value: null,
          selected: false,
        },
      ],
      datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
      },
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.filters.isLoading,
      filters: (state) => state.filters.data,
      error: (state) => state.filters.error,
    }),
    isDirty() {
      return Object.values(this.form.isDirty).some((item) => item === true);
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    collectData() {
      let data = {};
      if (is.existy(this.form.responsible)) data.responsible = this.form.responsible
      if (is.existy(this.form.dateStart.from)) data.dateStart_from = toLocalDate(this.form.dateStart.from);
      if (is.existy(this.form.dateEnd.from)) data.dateEnd_from = toLocalDate(this.form.dateEnd.from);
        if (is.existy(this.form.dateStart.to)) data.dateStart_to = toLocalDate(this.form.dateStart.to);
      if (is.existy(this.form.dateEnd.to)) data.dateEnd_to = toLocalDate(this.form.dateEnd.to);
       data.status = this.form.status
      return data;
    },
  },
  watch: {
    isDirty(value) {
      if (!value) this.$emit('clear');
    },
  },
  mounted() {
    if (
      is.existy(this.options.patients) ||
      is.existy(this.options.users) ||
      is.existy(this.options.devices)
    ) {
      this.getSearchData();
    }
  },
  methods: {
      selectResponsible(){
      this.form.isDirty.responsible = true
        this.getCount();
    },
       selectCheck(option) {
      this.videoOpts.map((el) => (el.selected = el.value === option.value));
      this.form.isDirty.status = true;
      this.form.status = option.value;
       this.form.isDirty.status = true
      this.getCount();

    },
    changeCheckbox() {
      this.form.isDirty.userTypes = true;
      this.getCount();
    },

    datepickerChange(slug) {
      this.form.isDirty[slug] = true;
      this.getCount();
    },
    datepickerClear(slug) {
      this.form.isDirty[slug] = false;
      this.form.isDirty[slug] = false;

      if (slug === 'dateTo') {
        this.form.time.to = null;
        this.form.isDirty.timeTo = false;
      }
      if (slug === 'dateFrom') {
        this.form.time.from = null;
        this.form.isDirty.timeFrom = false;
      }
    },
    notAfterDateTo(date) {
      if (this.form.date.to) return date > this.form.date.to;
    },
    notBeforeDateFrom(date) {
      if (this.form.date.from) return date < this.form.date.from;
    },
    notAfterTimeTo(time) {
      if (this.form.time.to) return time > this.form.time.to;
    },
    notBeforeTimeFrom(time) {
      if (this.form.time.from) return time < this.form.time.from;
    },
    setBtnPosition(anchor) {
      const el = document.querySelector(anchor || this.anchor);
      this.style.top = el ? `${el.getBoundingClientRect().y}px` : null;
    },
    open() {
      this.$emit('open');
    },
    close() {
      this.$emit('close');
      if (this.isTouchDevice) this.submit();
    },
    clear() {
this.form.dateStart.from = null;
this.form.dateStart.to = null;
this.form.dateEnd.from = null;
this.form.dateEnd.to = null;
this.form.status = null
 this.$refs.selectCheck.clear();
        this.form.responsible=null;
      // if (is.existy(this.options.date)) {
      //   this.form.date.from = null;
      //   this.form.date.to = null;
      // }

      // if (is.existy(this.options.time)) {
      //   this.form.time.from = null;
      //   this.form.time.to = null;
      // }

      for (let key in this.form.isDirty) {
        if (Object.prototype.hasOwnProperty.call(this.form.isDirty, key)) {
          this.form.isDirty[key] = false;
        }
      }
    },
    getCount() {
      this.$emit('getCount', this.collectData);
    },
    getSearchData() {
      this.$store.dispatch(filtersActionTypes.getFilters);
      this.$store.dispatch(filtersActionTypes.getFiltersType);
    },
    submit() {
      if (is.falsy(this.isDirty)) return false;
      this.$emit('submit', this.collectData);
    },

  },
};
</script>
