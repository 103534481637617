<template lang="pug">
  .profile-exams__inner
    .profile-exams__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'profile__title text-center'"
          ) Журнал осмотров

    .profile-exams__content.mt-20
      app-tab-nav(
        :tabs="tabs"
        :selected="activeTabId"
        :theme="'separate'"
        @selected="setActiveTab"
        :classes="'my-20'"
      )

      .position-relative
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

      // pending exams tab
      transition(name="translucent")
        div(v-if="pendingExams")
          app-tab(
            :id="pendingExamsTab.id"
            :is-selected="activeTabId === pendingExamsTab.id"
          )
            template(v-if="pendingExams.result && pendingExams.result.length")
              .profile-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        v-if="column.caption || column.icon"
                        type="button"
                        @click="sortingTable(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            app-svg-icon(name="down-arrow")

                ul.profile-table__body
                  li.profile-table__row(v-for="exam in pendingExams.result" :key="exam.id")
                    app-doctor-exam-card(
                      :data="exam"
                      :columns="columns"
                      @openModal="openExamDetailModal"
                    )

              .d-flex.justify-content-center.mt-5
                app-pagination(
                  :total="pendingExamsTab.pagination.total"
                  :limit="pendingExamsTab.pagination.limit"
                  :current-page="currentPage"
                  :url="baseUrl"
                )

            template(v-else)
              .text-color--blue.text-size--small Осмотров не найдено


      // checked exams tab
      transition(name="translucent")
        div(v-if="checkedExams")
          app-tab(
            :id="checkedExamsTab.id"
            :is-selected="activeTabId === checkedExamsTab.id"
          )
            template(v-if="checkedExams.result && checkedExams.result.length")
              .profile-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.g-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        v-if="column.caption || column.icon"
                        type="button"
                        @click="sortingTable(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span(v-if="column.caption") {{ column.caption }}
                          span.flex-shrink-0(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.flex-shrink-0.d-flex.ms-3
                            app-svg-icon(name="down-arrow")

                ul.profile-table__body
                  li.profile-table__row(v-for="exam in checkedExams.result" :key="exam.id")
                    app-doctor-exam-card(
                      :data="exam"
                      :columns="columns"
                      @openModal="openExamDetailModal"
                    )

              .d-flex.justify-content-center.mt-5
                app-pagination(
                  :total="checkedExamsTab.pagination.total"
                  :limit="checkedExamsTab.pagination.limit"
                  :current-page="currentPage"
                  :url="baseUrl"
                )
            template(v-else)
              .text-color--blue.text-size--small Осмотров не найдено


    // exam detail modal
    app-doctor-exam-detail-modal(
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
      @openCertificate="openCertificate"
      @success="getExams(activeTab.pagination.limit, 0)"
    )
    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
    )

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppTab from '@/components/common/Tab';
import AppTabNav from '@/components/common/TabNav';
import AppModal from '@/components/common/Modal';
import AppSelect from '@/components/common/Select';
import AppPagination from '@/components/common/Pagination';
import AppGallery from '@/components/common/Gallery';

import AppCertificateModal from '@/components/profile/common/CertificateModal';
import AppDoctorExamCard from '@/components/profile/doctor/exams/DoctorExamCard';
import AppDoctorExamDetailModal from '@/components/profile/doctor/exams/DoctorExamDetailModal';

import {galleryMixin} from '@/mixins/gallery';
import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {certificateModalMixin} from '@/mixins/certificateModal';

export default {
  name: 'AppDoctorExams',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppTab,
    AppTabNav,
    AppModal,
    AppSelect,
    AppPagination,
    AppGallery,
    AppCertificateModal,
    AppDoctorExamCard,
    AppDoctorExamDetailModal,
  },
  mixins: [galleryMixin, examDetailModalMixin, certificateModalMixin],
  data() {
    return {
      v: variables,
      activeTabId: variables.exams.statuses.pending,
      sortKey: '',
      sortInverse: false,
      sortOptions: [],
      columns: [
         {
          class: 'col-1',
          caption: '№',
          key: 'exam_number',
          inverse: false,
        },
        {
          class: 'col-4 col-xl-2 col-xxl-2',
          caption: 'Дата и время',
          key: 'exam_date_time',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'ФИО пользователя',
          key: 'patient_full_name',
          inverse: false,
        },
          {
          class: 'col-2',
          caption: 'Тип осмотра',
          key: 'exam_type_name',
          inverse: false,
        },
        {
          class: 'col-1',
          icon: 'pulse',
          key: 'bp_high',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'cardio',
          key: 'heart_rate',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'temp',
          key: 'body_temp',
          inverse: true,
        },
        {
          class: 'col-1',
          icon: 'breathalyzer',
          key: 'alcohol',
          inverse: true,
        },
        {
          class: 'col-1',
          key: 'health_complaint',
          inverse: false,
        },
        {
          class: 'col-2 ms-auto',
          key: 'admission_to_work',
          inverse: false,
        },
      ],
      tabs: [
        {
          id: variables.exams.statuses.pending,
          caption: 'Нет заключения',
          pagination: {
            limit: variables.pagination.limit,
            total: 0,
          },
        },
        {
          id: variables.exams.statuses.checked,
          caption: 'Есть заключение',
          pagination: {
            limit: variables.pagination.limit,
            total: 0,
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.doctors.isLoading,
      pendingExams: (state) => state.doctors.pendingExams,
      checkedExams: (state) => state.doctors.checkedExams,
      error: (state) => state.doctors.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.activeTab.pagination.limit - this.activeTab.pagination.limit;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    activeTab() {
      return this.tabs.find((el) => el.id === this.activeTabId);
    },
    pendingExamsTab() {
      return this.tabs[0];
    },
    checkedExamsTab() {
      return this.tabs[1];
    },
    settings() {
      return {
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey || 'exam_date_time',
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getExams(this.activeTab.pagination.limit, this.offset);
    },
  },
  created() {
    this.getExams(this.activeTab.pagination.limit, this.offset);
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1)
        this.$router.push({query: Object.assign({}, this.$route.query, {page: 1})});
    },
    setActiveTab(id) {
      this.goToFirstPage();
      this.activeTabId = id;

      this.getExams(this.activeTab.pagination.limit, this.offset);
    },
    collectData(limit, offset) {
      let data = this.settings;
      if (is.all.existy(limit, offset)) data.paging_params = {limit, offset};
      return data;
    },
    getExams(limit, offset) {
      const data = this.collectData(limit, offset);
      let actionType = 'getDoctorPendingExams';

      if (this.activeTabId === this.v.exams.statuses.checked) actionType = 'getDoctorCheckedExams';

      this.$store.dispatch(doctorsActionTypes[actionType], data).then((response) => {
        this.activeTab.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingTable(value, inverse);
    },
    sortingTable(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getExams(this.activeTab.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
  },
};
</script>
