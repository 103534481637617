import axios from '@/api/axios';

const getDoctors = (credentials) => axios.post('/doctor/info', credentials);

const getDoctorExams = (credentials) => axios.post('/doctor/exams', credentials);

const getDoctorExamsAdmin = (credentials) => axios.post('/doctor/exams_by_admin', credentials);

const createDoctor = (credentials) => axios.post('/doctor', credentials);

const changeDoctor = (credentials) => axios.post('/doctor/edit', credentials);

const setAdmission = (credentials) => axios.post('/doctor/set_admission_to_work', credentials);

const deleteDoctor = (id) => axios.delete(`/doctor/delete/${id}`);

export default {
  getDoctors,
  getDoctorExams,
  getDoctorExamsAdmin,
  createDoctor,
  changeDoctor,
  setAdmission,
  deleteDoctor,
};
