<template lang="pug">
  .profile-auto-doctor-widget(:class="[classes, `is-${type}`, {'is-paused': isPaused}]")
    template(v-if="type === 'mini'")
      app-title(:routerLink="{name: 'doctors'}") Aвтоматический врач

      ul.d-flex.align-items-center.text-size--small.my-3
        li.me-4
          span.text-color--blue Допущено:
          span.text-color--success &ensp;12
        li
          span.text-color--blue Не&nbsp;допущено:
          span.text-color--danger &ensp;2

      button(
        type="button"
        class=['link--accent', 'text-decoration--underline text-size--small']
      ) Настройки

      button(
        type="button"
        @click="isPaused = !isPaused"
        class=['profile-auto-doctor-widget__control-btn', 'd-flex align-items-center justify-content-center']
      )
        span.flex-shrink-0
          app-svg-icon(v-if="isPaused" name="play")
          app-svg-icon(v-else name="pause")


    template(v-else)
      .row.align-items-center.g-4
        .col-auto
          button(
            type="button"
            @click="isPaused = !isPaused"
            class=['profile-auto-doctor-widget__control-btn', 'd-flex align-items-center justify-content-center']
          )
            span.flex-shrink-0
              app-svg-icon(v-if="isPaused" name="play")
              app-svg-icon(v-else name="pause")

        .col
          app-title Aвтоматический врач

          ul.d-flex.align-items-center.text-size--small.mt-2
            li.me-4
              span.text-color--blue Всего:
              span.text-color--blue &ensp;120
            li.me-4
              span.text-color--blue Допущено:
              span.text-color--success &ensp;12
            li
              span.text-color--blue Не&nbsp;допущено:
              span.text-color--danger &ensp;2

        .col-auto
          .d-flex.align-items-center
            button(
              type="button"
              class=['profile-card__button profile-card__button--edit', 'flex-shrink-0 d-flex align-items-center justify-content-center me-3']
            )
              span.flex-shrink-0
                app-svg-icon(name="edit")

            button(
              type="button"
              class=['profile-card__button profile-card__button--delete', 'flex-shrink-0 d-flex align-items-center justify-content-center']
            )
              span.flex-shrink-0
                app-svg-icon(name="trash")

</template>

<script>
  import AppSvgIcon from '@/components/common/SvgIcon';
  import AppTitle from '@/components/common/Title';

  export default {
    name: 'AppAutoDoctorWidget',
    components: {
      AppTitle,
      AppSvgIcon
    },
    props: {
      type: {
        type: String,
        required: false,
        default: 'mini',
      },
      classes: {
        type: String,
        required: false,
        default: '',
      },
    },
    data() {
      return {
        isPaused: false
      }
    }
  };
</script>

<style lang="scss">
  .profile-auto-doctor-widget {
    position: relative;
    width: 100%;
    padding: $spacer * 1.4;
    border-radius: $border-radius-sm;

    &.is-mini {
      background: get($gradients, blue-light);

      .profile-auto-doctor-widget {
        &__control-btn {
          position: absolute;
          bottom: 0;
          right: 0;
          transform: translate3d($spacer * .4, $spacer * .4, 0);
        }
      }
    }
    &.is-full {
      background-color: get($colors, white);
    }
    &__control-btn {
      @include transition();
      width: $spacer * 4.2;
      height: $spacer * 4.2;
      border-radius: 50%;
      box-shadow: get($shadows, default);
      background-color: get($colors, white);

      svg {
        @include transition();
        width: $spacer * 1.4;
        height: $spacer * 1.4;
        fill: get($colors, orange);
      }
      @include interaction('mouse') {
        &:hover {
          background-color: get($colors, orange);

          svg {
            fill: get($colors, white);
          }
        }
      }
    }
  }
</style>