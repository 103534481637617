import axios from 'axios';

if (process.env.NODE_ENV !== 'production') {
  //axios.defaults.baseURL = 'http://safedrive.itfactory.site/api/v1';
  axios.defaults.baseURL = 'http://stage.safedrive/api/v1';
  axios.defaults.withCredentials = true;
} else {
  axios.defaults.baseURL = '/api/v1';
}

export default axios;
