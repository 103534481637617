<template lang="pug">
  app-accordion(
    :id="data.id"
    :parent-id="parentId"
    class="patient-exam-card"
    :class="classes"
  )
    template(v-slot:header)
      .patient-exam-card__header
        .row.align-items-end.align-items-xxl-center.g-4.gy-xxl-0
          .col-4.col-xl-3
            .d-flex.align-items-center
              app-status(:type="'exam'" :data="data" :caption="false")
              .ms-4
                .text-color--blue Серийный номер устройства
                .mt-3 {{ data.device_serial }}

          .col-4.col-xl-2.col-xxl-2
            .text-color--blue.mb-3 Дата осмотра
            span {{ toLocalDate(data.exam_datetime || data.exam_date_time) }}
            span.ms-3 {{ toLocalTime(data.exam_datetime || data.exam_date_time) }}

          .col
            .text-color--blue.mb-3 Результаты осмотра
            .row.gx-4
              .col-10.col-xxl-9
                .row.align-items-center.gx-4
                  .col
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="pulse")
                      span(v-if="data.bp_high && data.bp_low")
                        span(:class="bpHighColor(data.bp_high)") {{ data.bp_high }} /
                        span(:class="bpLowColor(data.bp_low)") {{ data.bp_low }}
                      span.text-color--blue(v-else) &#8212;

                  .col
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="cardio")
                      span(v-if="data.heart_rate") {{ data.heart_rate }}&nbsp;уд&#65279;/&#65279;мин
                      span.text-color--blue(v-else) &#8212;

                  .col
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="temp")
                      span(v-if="data.body_temp" :class="bpTemp(data.body_temp)") {{ data.body_temp }}&nbsp;c&#176;
                      span.text-color--blue(v-else) &#8212;

                  .col
                    .d-flex.align-items-center
                      span.flex-shrink-0.me-2
                        app-svg-icon(name="breathalyzer")
                      span(v-if="data.alcohol  || data.alcohol === 0" :class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
                      span.text-color--blue(v-else) &#8212;

                  .col
                    app-tooltip(
                      v-if="data.health_complaint"
                      :content="`<p class=\"mb-2\">Жалоба на состояние здоровья:</p><p class=\"text-color--black\">${data.health_complaint}<p>`"
                      :icon="'comment'"
                    )

              .col-auto.ms-auto
                .row.gx-4
                  .col-auto(v-if="data.exam_video")
                    button(
                      type="button"
                      @click.prevent="openModal('video', data)"
                      class=['link--accent']
                    )
                      app-svg-icon(name="video")

                  .col-auto(v-if="data.lat && data.long")
                    button(
                      type="button"
                      @click.prevent="openModal('map', data)"
                      class=['link--accent']
                    )
                      app-svg-icon(name="map-point")


    //- //////////////////////////////////////////////
    template(v-slot:body)
      ul.patient-exams__card-content
        li.patient-exams__card-content-el
          .row.align-items-start.gy-3
            .col-3
              .air-params
                .air-params__item(:vall="data.area_temp+'C'")  
                  app-tooltip(
                  :content="`<p class=\"mb-2\">Температура воздуха при осмотре<p>`"
                  :icon="'comment'"
                  )
                .air-params__item.air-params__item--water(:vall="data.area_humidity+'%'") 
                  app-tooltip(
                  :content="`<p class=\"mb-2\">Влажность воздуха при осмотре<p>`"
                  :icon="'comment'"
                )
              .d-flex.align-items-center
                app-signature(
                  v-if="data.signature"
                  :sample="data.signature_sample"
                  :instance="data.signature"
                  :theme="'shadow'"
                  :classes="'small'"
                  @openGallery="openGallery"
                )
            .col-2   
              div
                .text-color--blue.mb-3 Тип осмотра
                span(v-if="data.exam_type_name") {{data.exam_type_name}}
                span(else) &nbsp;
            .col-2.col-xxl-2
              .text-color--blue.mb-3 Статус
              .text-color--blue-dark(v-if="data.exam_completed") Завершён
              .text-color--blue(v-else) Не завершен
            .col-2.col-xxl-2
             .text-color--blue.mb-3 Допуск
             .status.red(v-if="colorAdmt(data) == 'red'") Не допущен
             .status.green(v-if="colorAdmt(data) == 'green'") Допущен
            .col-2.col-xxl-2
             .text-color--blue.mb-3 Сон
             span.orange(v-if="data.sleep_info=='no'") Не выспался
             span(v-if="data.sleep_info=='yes'") Выспался
             span(v-if="!data.sleep_info") -
            .col-3
                 template(v-if="data.doctor_id")
                    .text-color--blue.mb-2.d-flex Врач
                    router-link(
                      v-if="userRole === 'admin' && type !== 'doctor-detail'"
                      :to="{name: 'doctor-detail', params: {id: data.doctor_id}}"
                      class="text-color--blue-dark link--accent d-flex align-items-center text-decoration-none"
                    ) {{ data.doctor_fullname_history ? data.doctor_fullname_history : data.doctor_fullname }}
                     span.ps-2.doctorSign(type="button" @click.prevent="openCertificate(data)")
                    span.text-color--blue-dark.d-flex(v-else) {{ data.doctor_fullname_history ? data.doctor_fullname_history : data.doctor_fullname }}
                     span.doctorSign(type="button" @click.prevent="openCertificate(data)")
            .col-14
              hr.mt-0.mb-0
            .col-3
              .d-flex
                div
                  .text-color--blue.mb-3 Координаты
                  span(v-if="data.lat && data.long") {{ data.lat }}, {{ data.long }}
                  span.text-color--blue-dark(v-else) &#8212;
            .col-3.col-xl-2
              .text-color--blue.mb-3 Контроль целостности ПО
              span.text-color--blue-dark.green(v-if='data.device_integrity === true') Пройден
              span.text-color--blue-dark.red(v-else-if='data.device_integrity === false') Не пройден
              span.text-color--blue-dark(v-else) &#8212;
            .col-2.col-xxl
              .text-color--blue.mb-3 Поверка
              span(v-if='data.device_verified' class="green") Поверено
              span(v-else class='red') Не поверено

            .col-4.col-xxl-4
              .text-color--blue.mb-3 Корпус
              template(v-if="data.case_opened")
                .d-flex.align-items-center.text-color--danger
                  span.me-2 Вскрыт
                  span.d-flex
                    app-svg-icon(name="attention")
              span(v-else) Не вскрыт
            .col-3.col-xxl
              .text-color--blue.mb-3 Синхронизация
              span(v-if="data.ntp_sync" class="green") Синхронизировано
              span.text-color--accent.red(v-else) Не синхронизировано
</template>

<script>
import {mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';

import {toLocalDate, toLocalTimeExam} from '@/helpers/utils';

import AppSignature from '@/components/profile/common/Signature';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAccordion from '@/components/common/Accordion';
import AppStatus from '@/components/profile/common/Status';
import AppTooltip from '@/components/common/Tooltip';

export default {
  name: 'AppPatientExamCard',
  components: {
    AppTooltip,
    AppStatus,
    AppAccordion,
    AppSvgIcon,
    AppSignature,
  },
  props: {
    patient: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    classes: {
      type: String,
      required: false,
      default: null,
    },
    parentId: {
      type: String,
      required: false,
      default: 'patient-exams',
    },
  },
  computed: {
    ...mapGetters({
      userRole: authGettersTypes.userRole,
    }),
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
    openModal(slug, data) {
      this.$emit('openModal', slug, data);
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),  
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<=99)|| (key<60),  
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
      bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    },
    colorAdmt(data){
      return !data.exam_completed
          ? 'gray'
          : data.admission_to_work === null
          ? 'orange'
          : !data.admission_to_work
          ? 'red'
          : 'green';
    }
  },
};
</script>

<style lang="scss">
.patient-exam-card {
  position: relative;
  padding: $spacer 0;
  @include transition();

  @include mediaToDown($md) {
    background-color: get($colors, white);
    border-radius: $border-radius-sm;
    overflow: hidden;
  }
  &.is-show {
    color: get($colors, black);
  }
  &__header {
    padding-right: $spacer * 5;
  }
  &__content {
    background: get($gradients, blue-extra-light);
    border-radius: $border-radius-sm;
    padding: 0 $spacer * 1.6;

    @include mediaToDown($xxl) {
      padding: $spacer;
    }
  }
  .accordion {
    &__header {
      &[aria-expanded='true'] {
        .accordion {
          &__button {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
    &__button {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .icon {
    &__down-arrow {
      fill: get($colors, orange-hover);
    }
    &__circle {
      width: $spacer;
      height: $spacer;
    }
    &__pulse,
    &__cardio,
    &__temp,
    &__breathalyzer {
      fill: get($colors, blue);
      width: $spacer * 2.2;
      height: $spacer * 2.2;
    }
    &__video {
      height: $spacer * 2;
      width: $spacer * 2.5;
    }
    &__map-point {
      width: $spacer * 2;
      height: $spacer * 2;
    }
    &__map-point,
    &__video {
      fill: get($colors, blue);
    }
  }
}
</style>
