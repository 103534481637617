<template lang="pug">
  section(
    v-if='data'
    class=['user-card', 'profile-card']
  )
    .row.align-items-center.g-4.gy-xl-0
      .col-7.col-md-2
        span.text-color--blue.text-color-md-blue-dark {{ data.creation_date }}

      .col-7.col-md-2
        .d-flex.align-items-center.justify-content-end.justify-content-md-start
          span.text-color--gray-light.me-2.d-md-none
            app-svg-icon(name="user-one")
          span.text-color--blue.text-color-md-blue-dark {{ data.user_type === 'person' ? 'Физ.лицо' : 'Юр.лицо' }}

      .col-14.col-md-3
        div {{ data.full_name }}
        .d-md-none.mt-2.text-color--blue {{ data.email }}

      .d-none.d-md-block.col-14.col-md-3.col-xxl-2
        div {{ data.email }}

      .col-14.col-md-4.col-xxl-5
        .d-flex.align-items-center
          span.text-color--gray-light.me-2.d-md-none
            app-svg-icon(name="diary")
          span(v-if="data.exam_count") {{ data.exam_count }} {{ declOfNum(data.exam_count, ['осмотр', 'осмотра', 'осмотров']) }}
          span.text-color--blue(v-else) нет осмотров

          span.d-none.d-md-inline.ms-auto.text-color--accent
            app-svg-icon(name="right-arrow")


</template>

<script>
import {declOfNum} from '@/helpers/utils';

import AppSvgIcon from '@/components/common/SvgIcon';

export default {
  name: 'AppAdminUserCard',
  components: {
    AppSvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      declOfNum,
    };
  },
};
</script>

<style lang="scss"></style>
