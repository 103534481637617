<template lang="pug">
  .profile-doctors
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(:type="'large'") Пдн

          .tabs-nav.separate-theme.mt-20
            .row.g-4
              .col.col-md-auto
                router-link(
                  :to="{name: 'pdn'}"
                  :class="['tabs-nav__button', 'button', 'w-100']"
                  active-class="is-active"
                )
                  span.button__content пдны

              .col.col-md-auto
                router-link(
                  :to="{name: 'pdn-users'}"
                  :class="['tabs-nav__button', 'button', 'w-100']"
                  active-class="is-active"
                )
                  span.button__content подписи

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              class=['button button--border-blue']
              @click='isModalVisible.addPdn=true'
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить пдн


    .profile-doctors__content.position-relative.mt-25
      transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="pdns")
          template(v-if="pdns.result && pdns.result.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingDoctors(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="pdn in pdns.result" :key="pdn.id")
                    section(
                        class=['user-card', 'profile-card']
                    )
                        .row.align-items-center.g-4.gy-xl-0
  
                            .col-2
                                div {{ pdn.id }}
                              
                            .col-14.col-md-2
                                div {{$moment(pdn.creation_time).format('DD.MM.YYYY')}}  {{$moment(pdn.creation_time).format('HH:mm:ss')}}
                            
                            .col-7.col-md-3
                                div 
                                    a(
                                      class=["pdn-link"]
                                      @click="download(pdn.link)"
                                    ) {{ "скачать" }}

          template(v-else)
            .text-color--blue.text-size--small Соглашений пока не существует в системе
          transition(name="fade" mode="out-in")
    app-modal(v-if="isModalVisible.addPdn" @close="closeModal('addPdn')")
        template(v-slot:body)
          .row.justify-content-center
            .col-14.col-md-10
              .text-center
                app-title(:type="'large'") Редактирование ПДН
                .mt-3
                  p Загрузив новое соглашение, пользователям придет уведомление о подписании нового соглашения

              .mt-5
                form(@submit.prevent :class="['form', {'is-submitting': isLoading}]")
                  .row.flex-column.align-items-center
                   .col-14.col-md-13
                    .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                      input(
                        class="d-none"
                        type="file"
                        name="document"
                        @change="onFileSelected"
                        @click="v$.$touch()"
                        ref="fileInput"
                        accept="application/pdf"
                      )

                      div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                        span.flex-shrink-0.me-3
                          app-svg-icon(name="document")

                        ul.flex-grow-1.overflow-hidden
                          li.form__file-name.text-color--black {{ form.file.name }}
                          li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                        button(
                          type="button"
                          @click="clearFileInput"
                          class=['form__file-delete', 'flex-shrink-0 ms-3']
                        )
                          app-svg-icon(name="trash")

                      .text-color--blue.text-size--small(v-else) Приложите документ .pdf

                      button(
                        class=['form__file-button', 'd-flex justify-content-end align-items-center']
                        @click="$refs.fileInput.click()"
                      )
                        app-svg-icon(name="clip")

                    //- ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                    //-   li {{ v.errors.required }}
                    .col
                      button(
                        type="submit"
                        :disabled="v$.form.$invalid"
                        class=['button mt-5', 'button--accent', 'form__button']
                        @click="onSubmitPdnForm"
                      )
                        span.button__content Добавить
</template>


<script>
import {mapState} from 'vuex';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppModal from '@/components/common/Modal';
import userAgreements from '@/api/pdns';
import {requiredIf} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {modalsMixin} from '@/mixins/modals';
export default {
    name: 'Pdn',
    components: {
        AppTitle,
        AppSvgIcon,
        AppLoading,
        AppModal,
    },
     mixins: [modalsMixin],
      setup() {
    return {
      v$: useVuelidate(),
    };
  },
    data() {
        return {
            columns: [
                {
                    num: 0,
                    class: 'col-2',
                    caption: '№',
                    key: 'id',
                    inverse: false,
                },
                {
                    class: 'col-14 col-md-2',
                    caption: 'Дата',
                    key: 'creation_date',
                    inverse: false,
                },
                {
                    class: 'col-14 col-md-3',
                    caption: 'Ссылка',
                    key: 'link',
                    inverse: false,
                }
            ],
            pdns: {
                'result': [
                    {
                        'id': 0,
                        'date': '10.02.2023',
                        'link': 'http://link.png',
                        'name': 'name'
                    }
                ]
            },
            isModalVisible: {
              addPdn:false
            },
            form: {
        file: null,
      },
        }
    },
    validations() {
    return {
      form: {
        file: {
          required: requiredIf(() => this.form.file === null),
        },
      },
    };
  },
  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
    async created() {
        console.log('created');
        let data = await userAgreements.userAgreements();
        this.pdns.result = data.data.user_agreements
        console.log(data.data.user_agreements);
    },
    methods: {
      download(link) {
        console.log(link);
        var frame = document.createElement("iframe");
        frame.src = `${this.settings.s3_host}${link}`;
        frame["download"] = 1
        document.body.appendChild(frame);
      },
      clearFileInput() {
        this.form.file = null;
        this.$refs.fileInput.value = '';
      },
      onFileSelected({target}) {
        this.form.file = target.files[0];
      },
     async onSubmitPdnForm(){
        const formData = new FormData();
        console.log('this.form.file',this.form.file)
        formData.append('agreements', this.form.file);
        formData.append('title', '123');
        await this.$store.dispatch("actionUploadPdn", formData);
        this.isModalVisible.addPdn = false
        this.$toast.success('ПДН добавлен')
    }
    },
}

</script>

<style>
.pdn-link {
  cursor: pointer;
}
</style>