<template lang="pug">
    .profile-filter(:class="{'is-open': toggle}")
      .profile-filter__container.d-flex.flex-column
        button(
          @click="open"
          type="button"
          :class="{'is-dirty': isDirty}"
          class=['profile-filter__open-button-mobile', 'd-xl-none']
          :style="{'top': style.top}"
        )
          app-svg-icon(name="filter")

        .profile-filter__header.d-flex.align-items-center.justify-content-between.p-3
          button(
            @click="open"
            type="button"
            class="profile-filter__open-button"
          )
            app-svg-icon(name="filter")

          transition(name="fade")
            button(

              type="button"
              @click="clear"
              class=['profile-filter__button', 'text-color--blue-dark text-size--small']
            )
              span.me-3
                app-svg-icon(name="close")
              span Сбросить фильтры

          button(
            @click="close"
            type="button"
            class="filter__close-button"
          )
            app-svg-icon(name="right-long-arrow")

        .profile-filter__content.flex-grow-1.d-flex.flex-column.pt-3
          simplebar(class="profile-filter__form flex-grow-1")
            form(@submit.prevent :class="['form']" id="filter-form")
              .container-fluid.px-3
                .row.flex-column.align-items-center.g-3
                    .col
                      .form__caption.mb-3 Пользователи:
                      app-multiple-select(
                        ref="patients_ids_selected"
                        :search-type="'patients'"
                        :placeholder="'Введите ФИО'"
                        @update="selectPatientsIds"
                        :clean='cleanSelect'
                      )

                .row.flex-column.align-items-center.g-3.mt-3
                  .col
                    .form__caption.mb-3 Дата выдачи документа
                    .row.g-3
                      .col-7
                        date-picker(
                          ref="issue_date_from"
                          v-model="issue_date_from"
                          prefix-class="app"
                          placeholder="от"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          @change="datepickerChange('dateFrom')"
                          @clear="datepickerClear('dateFrom')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")

                      .col-7
                        date-picker(
                          ref="issue_date_to"
                          v-model="issue_date_to"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          @change="datepickerChange('dateTo')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")

                .row.flex-column.align-items-center.g-3.mt-3
                  .col
                    .form__caption.mb-3 Срок действия документа
                    .row.g-3
                      .col-7
                        date-picker(
                          ref="expiration_date_from"
                          v-model="expiration_date_from"
                          prefix-class="app"
                          placeholder="от"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          @change="datepickerChange('dateFrom')"
                          @clear="datepickerClear('dateFrom')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")

                      .col-7
                        date-picker(
                          ref="expiration_date_to"
                          v-model="expiration_date_to"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          @change="datepickerChange('dateTo')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")
                            // date interval
                template
                .row.flex-column.align-items-center.g-3.mt-2
                  .col
                    .form__caption.mb-3 Дата и время регистрации документа
                    .row.g-3
                      .col-7
                        date-picker(
                          ref="create_date_from"
                          v-model="create_date_from"
                          prefix-class="app"
                          placeholder="от"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          :disabled-date="notAfterDateTo"
                          @change="datepickerChange('dateFrom')"
                          @clear="datepickerClear('dateFrom')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")

                      .col-7
                        date-picker(
                          ref="create_date_to"
                          v-model="create_date_to"
                          prefix-class="app"
                          placeholder="до"
                          format="DD.MM.YYYY"
                          :lang="datepicker.lang"
                          :editable="false"
                          :disabled-date="notBeforeDateFrom"
                          @change="datepickerChange('dateTo')"
                          @clear="datepickerClear('dateTo')"
                        )
                          template(v-slot:icon-calendar)
                            app-svg-icon(name="calendar")

                      // time interval
                      template
                        .col-7
                          date-picker(
                            ref="create_time_from"
                            v-model="create_time_from"
                            prefix-class="app"
                            placeholder="от"
                            format="HH:mm"
                            type="time"
                            :editable="false"
                            @change="datepickerChange('timeFrom')"
                            @clear="datepickerClear('timeFrom')"
                          )
                            template(v-slot:icon-calendar)
                              app-svg-icon(name="time")
                        .col-7
                          date-picker(
                            ref="create_time_to"
                            v-model="create_time_to"
                            prefix-class="app"
                            placeholder="до"
                            format="HH:mm"
                            type="time"
                            :editable="false"
                            @change="datepickerChange('timeTo')"
                            @clear="datepickerClear('timeTo')"
                          )
                            template(v-slot:icon-calendar)
                              app-svg-icon(name="time")
                      .col
                        app-select(
                          ref="document_status_selected"
                          :type="'form'"
                          :placeholder="'Статус документа'"
                          :options="document_statuses"
                          @select="selectDocumentStatus"
                        )

          // submit button
          .profile-filter__submit-button.flex-shrink-0.mt-auto
            button(
              type="submit"
              form="filter-form"

              @click='submit'
              class=['button', 'button--accent', 'form__button', 'mt-auto d-flex justify-content-center']
            )
              span.button__content
                span Показать
                transition(name="translucent" mode="out-in")
                  //span.profile-filter__count(v-if="isDirty && count" :key="count") &nbsp;({{ count }})

        button(
          v-if="isFiltered && isDirty"
          type="button"
          @click="clear"
          class=['profile-filter__clear-button', 'd-flex flex-column align-items-center']
        )
          span.d-flex.mb-2
            app-svg-icon(name="close")
          span.text-size--extra-small.text-decoration--underline Сбросить

  </template>

  <script>
  import {mapState} from 'vuex';
  import {actionTypes as filtersActionTypes} from '@/store/modules/filters';

  import is from 'is_js';

  import simplebar from 'simplebar-vue';
  import Slider from '@vueform/slider/dist/slider.vue2';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/locale/ru';

  import AppValidationErrors from '@/components/common/ValidationErrors';
  import AppSvgIcon from '@/components/common/SvgIcon';
  import AppMultipleSelect from '@/components/common/MultipleSelect';
  import AppSelect from '@/components/common/Select';
  import AppTooltip from '@/components/common/Tooltip';

  export default {
    name: 'AppFilterPageNotices',
    components: {
      AppTooltip,
      AppSelect,
      AppMultipleSelect,
      AppSvgIcon,
      AppValidationErrors,
      DatePicker,
      Slider,
      simplebar,
    },
    props: {
      isOpen: {
        type: Boolean,
        required: true,
        default: true,
      },
      isFiltered: {
        type: Boolean,
        required: true,
        default: false,
      },
      count: {
        type: Number,
        required: false,
        default: null,
      },
      options: {
        type: Object,
        required: true,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        device_verification_owner_is_admin_selected: null, // flase, true, null
        case_opened_selected: null, // flase, true, null
        device_integrity_selected: null, // flase, true, null
        device_verification_ending_to_selected: null, // '28.11.2022', null
        device_verification_ending_from_selected: null, // '01.01.2023', null
        device_ids_selected: null, // ['6786786786', '7654456456'], null
        device_verified_selected: null, // false, true, null
        device_attestation_status_selected: null, // isPending, isRejected, isApproved, null
        document_status_selected: null,
        patients_ids_selected: [],
        issue_date_from: null,
        issue_date_to: null,
        expiration_date_from: null,
        expiration_date_to: null,
        create_date_from: null,
        create_date_to: null,
        create_time_from: null,
        create_time_to: null,
        cleanSelect: false,
        toggle:false,
        admissionsChecked:[true,true],
        admissions:["admissed","not_admissed"],
        style: {
          top: null,
        },
        delay: 2000,
        anchor: '#isAnchorForBtn',
        form: {
          isDirty: {
            users: false,
            patients: false,
            caseOpened: false,
          },
          users: [],
          userTypes: ['person', 'company'],
          patients: [],
          negative: {
            users: null,
            patients: null,
          },
          devices: [],
          date: {
            from: null,
            to: new Date(),
          },
          time: {
            from: null,
            to: null,
          },
          status: null,
          caseOpened: null,
          coords: null,
          video: null,
          sync: null,
          bpHigh: {
            min: null,
            max: null,
          },
          bpLow: {
            min: null,
            max: null,
          },
          heartRate: {
            min: null,
            max: null,
          },
          bodyTemp: {
            min: null,
            max: null,
          },
        },

        datepicker: {
          lang: {
            formatLocale: {
              firstDayOfWeek: 1,
            },
            monthBeforeYear: false,
          },
        },
        device_admission_owner: [
          {
            caption: 'Клиент',
            icon: 'allright',
            value: 'client',
            selected: false,
          },
          {
            caption: 'MedControl',
            icon: 'allright',
            value: 'medcontrol',
            selected: false,
          },
        ],
        device_statuses: [
          {
            caption: 'На проверке',
            icon: 'allright',
            value: 'isPending',
            selected: false,
          },
          {
            caption: 'Зарегистрировано',
            icon: 'allright',
            value: 'isApproved',
            selected: false,
          },
          {
            caption: 'Отклонено',
            icon: 'allright',
            value: 'isRejected',
            selected: false,
          },
        ],
        document_statuses: [
          {
            caption: 'Подтверждено',
            icon: 'allright',
            value: 'confirmed',
            selected: false,
          },
          {
            caption: 'На подтверждении',
            icon: 'allright',
            value: 'awaiting',
            selected: false,
          },
          {
            caption: 'Отклонено',
            icon: 'allright',
            value: 'rejected',
            selected: false,
          },
          {
            caption: 'В архиве',
            icon: 'allright',
            value: 'archived',
            selected: false,
          },
        ],
        device_integrity_control_types: [
          {
            caption: 'Пройден',
            icon: 'allright',
            value: true,
            selected: false,
          },
          {
            caption: 'Не пройден',
            icon: 'wrong',
            value: false,
            selected: false,
          },
        ],
        device_admissions: [
          {
            caption: 'Поверено',
            icon: 'allright',
            value: true,
            selected: false,
          },
          {
            caption: 'Не поверено',
            icon: 'wrong',
            value: false,
            selected: false,
          },
        ],
        case_integrity: [
          {
            caption: 'Не вскрыт',
            icon: 'allright',
            value: false,
            selected: false,
          },
          {
            caption: 'Вскрыт',
            icon: 'wrong',
            value: true,
            selected: false,
          },
        ],
      };
    },
    computed: {
      ...mapState({
        isLoading: (state) => state.filters.isLoading,
        filters: (state) => state.filters.data,
        error: (state) => state.filters.error,
      }),
      isDirty() {
        return Object.values(this.form.isDirty).some((item) => item === true);
      },
      isTouchDevice() {
        return is.touchDevice();
      },
    },
    watch: {

      isDirty(value) {
        if (!value) this.$emit('clear');
      },
    },
    async mounted() {
      if (
        is.existy(this.options.patients) ||
        is.existy(this.options.users) ||
        is.existy(this.options.devices)
      ) {
        this.getSearchData();
      }
    },
    methods: {
      selectDeviceIds(option) {
        console.log(option.map(el => el.value));
        this.device_ids_selected = option.map(el => el.value);
      },
      selectDocumentStatus(option) {
        this.document_statuses.map((el) => (el.selected = el.value === option.value));
        console.log(option.caption);
        this.document_status_selected = option.value;
      },
      selectPatientsIds(option) {
        this.patients_ids_selected = option.map(el => el.value);
      },
      selectDeviceAdmissionOwner(option) {
        this.device_admission_owner.map((el) => (el.selected = el.value === option.value));
        if (option.value == "client"){
          this.device_verification_owner_is_admin_selected = false;
        } else {
          this.device_verification_owner_is_admin_selected = true;
        }
        console.log(option);
      },
      selectDeviceStatuses(option) {
        this.device_statuses.map((el) => (el.selected = el.value === option.value));
        console.log(option.caption);
        this.device_attestation_status_selected = option.value;
      },
      selectDeviceIntegrityControlTypes(option) {
        this.device_integrity_control_types.map((el) => (el.selected = el.value === option.value));
        console.log(option);
        this.device_integrity_selected = option.value;
      },
      selectDeviceAdmissions(option) {
        this.device_admissions.map((el) => (el.selected = el.value === option.value));
        console.log(option);
        this.device_verified_selected = option.value;
      },
      selectCaseIntegrity(option) {
        this.case_integrity.map((el) => (el.selected = el.value === option.value));
        console.log(option);
        this.case_opened_selected = option.value;
      },

      datepickerChange(slug) {
        this.form.isDirty[slug] = true;
       // this.getCount();
      },
      datepickerClear(slug) {
        this.form.isDirty[slug] = false;
        this.form.isDirty[slug] = false;

        if (slug === 'dateTo') {
          this.form.time.to = null;
          this.form.isDirty.timeTo = false;
        }
        if (slug === 'dateFrom') {
          this.form.time.from = null;
          this.form.isDirty.timeFrom = false;
        }
      },
      notAfterDateTo(date) {
        if (this.form.date.to) return date > this.form.date.to;
      },
      notBeforeDateFrom(date) {
        if (this.form.date.from) return date < this.form.date.from;
      },

      open() {
        this.toggle = true
      },
      close() {
        this.toggle = false;
      },
     async clear() {
        console.log('clear');
        this.$refs.document_status_selected.clear();
        //this.$refs.patients_ids_selected.clear();
        this.$refs.issue_date_from.clear();
        this.$refs.issue_date_to.clear();
        this.$refs.expiration_date_from.clear();
        this.$refs.expiration_date_to.clear();
        this.$refs.create_date_from.clear();
        this.$refs.create_date_from.clear();
        this.$refs.create_time_from.clear();
        this.$refs.create_time_to.clear();

        console.log(this.cleanSelect);
        this.cleanSelect = !this.cleanSelect;
        console.log(this.cleanSelect);

        this.document_status_selected = null;
        this.patients_ids_selected = [];
        this.issue_date_from = null;
        this.issue_date_to = null;
        this.expiration_date_from = null;
        this.expiration_date_to = null;
        this.create_date_from = null;
        this.create_date_to = null;
        this.create_time_from = null;
        this.create_time_to = null;
        this.$emit('submit', {});
      },
      getSearchData() {
        this.$store.dispatch(filtersActionTypes.getFilters);
      },
      async submit() {
        const data = {
              // 'date_from': this.collectData.date_from,
              // 'date_to': this.collectData.date_to,
              // 'time_from': this.collectData.time_from,
              // 'time_to': this.collectData.time_to,
              "patients": {
                  "negative": false,
                  "payload": this.patients_ids_selected
              },
              //   "pre_diagnosis": {
              //     "negative": false,
              //     "payload": this.collectData.pre_diagnosis.payload
              // }
        }
        if (this.expiration_date_from) data.expiration_date_from = this.$moment(this.expiration_date_from).format('DD.MM.YYYY')
        if (this.expiration_date_to) data.expiration_date_to = this.$moment(this.expiration_date_to).format('DD.MM.YYYY')
        if (this.create_date_from) data.create_date_from = this.$moment(this.create_date_from).format('DD.MM.YYYY')
        if (this.create_date_to) data.create_date_to = this.$moment(this.create_date_to).format('DD.MM.YYYY')
        if (this.create_time_from) data.create_time_from = this.$moment(this.create_time_from).utc().format('HH:mm:ss')
        if (this.create_time_to) data.create_time_to = this.$moment(this.create_time_to).utc().format('HH:mm:ss')
        if (this.document_status_selected) data.document_status = this.document_status_selected
        if (this.issue_date_from) data.issue_date_from = this.$moment(this.issue_date_from).format('DD.MM.YYYY')
        if (this.issue_date_to) data.issue_date_to = this.$moment(this.issue_date_to).format('DD.MM.YYYY')
        this.$emit('submit', data);
        console.log(132);
      },
    },
  };
  </script>

  <style lang="scss">
  $bg-color: #0c1120;

  .profile-filter {
    pointer-events: none;
    position: fixed;
    z-index: get($index, xl);
    top: 0;
    right: 0;
    width: 16.66667%;
    height: 100%;
    padding-top: $header-height;
    @include transition(transform);
    transform: translateX(85%);

    &:before {
      @include pseudo-wrapper();
      background-color: rgba($bg-color, 0.8);
      backdrop-filter: blur(4px);
      @include transition();
      opacity: 0;
    }
    @include mediaToDown($xxl) {
      width: 25%;
    }
    @include mediaToDown($xl) {
      width: 50%;
      transform: translateX(100%);

      &.is-open {
        .profile-filter {
          &__container {
            box-shadow: get($shadows, default);
          }
        }
      }
    }
    @include mediaToDown($md) {
      padding-top: 0;
      width: 100%;

      &.is-open {
        padding-top: $spacer;
        padding-left: $spacer;
        padding-right: $spacer;

        &:before {
          opacity: 1;
          transition-delay: #{$transition-duration}s;
        }
      }
    }
    &.is-open {
      transform: translateX(0);

      .profile-filter {
        &__content {
          opacity: 1;
          visibility: visible;
        }
        &__clear-button,
        &__open-button-mobile {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    &__container {
      position: relative;
      pointer-events: auto;
      width: 100%;
      height: 100%;
      background-color: get($colors, white);
      box-shadow: get($shadows, default);
      border-radius: $border-radius-sm 0 0 0;
      color: get($colors, blue);
      padding-top: $spacer * 1.3;
      @include transition();

      @include mediaToDown($xl) {
        box-shadow: none;
      }
    }
    &__header {
      width: 100%;
    }
    &__content {
      position: relative;
      @include transition();
      opacity: 0;
      visibility: hidden;
      height: 90%;
    }
    &__form {
      max-height: 90%;
      overflow-x: hidden;
      padding-bottom: $spacer;
    }
    &__submit-button {
      position: relative;
      width: 105%;
      left: -5%;
      padding: $spacer * 1.2 $spacer * 1.2 $spacer * 1.2 $spacer * 2.3;
      border: $border-width solid get($colors, blue-light);
      border-radius: $border-radius-lg 0 0 0;
      background-color: get($colors, white);
      box-shadow: get($shadows, default);

      @include mediaToDown($xl) {
        border-radius: $border-radius-lg $border-radius-lg 0 0;
      }
      @include mediaToDown($md) {
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &__count {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
    }
    &__clear-button {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 15%;
      background: get($gradients, orange);
      color: get($colors, white);
      padding: $spacer 0;
      @include transition();

      @include interaction('mouse') {
        &:hover {
          box-shadow: get($shadows, default);
        }
      }
    }
    &__open-button,
    &__close-button {
      @include transition(color);

      @include interaction('mouse') {
        &:hover {
          color: get($colors, orange-hover);
        }
      }
    }
    &__open-button-mobile {
      position: absolute;
      z-index: get($index, default);
      width: $spacer * 4.4;
      height: $spacer * 4.4;
      right: 100%;
      top: 0;
      background-color: get($colors, white);
      border-radius: $spacer * 3 $spacer * 1.6 0 $spacer * 3;
      padding: $spacer;
      box-shadow: get($shadows, default);
      transition: background-color #{$transition-duration}s, color #{$transition-duration}s;
      transform: translateY($spacer);

      &.is-dirty {
        background-color: get($colors, blue-dark);
        color: get($colors, white);
      }
    }
    .icon {
      &__filter {
        width: $spacer * 2.2;
        height: $spacer * 2.2;
      }
    }
  }
  </style>
