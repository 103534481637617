import axios from '@/api/axios';

const getDevices = (payload) => axios.post('/device', payload);

const filterDevices = (credentials) => axios.post('/device/all', credentials);

const getCountDevices = (credentials) => axios.post('/device/all/count', credentials);

const exportDevices = (credentials) =>
  axios.post('/device/all/export', credentials, {responseType: 'blob'});

const deleteDevice = (deviceId) => axios.delete(`/device/${deviceId}`);

const deleteRejectedDevice = (requestId, deviceId) =>
  axios.delete(`/device/rejected/${requestId}/${deviceId}`);

export default {
  getDevices,
  filterDevices,
  getCountDevices,
  exportDevices,
  deleteDevice,
  deleteRejectedDevice,
};
