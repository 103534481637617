<template lang="pug">
  .profile-users
    .profile-users__inner.is-filterable(:class="{'filter-is-open': isFilterOpen}")
      .profile-users__header
        .row.align-items-center
          .col-14.col-md-auto
            app-title(
              :type="'large'"
              :classes="'text-center mb-3 mb-md-0'"
            ) {{ isTouchDevice ? 'Клиенты' : 'Просмотр клиентов' }}

      .profile-users__content.mt-20.position-relative
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")
        
        transition(name="translucent")
          div(v-if="users")
            template(v-if="users.result.length")
              app-select(
                @select="sortBySelect"
                :classes="'d-xl-none mb-4'"
                :options="sortOptions"
                :placeholder="'выберите...'"
              )
              .profile-table
                .profile-table__header.d-none.d-xl-block
                  .row.align-items-center.gx-4
                    div(v-for="column in columns" :key="column.key" :class="column.class")
                      button(
                        type="button"
                        @click="sortingUsers(column.key, column.inverse)"
                        class=['profile-table__sort-button']
                        :class="{'is-active': sortKey === column.key}"
                      )
                        span.d-flex.align-items-center
                          span.text-overflow(v-if="column.caption") {{ column.caption }}
                          span(v-else-if="column.icon")
                            app-svg-icon(:name="column.icon")
                          span.d-flex.ms-3
                            app-svg-icon(name="down-arrow")


                ul.profile-table__body
                  li.profile-table__row(v-for="user in users.result.filter(el => el.id !== currentUser.id)" :key="user.id")
                    router-link(:to="{name: 'user-detail', params: {id: user.id}}" class=['d-block'])
                      app-admin-user-card(:data="user")

              .d-flex.justify-content-center.mt-5
                app-pagination(
                  :total="pagination.total"
                  :limit="pagination.limit"
                  :current-page="currentPage"
                  :url="baseUrl"
                )

            template(v-else)
              .text-color--blue.text-size--small Клиенты не найдены


      // filter
      app-filter(
        :options="filterOptions"
        :is-open="isFilterOpen"
        :is-filtered="isFiltered"
        :count="usersCount"
        @getCount="getCountFilter"
        @open="openFilter"
        @close="closeFilter"
        @clear="clearFilter"
        @submit="submitFilter"
      )

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes} from '@/store/modules/users';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';

import AppFilter from '@/components/profile/common/Filter';
import AppAdminUserCard from '@/components/profile/admin/users/AdminUserCard';

import {filterMixin} from '@/mixins/filter';

export default {
  name: 'AppAdminUsers',
  components: {
    AppAdminUserCard,
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppFilter,
    AppSelect,
  },
  mixins: [filterMixin],
  data() {
    return {
      v: variables,
      filterOptions: {
        users: true,
        userTypes: true,
        date: true,
        devices: true,
        methods: {
          getData: ['getUsers'],
          getCount: 'getCountUsers',
        },
      },
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'creation_date',
          selected: true,
          inverse: true,
        },
        {
          caption: 'типу клиента',
          value: 'user_type',
          selected: false,
          inverse: false,
        },
        {
          caption: 'имени клиента',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'e-mail',
          value: 'email',
          selected: false,
          inverse: false,
        },
        {
          caption: 'кол-ву осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-2',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Тип клиента',
          key: 'user_type',
          inverse: false,
        },
        {
          class: 'col-3',
          caption: 'Клиент',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-md-3 col-xxl-2',
          caption: 'E-mail',
          key: 'email',
          inverse: false,
        },
        {
          class: 'col-md-4 col-xxl-5',
          caption: 'Осмотры',
          key: 'exam_count',
          inverse: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.users.isLoading,
      users: (state) => state.users.data,
      usersCount: (state) => state.users.count,
      error: (state) => state.users.error,
    }),
    ...mapState({
      currentUser: (state) => state.auth.currentUser,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    baseUrl() {
      return this.$route.path;
    },
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getUsers(this.filters, this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getUsers(this.filters, this.pagination.limit, this.offset);
  },
  methods: {
    goToFirstPage() {
      if (this.currentPage !== 1) this.$router.push({query: {page: '1'}});
    },
    getUsers(filters, limit, offset) {
      let data = {
        filters: this.settings.filters,
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      data.filters = Object.assign({}, this.settings.filters, filters);

      this.$store.dispatch(actionTypes.getUsers, data).then((response) => {
        if (this.isTouchDevice) this.closeFilter('submit');
        this.pagination.total = response.total;
        this.isFiltered = Object.keys(filters).length > 0;
        this.reverseSorting();
      });
    },
    getCountUsers(filters) {
      let data = {
        sorting_params: this.settings.sorting_params,
      };
      data.filters = Object.assign({}, this.settings.filters, filters);

      this.$store.dispatch(actionTypes.getCountUsers, data);
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingUsers(value, inverse);
    },
    sortingUsers(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getUsers(this.filters, this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.profile-users {
  .profile-table {
    &__row {
      & + .profile-table__row {
        margin-top: $border-width * 2;
      }
      @include mediaToDown($xl) {
        & + .profile-table__row {
          margin-top: $spacer * 0.6;
        }
      }
    }
  }
}
</style>
