<template lang="pug">
  section(
    class=['exam-card-mobile', 'profile-card']
    :class="classes"
    @click="openModal(data)"
  )
    .row.flex-column.g-4
      .col
        app-status(
          :type="'exam'"
          :data="data"
          :classes="'mb-md-4'"
        )
        .d-flex.align-items-center.text-color--blue
          span.me-3 №{{data.exam_number}}
          span.me-3 {{ toLocalDate(data.exam_datetime || data.exam_date_time) || data.exam_date }}
          span.me-3 {{ toLocalTimeExam(data.exam_date_time) }}
          
          app-status(:type="'exam-caption'" :data="data" :line="false" :classes="'me-3'")
          
          span.ms-auto.ms-md-3.me-3.text-color--gray-light(v-if="data.health_complaint")
            app-svg-icon(name="comment")
          
          button(
            type="button"
            class=['exam-card-mobile__button', 'text-color--accent', 'ms-auto']
          )
            span.d-flex.align-items-center.justify-content-center
              app-svg-icon(name="right-arrow")

      .col(v-if="type === 'map'")
        span(v-if="data.lat && data.long") {{ data.lat }}, {{ data.long }}
        span(v-else) &#8212;

      .col(v-if="type !== 'patient-detail'")
        span(:class="{'text-color--blue': type === 'map'}") {{ data.patient_full_name }}
      .col
        .row.align-items-center.g-4.flex-nowrap
          .col-auto
            .d-flex.align-items-center
              span(:class="[bpHighColor(data.bp_high), bpLowColor(data.bp_low)]").flex-shrink-0.me-3.text-color--blue
                app-svg-icon(name="pulse")

              span(:class="`text-color--${type === 'patient-detail' ? 'black' : 'blue'}`")
                span(v-if="data.bp_high && data.bp_low") 
                 span(:class="bpHighColor(data.bp_high)") {{ data.bp_high }} /
                 span(:class="bpLowColor(data.bp_low)") {{ data.bp_low }}
                span(v-else) &#8212;
          
          .col-auto
            .d-flex.align-items-center
              span.flex-shrink-0.me-3.text-color--blue
                app-svg-icon(name="cardio")

              span(:class="`text-color--${type === 'patient-detail' ? 'black' : 'blue'}`")
                span(v-if="data.heart_rate") {{ data.heart_rate }}
                span(v-else) &#8212;
          
          .col-auto
            .d-flex.align-items-center
              span.flex-shrink-0.me-3.text-color--blue
                app-svg-icon(name="temp")

              span(:class="`text-color--${type === 'patient-detail' ? 'black' : 'blue'}`")
                span(v-if="data.body_temp" :class="bpTemp(data.body_temp)") {{ data.body_temp }}&nbsp;c&#176;
                span(v-else) &#8212;
          
          .col-auto
            .d-flex.align-items-center
              span.flex-shrink-0.me-3.text-color--blue(:class="alcoholColor(data.alcohol)")
                app-svg-icon(name="breathalyzer")

              span(:class="`text-color--${type === 'patient-detail' ? 'black' : 'blue'}`")
                span(v-if="data.alcohol || data.alcohol === 0" :class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
                span(v-else) &#8212;
</template>

<script>
import {toLocalDate, toLocalTimeExam} from '@/helpers/utils';

import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppStatus from '@/components/profile/common/Status';

export default {
  name: 'AppExamCardMobile',
  components: {
    AppStatus,
    AppSvgIcon,
    AppModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      required: false,
      default: 'exams',
    },
    classes: {
      type: String,
      required: false,
      default() {
        return null;
      },
    },
  },
  methods: {
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
    openModal(data) {
      this.$emit('openModal', data);
    },
        bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),  
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<99)|| (key<60),  
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
     bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    }
  },
};
</script>

<style lang="scss">
.exam-card-mobile {
  &__button {
    svg {
      width: $spacer;
      height: $spacer;
    }
  }
  &.profile-card {
    @include mediaToDown($md) {
      padding-top: $spacer * 2;
    }
  }
}
</style>
