<template lang="pug">
  .profile-doctors
    .profile-doctors__header
      .row.align-items-center
        .col-14.col-md-7
          .d-flex.align-items-center
            app-title(:type="'large'") Просмотр врачей

            .d-md-none.ms-auto
              button(
                type="button"
                @click="openModal('createDoctor')"
                class=['button button--icon button--border-blue']
              )
                span.flex-shrink-0
                  app-svg-icon(name="plus-thin")

          .tabs-nav.separate-theme.mt-20
            .row.g-4
              .col.col-md-auto
                router-link(
                  :to="{name: 'doctors'}"
                  :class="['tabs-nav__button', 'button', 'w-100']"
                  active-class="is-active"
                )
                  span.button__content Врачи

              .col.col-md-auto
                router-link(
                  :to="{name: 'auto-doctor'}"
                  :class="['tabs-nav__button', 'button', 'w-100']"
                  active-class="is-active"
                )
                  span.button__content Автоматический врач

        .col-14.col-md-7.d-none.d-md-block
          .d-flex.justify-content-end
            button(
              type="button"
              @click="openModal('createDoctor')"
              class=['button button--border-blue']
            )
              span.button__content
                span.d-flex.flex-shrink-0.me-3
                  app-svg-icon(name="plus-thin")
                span Добавить врача


    .profile-doctors__content.position-relative.mt-25
      transition(name="translucent")
        app-loading(v-if="isLoading")
        app-error-message(v-if="error" :message="error")

        div(v-if="doctors")
          template(v-if="doctors.result && doctors.result.length")
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.g-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      type="button"
                      @click="sortingDoctors(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

              ul.profile-table__body
                li.profile-table__row(v-for="doctor in doctors.result" :key="doctor.id")
                  router-link(:to="{name: 'doctor-detail', params: {id: doctor.id}}" class=['d-block'])
                    app-admin-doctor-card(
                      :data="doctor"
                      :columns="columns"
                    )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )

          template(v-else)
            .text-color--blue.text-size--small Врачей пока не существует в системе


    // create doctor modal
    app-create-doctor-modal(
      :is-visible="isModalVisible.createDoctor"
      @close="closeModal('createDoctor')"
      @success="getDoctors(pagination.limit, 0)"
    )

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as filtersActionTypes} from '@/store/modules/filters';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppModal from '@/components/common/Modal';
import AppValidationErrors from '@/components/common/ValidationErrors';
import AppPagination from '@/components/common/Pagination';

import AppCreateDoctorModal from '@/components/profile/admin/doctors/CreateDoctorModal';
import AppAdminDoctorCard from '@/components/profile/admin/doctors/AdminDoctorCard';

import {modalsMixin} from '@/mixins/modals';

export default {
  name: 'AppAdminDoctors',
  components: {
    AppTitle,
    AppSvgIcon,
    AppLoading,
    AppPagination,
    AppErrorMessage,
    AppValidationErrors,
    AppModal,
    AppAdminDoctorCard,
    AppCreateDoctorModal,
  },
  mixins: [modalsMixin],
  data() {
    return {
      is,
      v: variables,
      isModalVisible: {
        createDoctor: false,
      },
      sortKey: '',
      sortInverse: false,
      pagination: {
        limit: variables.pagination.limit,
        total: 0,
      },
      columns: [
        {
          class: 'col-14 col-md-2',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: false,
        },
        {
          class: 'col-14 col-md-3',
          caption: 'Ф.И.О. врача',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-14 col-md-2',
          caption: 'E-mail',
          key: 'email',
          inverse: false,
        },
        {
          class: 'col-14 col-md-3',
          caption: 'Название мед.организации',
          key: 'place_of_work',
          inverse: false,
        },
        {
          class: 'col-auto col-md-2',
          caption: 'Кол-во клиентов',
          key: 'clients_count',
          inverse: true,
        },
        {
          class: 'col-auto col-md-2 flex-grow-1',
          caption: 'Пользователей на осмотре',
          key: 'patients_count',
          inverse: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.doctors.isLoading,
      doctors: (state) => state.doctors.data,
      error: (state) => state.doctors.error,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
  },
  watch: {
    currentPage() {
      this.getDoctors(this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getSearchData();
    this.getDoctors(this.pagination.limit, this.offset);
  },
  methods: {
    getSearchData() {
      this.$store.dispatch(filtersActionTypes.getFilters);
    },
    getDoctors(limit, offset) {
      let data = {
        paging_params: {
          limit,
          offset,
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };

      this.$store.dispatch(doctorsActionTypes.getDoctors, data).then((response) => {
        this.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    sortingDoctors(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getDoctors(this.pagination.limit, this.offset);
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingDoctors(value, inverse);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
  },
};
</script>
