<template lang="pug">
    section(class=['patient-card', 'profile-card'] :class="classes")
      .row.align-items-center.g-4.gy-xl-0 
        .col-auto
            span.idDoc {{data.id}}        
        .d-none.d-xl-block.col-2
          .blue(v-if='data.document_status=="confirmed"') {{ "Подтверждено" }}
          .yellow(v-else-if='data.document_status=="awaiting"') {{ "На подтверждении" }}
          .red(v-else-if='data.document_status=="rejected"') {{ "Отклонено" }}
          .blue(v-else) {{" В архиве "}}
        .col-auto.col-2
          // подкладываем ровно то время которое пришло с сервера
          span {{ $moment(data.create_datetime).format('DD.MM.YYYY HH:mm:ss') }}
        .col-auto.col-2
          span {{ data.patient_full_name }}
        .col-2
          span.text-overflow {{ $moment(data.issue_date).format('DD.MM.YYYY') }}
        .col-2
          span.text-overflow(v-if='data.expiration_date!=null') {{ $moment(data.expiration_date).format('DD.MM.YYYY') }}
        .col-2
          span.text-overflow(v-if='data.document_status!=="awaiting" && data.document_status!=="archived"') {{ $moment(data.verification_datetime).format('DD.MM.YYYY HH:mm') }}
        .col-auto
          span.ms-auto.text-color--accent.d-xl-inline.flex-shrink-0.pointer(@click='openShowModal(data)')
            span 
              img(src="@/assets/img/notice.svg" ) 
</template>
  
  <script>
  import {mapState} from 'vuex';

  import {toLocalDate, declOfNum} from '@/helpers/utils';
  
  import AppSvgIcon from '@/components/common/SvgIcon';
  import AppAvatar from '@/components/profile/common/Avatar';

  import ShowDocModal from '@/components/profile/client/docs/ShowDocModal.vue'
  
  export default {
    name: 'ClientIncomingNotice',
    components: {
      AppAvatar,
      AppSvgIcon,
      ShowDocModal
    },
    props: {
      data: {
        type: Object,
        required: true,
        default() {
          return null;
        },
      },
      classes: {
        type: String,
        required: false,
        default() {
          return null;
        },
      },
      openShowModal: {
        type: Function,
        required: false,
      }
    },
    data() {
      return {
        declOfNum
      };
    },
    computed: {
      ...mapState({
        settings: (state) => state.settings.data,
      }),
    },
    methods: {
      toLocalDate(date) {
        if (date) {
          return toLocalDate(date);
        } else return false;
      },
      download(urls) {
        for(let i = 0; i < urls.length; i++) {
          if(urls[i]){
          var frame = document.createElement("iframe");
          frame.src = `${this.settings.s3_host}${urls[i]}`;
          frame["download"] = 1
          document.body.appendChild(frame);
          }
        }
      }
    },
  };
  </script>
  
  <style lang="scss">
  .idDoc{
    min-width: 30px;
    display: block;
  }
  .patient-card {
    &.profile-card {
      padding-top: $spacer * 0.6;
      padding-bottom: $spacer * 0.6;
  
      .icon {
        &__breathalyzer {
          width: $spacer * 1.8;
          height: $spacer * 1.8;
        }
      }
  
      @include mediaToDown($md) {
        padding-top: $spacer * 1.6;
        padding-bottom: $spacer * 1.6;
      }
    }
  }
  .pointer{
    cursor: pointer;
  }
  </style>
  