<template lang="pug">
 transition(name="fade" mode="out-in")
  app-modal(v-if="isVisible" @close="closeModal" :classes="`modal-refuse`")
    template(v-slot:body)
      .row.flex-column.align-items-center
        .col-12
          .text-center.pt-4
            app-title(:type="'large'" v-if="type=='1no' || type=='3no'") ПОДТВЕРДИТЕ ОТСТРАНЕНИЕ 
            app-title(:type="'large'" v-else) ПОДТВЕРДИТЕ прохождение осмотра
            p.text-color--blue.mt-3.text-uppercase {{ data.patient_full_name }}
          .row.gx-5.justify-content-center.mb-4.mt-3
            .col-auto
              .d-flex
                span(:class="[bpHighColor(data.bp_high), bpLowColor(data.bp_low)]").flex-shrink-0.me-2
                  app-svg-icon(name="pulse")
                span(v-if="data.bp_high && data.bp_low" :class="bpHighColor(data.bp_high)") {{ data.bp_high }}/
                span(v-if="data.bp_high && data.bp_low" :class="bpLowColor(data.bp_low)") {{ data.bp_low }}
                span.text-color--blue(v-else) &#8212;

            .col-auto
              .d-flex
                span.flex-shrink-0.me-2
                  app-svg-icon(name="cardio")
                span(v-if="data.heart_rate") {{ data.heart_rate }}
                span.text-color--blue(v-else) &#8212;

            .col-auto
              .d-flex
                span.flex-shrink-0.me-2
                  app-svg-icon(name="temp")
                span(v-if="data.body_temp") {{ data.body_temp }}&nbsp;c&#176;
                span.text-color--blue(v-else) &#8212;

            .col-auto
              .d-flex
                span(:class="alcoholColor(data.alcohol)").flex-shrink-0.me-2
                  app-svg-icon(name="breathalyzer")
                span(:class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
            .col-auto(v-if="data.sleep_info=='no'")
             .d-flex.align-items-center
              span.flex-shrink-0.me-2
               img(src="@/assets/img/sleep.png")
              span.orange Не выспался
          .row.g-3 
            .col-12
             .refuseCauseHeader.mt-4.mb-2 Причина отстранения
            .col-12
             .row.g-2
              template(v-for='reason in reasons')
               template(v-if='reason.left || reason.right') 
                .col-auto(v-if='reason.both == null && reason.left && reason.right')
                 .refuseCause Травма: {{reason.text}}, слева, справа
                .col-auto(v-if='reason.both')
                 .refuseCause Травма: {{reason.text}} 
                .col-auto(v-if='reason.left && !reason.right')
                 .refuseCause Травма: {{reason.text}}, слева
                .col-auto(v-if='reason.right && !reason.left')
                 .refuseCause Травма: {{reason.text}}, справа
               template(v-else) 
                .col-auto(v-if='reason.text !== "Травма"')
                 .refuseCause {{reason.text}}
          .row
            .col-14 
              textarea.refuseDesc(v-model="reasonText" placeholder="При необходимости укажите дополнительную причину отстранения")
          .d-flex.justify-content-center.mt-4
            button(
              @click="sendResultData('success')"
              type="button"
              class=['button button--accent w-100']
              v-if="type=='1no' || type=='3no'"
              :disabled='reasons.length==0 && reasonText.length==0'
            )
              span.button__content Подтвердить
            button(
              @click="sendResultData('success')"
              type="button"
              class=['button button--accent w-100']
              v-else
            )
              span.button__content Подтвердить
</template>

<script>
import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
export default {
    name: 'addDoctorReasonsRefuse',
    data() {
      return {
        reasonText:''
      }
    },
    props: {
        isVisible: {
            required: true,
            type: Boolean,
            default: false,
        },
        data: {
            required: true,
            type: Object,

        },
        reasons:{
           required: true,
            type: Array,
        },
        type:{
          type: String
        },
        time:{
            required: true,
            type: Number,
            default: 0,
        }
    },
        components: {
        AppModal,
        AppTitle,
        AppSvgIcon,
    },
    methods: {
        bpHighColor(key) {
            return {
                'yellowText': (key > 140 && key < 160) || (key > 60 && key < 100),
                'redText': key >= 160
            }
        },
        bpLowColor(key) {
            return {
                'yellowText': (key >= 90 && key <= 99) || (key < 60),
                'redText': key >= 100
            }
        },
        alcoholColor(key) {
            return {
                'yellowText': (key > 0 && key <= 0.16),
                'redText': key > 0.16
            }
        },
        bpTemp(key) {
            return {
                'yellowText': (key > 37 && key <= 37.9),
                'redText': key >= 38
            }
        },
        sendResultData() {
          console.log('refuse user');
          console.log(this.reasonText);
          //this.resetForm();
          this.$emit('close');
          this.$emit('closeReasons');
          this.$emit('setRefuseText', this.reasonText);
          this.$emit('sendReasons');
        },
        closeModal() {
          console.log('refuse user');
          //this.resetForm();
          this.$emit('close');
        },
    },
    watch: {
      time(){
         this.reasonText = ''
      }
    }
}
</script>
<style lang="scss">
.refuseCauseHeader{
  letter-spacing: 0.04em;
text-transform: uppercase;
font-size: 14px;
color: #000000;
}
  .refuseCause{
    font-size: 14px;
    color: #fff;
    background: #59658D;
border-radius: 4px;
padding: 4px 14px;
  }
  .modal-refuse{
    width: 771px;
  }
</style>
