import axios from '@/api/axios';

const updateAutoDoctor = (credentials) => axios.post('/auto_doctor', credentials); // update or create

const getAutoDoctor = () => axios.get('/auto_doctor/info');

export default {
  getAutoDoctor,
  updateAutoDoctor,
};
