<template lang="pug">
  transition(name="fade" mode="out-in")
    app-modal(
      v-if="isVisible"
      @close="closeModal"
      :without-close-btn="true"
      :classes="'exam-detail-modal large'"
    )
      template(v-slot:body)
        .exam-detail-modal__header.mb-4
          .row.align-items-center.g-4
            .col-14.col-md-7
              .d-md-none.d-flex.align-items-center.mb-3
                ul.text-family--light
                  li
                    span(v-if="data.exam_date") {{ data.exam_date }}
                    span(v-else) {{ toLocalDate(data.exam_datetime) }}
        
                    span.ms-3(v-if="data.exam_time") {{ data.exam_time }}
                    span.ms-3(v-else) {{ toLocalTime(data.exam_datetime) }}
                    
                button(
                  @click="closeModal"
                  type="button"
                  class=['d-flex', 'ms-auto']
                  aria-label="Close modal"
                )
                  span.d-flex
                    app-svg-icon(name="close")
                  
              .d-flex.align-items-center
                app-avatar(
                  :src="data.patient_avatar"
                  :classes="'square extra-large'"
                  :viewPhoto="true"
                  @openGallery="openGallery"
                )
                ul.ms-4.text-family--light
                  li.mb-2.d-none.d-md-inline-block
                    span(v-if="data.exam_date") {{ data.exam_date }}
                    span(v-else) {{ toLocalDate(data.exam_datetime) }}

                    span.ms-3(v-if="data.exam_time") {{toLocalTimeExam(data.exam_date_time)}}

                  li.mb-2 {{ data.exam_number }} {{ data.patient_full_name }}
                  li {{ data.email }}

            .col-14.col-md-7
              .d-flex.align-items-center
                .exam-detail-modal__info.text-size--small
                  transition(name="fade" mode="out-in")
                    .row.align-items-center.justify-content-center.g-3.g-sm-5(:key="data.id || data.exam_id")
                      .col-auto
                        .d-flex.align-items-center
                          span.me-2.me-sm-3
                            app-svg-icon(name="pulse")
                          span(v-if="data.bp_high && data.bp_low")
                           span(:class="bpHighColor(data.bp_high)") {{ data.bp_high }} /
                           span(:class="bpLowColor(data.bp_low)") {{ data.bp_low }}
                          span(v-else) &#8212;
                      .col-auto
                        .d-flex.align-items-center
                          span.me-2.me-sm-3
                            app-svg-icon(name="cardio")
                          span(v-if="data.heart_rate") {{ data.heart_rate }}
                          span(v-else) &#8212;
                      .col-auto
                        .d-flex.align-items-center
                          span.me-2.me-sm-3
                            app-svg-icon(name="temp")
                          span(v-if="data.body_temp" :class="bpTemp(data.body_temp)") {{ data.body_temp }}&nbsp;c&#176;
                          span(v-else) &#8212;
                      .col-auto
                        .d-flex.align-items-center
                          span.me-2.me-sm-3
                            app-svg-icon(name="breathalyzer")
                          span(v-if="data.alcohol || data.alcohol === 0" :class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л
                          span(v-else) &#8212;
                  
                
                button(
                  @click="closeModal"
                  type="button"
                  class=['d-none d-md-flex', 'ms-4']
                  aria-label="Close modal"
                )
                  span.d-flex
                    app-svg-icon(name="close")
          
        
        .exam-detail-modal__content
          // map
          template(v-if="type === 'map'")
            .exam-detail-modal__map.profile-map
              app-loading(v-if="isLoading")
              transition(name="fade" mode="out-in")
                yandex-map(
                  :key="data.id || data.exam_id"
                  :settings="v.map.default"
                  :options="map.options"
                  :coords="[data.lat, data.long]"
                  :scroll-zoom="false"
                  :init-without-markers="false"
                  :zoom="map.zoom"
                  :controls="map.controls"
                  :behaviors="map.behaviors"
                  @map-was-initialized="initializedMap"
                )
                  ymap-marker(
                    :marker-id="data.id || data.exam_id"
                    :coords="[data.lat, data.long]"
                    :icon="map.marker"
                  )
                    app-map-balloon(slot="balloon" :data="[{device: data.device_serial, coords: [data.lat, data.long]}]")
          // video
          template(v-if="type === 'video'")
            .exam-detail-modal__video
              app-loading(v-if="isLoading")
              transition(name="fade" mode="out-in")
                video-player(
                  :key="data.id || data.exam_id"
                  :options="video"
                )
          // navigation
          .d-flex.align-items-center(v-if="isSlider")
            button(
              v-if="navigation.prev"
              type="button"
              :disabled="isLoading"
              @click.prevent="slideTo(navigation.prev)"
              class=['exam-detail-modal__prev-btn', 'me-auto d-flex align-items-center flex-xl-column align-items-xl-start', 'text-family--light link--accent']
            )
              span.flex-shrink-0.me-3.me-xl-0.mb-xl-3
                app-svg-icon(name="circle-left-arrow")
              span.d-flex.flex-column
                span(v-if="navigation.prev.exam_date") {{ navigation.prev.exam_date }}
                span(v-else) {{ toLocalDate(navigation.prev.exam_datetime) }}
  
                span.mt-1(v-if="navigation.prev.exam_time") {{ navigation.prev.exam_time }}
                span.mt-1(v-else) {{ toLocalTime(navigation.prev.exam_datetime) }}
              
            button(
              v-if="navigation.next"
              type="button"
              :disabled="isLoading"
              @click.prevent="slideTo(navigation.next)"
              class=['exam-detail-modal__next-btn', 'ms-auto d-flex align-items-center flex-row-reverse flex-xl-column align-items-xl-start', 'text-family--light link--accent']
            )
              span.flex-shrink-0.ms-3.ms-xl-0.mb-xl-3
                app-svg-icon(name="circle-right-arrow")
              span.d-flex.flex-column
                span(v-if="navigation.next.exam_date") {{ navigation.next.exam_date }}
                span(v-else) {{ toLocalDate(navigation.next.exam_datetime) }}
                
                span.mt-1(v-if="navigation.next.exam_time") {{ navigation.next.exam_time }}
                span.mt-1(v-else) {{ toLocalTime(navigation.next.exam_datetime) }}

</template>

<script>
import {mapState} from 'vuex';
import filesApi from '@/api/files';

import {toLocalDate, toLocalTime, sortBy, toLocalTimeExam} from '@/helpers/utils';
import variables from '@/helpers/variables';

import is from 'is_js';
import {yandexMap, ymapMarker} from 'vue-yandex-maps';
import {videoPlayer} from 'vue-video-player';

import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
import AppLoading from '@/components/common/Loading';
import AppMapBalloon from '@/components/profile/common/map/MapBalloon';

export default {
  name: 'AppExamDetailModal',
  components: {
    AppModal,
    AppSvgIcon,
    AppAvatar,
    AppLoading,
    AppMapBalloon,
    yandexMap,
    ymapMarker,
    videoPlayer,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    isSlider: {
      type: Boolean,
      required: false,
      default: false,
    },
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      required: true,
      default: 'map',
    },
  },
  data() {
    return {
      v: variables,
      isLoading: true,
      map: {
        ymaps: null,
        instance: null,
        zoom: 16,
        controls: ['zoomControl'],
        behaviors: ['default', 'dblClickZoom', 'scrollZoom', 'multiTouch'],
        marker: {
          layout: 'default#imageWithContent',
          imageHref:
            'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMzUiIHZpZXdCb3g9IjAgMCAyOCAzNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI4IDE0LjcwNTlDMjggMjUuMjk0MSAxNCAzNSAxNCAzNUMxNCAzNSAwIDI1LjI5NDEgMCAxNC43MDU5QzAgNi41ODQwNSA2LjI2ODAxIDAgMTQgMEMyMS43MzIgMCAyOCA2LjU4NDA1IDI4IDE0LjcwNTlaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXIpIi8+CjxjaXJjbGUgY3g9IjE0LjAwMDIiIGN5PSIxNCIgcj0iNy44NCIgZmlsbD0id2hpdGUiLz4KPGRlZnM+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9IjE0IiB5MT0iLTIzLjQ4NjgiIHgyPSIzMi4xMzIiIHkyPSItMjEuMzEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0VDOUQyNSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiNFQzg1MjUiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K',
          imageSize: [28, 35],
          imageOffset: [-14, -35],
          content: '',
          contentOffset: [8, 5],
          contentLayout:
            '<span style="text-align: center; font-size: 0.625rem">$[properties.iconContent]</span>',
        },
      },
      video: {
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{type: 'video/mp4', src: ''}],
        poster: '',
      },
      toLocalDate,
      toLocalTime,
    };
  },
  computed: {
    ...mapState({
      patients: (state) => state.patients.data,
    }),
    patient() {
      if (this.patients)
        return this.patients.result.filter((el) => el.login === this.data.patient_login)[0];
      else return false;
    },
    navigation() {
      if (this.patient && this.patient.exams) {
        const sortedExams = sortBy(this.patient.exams, 'exam_datetime');

        const currentId = this.data.exam_id || this.data.id;
        const currentIndex = sortedExams.findIndex((value) => value.exam_id === currentId);

        const prev = sortedExams[currentIndex - this.setOffset('prev', currentIndex, sortedExams)];
        const next = sortedExams[currentIndex + this.setOffset('next', currentIndex, sortedExams)];

        return {prev, next};
      } else return false;
    },
  },
  watch: {
    data(value) {
      if (value) {
        this.isLoading = true;
        if (this.type === 'video') this.getVideo(this.data.exam_video);
      }
    },
  },
  methods: {
    initializedMap(instance) {
      this.isLoading = false;
      this.map.instance = instance;
      // eslint-disable-next-line no-undef
      ymaps.ready(() => (this.map.ymaps = ymaps));
    },
    closeModal() {
      this.$emit('close');
    },
    getVideo(hash) {
      filesApi.getVideo(hash).then((url) => {
        this.video.sources[0].src = url;
        this.isLoading = false;
      });
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
    slideTo(data) {
      this.$emit('slideTo', this.type, data);
    },
    setOffset(direction, currentIndex, exams) {
      let offset = 1;

      for (let i = 1; i < exams.length; i++) {
        const element = exams[direction === 'next' ? currentIndex + i : currentIndex - i];

        if (is.existy(element)) {
          if (this.type === 'video') {
            if (!is.existy(element.exam_video)) ++offset;
            else break;
          } else {
            if (!is.existy(element.lat) && !is.existy(element.long)) ++offset;
            else break;
          }
        } else break;
      }

      return offset;
    },
        bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),  
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<=99)|| (key<60),  
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
      bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
  },
};
</script>

<style lang="scss">
.exam-detail-modal {
  background-color: transparent;
  color: get($colors, white);
  box-shadow: none;
  border-radius: 0;
  padding: 0;

  .modal__close-button {
    right: 0;
    top: 0;
  }
  &__info {
    width: 100%;
    border-radius: $border-radius-lg;
    background-color: get($colors, gray);
    box-shadow: get($shadows, default);
    padding: $spacer;
    color: get($colors, black);

    svg {
      fill: get($colors, blue-dark);
      width: $spacer * 2;
      height: $spacer * 2;
    }
  }
  &__content {
    position: relative;
    width: 100%;
  }
  &__map,
  &__video {
    position: relative;
    width: 100%;
    height: $spacer * 50;
    border: $border-width solid get($colors, blue);
    background-color: get($colors, gray);
    border-radius: $border-radius-lg/2;
    box-shadow: get($shadows, default);
    overflow: hidden;

    @include mediaToDown($md) {
      height: $spacer * 30;
    }
  }
  &__prev-btn,
  &__next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: get($colors, white);
    text-align: left;

    @include mediaToDown($xl) {
      position: static;
      transform: none;
    }
    &[disabled] {
      pointer-events: none !important;
      opacity: 0.5 !important;
    }
    svg {
      width: $spacer * 2.4;
      height: $spacer * 2.4;
    }
  }
  &__prev-btn {
    right: 100%;
    padding-right: $spacer * 2.5;

    @include mediaToDown($xl) {
      padding-right: 0;
      padding-top: $spacer * 2;
    }
  }
  &__next-btn {
    left: 100%;
    padding-left: $spacer * 2.5;

    @include mediaToDown($xl) {
      padding-left: 0;
      padding-top: $spacer * 2;
    }
  }
}
</style>
