<template lang="pug">
  .profile-patients
    .profile-patients__header
      .row.align-items-center
        .col-14.col-md-auto
          app-title(
            :type="'large'"
            :classes="'text-center'"
          ) Пользователи

        .col-auto.ms-auto.d-none.d-xl-block
          button(
            type="button"
            class=['button button--border-blue button--icon']
            @click="openModal('createPatients')"
          )
            .d-flex.align-items-center.justify-content-center
              app-svg-icon(name="plus-thin")

    .profile-patients__content.mt-20.position-relative
      app-loading(v-if="isLoading")
      app-error-message(v-if="error" :message="error")

      transition(name="translucent")
        div(v-if="patients")
          template(v-if="patients.result.length")
            .d-xl-none.d-flex.align-items-center.mb-4
              app-select(
                @select="sortBySelect"
                :options="sortOptions"
                :placeholder="'выберите...'"
              )
              button(
                type="button"
                class=['button button--border-blue button--icon', 'ms-auto']
                @click="openModal('createPatients')"
              )
                .d-flex.align-items-center.justify-content-center
                  app-svg-icon(name="plus-thin")
              
            .profile-table
              .profile-table__header.d-none.d-xl-block
                .row.align-items-center.gx-4
                  div(v-for="column in columns" :key="column.key" :class="column.class")
                    button(
                      v-if="column.key"
                      type="button"
                      @click="sortingPatients(column.key, column.inverse)"
                      class=['profile-table__sort-button']
                      :class="{'is-active': sortKey === column.key}"
                    )
                      span.d-flex.align-items-center
                        span.text-overflow(:title="column.caption") {{ column.caption }}
                        span.d-flex.ms-3
                          app-svg-icon(name="down-arrow")

                    span.text-overflow(v-else-if="column.caption") {{ column.caption }}

              ul.profile-table__body(id="patient-exams")
                li.profile-table__row(v-for="patient in patients.result" :key="patient.code")
                  app-client-patient-card(
                    :data="patient"
                    :classes="'d-none d-xl-block'"
                    @openModal="openExamDetailModal"
                    @openGallery="openGallery"
                    @openCertificate="openCertificate"
                    @delete="openDeletePatientModal"
                    @change="openChangePatientModal"
                    @changeAvatar="openChangeAvatarModal"
                    @changeSignature="openChangeSignatureModal"
                  )
                  router-link(:to="{name: 'patient-detail', params: {id: patient.code}}" class=['d-block d-xl-none'])
                    app-admin-patient-card(
                      :data="patient"
                      @openModal="openExamDetailModal"
                    )

            .d-flex.justify-content-center.mt-5
              app-pagination(
                :total="pagination.total"
                :limit="pagination.limit"
                :current-page="currentPage"
                :url="baseUrl"
              )
    
          template(v-else)
            .row.flex-column.align-items-start.g-4
              .col-auto.col-md-14.align-self-center
                .text-size--small.text-color--blue Пользователи не найдены
              .col-14.col-md-auto
                button(
                  type="button"
                  class=['button button--middle button--accent', 'w-100']
                  @click="openModal('createPatients')"
                )
                  span.button__content
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="plus-thin")
                    span Добавить пользователя


    // certificate modal
    app-certificate-modal(
      :is-visible="isModalVisible.certificate"
      :data="certificate"
      @close="closeModal('certificate')"
    )
    // detail exam modal
    app-exam-detail-modal(
      :is-slider="true"
      :is-visible="isModalVisible.examDetail"
      :data="currentExam.data"
      :type="currentExam.type"
      @close="closeModal('examDetail')"
      @openGallery="openGallery"
      @slideTo="openExamDetailModal"
    )
    // create patients modal
    app-create-patients-modal(
      :is-visible="isModalVisible.createPatients"
      @open="openModal('createPatients')"
      @close="closeModal('createPatients')"
      @openGallery="openGallery"
      @success="getPatients(pagination.limit, offset)"
    )
    // change patient modal
    app-change-patient-modal(
      :patient="currentPatient"
      :is-visible="isModalVisible.changePatient"
      @close="closeModal('changePatient')"
      @success="successChangePatient('changePatient')"
    )
    // delete patient modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deletePatient" @close="closeModal('deletePatient')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deletePatient')"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deletePatient(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! При удалении пользователя удалятся все его осмотры.<br>Отменить действие нельзя.

    // gallery
    app-gallery(
      :is-visible="isModalVisible.gallery"
      :options="gallery"
      @close="closeGallery"
      @openEditModal="openModal"
      @openDeleteModal="openModal"
    )
    // change avatar modal
    app-patient-change-avatar-modal(
      ref="changeAvatar"
      :patient="currentPatient"
      :is-visible="isModalVisible.changeAvatar"
      @success="successChangePatient('changeAvatar')"
      @close="closeModal('changeAvatar')"
    )
    // change signature modal
    app-patient-change-signature-modal(
      ref="changeSignature"
      :patient="currentPatient"
      :is-visible="isModalVisible.changeSignature"
      @success="successChangePatient('changeSignature')"
      @close="closeModal('changeSignature')"
    )
    // delete avatar modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteAvatar" @close="closeModal('deleteAvatar')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить фотографию пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteAvatar')"
                class=['button', 'button--danger', 'form__button']
              )
                .button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteAvatar(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                .button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

    // delete signature modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.deleteSignature" @close="closeModal('deleteSignature')")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Удалить подпись пользователя?
            p.text-color--blue.mt-3 {{ currentPatient.full_name }}

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('deleteSignature')"
                class=['button', 'button--danger', 'form__button']
              )
                .button__content Не удалять
            .col-7.col-md-auto
              button(
                type="button"
                @click="deleteSignature(currentPatient)"
                class=['button', 'button--border-blue', 'form__button']
              )
                .button__content Да, удалить

          p.text-color--blue.text-size--small.text-center.mt-4 Внимание! Отменить действие нельзя.

    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") {{ successMessage }}
            p.text-color--blue.mt-3 Ф.И.О. пользователя: {{ currentPatient.full_name }}.

          .d-flex.justify-content-center.mt-5
            button(
              @click="closeModal('success')"
              type="button"
              class=['button button--accent']
            )
              .button__content Назад в список
    // filter
    filter-users(
      ref="filter"
      :options="filterOptions"
      :is-open="isFilterOpen"
      :is-filtered="isFiltered"
      :count="examsCount"
      @getCount="getCountFilter"
      @open="openFilter"
      @close="closeFilter"
      @clear="clearFilter"
      @submit="submitFilter"
    )
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as patientsActionTypes} from '@/store/modules/patients';

import is from 'is_js';
import variables from '@/helpers/variables';

import AppModal from '@/components/common/Modal';
import AppTitle from '@/components/common/Title';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppLoading from '@/components/common/Loading';
import AppErrorMessage from '@/components/common/ErrorMessage';
import AppPagination from '@/components/common/Pagination';
import AppSelect from '@/components/common/Select';
import AppGallery from '@/components/common/Gallery';

import AppClientPatientCard from '@/components/profile/client/patients/ClientPatientCard';
import AppCreatePatientsModal from '@/components/profile/client/patients/CreatePatientsModal';
import AppAdminPatientCard from '@/components/profile/admin/patients/AdminPatientCard';
import AppExamDetailModal from '@/components/profile/common/exams/ExamDetailModal';
import AppAvatar from '@/components/profile/common/Avatar';
import AppCertificateModal from '@/components/profile/common/CertificateModal';

import AppChangePatientModal from '@/components/profile/common/patients/ChangePatientModal';
import AppPatientChangeAvatarModal from '@/components/profile/common/patients/PatientChangeAvatarModal';
import AppPatientChangeSignatureModal from '@/components/profile/common/patients/PatientChangeSignatureModal';

import {modalsMixin} from '@/mixins/modals';
import {examDetailModalMixin} from '@/mixins/examDetailModal';
import {certificateModalMixin} from '@/mixins/certificateModal';
import {galleryMixin} from '@/mixins/gallery';
import FilterUsers from '@/components/profile/common/FilterUsers';
export default {
  name: 'AppClientPatients',
  components: {
    AppAvatar,
    AppModal,
    AppTitle,
    AppGallery,
    AppSvgIcon,
    AppLoading,
    AppErrorMessage,
    AppPagination,
    AppSelect,
    AppClientPatientCard,
    AppAdminPatientCard,
    AppExamDetailModal,
    AppCreatePatientsModal,
    AppChangePatientModal,
    AppPatientChangeAvatarModal,
    AppPatientChangeSignatureModal,
    AppCertificateModal,
    FilterUsers,
  },
  mixins: [modalsMixin, examDetailModalMixin, certificateModalMixin, galleryMixin],
  data() {
    return {
      v: variables,
      isModalVisible: {
        changeAvatar: false,
        changeSignature: false,
        deleteAvatar: false,
        deleteSignature: false,
        deletePatient: false,
        createPatients: false,
        changePatient: false,
      },
      pagination: {
        limit: 12,
        total: 0,
      },
      sortKey: '',
      sortInverse: false,
      sortOptions: [
        {
          caption: 'дате регистрации',
          value: 'creation_date',
          selected: true,
          inverse: true,
        },
        {
          caption: 'e-mail',
          value: 'email',
          selected: false,
          inverse: false,
        },
        {
          caption: 'ФИО пользователя',
          value: 'full_name',
          selected: false,
          inverse: false,
        },
        {
          caption: 'кол-ву осмотров',
          value: 'exam_count',
          selected: false,
          inverse: true,
        },
        {
          caption: 'кол-ву устройств',
          value: 'devices_count',
          selected: false,
          inverse: true,
        },
      ],
      columns: [
        {
          class: 'col-3',
          caption: 'Фото и e-mail',
          key: 'email',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Телефон',
          key: 'phone',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Дата регистрации',
          key: 'creation_date',
          inverse: true,
        },
        {
          class: 'col-3',
          caption: 'ФИО пользователя',
          key: 'full_name',
          inverse: false,
        },
        {
          class: 'col-2',
          caption: 'Кол-во осмотров',
          key: 'exam_count',
          inverse: true,
        },
        {
          class: 'col-2',
          caption: 'Кол-во устройств',
          key: 'devices_count',
          inverse: true,
        },
      ],
      currentPatient: {},
      successMessage: 'Успешно!',
              filterOptions: {
        patients: true,
        date: true,
        time: true,
        devices: true,
        methods: {
          getData: ['getExams'],
          getCount: 'getCountExams',
        }
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    ...mapState({
      isLoading: (state) => state.patients.isLoading,
      patients: (state) => state.patients.data,
      error: (state) => state.patients.error,
      validationErrors: (state) => state.patients.validationErrors,
    }),
    currentPage() {
      return Number(this.$route.query.page || '1');
    },
    baseUrl() {
      return this.$route.path;
    },
    offset() {
      return this.currentPage * this.pagination.limit - this.pagination.limit;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
    settings() {
      return {
        filters: {
          users: {
            negative: false,
            payload: [this.currentUser.id],
          },
          patients: {
            negative: false,
            payload: [],
          },
        },
        sorting_params: {
          desc: this.sortInverse,
          field: this.sortKey,
        },
      };
    },
  },
  watch: {
    currentPage() {
      this.getPatients(this.pagination.limit, this.offset);
    },
  },
  created() {
    this.getPatients(this.pagination.limit, this.offset);
  },
  methods: {
    getPatients(limit, offset) {
      let data = {
        filters: this.settings.filters,
        paging_params: {
          limit,
          offset,
        },
        sorting_params: this.settings.sorting_params,
      };

      this.$store.dispatch(patientsActionTypes.getPatients, data).then((response) => {
        this.pagination.total = response.total;
        this.reverseSorting();
      });
    },
    sortBySelect({value, inverse}) {
      this.sortOptions.forEach((el) => (el.selected = el.value === value));
      this.sortingPatients(value, inverse);
    },
    sortingPatients(sortKey, inverse) {
      this.sortKey = sortKey;
      this.sortInverse = inverse;

      this.getPatients(this.pagination.limit, this.offset);
    },
    reverseSorting() {
      if (is.not.empty(this.sortKey)) {
        this.columns.forEach((el) => {
          if (this.sortKey === el.key) el.inverse = !el.inverse;
        });
      }
    },
    openDeletePatientModal(data) {
      this.currentPatient = data;
      this.openModal('deletePatient');
    },
    openChangePatientModal(data) {
      this.currentPatient = data;
      this.openModal('changePatient');
    },
    openChangeAvatarModal(data) {
      this.currentPatient = data;
      this.openModal('changeAvatar');
    },
    openChangeSignatureModal(data) {
      this.currentPatient = data;
      this.openModal('changeSignature');
    },
    deletePatient({code}) {
      this.$store
        .dispatch(patientsActionTypes.deletePatient, {
          id: code,
        })
        .then(() => {
          this.successMessage = 'Успешно удалён!';
          this.closeModal('deletePatient');
          this.openModal('success');
          this.getPatients(this.pagination.limit, this.offset);
        });
    },
    deleteAvatar({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientAvatar, formData).then(() => {
        this.successChangePatient('deleteAvatar');
      });
    },
    deleteSignature({code}) {
      const formData = new FormData();
      formData.append('code', code);

      this.$store.dispatch(patientsActionTypes.deletePatientSignature, formData).then(() => {
        this.successChangePatient('deleteSignature');
      });
    },
    successChangePatient(slug) {
      switch (slug) {
        case 'changeAvatar':
          this.successMessage = 'Фотография успешно изменена!';
          break;

        case 'changeSignature':
          this.successMessage = 'Подпись успешно изменена!';
          break;

        case 'deleteAvatar':
          this.successMessage = 'Фотография успешно удалена!';
          break;

        case 'deleteSignature':
          this.successMessage = 'Подпись успешно удалена!';
          break;

        default:
          this.successMessage = 'Данные успешно изменены!';
          break;
      }

      this.closeGallery();
      this.closeModal(slug);
      this.openModal('success');

      this.getPatients(this.pagination.limit, this.offset);
    },
  },
};
</script>

<style scoped lang="scss">
.profile-patients {
  .profile-table {
    &__header {
      padding-right: $spacer * 7;

      @include mediaToDown($xxl) {
        padding-right: $spacer * 4;
      }
    }
  }
}
.profile-patients{
  width: 95%;
}
</style>
