<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(v-if="isVisible" @close="closeForm")
        template(v-slot:body)
          .text-center.mb-5
            app-title(:type="'large'") Добавление врача

          .px-md-4
            form(@submit.prevent="onSubmit" :class="['form', {'is-submitting': isSubmitting}]")
              app-validation-errors(
                v-if="localValidationErrors"
                :validation-errors="localValidationErrors"
                :classes="'mb-3'"
              )
              .row.g-4
                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.firstName.$errors.length }]"
                      type="text"
                      v-model="v$.form.firstName.$model"
                      placeholder="Имя"
                    )
                  ul.form__error(v-if="v$.form.firstName.$errors.length")
                    li(v-for="error of v$.form.firstName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.lastName.$errors.length }]"
                      type="text"
                      v-model="v$.form.lastName.$model"
                      placeholder="Фамилия"
                    )
                  ul.form__error(v-if="v$.form.lastName.$errors.length")
                    li(v-for="error of v$.form.lastName.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  label.form__label
                    input(
                      class=['form__field']
                      type="text"
                      v-model="form.middleName"
                      placeholder="Отчество"
                    )

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.email.$errors.length }]"
                      type="email"
                      v-model="v$.form.email.$model"
                      placeholder="E-mail"
                      autocomplete="nope"
                    )
                  ul.form__error(v-if="v$.form.email.$errors.length")
                    li(v-for="error of v$.form.email.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="error.$validator === 'email'") {{ v.errors.email }}

                .col-14.col-md-7
                  .position-relative
                    .form__hint.d-none.d-md-block
                      app-tooltip(
                        :classes="'text-color--accent'"
                        :placement="'left'"
                        :content="v.errors.password.requirements"
                      )
                    label.form__label
                      input(
                        :class="['form__field', { 'is-error': v$.form.password.$errors.length }]"
                        :type="showPass ? 'text' : 'password'"
                        v-model="v$.form.password.$model"
                        placeholder="Пароль"
                        autocomplete="nope"
                      )
                      span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                        app-svg-icon(v-if="showPass" :name="'eye-open'")
                        app-svg-icon(v-else :name="'eye-close'")

                  ul.form__error(v-if="v$.form.password.$errors.length")
                    li(v-for="error of v$.form.password.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                      template(v-if="v$.form.password.$model")
                        template(v-if="error.$validator === 'minLength'") {{ v.errors.password.minLength }}
                        template(v-if="error.$validator === 'valid'") {{ v.errors.password.valid }}
                        template(v-if="error.$validator === 'containsUppercase'") {{ v.errors.password.containsUppercase }}
                        template(v-if="error.$validator === 'containsLowercase'") {{ v.errors.password.containsLowercase }}
                        template(v-if="error.$validator === 'containsNumber'") {{ v.errors.password.containsNumber }}

                  app-tooltip(
                    :classes="'d-md-none mt-2 text-size--extra-small text-color--accent'"
                    :placement="'right'"
                    :content="v.errors.password.requirements"
                  )
                    template(v-slot:body)
                      span.ms-2 Требования к паролю

                .col-14.col-md-7
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.confirmPassword.$errors.length }]"
                      :type="showPass ? 'text' : 'password'"
                      v-model="v$.form.confirmPassword.$model"
                      placeholder="Повторите пароль"
                      autocomplete="nope"
                    )
                    span(class="form__icon cursor-pointer" @click="showPass = !showPass")
                      app-svg-icon(v-if="showPass" :name="'eye-open'")
                      app-svg-icon(v-else :name="'eye-close'")

                  ul.form__error(v-if="v$.form.confirmPassword.$errors.length")
                    li(v-for="error of v$.form.confirmPassword.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                      template(v-if="v$.form.confirmPassword.$model && error.$validator === 'sameAsPassword'") {{ v.errors.password.sameAs }}

                .col-14.col-md-14
                  .text-size--small.mb-3 Место работы
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.placeOfWork.$errors.length }]"
                      type="text"
                      v-model="v$.form.placeOfWork.$model"
                      placeholder="Название организации"
                    )
                  ul.form__error(v-if="v$.form.placeOfWork.$errors.length")
                    li(v-for="error of v$.form.placeOfWork.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                //new
                .col-14.col-md-7
                  .text-size--small.mb-3 ИНН медицинской организации
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.inn.$errors.length }]"
                      type="text"
                      v-model="v$.form.inn.$model"
                      placeholder="ИНН"
                      v-mask="'##########'"
                    )
                  ul.form__error(v-if="v$.form.placeOfWork.$errors.length")
                    li(v-for="error of v$.form.placeOfWork.$errors" :key="error.$uid")
                      template(v-if="error.$validator === 'required'") {{ v.errors.required }}

                .col-14.col-md-7
                  .text-size--small.mb-3 Документ о медицинском образовании
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.seriesNumber.$errors.length }]"
                      type="text"
                      v-model="v$.form.seriesNumber.$model"
                      placeholder="Серия и номер"
                    )
                .col-14.col-md-7
                  .text-size--small.mb-3 Номер лицензии организации
                  label.form__label
                    input(
                      :class="['form__field', { 'is-error': v$.form.licensenNumber.$errors.length }]"
                      type="text"
                      v-model="v$.form.licensenNumber.$model"
                      placeholder="Номер лицензии"
                    )
                .col-14.col-md-7
                 .row.gx-3
                  .col-14 
                   .text-size--small.mb-3 Дата выдачи и срок окончания лицензии
                  .col-7
                    label(:class="['form__label', { 'is-error': v$.form.dateOfIssue.$errors.length }]")
                      date-picker(
                        v-model="v$.form.dateOfIssue.$model"
                        prefix-class="app"
                        placeholder="Дата"
                        format="DD.MM.YYYY"
                        :lang="datepicker.lang"
                        :editable="true"
                        :default-panel="datepicker.defaultValue.panel"
                        :default-value="new Date()"
                        :disabled-date="disableAfterToday"
                        @change="changeField('birthdate')"
                      )
                  .col-7
                    label.form__label 
                      date-picker(
                        v-model="form.expirationDate"
                        prefix-class="app"
                        placeholder="Дата"
                        format="DD.MM.YYYY"
                        :lang="datepicker.lang"
                        :editable="true"
                        :default-panel="datepicker.defaultValue.panel"
                        :default-value="defaultDate"
                        :disabled-date="disableExpiration"
                        @change="changeField('birthdate')"
                      )
                  //- ul.form__error(v-if="v$.form.seriesNumber.$errors.length")
                  //-   li(v-for="error of v$.form.seriesNumber.$errors" :key="error.$uid")
                  //-     template(v-if="error.$validator === 'required'") {{ v.errors.required }}
                  //-     template(v-if="v$.form.seriesNumber.$model && error.$validator === 'valid'") {{ v.errors.invalid }}

                .col-14
                  .text-size--small.mb-3 Сертификат о подготовке медработника
                  .form__file.d-flex.align-items-center(:class="{ 'is-error': v$.form.file.$invalid && v$.form.$dirty }")
                    input(
                      class="d-none"
                      type="file"
                      name="document"
                      @change="onFileSelected"
                      @click="v$.$touch()"
                      ref="fileInput"
                      accept="application/pdf,image/png,image/jpeg,image/jpg,image/tiff,image/bmp,image/gif"
                    )

                    div(v-if="form.file" class=['form__file-element', 'd-flex align-items-center', 'text-size--small'])
                      span.flex-shrink-0.me-3
                        app-svg-icon(name="document")

                      ul.flex-grow-1.overflow-hidden
                        li.form__file-name.text-color--black {{ form.file.name }}
                        li.mt-1 {{ Number(form.file.size/1000000).toFixed(2) }} mb

                      button(
                        type="button"
                        @click="clearFileInput"
                        class=['form__file-delete', 'flex-shrink-0 ms-3']
                      )
                        app-svg-icon(name="trash")

                    .text-color--blue.text-size--small(v-else) Приложите скан документа

                    button(
                      class=['form__file-button', 'd-flex justify-content-end align-items-center']
                      @click="$refs.fileInput.click()"
                    )
                      app-svg-icon(name="clip")

                  ul.form__error(v-if="v$.form.file.$invalid && v$.form.$dirty")
                    li {{ v.errors.required }}


              .row.g-5.mt-0
                .col-14
                  .mb-4
                    app-title Назначить клиентов
                    p.text-size--small.text-color--blue.mt-2 Назначьте клиентов для проверки их пользователей врачом

                  app-multiple-select(
                    v-if="filters"
                    ref="multipeSelectUsers"
                    :results-view="'detail'"
                    :search-type="'users'"
                    :placeholder="'Введите название организации или ФИО ИП'"
                    @update="updateSelectedUsers"
                  )
                  ul.form__error(v-if="v$.form.users.$invalid && v$.form.$dirty")
                    li {{ v.errors.required }}

                .col-14
                  button(
                    type="submit"
                    :disabled="v$.form.$invalid"
                    class=['button', 'button--accent', 'form__button']
                  )
                    span.button__content Добавить врача
    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeSuccessModal")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Новый врач добавлен

          .row.justify-content-center.mt-5
            .col-14.col-md-7
              button(
                @click="closeSuccessModal"
                type="button"
                class=['button button--accent', 'w-100']
              )
                span.button__content На страницу врачей

</template>

<script>
import {mapState} from 'vuex';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';

import variables from '@/helpers/variables';

import useVuelidate from '@vuelidate/core';
import {required, requiredIf, minLength, sameAs, email} from '@vuelidate/validators';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppMultipleSelect from '@/components/common/MultipleSelect';
import AppTooltip from '@/components/common/Tooltip';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppValidationErrors from '@/components/common/ValidationErrors';

import {modalsMixin} from '@/mixins/modals';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/ru';

import {mask} from 'vue-the-mask';
import {
    convertDate,
} from '@/helpers/utils';
export default {
  name: 'AppCreateDoctorModal',
  components: {
    AppTitle,
    AppModal,
    AppTooltip,
    AppSvgIcon,
    AppMultipleSelect,
    AppValidationErrors,
    DatePicker,
  },
  directives: {
    mask,
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      v: variables,
      form: {
        firstName: null,
        lastName: null,
        middleName: '',
        email: null,
        password: null,
        confirmPassword: null,
        placeOfWork: null,
        seriesNumber: null,
        file: null,
        users: [],
        inn: null,
        licensenNumber:null,
        dateOfIssue:'',
        expirationDate:''
      },
        datepicker: {
        lang: {
          formatLocale: {
            firstDayOfWeek: 1,
          },
          monthBeforeYear: false,
        },
        defaultValue: {
          panel: 'year',
          year: '1980',
        },
      },
      showPass: false,
      localValidationErrors: null,
    };
  },
  validations() {
    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        password: {
          required,
          valid: function (value) {
            return this.v.regexes.password.test(value);
          },
          containsUppercase: function (value) {
            return /[A-Z]/.test(value);
          },
          containsLowercase: function (value) {
            return /[a-z]/.test(value);
          },
          containsNumber: function (value) {
            return /[0-9]/.test(value);
          },
          minLength: minLength(6),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs(this.form.password),
        },
        placeOfWork: {
          required,
        },
        seriesNumber: {
          required,
          valid: function (value) {
            return this.v.regexes.alphaNumeric.test(value);
          },
        },
        file: {
          required: requiredIf(() => this.form.file === null),
        },
        users: {
          required,
        },
        licensenNumber:{
          required
        },
        inn:{
          required,
          minLength: minLength(10),
        },
        dateOfIssue:{
          required,
        },
        expirationDate:{
          required,
        }
      },
    };
  },
  computed: {
    ...mapState({
      isSubmitting: (state) => state.doctors.isSubmitting,
      validationErrors: (state) => state.doctors.validationErrors,
      filters: (state) => state.filters.data,
    }),
  },
  methods: {
    updateSelectedUsers(data) {
      this.form.users = data.map((el) => el.value);
    },
    onFileSelected({target}) {
      this.form.file = target.files[0];
    },
    clearFileInput() {
      this.form.file = null;
      this.$refs.fileInput.value = '';
    },
    resetForm() {
      this.v$.$reset();

      for (const key in this.form) {
        this.form[key] = null;
      }

      this.form.users = [];
      this.$refs.multipeSelectUsers.clear();

      this.localValidationErrors = null;
      this.clearFileInput();
    },
    closeForm() {
      this.resetForm();
      this.$emit('close');
    },
    closeSuccessModal() {
      this.$emit('success');
      this.closeModal('success');
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        const formData = new FormData();

        formData.append('first_name', this.form.firstName);
        formData.append('middle_name', this.form.middleName);
        formData.append('last_name', this.form.lastName);
        formData.append('email', this.form.email);
        formData.append('password', this.form.password);
        formData.append('place_of_work', this.form.placeOfWork);
        formData.append('series_number', this.form.seriesNumber);
        formData.append('certificate', this.form.file);
        formData.append('clients', this.form.users);

        formData.append('licence_number', this.form.licensenNumber);
        formData.append('med_organisation_inn', this.form.inn);
        formData.append('licence_data_reciving',	 convertDate(this.form.dateOfIssue));
        formData.append('licence_data_ending',  convertDate(this.form.expirationDate));

       
        



        this.$store
          .dispatch(doctorsActionTypes.createDoctor, formData)
          .then(() => {
            this.openModal('success');
            this.closeForm();
          })
          .catch(() => (this.localValidationErrors = this.validationErrors));
      }
    },
    //Отключаем лишние даты
    disableAfterToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today;
    },
    disableExpiration(date){
      console.log('this.form.dateOfIssue',this.form.dateOfIssue)
      return date<this.form.dateOfIssue;
    },
    defaultDate() {
            return new Date().setYear(this.datepicker.defaultValue.year);
    },
    changeField(){
      console.log('changed')
    }
  },
};
</script>
