import axios from 'axios';
//Получаем данные для диграммы по давлению

export default {
    state: {
        inquiries:[]
    },
    mutations: {
        setInquiries(state, payload) {
          state.inquiries = payload;
    }
},
    actions: {
        async actionGetDoc({ commit }, data) {
            try {
                var res = await axios({
                    url: '/reports/med_and_alco',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }

            console.log('result', res.data);

            commit('setInquiries', {
                inquiries: res.data,
              });
            return res;
        },
        async actionGetOutRef({ commit }, data) {
            try {
                var res = await axios({
                    url: '/reports/med',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }

            console.log('result', res.data);

            commit('setInquiries', {
                inquiries: res.data,
              });
            return res;
        },
        async actionGetOutDir({ commit }, data) {
            try {
                var res = await axios({
                    url: '/reports/alco',
                    method: 'POST',
                    data: data,
                });
            } catch (error) {
                alert(error)
            }

            console.log('result', res.data);

            commit('setInquiries', {
                inquiries: res.data,
              });
            return res;
        },
        async actionExportXls(_, data) {
           // delete data.filters.paging_params
            try {
                var res = await axios({
                    url: '/reports/med/export',
                    method: 'POST',
                    data: data,
                    responseType: 'blob'
                });
            } catch (error) {
                alert(error)
            }
            return res.data;
        },
        async actionExportAlcoXls(_, data) {
           // delete data.filters.paging_params
            try {
                var res = await axios({
                    url: '/reports/alco/export',
                    method: 'POST',
                    data: data,
                    responseType: 'blob'
                });
            } catch (error) {
                alert(error)
            }
            return res.data;
        },
    }
}
