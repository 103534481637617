import settingsApi from '@/api/settings';

const state = {
  data: null,
  isLoading: false,
  error: null,
};

export const mutationTypes = {
  getSettingsStart: '[settings] get data start',
  getSettingsSuccess: '[settings] get data success',
  getSettingsFailure: '[settings] get data failure',
};

export const actionTypes = {
  getSettings: '[settings] get data',
};

export const gettersTypes = {};

const getters = {};

const mutations = {
  [mutationTypes.getSettingsStart](state) {
    state.isLoading = true;
    state.data = null;
  },
  [mutationTypes.getSettingsSuccess](state, payload) {
    state.isLoading = false;
    state.data = payload;
  },
  [mutationTypes.getSettingsFailure](state) {
    state.isLoading = false;
  },
};

const actions = {
  [actionTypes.getSettings](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSettingsStart);
      settingsApi
        .getSettings()
        .then((response) => {
          context.commit(mutationTypes.getSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getSettingsFailure,
            result.response.data.message
          );
          reject(result.response.data.message);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
