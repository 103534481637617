<template lang="pug">
  .profile-mobile-tab-bar__content.d-flex.align-items-center(v-click-outside="closeMenu")
    router-link(
      :to="{name: 'dashboard'}"
      :class="{'is-active': $route.name === 'dashboard'}"
      class=['d-flex align-items-center justify-content-center', 'profile-mobile-tab-bar__logo']
    )
      transition(name="fade")
        img(v-if="$route.name === 'dashboard'" src="@/assets/img/logo-mobile.svg" alt="SD")
        img(v-else src="@/assets/img/logo-mobile-gray.svg" alt="SD")
      
    .profile-mobile-tab-bar__menu
      .row.align-items-center.justify-content-around.flex-nowrap.gx-4.gx-md-5
        .col-auto
          router-link(
            :to="{name: 'requests'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block', 'position-relative']
            active-class="is-active"
          )
            app-svg-icon(name="add-circle")
            transition(name="translucent")
              span.profile-mobile-tab-bar__menu-notify(v-if="requestsPending.length")
        
        .col-auto
          router-link(
            :to="{name: 'devices'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            app-svg-icon(name="smartwatch" view-box="0 0 17 24")
        
        .col-auto
          router-link(
            :to="{name: 'exams'}"
            :class="['profile-mobile-tab-bar__menu-link', 'd-block', `${$route.name.indexOf('map') > -1 ? 'is-active' : ''}`]"
            active-class="is-active"
          )
            app-svg-icon(name="diary")

        .col-auto.d-none.d-md-block
          router-link(
            :to="{name: 'users'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            app-svg-icon(name="users")

        .col-auto.d-none.d-md-block
          router-link(
            :to="{name: 'patients'}"
            class=['profile-mobile-tab-bar__menu-link', 'd-block']
            active-class="is-active"
          )
            app-svg-icon(name="patients")

        .col-auto.d-md-none
          button(
            type="button"
            @click="menuIsOpen = !menuIsOpen"
            class=['profile-mobile-tab-bar__menu-button']
            :class="[{'is-open': menuIsOpen}, {'is-active': $route.name === 'users' || $route.name === 'user-detail' ||  $route.name === 'patients' ||  $route.name === 'patient-detail'}]"
          )
            span
              app-svg-icon(name="menu-points")
        
    
    
    transition(name="fade")
      ul.profile-mobile-tab-bar__menu-hidden(v-show="menuIsOpen")
        li.profile-mobile-tab-bar__menu-hidden-item
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'users'}" :classes="'d-block text-center'")
              span Клиенты
  
        li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'patients'}" :classes="'d-block text-center'")
              span Пользователи

        li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'doctors'}" :classes="'d-block text-center'")
              span Врачи
        li.profile-mobile-tab-bar__menu-hidden-item(@click="menuIsOpen = false")
          div(@click="menuIsOpen = false")
            app-title(:routerLink="{name: 'chart'}" :classes="'d-block text-center'")
              span Отчеты

</template>

<script>
import {mapState} from 'vuex';

import is from 'is_js';
import variables from '@/helpers/variables';

import ClickOutside from 'vue-click-outside';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppTitle from '@/components/common/Title';

export default {
  name: 'AppAdminMobileTabBar',
  components: {
    AppTitle,
    AppSvgIcon,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      v: variables.requests,
      menuIsOpen: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.requests.isLoading,
      requests: (state) => state.requests.data,
      error: (state) => state.requests.error,
    }),
    requestsPending() {
      if (this.requests) {
        return this.requests.filter((el) => el.status_code === this.v.statuses.pending);
      } else return false;
    },
    isTouchDevice() {
      return is.touchDevice();
    },
  },
  methods: {
    closeMenu() {
      this.menuIsOpen = false;
    },
  },
};
</script>
