<template lang="pug">
  div
    transition(name="fade" mode="out-in")
      app-modal(
        v-if="isVisible"
        @close="close"
        :without-close-btn="true"
        :classes="`doctor-exam-detail-modal modal-detail ${isPending && 'is-pending'}`"
      )
        template(v-slot:body)
          .row.gx-3
           .col-12.text-size--middle.text-color--black.mb-3
            span.flex-shrink-0.me-2 №
            span(v-if="data.exam_number") {{data.exam_number}}
            span.text-color--blue(v-else) &#8212;
           .col-2
              button(
                @click="close"
                type="button"
                class=['d-flex', 'ms-auto']
                aria-label="Close modal"
              )
                span.d-flex
                  app-svg-icon(name="close")
           .col-3
            .d-flex.me-4.text-size--middle.text-color--black.mb-2
              span(v-if="data.exam_date") {{data.exam_date}} &nbsp
              span.text-color--blue(v-else) &#8212;
              span(v-if="data.exam_time") {{toLocalTimeExam(data.exam_date_time)}}
              span.text-color--blue(v-else) &#8212;
            .d-block.mb-2 {{ data.exam_type_name }}
            .d-block.mb-3 {{ data.device_owner_full_name }}
            .doctor-exam-detail-modal__avatar
                app-avatar(
                  :src="data.patient_avatar"
                  :classes="'square extra-large'"
                  :viewPhoto="true"
                  @openGallery="openGallery"
                )
            .w-100.text-color--black.mb-4.text-center.pointer.underline.mt-2 АРХИВИРОВАТЬ ФОТО
            .doctor-exam-detail-modal__signature.mb-3
             .doctor-exam-detail-modal__signature-lbl.pe-4 Подпись<br>осмотра
             app-signature(
               v-if="data.signature"
               :sample="data.signature_sample"
               :instance="data.signature"
               :theme="'square'"
               :classes="'large pl130'"
               @openGallery="openGallery"
              )
             .text-size--small.text-color--gray-light(v-else) Нет подписи
            .doctor-exam-detail-modal__signature.mb-4
             .doctor-exam-detail-modal__signature-lbl Подпись<br>оригинал
             app-signature(
               v-if="patient.signature"
               :sample="patient.signature"
               :theme="'square'"
               :classes="'large pl130'"
                    @openGallery="openGallery"
              )
             .text-size--small.text-color--gray-light(v-else) Нет подписи
           .col-11
            .row.gx-3
              .col-7
               .d-flex.me-4.text-size--middle.text-color--black.mb-2
                 span(v-if="data.patient_full_name") {{data.patient_full_name}} 
                 span.text-color--blue(v-else) &#8212;
               .d-block.mb-2
                .d-flex
                 .me-3
                   span Возраст:&#32;
                   span {{ patientAge }}&nbsp;{{ declOfNum(patientAge, ['год', 'года', 'лет']) }} ({{ toLocalDate(data.date_birth) }})    
               .d-block.mb-3 
                span Пол:&#32;
                span {{ data.gender === 2 ? 'Жен.' : 'Муж.' }}
               .doctor-exam-detail-modal__video
                   template(v-if="data.exam_video")
                     app-loading(v-if="video.isLoading")
                     transition(name="fade" mode="out-in")
                       video-player(
                         :key="data.id"
                         :options="video"
                       )
  
                   .text-color--gray-light(v-else)
                     app-svg-icon(name="no-video")
               .title.title--normal.mt-4.mb-2.text-color--black Документы
               .doctor-exam-detail-modal__docs
                  li(v-for="doc in docs" :key="doc.id")
                    a( :href="generateDocLink(doc)") {{ generateDocName(doc) }}
               .title.title--normal.mt-4.mb-2.text-color--black.underline.pointer(@click="showLastExams=!showLastExams") История осмотров
              .col-7
               .doctor-exam-detail-modal__table
                table.table.table-striped
                 thead
                  th Параметры
                  th.text-center.bg-blue.pt-2 Результаты осмотора
                  th.text-center Средние показатели
                  th.text-center Индивидуальные границы
                 tbody
                  tr
                   td АД сист. 
                   td.text-center.bg-blue
                     span(:class="bpHighColor(data.bp_high)") {{ data.bp_high }} 
                   td.text-center {{ patient.avg_bp_high }}
                   td.text-center {{ patient.border_bp_high.min_value }}-{{ patient.border_bp_high.max_value }}
                  tr
                   td АД диаст. 
                   td.text-center.bg-blue 
                    span(:class="bpLowColor(data.bp_low)") {{ data.bp_low }} 
                   td.text-center {{ patient.avg_bp_low }}
                   td.text-center {{ patient.border_bp_low.min_value }}-{{ patient.border_bp_low.max_value }}
                  tr
                   td Пульс 
                   td.text-center.bg-blue
                    span(v-if="data.heart_rate") {{ data.heart_rate }}
                    span.text-color--blue(v-else) &#8212; 
                   td.text-center {{ patient.avg_heart_rate }}
                   td.text-center {{ patient.border_heart_rate.min_value }}-{{ patient.border_heart_rate.max_value }}
                  tr
                   td(class="pe-4") Температура 
                   td.text-center.bg-blue
                    span(v-if="data.body_temp" :class="bpTemp(data.body_temp)") {{ data.body_temp }}&nbsp;c&#176;
                    span.text-color--blue(v-else) &#8212; 
                   td.text-center {{ patient.avg_body_temp }}
                   td.text-center {{ patient.border_body_temp.min_value }}-{{ patient.border_body_temp.max_value }}
                  tr
                   td Алкоголь 
                   td.text-center.bg-blue 
                    span(:class="alcoholColor(data.alcohol)") {{ data.alcohol }}&nbsp;мг&#65279;/&#65279;л 
                   td.text-center {{ patient.avg_alcohol }}
                   td.text-center {{ patient.border_alcohol.min_value }}-{{ patient.border_alcohol.max_value }}
                .row.mt-3
                 .col-4 
                  span Режим отдыха
                 .col-10
                  span.orange(v-if="data.sleep_info=='no'") Не выспался
                  span(v-if="data.sleep_info=='yes'") Выспался
                  span(v-if="!data.sleep_info") -
                .row.mt-3
                 .col-4 
                  span Жалобы 
                 .col-10
                  span.orange(v-if="data.health_complaint") {{ data.health_complaint }}
                  span(v-else) нет
                .row.mt-3
                 .col-14
                  .title.title--normal.mt-2.mb-3.text-color--black Комментарии медработников
                  label.submit-form-comment
                        textarea(
                          :class="['form__field w-100 doctor-exam-detail-modal__text']"
                          placeholder="Добавить комментарий"
                          v-model="currentComment"
                        )
                        button(
                          type="button"
                          @click="createComment()"
                          class=['send-comment']
                        )
                          span.send-comment-content >
                  .comments-section 
                    div(v-for="comment in comments" :key="comment.id") 
                      p.comment-header {{ comment.user.fio_name }} {{ $moment(comment.created_at_utc).format('HH:mm DD.MM.YYYY') }}
                      p {{ comment.comment }}
                

           .col-14
             doctor-exam-detail-modal-table(v-if='showLastExams' :userId="data.id")   
           .col-14
            template(v-if="isPending")
            .row.justify-content-end.gx-5.mt-4
              .col-auto
                button(
                  type="button"
                  @click="openModal('reasons'), admissionValue = 'no', typeAdmission = data.exam_type_id+'no', clearCounter = clearCounter + 1"
                  class=['button', 'button--border-blue']
                )
                  span.button__content.d-flex.align-items-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="wrong")
                    span(v-if="data.exam_type_id==3 || data.exam_type_id==1 || data.exam_type_id==5") Отстранить
                    span(v-else) Не прошел
              .col-auto
                button(
                  v-if="data.exam_type_id==3 || data.exam_type_id==1 || data.exam_type_id==5"
                  type="button"
                  :disabled="isProcess"
                  @click="onSetAdmission('yes'),admissionValue = 'yes', typeAdmission = data.exam_type_id+'yes'"
                  class=['button', 'button--accent']
                )
                 span.button__content.d-flex.align-items-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="allright")
                    span Допустить
                button(
                  v-else
                  type="button"
                  :disabled="isProcess"
                  @click="openModal('reasons'), admissionValue = 'yes', typeAdmission = data.exam_type_id+'yes', clearCounter = clearCounter + 1"
                  class=['button', 'button--accent']
                )
                  span.button__content.d-flex.align-items-center
                    span.d-flex.flex-shrink-0.me-3
                      app-svg-icon(name="allright")
                    span Прошел    
    // select certificate
    transition(name="fade" mode="out-in")
      app-modal(
        v-if="isModalVisible.selectCertificate"
        @close="closeModal('selectCertificate')"
        :classes="'extra-large'"
      )
        template(v-slot:body)
          .row.justify-content-center.mb-5
            .col-14.col-xl-10
              .text-center
                app-title(:type="'large'") Выберите сертификат
                p.text-size--small.text-color--blue.mt-3 Пожалуйста, выберите сертификат для подписи осмотра

          simplebar.doctor-certificate-container.mb-50
            .profile-table(v-if="certificates.all.length")
              .profile-table__header
                .row.align-items-center.g-4
                  .col-3
                    span ФИО
                  .col-3
                    span Владелец
                  .col-4
                    span Удостоверяющий центр
                  .col-4
                    span Срок действия

              ul.profile-table__body
                li.profile-table__row(v-for="(certificate, index) in certificates.all" :key="index")
                  section(
                    @click="onSelectCertificate(certificate)"
                    class=['doctor-certificate-card', 'profile-card no-hover']
                    :class="{'is-selected' : certificate === certificates.selected}"
                  )
                    .row.align-items-center.g-4
                      .col-3
                        span(v-for="(owner, i) in certificate.owner" :key="i")
                          template(v-if="owner.title === 'Фамилия'") {{ owner.description }}
                          template(v-if="owner.title === 'Имя Отчество'") &#32;{{ owner.description }}

                      .col-3
                        span(v-for="(owner, i) in certificate.owner" :key="i")
                          template(v-if="owner.title === 'Владелец'") {{ owner.description }}

                      .col-4
                        span(v-for="(issuer, i) in certificate.issuer" :key="i")
                          template(v-if="issuer.title === 'Удостоверяющий центр'") {{ issuer.description }}

                      .col-4
                        span {{ toLocalDate(certificate.validTo) }}




            .text-color--blue.text-size--small(v-else) Сертификаты не найдены

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('selectCertificate')"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Отмена

            .col-7.col-md-auto
              button(
                v-if="admissionValue === 'yes'"
                type="button"
                :disabled="!certificates.selected || isProcess"
                @click="checkValidCertificate"
                class=['button', 'button--accent', 'form__button']
              )
                span.button__content Допустить

              button(
                v-else
                type="button"
                :disabled="!certificates.selected || isProcess"
                 @click="checkValidCertificate"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Подтвердить
    
    // confirmation modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.confirmation" @close="closeModal('confirmation')")
        template(v-slot:body)
          .row.justify-content-center.mb-5
            .col-14.col-xl-10
              .text-center
                app-title(:type="'large'") Подтвердите недопуск
                p.text-size--small.text-color--blue.mt-3 Если вы отклоните результаты осмотра, пользователь не&nbsp;будет допущен к выполнению трудовых обязанностей

          .row.align-items-center.justify-content-center.g-4
            .col-7.col-md-auto
              button(
                type="button"
                @click="closeModal('confirmation')"
                class=['button', 'button--border-blue', 'form__button']
              )
                span.button__content Отмена

            .col-7.col-md-auto
              button(
                type="button"
                @click="checkValidCertificate"
                class=['button', 'button--danger', 'form__button']
              )
                span.button__content Подтвердить

    // success modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.success" @close="closeModal('success')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'") Заключение успешно сформировано!
            p.text-color--blue.mt-3 ФИО пользователя: {{ data.patient_full_name }}.
        
          .d-flex.justify-content-center.mt-5
            button(
              @click="closeModal('success')"
              type="button"
              class=['button button--accent']
            )
              span.button__content Назад в список

    // error modal
    transition(name="fade" mode="out-in")
      app-modal(v-if="isModalVisible.error" @close="closeModal('error')")
        template(v-slot:body)
          .text-center.pt-4
            app-title(:type="'large'" :classes="'text-color--danger'") {{ error.title }}
            p.text-color--blue.mt-3(v-html="error.description")
    //Reasons modal
    app-doctor-reasons(
      :is-visible="isModalVisible.reasons"
      @close="closeModal('reasons')"
      :data="data",
      :type="typeAdmission"
      @refuseOpen='refuseOpen'
      @cleanReasons='cleanReasons'
      :time='clearCounter'
    )
    // Отказать причина 
    app-doctor-reasons-refuse(
      :is-visible="isModalVisible.refuse"
      @closeReasons="closeModal('reasons')"
      @close="closeModal('refuse')"
      @setRefuseText='setRefuseText'
      @sendReasons="onSetAdmission('no')"
      :type="typeAdmission"
      :data="data"
      :reasons="reasons"
      :time='clearCounter'
    )
</template>

<script>
import {mapState} from 'vuex';
import {mapGetters} from 'vuex';

import {gettersTypes as authGettersTypes} from '@/store/modules/auth';
import {actionTypes as doctorsActionTypes} from '@/store/modules/doctors';
import filesApi from '@/api/files';

import {toLocalDate, toLocalTime, declOfNum, toLocalTimeExam} from '@/helpers/utils';
import variables from '@/helpers/variables';

import is from 'is_js';
import simplebar from 'simplebar-vue';
import {yandexMap, ymapMarker} from 'vue-yandex-maps';
import {videoPlayer} from 'vue-video-player';
import {getUserCertificates, createDetachedSignature, createHash} from 'crypto-pro';

import AppTitle from '@/components/common/Title';
import AppModal from '@/components/common/Modal';
import AppSvgIcon from '@/components/common/SvgIcon';
import AppAvatar from '@/components/profile/common/Avatar';
import AppSignature from '@/components/profile/common/Signature';
import AppLoading from '@/components/common/Loading';
import AppMapBalloon from '@/components/profile/common/map/MapBalloon';
import AppStatus from '@/components/profile/common/Status';

import {modalsMixin} from '@/mixins/modals';

import DoctorExamDetailModalTable from './DoctorExamDetailModalTable';

import AppDoctorReasons from '@/components/profile/doctor/exams/AppDoctorReasons';

import AppDoctorReasonsRefuse from '@/components/profile/doctor/exams/AppDoctorReasonsRefuse';

import patientApi from '@/api/patients';


export default {
  name: 'AppDoctorExamDetailModal',
  components: {
    AppTitle,
    AppModal,
    AppSvgIcon,
    AppAvatar,
    AppSignature,
    AppLoading,
    AppStatus,
    AppMapBalloon,
    yandexMap,
    ymapMarker,
    simplebar,
    videoPlayer,
    DoctorExamDetailModalTable,
    AppDoctorReasons,
    AppDoctorReasonsRefuse
  },
  mixins: [modalsMixin],
  props: {
    isVisible: {
      type: Boolean,
      required: true,
      default: false,
      reasons:false,
    },
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    type: {
      type: String,
      required: true,
      default: 'checked',
    },
  },
  data() {
    return {
      declOfNum,
      v: variables,
      patient: {
        "avg_bp_high": '',
        "avg_bp_low": '',
        "avg_heart_rate": '',
        "avg_body_temp": '',
        "avg_alcohol": '',
        "border_bp_high": {
          "min_value": '',
          "max_value": ''
        },
        "border_bp_low": {
          "min_value": '',
          "max_value": ''
        },
        "border_heart_rate": {
          "min_value": '',
          "max_value": ''
        },
        "border_body_temp": {
          "min_value": '',
          "max_value": ''
        },
        "border_alcohol": {
          "min_value": '',
          "max_value": ''
        },
      },
      docs: [],
      comments: [],
      currentComment: "",
      isModalVisible: {
        selectCertificate: false,
        confirmation: false,
        error: false,
        refuse:false,
        reasons:false
      },
      isProcess: false,
      certificates: {
        all: [],
        selected: null,
      },
      admissionValue: null,
      error: {
        title: 'Произошла ошибка',
        description: 'Пожалуйста, попробуйте ещё раз через некоторое время',
      },
      map: {
        isLoading: false,
        ymaps: null,
        instance: null,
        zoom: 16,
        controls: ['zoomControl'],
        behaviors: ['default', 'dblClickZoom', 'scrollZoom', 'multiTouch'],
        marker: {
          layout: 'default#imageWithContent',
          imageHref:
            'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAxOCAyMyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGw9IiM1MTVGOEYiIGQ9Ik0xOCA5LjQ1Mzc4QzE4IDE2LjI2MDUgOSAyMi41IDkgMjIuNUM5IDIyLjUgMCAxNi4yNjA1IDAgOS40NTM3OEMwIDQuMjMyNiA0LjAyOTQ0IDAgOSAwQzEzLjk3MDYgMCAxOCA0LjIzMjYgMTggOS40NTM3OFoiLz4NCjxjaXJjbGUgY3g9IjkuMDAwOTQiIGN5PSI4Ljk5OTk2IiByPSI1LjA0IiBmaWxsPSJ3aGl0ZSIvPg0KPC9zdmc+DQo=',
          imageSize: [28, 35],
          imageOffset: [-14, -35],
          content: '',
          contentOffset: [8, 5],
          contentLayout:
            '<span style="text-align: center; font-size: 0.625rem">$[properties.iconContent]</span>',
        },
      },
      video: {
        isLoading: false,
        muted: false,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{type: 'video/mp4', src: ''}],
        poster: '',
      },
      showMap:false,
      reasons:[],
      reasonText: '',
      typeAdmission: null,
      clearCounter:1,
      showLastExams:false
    };
  },
  computed: {
    ...mapGetters({
      currentUser: authGettersTypes.currentUser,
    }),
    patientAge() {
      return new Date().getFullYear() - new Date(this.data.date_birth).getFullYear();
    },
    isPending() {
      return is.null(this.data.admission_to_work);
    },
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },
  watch: {
    data(value) {
      if (value) {
        if (value.lat && value.long) this.map.isLoading = true;
        this.getVideo(value.exam_video);
      }
    },
    async isVisible(newVal, oldVal) {
      console.log(newVal, oldVal);
      if (newVal === true){
        console.log(this.data.patient_code);
        const docs = await patientApi.medDocsUser({
              "filters": {
                "patients": {
                  "negative": false,
                  "payload": [
                      this.data.patient_code
                  ]
                },
                "document_status": "confirmed"
              }
            })
        this.docs = docs.data.result;
        console.log(docs.data);
        const patient = await patientApi.getPatients({
              "filters": {
                "patients": {
                  "negative": false,
                  "payload": [
                      this.data.patient_code
                  ]
                }
              }
          })
          this.patient = patient.data.result[0];
          console.log(patient.data);

          let comments = await patientApi.getPatientComment(this.data.patient_code);
          comments = await comments.json();
          console.log(comments);
          this.comments = comments.items;
      } else {
        this.comments = []
      }

    }
  },
  methods: {
    async createComment(){
      await patientApi.createPatientComment(JSON.stringify({'patient_code': this.data.patient_code, 'comment': this.currentComment}));
      this.currentComment = '';
      let comments = await patientApi.getPatientComment(this.data.patient_code);
      comments = await comments.json();
      console.log(comments);
      this.comments = comments.items;
    },
    generateDocLink(doc) {
      return `${this.settings.s3_host}` + doc.file_link
    },
    generateDocName(doc) {
      console.log(doc)
      let typeOpts = {
                'health_certificate' : 'Cправка о состоянии здоровья',
                'injury_certificate': 'Cправка о травме',
                'hti_certificate': 'Справка о результатах ХТИ',
                'medical_exam_conclusion': 'Заключение предварительного или периодический мед. осмотра',
                'medical_exam_certificate': 'Акт медицинского освидетельствования',
                'client_request': 'Официальное обращение клиента',
              }
      return `${typeOpts[doc.document_type]} № ${doc.id} от ${this.$moment(doc.create_datetime).format('DD.MM.YYYY')}`
    },
    reasonsToIds(reasons){
      let result = [];
      let injuries = {
        'Травма: Волосистой области, слева': 32,
        'Травма: Волосистой области, справа': 33,
        'Травма: Волосистой области': 34,
        'Травма: Околоушно-жевательной области, слева': 35,
        'Травма: Околоушно-жевательной области, справа': 36,
        'Травма: Околоушно-жевательной области, слева, справа': 37,
        'Травма: Лобной области, слева': 38,
        'Травма: Лобной области, справа': 39,
        'Травма: Лобной области': 40,
        'Травма: Уха, слева': 41,
        'Травма: Уха, справа': 42,
        'Травма: Уха, слева, справа': 43,
        'Травма: Височной области, слева': 44,
        'Травма: Височной области, справа': 45,
        'Травма: Височной области, слева, справа': 46,
        'Травма: Мягких тканей лица, слева': 47,
        'Травма: Мягких тканей лица, справа': 48,
        'Травма: Мягких тканей лица': 49,
        'Травма: Надбровной области, слева': 50,
        'Травма: Надбровной области, справа': 51,
        'Травма: Надбровной области, слева, справа': 52,
        'Травма: Верхней губы, слева': 53,
        'Травма: Верхней губы, справа': 54,
        'Травма: Верхней губы': 55,
        'Травма: Параорбитальная гематома, слева': 56,
        'Травма: Параорбитальная гематома, справа': 57,
        'Травма: Параорбитальная гематома, слева, справа': 58,
        'Травма: Нижней губы, слева': 59,
        'Травма: Нижней губы, справа': 60,
        'Травма: Нижней губы': 61,
        'Травма: Века, слева': 62,
        'Травма: Века, справа': 63,
        'Травма: Века, слева, справа': 64,
        'Травма: Подбородочной области, слева': 65,
        'Травма: Подбородочной области, справа': 66,
        'Травма: Подбородочной области': 67,
        'Травма: Воспаление века неуточненное, слева': 68,
        'Травма: Воспаление века неуточненное, справа': 69,
        'Травма: Воспаление века неуточненное, слева, справа': 70,
        'Травма: Множественные травмы головы, слева': 71,
        'Травма: Множественные травмы головы, справа': 72,
        'Травма: Множественные травмы головы': 73,
        'Травма: Подглазничной области, слева': 74,
        'Травма: Подглазничной области, справа': 75,
        'Травма: Подглазничной области, слева, справа': 76,
        'Травма: Шеи неуточненная, слева': 77,
        'Травма: Шеи неуточненная, справа': 78,
        'Травма: Шеи неуточненная': 79,
        'Травма: Скуловой области, слева': 80,
        'Травма: Скуловой области, справа': 81,
        'Травма: Скуловой области, слева, справа': 82,
        'Травма: Грудной клетки неуточненная, слева': 83,
        'Травма: Грудной клетки неуточненная, справа': 84,
        'Травма: Грудной клетки неуточненная': 85,
        'Травма: Спинки носа, слева': 86,
        'Травма: Спинки носа, справа': 87,
        'Травма: Спинки носа': 88,
        'Травма: Верхней конечности, слева': 89,
        'Травма: Верхней конечности, справа': 90,
        'Травма: Верхней конечности, слева, справа': 91,
        'Травма: Носа, слева': 92,
        'Травма: Носа, справа': 93,
        'Травма: Носа': 94,
        'Травма: Нижней конечности, слева': 95,
        'Травма: Нижней конечности, справа': 96,
        'Травма: Нижней конечности, слева, справа': 97
      };
      for (let reason of reasons){
        if ('checked' in reason && reason.text !== 'Травма'){
          console.log(reason);
          result.push(reason.id)
        }
        else {
          if (reason.both){
            result.push(injuries[`Травма: ${reason.text}`])
          }
          else if (reason.both == null && reason.left && reason.right) {
            result.push(injuries[`Травма: ${reason.text}, слева, справа`])
          }
          else {
            if (reason.left){
              result.push(injuries[`Травма: ${reason.text}, слева`])
            }
            if (reason.right){
              result.push(injuries[`Травма: ${reason.text}, справа`])
            }
          }
        }
      }
      return result.join(',')
    },
    toLocalDate(date) {
      if (date) {
        return toLocalDate(date);
      } else return false;
    },
    toLocalTime(time) {
      if (time) {
        return toLocalTime(time);
      } else return false;
    },
    toLocalTimeExam(time) {
      if (time) {
        return toLocalTimeExam(time);
      } else return false;
    },
    openGallery(data) {
      this.$emit('openGallery', data);
    },
    openCertificate(data) {
      this.$emit('openCertificate', data);
    },
    initializedMap(instance) {
      this.map.isLoading = false;
      this.map.instance = instance;
      // eslint-disable-next-line no-undef
      ymaps.ready(() => (this.map.ymaps = ymaps));
    },
    getVideo(hash) {
      if (hash) {
        this.video.isLoading = true;

        filesApi.getVideo(hash).then((url) => {
          this.video.sources[0].src = url;
          this.video.isLoading = false;
        });
      }
    },
    createDocument() {
      const content = {
        date: `${this.toLocalDate(this.data.exam_date_time)} ${this.toLocalTime(
          this.data.exam_date_time
        )}`,
        patient: {
          name: this.data.patient_full_name,
          gender: this.data.gender === 2 ? 'Женский' : 'Мужской',
          birthdate: this.toLocalDate(this.data.date_birth),
        },
        bpHigh: this.data.bp_high,
        bpLow: this.data.bp_low,
        heartRate: this.data.heart_rate,
        temperature: this.data.temp,
        alcohol: this.data.alcohol,
        healthComplaint: this.data.health_complaint ? this.data.health_complaint : 'Нет',
        doctorDecision: this.admissionValue === 'yes' ? 'Допущен' : 'Не допущен',
      };

      return JSON.stringify(content);
    },
    onSetAdmission() {
      console.log(this.reasonsToIds(this.reasons));
      // this.admissionValue = value;
      this.getCertificates();
    },
    getCertificates() {
      console.log(this.reasonsToIds(this.reasons));
      getUserCertificates(true)
        .then((certificates) => {
          this.certificates.all = certificates;
          certificates.forEach((certificate, i) => {
            const isLastElement = i === certificates.length - 1;
            this.getOwnerInfoCertificate(certificate, isLastElement);
          });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },
    getOwnerInfoCertificate(certificate, callback = false) {
      certificate
        .getOwnerInfo()
        .then((owner) => {
          certificate.owner = owner;
          certificate
            .getIssuerInfo()
            .then((issuer) => {
              certificate.issuer = issuer;
              if (callback) this.openModal('selectCertificate');
            })
            .catch((error) => {
              this.error.title = error;
              this.openModal('error');
            });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },
    onSelectCertificate(certificate) {
      this.certificates.selected = certificate;
    },
    checkOwnerCertificate() {
      let isValidLastName = false;
      let isValidFirstName = false;
      let isValidMiddleName = true;

      this.certificates.selected.owner.forEach((el) => {
        if (el.title === 'Фамилия') isValidLastName = el.description === this.currentUser.last_name;
        if (el.title === 'Имя Отчество') {
          isValidFirstName = el.description.split(' ')[0] === this.currentUser.first_name;
          if (this.currentUser.middle_name)
            isValidMiddleName = el.description.split(' ')[1] === this.currentUser.middle_name;
        }
      });

      return Boolean(isValidLastName && isValidFirstName && isValidMiddleName);
    },
    checkValidCertificate() {
      if (this.checkOwnerCertificate()) {
        this.certificates.selected
          .isValid()
          .then(() => {
            this.closeModal('selectCertificate');
            if (this.admissionValue === 'no') this.closeModal('confirmation');
            this.isProcess = true;
            this.getSignature();
          })
          .catch((error) => {
            this.error.title = error;
            this.openModal('error');
          });
      } else {
        this.error.title = 'Ошибка при проверке сертификата';
        this.error.description =
          'Ваше ФИО не совпадает с ФИО владельца сертификата.<br>Попробуйте выбрать другой серификат.';

        if (this.admissionValue === 'no') this.closeModal('confirmation');
        this.openModal('error');
      }
    },
    getSignature() {
      const document = this.createDocument();
      const formData = new FormData();

      formData.append('exam_id', this.data.id);
      formData.append('admission_datetime', new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString());
      formData.append('admission_to_work', this.admissionValue);
      formData.append('data', new Blob([document]), 'commission.json');
      formData.append('number_kep', this.certificates.selected.thumbprint);
      formData.append('owner_kep', this.certificates.selected.name);
      formData.append(
        'validity_kep',
        `от ${this.toLocalDate(this.certificates.selected.validFrom)} до ${this.toLocalDate(
          this.certificates.selected.validTo
        )}`
      );
      // если есть checked то просто добавляем id, иначе собираем все причины, берем id из мапы и подкладываем
      //if(this.admissionValue == 'no') {
        formData.append('exam_reasons', this.reasonsToIds(this.reasons));
        formData.append('exam_comment', this.reasonText); 
      // } else {
      //   formData.append('exam_reasons', '');
      //   formData.append('exam_comment', '');
      //   this.reasonText = ''
      // }
      
      this.reasons = [];
      this.reasonsText = '';

      createHash(document)
        .then((messageHash) => {
          createDetachedSignature(this.certificates.selected.thumbprint, messageHash)
            .then((signature) => {
              formData.append('sert_thumbprint', this.certificates.selected.thumbprint);
              formData.append('sign_file', new Blob([signature]), 'commission.sig');
              this.setAdmission(formData);
            })
            .catch((error) => {
              this.error.title = error;
              this.openModal('error');
            });
        })
        .catch((error) => {
          this.error.title = error;
          this.openModal('error');
        });
    },
    setAdmission(formData) {
      this.$store
        .dispatch(doctorsActionTypes.setAdmission, formData)
        .then(() => {
          this.close();
          this.$toast.success('Заключение сформировано')
         // this.openModal('success');
          this.$emit('success');
          // setTimeout(() => {
          //    this.$router.go(0)
          // }, 2000)
        })
        .catch(() => {
          this.close();
          this.error.title = 'Ошибка при формировании заключения';
          this.openModal('error');
        });
    },
    reset() {
      this.isProcess = false;
      this.certificates.selected = null;
      this.admissionValue = null;
    },
    close() {
      this.reset();
      this.$emit('close');
    },
     bpHighColor(key){
        return {
            'yellowText':  (key>140 && key<160)|| (key>60 && key<100),  
            'redText':  key>=160}
    },
    bpLowColor(key){
        return {
            'yellowText':  (key>=90 && key<=99)|| (key<60),  
            'redText':  key>=100}
    },
    alcoholColor(key){
        return {
          'yellowText': (key>0 && key<=0.16),
          'redText': key>0.16
        }
    },
    bpTemp(key){
       return {
          'yellowText': (key>37 && key<=37.9),
          'redText': key>=38
        }
    },
    refuseOpen(items){
      this.reasons = items
      this.openModal('refuse')
    },
    cleanReasons(){
      this.reasons = []
    },
    setRefuseText(reasonText){
      console.log(reasonText);
      this.reasonText = reasonText;
    }
  },
};
</script>

<style lang="scss">
.doctor-exam-detail-modal {
  padding-top: $spacer * 2;
  padding-bottom: $spacer * 4.6;
  border-radius: $border-radius-sm;

  &.is-pending {
    padding-bottom: $spacer * 2;
  }
  &__map,
  &__video,
  &__signature,
  &__info {
    position: relative;
    width: 100%;
    background: get($gradients, blue-light);
    border-radius: $border-radius-lg/2;
    overflow: hidden;
  }
  &__map,
  &__video {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__signature {
    display: flex;
    align-items: center;
  }
  &__video {
    height: $spacer * 28;

    .icon {
      &__no-video {
        width: $spacer * 10;
        height: $spacer * 10;
      }
    }
    @include mediaToDown($md) {
      height: $spacer * 20;
    }
  }
  &__map {
    height: $spacer * 15;
  }
  &__signature {
    background: get($colors, white);
    box-shadow: get($shadows, default);
    height: $spacer * 8.6;
  }
  &__info {
    @include font-size(get($text-sizes, small));
    color: get($colors, black);
    padding: $spacer * 1.5 $spacer * 2;

    .icon {
      &__pulse,
      &__cardio,
      &__temp,
      &__breathalyzer {
        fill: get($colors, blue-dark);
        width: $spacer * 2;
        height: $spacer * 2;
      }
    }
    &-item {
      & + .doctor-exam-detail-modal__info-item {
        padding-top: $spacer * 1.2;
        margin-top: $spacer * 1.2;
        border-top: $border-width solid get($colors, gray);
      }
    }
  }
}
.doctor-certificate-container {
  width: 100%;
  min-height: $spacer * 10;
  max-height: $spacer * 20;

  .profile-table {
    width: 100%;
    padding-bottom: $spacer * 3;
  }
}
.doctor-certificate-card {
  padding-top: $spacer;
  padding-bottom: $spacer;
  border: $border-width solid transparent;
  @include transition();

  &.is-selected {
    border-color: get($colors, turq);
    pointer-events: none;
  }
  @include interaction('mouse') {
    &:hover {
      cursor: pointer;
      background-color: rgba(get($colors, gray), 0.7);
    }
  }
}
.orange{
  color:#EC9225;
}
.fz12{
  font-size: 12px;
}
.doctor-exam-detail-modal__results{
  background: #E5EFFE;
border-radius: 10px 10px 0 0;
height: 100px;
margin-top: 20px;
display: flex;
flex-direction: column;
justify-content: center;
padding-left: 20px;
padding-right: 10px;
}
.showMap{
  display: inline-block;
  cursor: pointer;
  color: #59658D;
  border-bottom: 1px solid  #59658D;
  line-height: 1.1;
  //margin-left: 16px;
}
.doctor-exam-detail-modal__info{
  border-radius: 10px 0 10px 10px;
}
.air-params__item--temp{
  border-radius: 10px 10px 0 0;
 background: #fff;
}
.h33{
  padding-top: 7px !important;
  padding-bottom:7px !important;
  border-radius: 20px !important;

  margin-top: 10px;
}
.refuseDesc{
  border: 1px solid #DFE4F8;
border-radius: 6px;
resize: none;
width: 100%;
height: 66px;
margin-top: 20px;
padding: 14px;
&::placeholder{
 color: #C5CBE1;
 font-size: 14px;
}
}
.doctor-exam-detail-modal__signature{
  position: relative;
}
.doctor-exam-detail-modal__signature-lbl{
  position: absolute;
  left: 20px;
}
.doctor-exam-detail-modal__avatar{
  figure{
    width: 100% !important;
    height: 250px !important;
  }
}
.doctor-exam-detail-modal__docs{
  a {
    display: block;
    text-decoration: underline;
  }
}
.bg-blue{
  background: #DFE4F8;
}
.doctor-exam-detail-modal__table{
  color:#59658D;
  thead{
    font-size: 12px; 
   
    th{
font-weight: 400;
 padding-bottom: 8px;
    }
  }
  tbody{
    tr{
      border-bottom: 1px solid #59658D;
    }
  td{
    font-size: 14px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
   td:first-child{
    font-size: 12px;
   }
  }

}
.doctor-exam-detail-modal__text{
  height: 50px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.pl130{
  padding-left: 90px;
}

.submit-form-comment {
  display: flex;
}

.send-comment {
  margin: 0px 10px;
  font-size: 20px;
  line-height: 20px;
  background: #989FB8;
  border-radius: 10px;
  padding: 0px 15px 2px 15px;
  color: white;
}

.send-comment-content {
  font-size: 40px;
}

.comment-header {
  font-weight: 600;
}

.comments-section {
  margin-top: 20px;
  height: 200px;
  overflow: auto;
  border: 1px solid #DFE4F8;
  border-radius: 6px;
}
</style>
