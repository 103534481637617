<template lang="pug">
  .profile-exams
    component(:is="currentComponent")
</template>

<script>
import AppClientExams from '@/components/profile/client/exams/ClientExams';
import AppAdminExams from '@/components/profile/admin/exams/AdminExams';
import AppDoctorExams from '@/components/profile/doctor/exams/DoctorExams';

export default {
  name: 'AppExams',
  data() {
    return {
      roleMapping: {
        client: AppClientExams,
        admin: AppAdminExams,
        doctor: AppDoctorExams,
      },
    };
  },
  computed: {
    currentComponent() {
      return this.roleMapping[this.$store.state.auth.userRole];
    },
  },
};
</script>
